const translation = {
  userGuiding: {
    common: {
      roleRequired: "Role required to access the page: ",
      roleBasic: "Basic",
      roleAdvanced: "Advanced",
      roleManager: "Manager",
      cancel: "Cancel / exit.",
      save: "Save.",
    },
    introduction: {
      modalTitle: "ESSENTIALbale Overview",
      tabOverview: {
        title: "Overview",
        description:
          "ESSENTIALbale is a system that accurately controls cotton bale laydowns using all quality parameters provided by HVI, plus other parameters provided by the company.",
        description2:
          "The main objective of the system is to eliminate variations between laydowbs, ensuring the production of high quality yarns.The system controls the inventory and quality of the cotton from the arrival of the bales to consumption in the opening room thus allowing the production of large yarn lots without variation in quality.",
        description3:
          "With a unique methodology in the market, the system eliminates the separation of inventory into classes. Only the HVI data of each bale is needed, with this information the software plans and elaborates the laydowns controlling the variation and maintaining the uniformity of the process.",
        description4:
          "ESSENTIALbale is a fully flexible system. The system is designed to adapt to the most diverse ways of working, both in terms of the mixing process and the management of cotton inventorys.",
      },
      tabOverflow: {
        title: "General system flow",
        description: "This is the general system flow:",
      },
      tabUserLevels: {
        title: "User privilege levels",
        description:
          "There are 3 levels of privileges that can be assigned to users in ESSENTIALbale:",
        field1: "Basic",
        fieldDescription1:
          "User has access to the registration function like producers, suppliers, origins, cotton types, piles, HVI file layouts. Basic user can also manage inventory and generate reports.",
        field2: "Advanced",
        fieldDescription2:
          "In addition to all functions contained in the 'Basic' level, the 'Advanced' user can access the registration of parameters, mix types, production lines, production line classes besides to manage all the functions of the mixing process.",
        field3: "Manager",
        fieldDescription3:
          "In addition to all functions contained in the 'Basic' and 'Advenced' levels, the 'Manager' user can create and manage new plants, add new users, manage quality warnings as well as check user action logs.",
        description2: "To create a new user, go to REGISTER > USERS.",
      },
      tabUserPreferences: {
        title: "Language and regional settings",
        description:
          "Each user can define their own language and regio settings to optimize the user experience.",
        step1: "Click on the icon ",
        step1_1:
          "in the upper right corner of any page, then click on the 'Preferences' option.",
        step2: "Define the following settings:",
        field1: "Language",
        fieldDescription1: "Language used on pages.",
        field2: "Region settings",
        fieldDescription2:
          "Region settings for formatting numeric values and dates.",
        field3: "Unit of measure for weight",
        fieldDescription3: "Unit of measure used for formatting weight fields.",
      },
    },

    plant: {
      modalTitle: "Guide - plants registration",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "  Plants are the different production units. A spinning mill can have several plants. Each plant has its own production lines and inventory. Some information, on the other hand, is common to all registered plants, such as producer records, quality parameters, among others.",
        flow: "This is the production units page workflow:",
      },
      tabAdd: {
        title: "Creating new plant",
        step1: "On the plant registration page, click on 'New plant'.",
        step2: "Enter the data in the fields.",
        fieldDescription1: "ID or code of the new plant",
        fieldDescription2: "Name or description of the new plant",
      },
      tabEdit: {
        title: "Changing plant name",
        step1: "Find the plant on plant registration page and click on ",
        step2: "Enter the new name or description to the plant.",
      },
      tabSettings: {
        title: "Editting plant settings",
        step1: "Find the plant on plant registration page and click on ",
        step2: "Edit the field values as needed.",
        fieldDescription1:
          "Set minimum weight allowed to new bales. Weight can be in kilograms or pounds, depending on user preferences.",
        fieldDescription2:
          "Set maximum weight allowed to new bales. Weight can be in kilograms or pounds, depending on user preferences.",
        fieldDescription3:
          "Check if the plant does not test 100% of the bales upon arrival of the cotton.",
        fieldDescription4:
          "Check whether the quantity of bales that are in pre-laydown should be considered when doing quality planning.",
      },
    },
    parameters: {
      modalTitle: "Help - Quality Parameters",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Quality parameters are the physical or chemical characteristics of the cotton. The most common parameters are those measured by an HVI machine, however any parameter that is measured in numbers can be registered and controlled by ESSENTIALbale in the laydowns.",
        flow: "This is the workflow of the quality parameters registration page:",
      },
      tabAdd: {
        title: "Creating a new parameter manually",
        step1:
          "On the parameter registration page, click on 'Add', and then select on 'Manually'.",
        step2: "Fill in the fields:",
        fieldDescription1: "Parameter's full name. For example: Micronaire",
        fieldDescription2:
          "Short name for the parameter. For example: Mic. The short name is what will appear wherever the parameter is used in ESSENTIALbale",
        fieldDescription3:
          "Minimum value allowed for the parameter. When adding a bale to inventory, the system will check if the parameter value is valid.",
        fieldDescription4:
          "Maximum value allowed for the parameter. When adding a bale to inventory, the system will check if the parameter value is valid.",
        fieldDescription5:
          "The difference between the most common minimum and maximum values.",
        fieldDescription6:
          "Sets the zoom applied to the parameter in the average laydown graphs for a better user experience. 1 is the most common value. However, the scale value for parameters with larger values should be larger accordingly.",
        fieldDescription7:
          "Parameter multiplication factor for calculating the CSP.",
        fieldDescription8:
          "Parameter multiplication factor for calculating the SCI.",
        fieldDescription9:
          "Check this option to assign average inventory quality when new bale does not contain values defined for this parameter.",
        fieldDescription10:
          "Check this option if the quality results for this parameter are released manually by the user.",
        fieldDescription11:
          "Check this option if the coefficient of variation (CV) of the parameter must be controlled to avoid variations in the laydowns.",
        fieldDescription12:
          "Check this option in case the parameter is CSP or SCI specifically and its value is calculated automatically by the system.",
      },
      tabAddPreConfigured: {
        title: "Adding a preconfigured parameter.",
        step1:
          "On the parameter registration page, click on 'Add', and then select 'Preconfigured'.",
        step2:
          "Find the parameter in the list of available parameters and click ' + '.",
      },
      tabEdit: {
        title: "Editing a parameter",
        step1:
          "On the parameters registration page, find the parameter in the registered parameters table and click on ",
        step2: "Change the required fields.",
      },
      tabReordering: {
        title: "Defining the display order of the parameters",
        step1: "On the unit registration page, click on 'Reorder'",
        step2:
          "Click on the parameter and drag it to the desired position. Parameters will be displayed in the order defined here everywhere within ESSENTIALbale.",
      },
    },
    mixTypes: {
      modalTitle: "Help - Laydown Types",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Laydown types encompass a set of settings that can be applied to production lines. A spinning mill can have different mix types, and each mix type can have multiple production lines connected to it.",
        flow: "This is the workflow of the laydown type registration page:",
      },
      tabAdd: {
        title: "Creating a new mix type",
        step1: "On the mix type registration page, click on 'New mix type'.",
        step2: "Fill in the fields:",
        fieldDescription1: "Name or description of the mix type.",
        fieldDescription2:
          "Code of the class linked to the mix type. The class code is used to define which piles are available for use on the production lines linked to the mix type.",
        fieldDescription3:
          "Minimum number of lots required for the laydown process. It is only used when the option 'Partial testing of bales' in the production units register is true.",
        fieldDescription4:
          "Minimum inventory in days required for the laydown process.",
        fieldDescription5:
          "Estimated monthly consumption for the mix type. Can be set in kilograms or pounds.",
      },
      tabEdit: {
        title: "Changing a mix type",
        step1:
          "On the mix type registration page, find the type in the table of registered types and click on ",
        step2: "Change the fields.",
      },
      tabDelete: {
        title: "Deleting a mix type",
        step1:
          "On the mix type registration page, find the type in the table of registered types and click on ",
        step2:
          "It will be possible to delete the mix type as long as no production lines are connected to it.",
      },
      tabAddQualityRange: {
        title: "Adding quality ranges",
        pageDescription:
          "Quality ranges are used to difinine the accepted quality for mix type inventory in cotton distribution.",
        pageDescription2:
          "When there is a defined range for some quality parameter, lots or bales with quality outside this range will not be accepted into the inventory of the mix type.",
        step1:
          "On the mix type registration page, find the type in the table of registered types and click on ",
        step2: "Click on 'New quality range'.",
        step3: "Fill in the fields:",
        fieldDescription1: "Select the quality parameter.",
        fieldDescription2: "Minimum accepted value for the parameter.",
        fieldDescription3: "Maximum value accepted for the parameter.",
        fieldDescription4: "Rating form. The options are:",
        option0: "Lot average",
        optionDescription0:
          "To consider the average quality of the lots as reference. In this case, if the average is within the defined range, the entire lot can be added to the inventory of the mix type, even if some bales have values outside the defined range.",
        option1: "Bale by bale",
        optionDescription1:
          "To consider the individual quality of each bale. In this case, the lot can be split and only the bales that have quality within the defined range can be added to the inventory of the mix type.",
      },
      tabEditQualityRange: {
        title: "Changing quality ranges",
        step1:
          "On the mix type registration page, find the type in the table of registered types and click ",
        step2:
          "In the quality ranges window, find the parameter in the quality ranges table and click on ",
        step3: "Change the fields",
        fieldDescription1:
          "Optionally, a short description can be added in the 'Reason for change' field to create a history of changes.",
      },
      tabDeleteQualityRange: {
        title: "Deleting quality ranges",
        step1:
          "On the mix type registration page, find the type in the table of registered types and click ",
        step2:
          "In the quality ranges window, find the parameter in the table of registered ranges and click ",
      },
    },
    productionLines: {
      modalTitle: "Help - Production lines",
      tabOverview: {
        title: "Overview",
        pageDescription: "The production lines are the laydowns or openings.",
        flow: "This is the workflow of the production lines registration page:",
      },
      tabAdd: {
        title: "Creating new line",
        step1:
          "On the production line registration page, click on 'New production line'.",
        step2: "Fill in the fields:",
        fieldDescription1:
          "Indicates whether the production line is active or not.",
        fieldDescription2:
          "Indicates whether the line uses pre-laydown, that is, it has a pre-laydown step in the mixing process.",
        fieldDescription3: "Laydown type to which the line is attached.",
        fieldDescription4: "Name or description of the production line.",
        fieldDescription5: "Number of bales used in each laydown.",
        fieldDescription6:
          "Quantity of bales used in the pre-laydown. Usually 50% more than the amount of bales defined for laydown.",
        fieldDescription7:
          "Number of bales in each sub-laydown. Sub-laydowns are the small groups of bales within the same laydown.",
        fieldDescription8:
          "Number of laydowns consumed per day in this production line.",
        fieldDescription9:
          "Number of days to consider for controlling the quality variation of the laydowns.",
        fieldDescription10:
          "Pre-laydown code or identifier. Production lines that share the same inventory must have the same pre-laydown codes. Otherwise, the pre-laydown codes must be different.",
        fieldDescription11:
          "Code or identifier of the production lot. Production lines that produce the same yarn type and quality must have the same production lot. Otherwise, the production lots must be different.",
      },
      tabEdit: {
        title: "Editing a line",
        step1:
          "On the production line registration page, find the line in the table of registered lines, click on ",
        step2: "Edit the fields.",
      },
      tabSettings: {
        title: "Configuring the quality",
        step1:
          "On the production line registration page, find the line in the table of registered lines, click on ",
        step2:
          "Change values directly in table cells. Quality settings must be defined by the spinning mill quality management.",
        fieldDescription1:
          "Priority or weight assigned to the parameter in the mixing process.",
        fieldDescription2:
          "Acceptable variation in average quality for the parameter considering the last laydowns. The number of laydowns in which the variation is controlled is determined by multiplying the daily consumption by the number of days of production control that were determined in the line register. For example, having a consumption of 1 laydown per day and a production control of 7 days, we will have 7 laydowns. Therefore, the variation of the average quality in the last 7 laydowns must be within the tolerance defined here.",
        fieldDescription3:
          "Acceptable variation for the parameter, to determine that the lot of the product produced must be changed. When the variation is greater than acceptable, the system will generate an alert to change the yarn lot.",
        fieldDescription4:
          "Percentage of acceptable variation in the coefficient of variation (CV) of the parameter between one laydown and another.",
        fieldDescription5:
          "Maximum acceptable coefficient of variation (CV) for the parameter in the laydowns.",
        fieldDescription6:
          "Indicates whether the system should generate an alert when the average quality of the parameter falls below the acceptable range in a given laydown.",
        fieldDescription7:
          "Indicates whether the system should generate an alert when the average quality of the parameter is above the acceptable range in a given laydown.",
        fieldDescription8:
          "Minimum value allowed for a bale of cotton to be used in the laydowns of this production line. Bales with values lower than the one defined here will not be accepted in the pre-laydown.",
        fieldDescription9:
          "Maximum value allowed for a bale of cotton to be used in the laydowns of this production line. Bales with values higher than the one defined here will not be accepted in the pre-laydown.",
      },
      tabDelete: {
        title: "Deleting a line",
        step1:
          "On the production lines registration page, find the line in the table of registered lines and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        step3: "Production lines can not be deleted if:",
        reason1:
          "The line is used in some existing pre-laydown. In this case, all pre-laydowns connected to the line must be deleted.",
        reason2:
          "The line is linked to some registered production line class. In this case, the classes linked to the line must be excluded.",
      },
      tabWaste: {
        title: "Defining waste positions",
        description:
          "Waste positions are spaces in the laydown reserved for internally generated waste bales that can be incorporated into the laydown, as long as in minimal quantities.",
        step1:
          "On the production lines registration page, find the line in the table of registered lines and click on ",
        step2:
          "To add a new waste position, click on 'New position' in the window.",
        step3:
          "To delete a new waste position, find it in the table and click ",
      },
    },
    productionLineClasses: {
      modalTitle: "Help - Production line classes",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Production line classes serve to link the piles to a production line. The first character of the name of the registered piles identifies the class, so the system identifies which piles are part of the production line's inventory.",
        flow: "This is the workflow of the production line class registration page:",
      },
      tabAdd: {
        title: "Creating new class",
        step1:
          "On the class registration page, click on 'New production line class'.",
        step2: "Select the production line.",
        step3:
          "Inform the class. It can be a letter or number. In the case of letters, it is advisable to use capital letters.",
      },
      tabDelete: {
        title: "Deleting a class",
        step1:
          "On the class registration page, find the class in the table of registered classes and click on ",
        step2: "Confirm the deletion in the confirmation window.",
      },
    },
    producers: {
      modalTitle: "Help - Producers",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Producers can be the person or even the farm that grows the cotton. Identification of producers allows filtering queries in reports as well as dosing the use of cotton.",
        flow: "This is the workflow for the producer registration page:",
      },
      tabAdd: {
        title: "Creating a new producer",
        step1: "On the producer registration page, click on 'New producer'.",
        step2: "Fill in the fields:",
        fieldDescription1: "Producer name or description.",
      },
      tabEdit: {
        title: "Changing the producer name",
        step1:
          "On the producer registration page, find the producer in the table of registered producers and click on ",
        step2: "Change the name.",
      },
      tabDelete: {
        title: "Deleting a producer",
        step1:
          "On the producer registration page, find the producer in the table of registered producers and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If there are bales in the inventory linked to the producer, exclusion will not be possible.",
      },
    },
    suppliers: {
      modalTitle: "Help - Suppliers",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Suppliers are people or companies that supply the cotton, that is, from whom the cotton was purchased. Each lot of cotton that enters the inventory is linked to a supplier.",
        flow: "This is the workflow for the supplier registration page:",
      },
      tabAdd: {
        title: "Creating a new supplier",
        step1: "On the supplier registration page, click on 'New supplier'.",
        step2: "Fill in the fields:",
        fieldDescription1: "supplier name or description.",
      },
      tabEdit: {
        title: "Changing the supplier name",
        step1:
          "On the supplier registration page, find the supplier in the table of registered suppliers and click on ",
        step2: "Change the name.",
      },
      tabDelete: {
        title: "Deleting a supplier",
        step1:
          "On the supplier registration page, find the supplier in the table of registered suppliers and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If there are bales in the inventory linked to the supplier, exclusion will not be possible.",
      },
    },
    cottonTypes: {
      modalTitle: "Help - Cotton types",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Cotton types can be any identifier that typifies the cotton. Names of cotton types vary by country or region.",
        flow: "This is the workflow for the cotton type registration page:",
      },
      tabAdd: {
        title: "Creating a new cotton type",
        step1:
          "On the cotton type registration page, click on 'New cotton type'.",
        step2: "Fill in the fields:",
        fieldDescription1: "cotton type name or description.",
      },
      tabEdit: {
        title: "Changing the cotton type name",
        step1:
          "On the cotton type registration page, find the cotton type in the table of registered cotton types and click on ",
        step2: "Change the name.",
      },
      tabDelete: {
        title: "Deleting a cotton type",
        step1:
          "On the cotton type registration page, find the cotton type in the table of registered cotton types and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If there are bales in the inventory linked to the cotton type, exclusion will not be possible.",
      },
    },
    origins: {
      modalTitle: "Help - Origins",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Origins can be the country or region the cotton comes from.",
        flow: "This is the workflow for the origin registration page:",
      },
      tabAdd: {
        title: "Creating a new origin",
        step1: "On the origin registration page, click on 'New origin'.",
        step2: "Fill in the fields:",
        fieldDescription1: "origin name or description.",
      },
      tabEdit: {
        title: "Changing the origin name",
        step1:
          "On the origin registration page, find the origin in the table of registered origins and click on ",
        step2: "Change the name.",
      },
      tabDelete: {
        title: "Deleting a origin",
        step1:
          "On the origin registration page, find the origin in the table of registered origins and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If there are bales in the inventory linked to the origin, exclusion will not be possible.",
      },
    },
    piles: {
      modalTitle: "Help - Piles",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Piles serve to divide the inventory, and may even be a representation of the physical space of the cotton warehouse.",
        pageDescription2:
          "Whenever a lot is made available through the 'Cotton Distribution' page, it must be allocated in a pile linked to the production line for which the lot will be available.",
        flow: "This is the pile registration page workflow:",
      },
      tabAdd: {
        title: "Creating new piles in inventory",
        step1: "On the pile registration page, click on 'New pile'.",
        step2: "Fill in the fields:",
        fieldDescription1:
          "Pile identification. It must contain a letter or number that identifies the class of production line to which the pile is connected, followed by a dash and a sequential number or not. For example: 'P-01', P-05, 'B-10', B-11' etc.",
        fieldDescription2:
          "Indicates whether the system should automatically create multiple piles, starting from the identification of the first pile and generating a sequence up to the defined amount. In this way, it is not necessary to register all the piles manually.",
        fieldDescription3:
          "Number of piles to be automatically generated, if applicable.",
      },
      tabDelete: {
        title: "Deleting one or multiple piles",
        step1:
          "On the piles registration page, select all the piles you want to delete and then click 'Delete'.",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If there are bales in inventory allocated in the pile, deletion will not be possible.",
      },
    },
    hviLayouts: {
      modalTitle: "Help - Layout of HVI files",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "In order to be able to import files with HVI results or any files that contain the quality of the bales, it is necessary to inform the system of the layout of these files.",
        pageDescription2:
          "Generally, these are excel spreadsheets or text files, and for the system to correctly read these files, it is necessary to register the layouts.",
        pageDescription3:
          "In summary, you must inform which columns of the file contain the bale number, in addition to the values of each registered quality parameter.",
        flow: "This is the layout registration page workflow:",
      },
      tabAdd: {
        title: "Adicionando novos layouts",
        step1: "On the layouts registration page, click on 'New layout'.",
        step2: "Enter the layout name or description.",
      },
      tabEdit: {
        title: "Changing the layout description",
        step1:
          "On the layout registration page, find it in the table of registered layouts and click on ",
        step2: "Change the layout description.",
      },
      tabColumns: {
        title: "Configuring the layout",
        step1:
          "On the layout registration page, find it in the table of registered layouts and click on ",
        step2: "Enter values directly into table cells.",
        fieldDescription1:
          "Column number that contains the bale number or quality values in the file.",
        fieldDescription2:
          "Conversion multiplier. Used to convert quality values from millimeters to inches, for example.",
      },
      tabDelete: {
        title: "Deleting a layout",
        step1:
          "On the layout registration page, find it in the table and click on ",
        step2: "Confirm the deletion in the confirmation window.",
      },
    },
    users: {
      modalTitle: "Help - Users",
      tabOverview: {
        title: "Overview",
        pageDescription: "Page for registering and managing users",
        flow: "This is the workflow for the user registration page:",
      },
      tabAdd: {
        title: "Creating a new user",
        step1: "On the user registration page, click on 'New User'.",
        step2: "Fill in the fields:",
        fieldDescription1:
          "User ID for login. Must be unique and cannot contain spaces.",
        fieldDescription2: "User's email address",
        fieldDescription3: "User's name.",
        fieldDescription4: "User's last name.",
        fieldDescription5:
          "User's privilege level. The levels serve to determine which pages the user can access. The options are:",
        option1: "Basic",
        optionDescription1:
          "The user has access to the registration function such as producers, suppliers, origins, cotton types, cuttings, HVI file layouts. The user 'Basic' can also manage the stock and generate reports.",
        option2: "Advanced",
        optionDescription2:
          "In addition to all the functions contained in the 'Basic' level, the 'Advanced' user can access the parameter record, blend types, production lines, production line classes, and manage all the functions of the blending process.",
        option3: "Manager",
        optionDescription3:
          "In addition to all the functions contained in the 'Basic' and 'Advanced' levels, the 'Manager' user can create and manage new plants, add new users, manage quality warnings, as well as check the user action logs.",
        description1:
          "The default password for all new users is 'change_me'. When logging into the application for the first time, the user will be prompted to change the password by entering this default password.",
      },
      tabEdit: {
        title: "Changing a user's data",
        step1:
          "On the user registration page, find the user in the registered users table and click ",
        step2: "Change the fields.",
        description1:
          "To change the password, use the 'Forgot password' option on the login screen.",
      },
      tabDelete: {
        title: "Deleting a user",
        step1:
          "On the user registration page, find the user in the table of registered users and click ",
        step2: "Confirm the deletion in the confirmation window.",
      },
    },
    lotInput: {
      modalTitle: "Help - Lot input",
      tabOverview: {
        title: "Overview",
        pageDescription: "page for launching lots of cotton in inventory.",
        flow: "This is the lot registration page workflow:",
      },
      tabAdd: {
        title: "Adding new lots",
        step1:
          "On the lot registration page, select the supplier in the 'Supplier' selection box, and then click on 'New lot'.",
        step2: "Enter the data of the new lot.",
        fieldDescription1: "Number of bales contained in the lot.",
        fieldDescription3:
          "Gross weight of the lot. Can be set in kilograms or pounds depending on user preferences.",
        fieldDescription4:
          "Net weight of the lot. Can be set in kilograms or pounds depending on user preferences.",
        fieldDescription5:
          "Producer name. The producer must be previously registered.",
        fieldDescription6:
          "Cotton type. The type must be previously registered.",
        fieldDescription7:
          "Origin of cotton. The origin must be previously registered.",
        fieldDescription8: "Date of entry of the lot into the unit.",
        fieldDescription9:
          "Name of the classifier, that is the laboratory that carried out the HVI classification of the bales.",
        fieldDescription10: "Purchase contract number.",
        fieldDescription11:
          "Purchase lot number. The HVI lot can be entered here, for example.",
      },
      tabEdit: {
        title: "Editing a lot",
        step1:
          "On the lot registration page, find it in the registered lot table and click on ",
        step2: "Change the fields.",
      },
      tabBales: {
        title: "Adding bales to the lot",
        step1:
          "On the lot registration page, find the lot in the registered lots table and click on ",
        step2: "Instructions are on the bale page.",
      },
      tabDelete: {
        title: "Deleting a lot",
        step1:
          "On the lot registration page, find the lot in the table and click on ",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "If the lot contains registered bales, it will not be possible to exclude them.",
      },
    },
    baleInput: {
      modalTitle: "Help - Bales input",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page to enter the bales of a lot. Bales can be entered manually or through file import.",
        flow: "This is the workflow for the bale input page:",
      },
      tabAdd: {
        title: "Adding new bales manually",
        step1:
          "On the bale input page, click 'New Bale' and then select 'Manually'.",
        step2: "Fill in the fields.",
        fieldDescription1: "Bale number. Can contain letters or numbers.",
        fieldDescription2:
          "Indicates whether the system should create multiple bales automatically, starting from the number of the first bale and generating a sequence up to the defined quantity.",
        fieldDescription3:
          "Quantity of bales to be generated automatically, if applicable.",
      },
      tabImportation: {
        title: "Adding new bales per file",
        step1:
          "On the bale input page, click on 'New Bale' and then select 'From File'. ",
        step2:
          "The instructions for this function are on the file import page.",
      },
      tabDelete: {
        title: "Excluding bales",
        step1:
          "On the bale input page, select all the bales you want to delete and then click 'Delete Bales'.",
        step2: "Confirm the deletion in the confirmation window.",
        reason1:
          "Only bales that are in inventory can be deleted. Bales that have already been consumed cannot be deleted.",
      },
    },
    baleInputImportation: {
      modalTitle: "Help - Importing bales from archive",
      tabOverview: {
        title: "Overwiew",
        pageDescription: "Page for importing bales from a file.",
        flow: "This is the workflow for the bale import page:",
      },
      tabAdd: {
        title: "Importing a file.",
        step1:
          "Select the layout corresponding to the file that will be imported. The layout must be previously registered and configured.",
        step2:
          "Click 'Select file' and browse for the file on your computer. Files in excel spreadsheet format or text files are accepted.",
        step3: "See below an example of a file to import.",
        step4: "After selecting the file, the data will be shown in the table:",
        step5:
          "ESSENTIALbale will try to identify the bales and their respective quality information in the file.",
        step6:
          "Columns highlighted in red indicate divergence between the registered layout and the file layout, in which case it will not be possible to import the data and corrections must be made to the registered layout.",
        step7:
          "Columns highlighted in green indicate that the column is in accordance with the registered layout.",
        step8: "Click 'Insert Bales'.",
      },
    },
    baleManagement: {
      modalTitle: "Help - Bale Management",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page for managing bales in inventory, where it is possible to change the status of the bales or even change the pile in which the bale is allocated.",
        pageDescription2:
          "There are two types of status that can be assigned to bundles:",
        field1: "On hold",
        fieldDescription1:
          "The bale is put on hold mode, and thus is not available for use in laydowns.",
        field2: "Available",
        fieldDescription2:
          "The bale switches from on hold  to available mode, and is thus again available for use in laydowns.",
        flow: "This is the workflow for the bale management page:",
      },
      tabStatus: {
        title: "Changing the status",
        step1:
          "Select the desired option in the 'Situation' selection box to search for the bales in inventory.",
        step2:
          "Select from the table the bales for which you want to change the status.",
        step3:
          "Click 'Put on hold', to put the bales in standby mode, or 'Turn to Available' to take the bales out of on hold mode making them available for use again. ",
      },
      tabPile: {
        title: "Changing the pile",
        step1:
          "Select the desired option in the 'Situation' selection box to search for the bales in inventory.",
        step2:
          "Select from the table the bales for which you want to change the pile.",
        step3: "Click on 'Change pile'.",
        step4: "Select the new pile that should be assigned to the bales.",
      },
    },
    lotManagement: {
      modalTitle: "Help - Lot Management",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page for managing lots in inventory, where it is possible to change the status of the lots or even change the pile in which the lot is allocated.",
        pageDescription2:
          "There are two types of status that can be assigned to lots:",
        field1: "On hold",
        fieldDescription1:
          "The lot is put on hold mode, and thus is not available for use in laydowns.",
        field2: "Available",
        fieldDescription2:
          "The lot switches from on hold  to available mode, and is thus again available for use in laydowns.",
        flow: "This is the workflow for the lot management page:",
      },
      tabStatus: {
        title: "Changing the status",
        step1:
          "Select the desired option in the 'Situation' selection box to search for the lots in inventory.",
        step2:
          "Select from the table the lots for which you want to change the status.",
        step3:
          "Click 'Put on hold', to put the lots in standby mode, or 'Turn to Available' to take the lots out of on hold mode making them available for use again. ",
      },
      tabPile: {
        title: "Changing the pile",
        step1:
          "Select the desired option in the 'Situation' selection box to search for the lots in inventory.",
        step2:
          "Select from the table the lots for which you want to change the pile.",
        step3: "Click on 'Change pile'.",
        step4: "Select the new pile that should be assigned to the lots.",
      },
      tabPlant: {
        title: "Transferring to another plant",
        step1:
          "Select the desired option in the 'Situation' selection box to search for the lots in inventory.",
        step2:
          "Select from the table the lots that you want to transfer to another plant.",
        step3: "Click on 'Plant Transfer'.",
        step4: "Select the destination plant for the lots.",
      },
    },
    cottonDistribution: {
      modalTitle: "Help - Cotton distribution",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page for directing cotton lots to the inventory of the production lines. When a new lot is entered into the system, the pile S-01 is autoamtically assigned to this lot. This pile is not linked to any production line and therefore new lots are not part of the inventory of any production line when it is entered.",
        pageDescription2:
          "In the distribution, the lots become part of the inventory of a production line and are available to be used in the laydowns. Basically, this step consists in assigning a new pile to the lot, which will then compose the inventory of the line to which the pile is linked.",
        flow: "This is the workflow for the cotton distribution page:",
      },
      tabManual: {
        title: "Distributing lots manually",
        step0:
          "In manual distribution, user selects lots manually and assign new piles to them.",
        step1:
          "On the cotton distribution page, click 'New manual distribution'.",
        step2: "Follow the instructions on the manual distribution page.",
      },
      tabAutomatic: {
        title: "Distributing lots automatically",
        step0:
          "In automatic distribution, ESSENTIALbale selects lots and assigns new piles automatically.",
        step1:
          "On the cotton distribution page, click 'New automatic distribution'.",
        step2: "Follow the instructions on the automatic distribution page.",
      },
      tabCancelAll: {
        title: "Cancelling a distribution",
        step1:
          "On the cotton distribution page, find the distribution in the table of distributions performed and click on ",
        step2:
          "The bales from all the lots in this distribution that have not yet been used in laydowns will be returned to the S-01 pile and will not be available for use.",
      },
      tabCancel: {
        title: "Cancelling the distribution of single lot",
        step1:
          "On the cotton distribution page, click on the distribution row in the table of distributions performed.",
        step2: "In the included lots table, find the desired lot and click ",
        step3:
          "The bales of the referred lot that have not yet been used in laydowns will be returned to the S-01 pile and will not be available for use.",
      },
    },
    manualCottonDistribution: {
      modalTitle: "Help - Manual distribution",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "In manual distribution, user manually choose the lots that are to be added to the inventory of the selected mix type.",
        flow: "This is the workflow for the manual distribution page:",
      },
      tabInfo: {
        title: "Understanding quality information",
        description:
          "On the manual distribution page there are two tabs with quality data.",
        table1: "Quality per pile",
        tableDescription1:
          "Shows the average quality and quantity of bales available for each mix type in the selected pile.",
        table2: "Quality by mix type",
        tableDescription2:
          "It shows an overview of the average inventory quality.",
        fieldDescription1:
          "Average quality of the total inventory, including cotton available for other mix types.",
        fieldDescription2:
          "Average quality of running inventory for the mix type.",
        fieldDescription3:
          "Average quality of total inventory available for distribution.",
        fieldDescription4: "Average inventory quality in the selected pile.",
        fieldDescription5:
          "Inventory projection if all lots in the selected pile are added to the inventory of the mix type.",
      },
      tabAdd: {
        title: "Distributing the lots manually",
        step1: "Select the pile that contains the lots for distribution.",
        step2: "Select the mix type for which you want to distribute the lots.",
        step3: "On the 'Lots in the selected pile' tab, select the lots.",
        step4: "Click on 'Make manual distribution'.",
      },
    },
    autoCottonDistribution: {
      modalTitle: "Help - Automatic distribution",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "In automatic distribution, ESSENTIALbale automatically selects the lots that are to be added to inventory for the mix type being selected. By always observing the types of cotton controlled in the laydown, it will select lots in which the cotton type is needed at that time.",
        pageDescription2:
          "Whenever the quantity of available lots for the mixing process is less than the minimum necessary quantity informed in the mix type registration, ESSENTIALbale will request the user to make new lots available.",

        flow: "This is the workflow for the automatic distribution page:",
      },
      tabInfo: {
        title: "Understanding quality information",
        table1: "Inventory quality table",
        tableDescription1:
          "It shows an overview of the average quality of the inventory, as well as the average quality of the lots suggested for distribution.",
        fieldDescription1:
          "Average quality of the total inventory, including cotton available for other mix types.",
        fieldDescription2:
          "Average quality of running inventory for the mix type.",
        fieldDescription3:
          "Average quality of inventory available for distribution.",
        fieldDescription4:
          "Average quality of the suggested lots in automatic distribution. It will be shown after processing.",
        fieldDescription5:
          "Inventory projection for the mix type if all suggested lots are confirmed.",
        table2: "Quality perspective for mix type table",
        tableDescription2:
          "Shows the average quality outlook for the selected mix type.",
        fieldDescription6: "Name of the quality parameter.",
        fieldDescription7:
          "Priority or weight given to the parameter when processing the distribution. Parameters with higher priority will have the achieved value closer to the expected value.",
        fieldDescription8:
          "Minimum acceptable value. If the achieved value falls below the minimum acceptable value, the suggested lots may change the average inventory quality of the mix type too much.",
        fieldDescription9:
          "Maximum acceptable value. If the achieved value is above the maximum acceptable value, the suggested lots may change the average inventory quality of the mix type too much.",
        fieldDescription10:
          "Ideal expected value when processing the lot selection.",
        fieldDescription11: "Value achieved in lot selection processing.",
      },
      tabAdd: {
        title: "Generating automatic distribution",
        step1:
          "Select the mix type for which you want to generate an automatic distribution",
        step2: "Click on 'Generate automatic distribution'.",
        step3:
          "Wait for the processing to finish. This process takes 30 seconds. At the end of the process, the lots selected by the system will be shown in the 'Suggested lots' table.",
        step4:
          "Select the lots from the table and click 'Confirm suggested lots'.",
      },
    },
    pileGroups: {
      modalTitle: "Help - Pile groups",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Pile groups are used to control the use of cotton in laydowns.",
        pageDescription2:
          "A group can gather several piles or even a single pile that contains cotton whose use must be controlled in the laydowns.",
        pageDescription3:
          "It is possible, for example, create groups of piles per producer or origin and define for each group how many bales will be used from each group in the laydowns.",
        flow: "This is the workflow for the pile groups page:",
      },
      tabAdd: {
        title: "Creating a new group",
        step1: "On the pile group page, click 'New pile group'.",
        step2: "Fill in the fields.",
        fieldDescription1: "Name or description of the group.",
        fieldDescription2:
          "Amount of bales that should be used in each laydown.",
        fieldDescription3: "Indicates whether the group is active or not.",
        step3:
          "In the table of piles, select all piles that will be part of the group.",
        fieldDescription5:
          "The legend 'Group representation (%)' shows how much the group represents in the laydown and in the inventory.",
        fieldDescription6:
          "The legend 'Representation of piles outside group (%)' shows how much the cotton that is not part of any group represents in the laydown and in the inventory.",
      },
      tabEdit: {
        title: "Editing a Group",
        step1:
          "On the pile group page, find it in the table of registered groups and click ",
        step2:
          "Change the fields or redefine the piles that are part of the group.",
      },
      tabDelete: {
        title: "Deleting a group",
        step1:
          "On the pile group page, find it in the table of registered groups and click ",
        step2: "Confirm the deletion in the confirmation window.",
      },
    },
    controlledCottonTypes: {
      modalTitle: "Help - Controlled Cotton Type",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "The control per cotton type serves to control the use of cotton in the laydowns according to cotton type.",
        pageDescription2:
          "This control method is commonly used in Asian countries, which usually dose the cotton in the laydowns according to type.",
        flow: "This is the workflow for the controlled cotton types page:",
      },
      tabAdd: {
        title: "Inserting a controlled type",
        step1:
          "On the controlled cotton yypes page, select the mix type from the 'Laydown Type' selection box and then click 'New controlled cotton type'.",
        step2: "Select the cotton type.",
        step3:
          "Enter in the field 'Bales per laydown' how many bales should be used in the laydowns.",
      },
      tabEdit: {
        title: "Changing a controlled type",
        step1:
          "On controlled cotton types page, find the type in the table of registered types and click ",
        step2: "Change the number of bales.",
      },
      tabDelete: {
        title: "Excluding a controlled type",
        step1:
          "On controlled cotton types page, find the type in the table of registered types and click ",
        step2: "Confirm the deletion in the confirmation window.",
      },
    },
    hviImportation: {
      modalTitle: "Help - Importing HVI results",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page for importing HVI results to assign or update bale quality data.",
        flow: "This is the workflow for the HVI results import page:",
      },
      tabAdd: {
        title: "Importing a file.",
        step1:
          "Select the layout corresponding to the file that will be imported. The layout must be previously registered and configured.",
        step2:
          "Click 'Select file' and browse for the file on your computer. Files in excel spreadsheet format or text files are accepted.",
        step3: "See below an example of a file to import.",
        step4: "After selecting the file, the data will be shown in the table:",
        step5:
          "ESSENTIALbale will try to identify the bales and their respective quality information in the file.",
        step6:
          "Columns highlighted in red indicate divergence between the registered layout and the file layout, in which case it will not be possible to import the data and corrections must be made to the registered layout.",
        step7:
          "Columns highlighted in green indicate that the column is in accordance with the registered layout.",
        step8: "Click 'Insert Bales'.",
      },
    },
    hviManualInput: {
      modalTitle: "Help - Manual input quality results",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Page for manual entry of quality data for the parameters that have manual entry set.",
        flow: "This is the workflow for the manual results input page:               ",
      },
      tabAdd: {
        title: "Entering results",
        step1:
          "Select the status in the 'Status' status box to search for suppliers.",
        fieldDescription1:
          "It gets only for suppliers who have any lots of cotton for which the results are pending.",
        fieldDescription2: "Gets all suppliers.",
        step2: "Select the supplier.",
        step3: "Select the lot.",
        step4: "Type the results directly into the table cells.",
        fieldDescription3:
          "Check this option to assign the same value to all bales. In this case, when typing the value in any cell, the same value will be replicated to all bales.",
        fieldDescription4:
          "Check this option if each bale has an individual value.",
        step5: "Click 'Save'.",
      },
    },
    baleInfo: {
      modalTitle: "Help - Bale info",
      tabOverview: {
        title: "Overview",
        pageDescription: "Page for querying the information of a bale.",
      },
      tabSearch: {
        title: "Consulting a bale",
        step1: "Type the bale number in the field and click 'Search'.",
      },
    },
    plannings: {
      modalTitle: "Help - Quality planning",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Quality planning is the first step in the mixing process. In planning, the quality that will serve as a basis for the next laydown is defined.",

        flow: "This is the workflow for the planning page:",
      },
      tabAdd: {
        title: "Creating a new planning",
        step1: "On the planning page, click 'New planning'.",
        step2: "Select the mix type to generate planning.",
        step3:
          "If applicable, check the option 'Change production lot'. This option should be used exclusively when starting a new production lot. It should be used when there is a big change in inventory quality as in crop changes, for example. When there is a change of production lot, the quality of the previous laydowns is not considered in the calculation of the new quality.",
      },
      tabEdit: {
        title: "Editing the planning data",
        step1:
          "On the planning page, find the planning in the table and click ",
        step2: "Change the mix type.",
        step3: "If it is the case, change the production lot change option.",
      },
      tabQuality: {
        title: "Generating the planning",
        step1: "Find the planning in the table and click ",
        step2: "Define what should be prioritized in the planning",
        fieldDescription1:
          "(Recommended) Select so that the planned quality lies exactly between the quality of the inventory and the quality of the last laydown.",
        fieldDescription2:
          "Select so that the planned quality is closer to the inventory quality.",
        fieldDescription3:
          "Select so that the planned quality is closer to the quality of the last laydown.",
        step3: "Click on 'Generate planning'.",
        description1: "The quality table shows the following values:",
        fieldDescription4: "Quality parameter.",
        fieldDescription5: "Average inventory quality for the mix type.",
        fieldDescription6: "Average quality of the last laydown",
        fieldDescription7:
          "Planned quality. It will be shown after clicking on 'Generate planning'.",
        fieldDescription8:
          "Difference between the planned quality and the quality of the last laydown. It will be shown after clicking on 'Generate planning'.",
        description2: "The consumption table shows the following values:",
        fieldDescription9:
          "Quantity of bales currently available in inventory for the mix type.",
        fieldDescription10: "Amount of laydowns consumed per day.",
        fieldDescription11: "Amount of bales used in each laydown.",
        fieldDescription12: "Total amount of bales consumed per day.",
        fieldDescription13:
          "Amount of bales remaining after consumption of the next laydown.",
      },
      tabViewQuality: {
        title: "Viewing planned quality",
        step1:
          "On the planning page, find the planning in the table and click ",
        step2:
          "The table will show the planned quality for each quality parameter.",
      },
      tabDelete: {
        title: "Excluding a planning",
        step1:
          "On the planning page, find the planning in the table and click ",
        step2:
          "Confirm the deletion in the confirmation window. If the icon is not visible, it means that the planning cannot be deleted.",
      },
    },
    preLaydowns: {
      modalTitle: "Help - Pre-laydown",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Pre-laydown is the second step in the mixing process. In this step, ESSENTIALbale determines how many bales should be used from each pile to create a pre-laydown.",
        pageDescription2:
          "Based on the quality planned in the planning stage, the system analyzes the quality of each available pile, and determines how many bales to use. At this stage, a larger quantity of bales is used in relation to the quantity of bales used in the laydown. Generally, 50% more bales are used in the pre-laydown.",
        flow: "This is the workflow for the pre-laydown page:",
      },
      tabAdd: {
        title: "Creating new pre-laydown",
        step1: "On the pre-laydown page, click on 'New pre-laydown'.",
        step2: "Select the production line.",
        step3:
          "Optionally, you can inform the yarn lots number, or output lot number. The yarn lots can be used for filtering in quality reports later.",
      },
      tabEdit: {
        title: "Editing a pre-laydown",
        step1:
          "On the pre-laydown page, find the pre-laydown in the table and click ",
        step2: "Select the production line.",
        step3: "Inform the lots of yarn.",
      },
      tabPiles: {
        title: "Generating the pre-laydown",
        description0:
          "When generating the pre-laydown, ESSENTIALbale analyzes the quality of the piles and defines how many bales will be used from each one.",
        step1:
          "On the pre-laydown page, find the pre-laydown in the table and click ",
        description1:
          "The 'Available piles' table shows all available piles. For each pile are shown:",
        fieldDescription1: "Number of bales available in the pile.",
        fieldDescription2:
          "Minimum amount of bales that can be used in the pre-laydown. It is defined by the system automatically, but can be changed by the user.",
        fieldDescription3:
          "Maximum number of bales that can be used in the pre-laydown. It is defined by the system automatically, but can be changed by the user.",
        fieldDescription4:
          "Quantity of bales defined for the pre-laydown. It is shown after the preparation of the pre-laydown.",
        parameter: "Quality parameter",
        fieldDescription5: "Average quality for the parameter.",

        description2:
          "In the 'Quality' table, for each quality parameter we have the following values:",
        fieldDescription6:
          "Priority or weight of the parameter in the preparation of the pre-laydown.",
        fieldDescription7: "Current average inventory quality.",
        fieldDescription8:
          "Quality planned in the planning stage and which serves as a reference for the system.",
        fieldDescription9:
          "Quality achieved with the elaboration of the pre-laydown. It is shown after the preparation of the pre-laydown and must always be between the minimum and maximum accepted values.",
        fieldDescription10:
          "Minimum acceptable value for the parameter. It is calculated automatically by the system.",
        fieldDescription11:
          "Maximum acceptable value for the parameter. It is calculated automatically by the system.",
        step2: "Click on 'Generate pre-laydown'.",
        description3:
          "Wait for processing to complete. This process takes 30 seconds. At the end of the process, the average quality achieved and the quantity of bales defined for each pile.",
      },
      tabViewPiles: {
        title: "Viewing used piles",
        step1:
          "On the pre-laydown page, find the pre-laydown in the table and click on ",
        step2:
          "The table will show the piles used in the pre-laydown and the respective amount of bales to be used for each one.",
      },
      tabDelete: {
        title: "Deleting a pre-laydown",
        step1:
          "On the pre-laydown page, find the pre-laydown in the table and click on ",
        step2:
          "Confirm the deletion in the confirmation window. If the icon is not visible, it means that the pre-laydown cannot be deleted because there are laydowns originating from the pre-laydown.",
      },
    },
    laydowns: {
      modalTitle: "Help - Laydowns",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "Laydown is the final step in the laydown process at ESSENTIALbale. ",
        pageDescription2:
          "In this step the bales are collected according to the quantities defined in the pre-laydown step. The system then analyzes the quality of each bale and chooses the bales that will be part of the laydown so that the laydown average is as close as possible to the quality planned in the first stage of the process.",
        pageDescription3:
          "After analyzing and selecting the bales the ESSENTIALbale further defines the position of each bale in the opening line.",
        flow: "This is the workflow for creating the laydown:",
        step1: "New laydown is created.",
        step2:
          "The bales are collected according to the quantity defined in the prelaydown.",
        step3:
          "The laydown is prepared. The quality of the collected bales is analyzed and the system chooses the appropriate bales for the laydown.",
        step4:
          "The layout of the laydown is defined. The system sorts the selected bales for assembly on the opening line.",
        step5: "The laydown is finished",
      },
      tabAdd: {
        title: "Creating a New laydown",
        step1:
          "In the 'prelaydown ID' box, select the prelaydown that will give rise to the new laydown.",
        step2: "Click on 'New Laydown'.",
        step3:
          "Click 'Bales' to go to the bales page where the next steps are done.",
      },
      tabBales: {
        title: "Including the bales",
        description1:
          "This page includes the bales in the prelaydown according to the quantity defined for each pile in the prelaydown elaboration step, so that the selection of the bales can be done later.",
        step1:
          "Click 'Include leftover' to include the bales that were included in the previous prelaydown, but were not selected for the previous laydown. The bales will appear in the 'Bales added to prelaydown' table.",
        step2:
          "Print the report listing the bales still needed from each pile by clicking ",
        description3:
          "This list should be passed to the warehouse personnel so that they collect the amount of bales still needed from each pile and place them in the prelaydown area along with the bales left over from the previous laydown. The number of bales collected in the piles should be noted.",
        step3:
          "Enter the numbers of the collected bales in the 'Bale Number' field. It is also possible to read a file that contains the bales in list form. If this is the case, click on 'Choose file' and search for the file on your computer.",
        description4:
          "As each bale is included, the data will be updated on the screen. In the table 'Required quantity of bales per pile' are shown for each pile:",

        fieldDescription1:
          "Necessary quantity of bales defined in the prelaydown preparation.",
        fieldDescription2: "Quantity of bales already included.",
        fieldDescription3:
          "Quantity of bales remaining to be included in the prelaydown.",

        description5:
          "In the 'Quality' table, for each quality parameter are shown:",
        fieldDescription4: "Quality planned in the planning stage",
        fieldDescription5:
          "Average quality of the to bales added to the prelaydown.",
        fieldDescription6:
          "Minimum acceptable value for the parameter. It is calculated automatically by the system.",
        fieldDescription7:
          "Maximum acceptable value for the parameter. It is calculated automatically by the system.",
        fieldDescription8:
          "Coefficient of variation (CV) of the bales added to the prelaydown.",

        step4:
          "After including the total amount of bales required it will be possible to proceed to the laydown preparation. Click on 'Generate Laydown'",
      },
      tabGenerate: {
        title: "Generating the laydown",
        description1:
          "After the inclusion of the required amount of bales for each pile done on the bale inclusion page, on this page the generation of the laydown itself is done.",
        step1:
          "Click 'Generate Laydown' and wait for the processing to complete.",

        description2:
          "The system will analyze the indivudual quality of each bale included in the prelaydown and select the bales to make up the laydown.",
        description3: "Processing takes 30 seconds.",
        description4:
          "After the processing is complete, the data will be updated on the page.",

        description5:
          "In the table 'Bales in the prelaydown' all bales included in the prelaydown are shown. The bales that have been selected will be highlighted in blue.",

        description6:
          "In the table 'Quality', for each quality parameter are shown:",
        fieldDescription1:
          "Priority or weight of the parameter's parameter in the laydown preparation. it is set in the production line settings, but can be changed here if needed.",
        fieldDescription2: "Planned quality in the planning stage.",
        fieldDescription3:
          "Average quality achieved with the selection of the bales.",
        fieldDescription4:
          "Minimum acceptable value for the parameter. It is calculated automatically by the system.",
        fieldDescription5:
          "Maximum acceptable value for the parameter. It is calculated automatically by the system.",
        fieldDescription6:
          "Coefficient of variation (CV) of the bales added to the prelaydown.",

        description7:
          "In the table 'Controlled groups', the data of the cotton usage control is shown. It can be of the controlled groups of piles or types of cotton.",
        fieldDescription7: "pile group or cotton type ID.",
        fieldDescription8: "Name of the pile group or cotton type.",
        fieldDescription9:
          "Minimum quantity of bales expected to in the laydown.",
        fieldDescription10:
          "Maximum quantity of bales expected for in the laydown.",

        fieldDescription11: "Quantity of bales reached in the laydown",
        fieldDescription12: "Quantity of bales left in the pre laydown.",

        description8:
          "If there are any quality parameters that have a controlled coefficient of variation (CV) (set in the pre laydown settings), the data will be shown in the 'CV Control' table. For each parameter with controlled CV, the following are shown:",
        fieldDescription13:
          "Minimum acceptable CV for the parameter. It is calculated automatically by the system.",
        fieldDescription14:
          "Maximum acceptable CV for the parameter. It is calculated automatically by the system.",
        fieldDescription15: "CV reached with the selection of the bales.",

        description9:
          "Once the laydown processing is complete, you can proceed to the next step: defining the positions of each selected bale.",

        step2: "Go back to the initial laydown screen, and click on 'Layout'.",
      },
      tabLayout: {
        title: "Defining the layout",
        description1:
          "After the laydown have been prepared you will be able to define the laydown layout, that is define the position of each bale in the opening line.",
        step0: "Set the priority in the layout elaboration.",
        fieldDescription1:
          "(Recommended) Select so that there is equal distribution of the bales according to their respective producers and origins.",
        fieldDescription2:
          "Select so that the bales are better distributed according to the producer.",
        fieldDescription3:
          "Select to have the bales better distributed according to origin.",

        step1: "Click 'Generate layout' and wait for the processing to finish.",

        description2:
          "The system will analyze the indivudual quality of each bale selected for laydown and define the position of each bale on the opening line.",
        description3:
          "Processing takes 30 seconds. After the processing is complete, the data will be updated on the page.",
        description5:
          "In the 'Selected Bales' table, all bales sealed for nixing and their respective positions defined by the system are shown.",
        description6:
          "The bales are grouped into sub laydowns as if they were small laydowns within the overall laydown. The number of bales in each sub-mix is defined in the production line register.",
        description7:
          "The average quality of each sub laydown will be as close as possible to the overall average quality of the laydown.",

        description8:
          "The overall average quality of the laydown is shown in the 'Laydown Quality' table.",
        description9:
          "Whereas the quality of each sub laydown is shown in the table 'Quality of the sub laydowns'.",

        description910: "After the layout is done, the laydown is complete.",

        step2:
          "Go back to the initial screen of laydowns, and click on 'Finish'.",
      },
      tabFinish: {
        title: "Finishing the laydown",
        description1:
          "After completion of all steps, if the laydown has no quality alerts, that is if the average achieved for all quality parameters is within tolerance, it will be possible to finalize the laydown.",
        description2:
          "If there are quality alerts, go to the alerts page for analysis.",
        step1: "Click 'Finish'.",
        step2: "Confirm the finalization in the confirmation window.",
        step3:
          "In the bale consumption confirmation window, inform whether you want to record the consumption or not.",
        step4: "Click 'Finish'.",
      },
      tabResume: {
        title: "Resuming the laydown",
        description1:
          "Resuming the laydown consists of making it editable again, that is it is possible to redo any of the steps. Only the last laydown of each production line can be resumed.",
        step1: "Click on 'Resume'.",
        step2: "Confirm completion in the confirmation window.",
      },
      tabDelete: {
        title: "Deleting the laydown",
        description1:
          "If the laydown is deleted, the bales are returned to inventory.",
        step1: "Click 'Delete'.",
        step2: "Confirm completion in the confirmation window.",
      },
      reporting: {
        balesAndQuality: {
          modalTitle: "Help - Laydown bales and quality report",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the selected bales and the laydown quality summary.",

            printText1: "Click ",
            printText2: "to open the print windo.",
          },
        },
        laydownCharts: {
          modalTitle: "Help - Laydown bales chart",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the graphical representation of the quality of the laydown bales.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        layoutPositions: {
          modalTitle: "Help - Laydown layout",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the positioning of the bales in the laydown.",

            printText1: "Click ",
            printText2: "to open the print window.",
          },
        },
        leftoverBales: {
          modalTitle: "Help - Bales in pre-laydown",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the list of bales that have not been selected for laydown and remain in the pre-laydown area.",

            printText1: "Click ",
            printText2: "to open the print window.",
          },
          tabRemove: {
            title: "Removing bales from pre-laydown",
            pageDescription:
              "Removing a bale from pre-laydown means returning it to the source pile. Physically, this bale must be removed from the pre-laydown area and returned to the source pile.",

            step1: "Select the bales you wish to remove from the pre-laydown.",
            step2: "Click 'Remove Selected Bales'.",
            step3: "Confirm the deletion in the confirmation window.",
          },
        },
        cottonTypeSummary: {
          modalTitle: "Help - Laydown summary by cotton type",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the percentage representation of each cotton type in the laydown.",

            printText1: "Click ",
            printText2: "to open the print window.",
          },
        },
        originSummary: {
          modalTitle: "Help - Laydown summary by origin",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the percentage representation of each origin in the laydown.",

            printText1: "Click ",
            printText2: " to open the print window.",
          },
        },
        pileSummary: {
          modalTitle: "Help - Laydown summary by pile",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the percentage representation of each pile in the laydown.",

            printText1: "Click ",
            printText2: " to open the print window.",
          },
        },
        producerSummary: {
          modalTitle: "Help - Laydown Summary by Producer",
          tabOverview: {
            title: "Overview",
            pageDescription:
              "This report shows the percentage representation of each producer in the laydown.",

            printText1: "Click ",
            printText2: "to open the print window.",
          },
        },
      },
    },
    consumptionControl: {
      modalTitle: "Help - Consumption control",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "On this page you can set the consumption date for laydowns. A laydown only starts counting as consumed and appearing in the consumption reports after the consumption is confirmed.",
        pageDescription2:
          "When a laydown is finalized in the system, the user can at the same time confirm the consumption of the laydown, or, if they want to set the consumption later, it can be done here.",
        flow: "This is the workflow for the consumption control registration page.",
      },
      tabEdit: {
        title: "Confirming or changing the consumption of the laydown",
        step1: "Select the production line in the 'Production Line' field.",
        step2: "Select the status in the 'Status' field.",
        fieldDescription1:
          "Search all laydowns in the production line, including those whose consumption has already been confirmed to, if applicable, change the consumption date.",
        fieldDescription2:
          "Fetches the laydowns from the production line in which consumption confirmation is pending.",
        step3: "Find the laydown in the 'Laydowns' table and click on ",
        step4: "Select the consumption date of the laydown.",
      },
    },
    warnnings: {
      modalTitle: "Help - Warnnings",
      tabOverview: {
        title: "Overview",
        pageDescription:
          "On this page you can manage the alerts posted by ESSENTIABale when a laydown presents some kind of inconsistency that requires a manager's analysis.",
        pageDescription2:
          "The alerts are launched when a group with controlled use does not reach the expected amount of bales in the laydown, or when the average reached for some quality parameter is out of tolerance.",
        flow: "This is the workflow for the alerts page.",
      },
      tabDetails: {
        title: "Analyzing the details of the alert",
        step1: "Find the alert in the 'Quality Warnnings' table and click ",
        description1:
          "The table will show the quality parameters or groups with controlled usage that generated the alert. The person in charge must analyze if the changes bring any risk to production. If it is of the understanding that there are no risks, the mix can be authorized to proceed, otherwise the technical support must be consulted about actions that can be taken to circumvent the problem.",
      },
      tabAuthorize: {
        title: "Authorizing the use of laydowns",
        description1:
          "If it is the understanding that the changes do not pose a risk to production, the use of the laydown should be authorized for the laydown to be finalized.",
        step1: "Find the alert in the 'Quality Warnnings' table and click ",
        step2: "Confirm the authorization in the confirmation window.",
      },
    },
    inventoryReports: {
      quality: {
        modalTitle: "Help - Inventory quality Report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "The inventory quality report shows an overview of the inventory in terms of quality and quantity.",
          pageDescription2:
            "In addition to total inventory information, the report shows the available inventory information for each production line and the inventory of each group with controlled usage.",
          filterText: "Filter options:",
          filter1: "Production line.",
          filterDescription1:
            "Select a specific production line to show data only for this line and the groups with controlled use linked to it.",
          legendDescription1:
            "The amount of bales available for each line is calculated by the system based on the usage percentages of each controlled group.",
          legendDescription2:
            "Controlled groups that have too few bales in inventory can interfere with the calculation of the available inventory for each production line. The result is a reduction in the amount of bales available for the production line.",
          legendDescription3:
            "In this case the group in question will be highlighted in yellow, and its percentage of use in the laydowns should be corrected.",
          printText1: "Click on ",
          printText2: " to open the print window.",
        },
      },
      bales: {
        modalTitle: "Help - Bale in inventory report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows the bales in inventory in addition to the quality summary according to applied filters.",
          filterText: "Filter options:",
          filter1: "Situation",
          filterDescription1: "Status of bales in inventory. The options are:",
          option0: "All",
          optionDescription0: "All bales in inventory.",
          option1: "In use",
          optionDescription1:
            "Bales that are available for use in the laydowns.",
          option2: "Available for distribution",
          optionDescription2:
            "Bales that are available for distribution, that is that are not yet available for laydowns.",
          option3: "With quality",
          optionDescription3: "Bales that have all the quality values.",
          option4: "Without quality",
          optionDescription4: "Bales that do not have any quality value.",
          option5: "On Hold",
          optionDescription5: "Bales that are on hold.",
          option6: "In piles",
          optionDescription6:
            "Bales that are in the piles with the exception of bales that are in the prelaydown.",
          option7: "Leftover",
          optionDescription7: "Bales that are currently in the prelaydown",
          filter2: "Pile",
          filterDescription2: "Pile containing the bales",
          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      general: {
        modalTitle: "Help - general inventory report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows general inventory information in addition to the quality summary according to the filters and grouping options applied.",
          filterText: "Filter options:",
          filter1: "Supplier",
          filterDescription1: "Select to filter by a specific supplier.",
          filter2: "Producer",
          filterDescription2: "Select to filter by a specific producer.",
          filter3: "Lot",
          filterDescription3: "Select to filter by a specific lot.",
          filter4: "Pile",
          filterDescription4: "Select to filter by a specific pile.",
          filter5: "Cotton type",
          filterDescription5: "Select to filter by a specific cotton type.",
          filter6: "Production line",
          filterDescription6: "Select to filter by a specific production line.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1: "Select to group the results by supplier",
          grouping2: "Producer",
          groupingDescription2: "Select to group the results by producer",
          grouping3: "Lot",
          groupingDescription3: "Select to group results by lot",
          grouping4: "Pile",
          groupingDescription4: "Select to group the results by pile",
          grouping5: "Cotton type",
          groupingDescription5: "Select to group the results by cotton type.",

          printText1: "Click ",
          printText2: "to open the print window.",
        },
      },
      composition: {
        modalTitle: "Help - Inventory composition report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows the percentage composition of the inventory according to the applied filters and grouping options.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1:
            "Select to see the inventory composition by supplier.",
          grouping2: "Producer",
          groupingDescription2:
            "Select to see the inventory composition by producer.",
          grouping3: "Origin",
          groupingDescription3:
            "Select to see the inventory composition by origin.",
          grouping4: "Cotton type",
          groupingDescription4:
            "Select to see the inventory composition by cotton type.",

          filterText: "Filter options:",
          filter1: "Situation",
          filterDescription1: "Status of bales in inventory. The options are:",
          option1: "All",
          optionDescription1: "All bales in inventory.",
          option2: "On Hold",
          optionDescription2: "Bales that are on hold.",

          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      lotEntry: {
        modalTitle: "Help - Lot input report by period",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows the lots entry by period according to the filters applied.",
          filterText: "Filter options:",
          filter1: "Supplier",
          filterDescription1: "Select to filter by a specific supplier.",
          filter2: "Producer",
          filterDescription2: "Select to filter by a specific producer",
          filter3: "Origin",
          filterDescription3: "Select to filter by a specific origin.",
          filter4: "Cotton type",
          filterDescription4: "Select to filter by a specific cotton type.",
          filter5: "Period",
          filterDescription5: "Enter the period to filter by date.",

          printText1: "Click ",
          printText2: "to open the print window.",
        },
      },
    },
    consumptionReports: {
      general: {
        modalTitle: "Help - General consumption report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows general consumption information in addition to the quality summary according to the applied filters and grouping options.",
          filterText: "Filter options:",
          filter1: "Supplier",
          filterDescription1: "Select to filter by a specific supplier.",
          filter2: "Producer",
          filterDescription2: "Select to filter by a specific producer",
          filter3: "Cotton type",
          filterDescription3: "Select to filter by a specific cotton type",
          filter4: "Production line",
          filterDescription4: "Select to filter by a specific production line.",
          filter5: "Period",
          filterDescription5:
            "Select to filter by a specific consumption period.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1: "Select to group the results by supplier.",
          grouping2: "Producer",
          groupingDescription2: "Select to group the results by producer",
          grouping3: "Production line",
          groupingDescription3:
            "Select to group the results by production line",
          grouping4: "Pile",
          groupingDescription4: "Select to group the results by pile",
          grouping5: "Cotton type",
          groupingDescription5: "Select to group the results by cotton type",
          grouping6: "Laydown",
          groupingDescription6: "Select to group the results by laydown.",

          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      perDay: {
        modalTitle: "Help - Per day consumption report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows cotton consumption information per day according to the filters and grouping options applied.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1:
            "Select to see the inventory composition by supplier.",
          grouping2: "Producer",
          groupingDescription2:
            "Select to see the inventory composition by producer.",
          grouping3: "Origin",
          groupingDescription3:
            "Select to see the inventory composition by origin.",
          grouping4: "Cotton type",
          groupingDescription4:
            "Select to see the inventory composition by cotton type.",

          filterText: "Filter options:",
          filter1: "Production line",
          filterDescription1: "Select to filter by a specific production line.",
          filter2: "Period",
          filterDescription2:
            "Select to filter by a specific consumption period.",

          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      perMonth: {
        modalTitle: "Help - Per month consumption report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows cotton consumption information per month according to the filters and grouping options applied.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1:
            "Select to see the inventory composition by supplier.",
          grouping2: "Producer",
          groupingDescription2:
            "Select to see the inventory composition by producer.",
          grouping3: "Origin",
          groupingDescription3:
            "Select to see the inventory composition by origin.",
          grouping4: "Cotton type",
          groupingDescription4:
            "Select to see the inventory composition by cotton type.",

          filterText: "Filter options:",
          filter1: "Production line",
          filterDescription1: "Select to filter by a specific production line.",
          filter2: "Period",
          filterDescription2:
            "Select to filter by a specific consumption period.",

          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      perLaydown: {
        modalTitle: "Help - Consumption report by laydown",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows cotton consumption information per laydown according to the filters applied.",

          filterText: "Filter options:",
          filter1: "Production line",
          filterDescription1: "Select to filter by a specific production line.",
          filter2: "Yarn lot.",
          filterDescription2:
            "Select to filter by a specific yarn lot. The yarn lot is entered when creating the pre-laydown.",
          filter3: "Period",
          filterDescription3:
            "Select to filter by a specific consumption period.",

          printText1: "Click ",
          printText2: "to open the print window.",
        },
      },
      composition: {
        modalTitle: "Help - Consumption composition report",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows the percentage composition of the consumption according to the applied filters and grouping options.",

          groupingText: "Grouping options:",
          grouping1: "Supplier",
          groupingDescription1:
            "Select to see the consumption composition by supplier.",
          grouping2: "Producer",
          groupingDescription2:
            "Select to see the consumption composition by producer.",
          grouping3: "Origin",
          groupingDescription3:
            "Select to see the consumption composition by origin.",
          grouping4: "Cotton type",
          groupingDescription4:
            "Select to see the consumption composition by cotton type.",

          filterText: "Filter options:",
          filter1: "Production line",
          filterDescription1: "Select to filter by a specific production line.",
          filter2: "Period",
          filterDescription2:
            "Select to filter by a specific consumption period.",

          printText1: "Click on ",
          printText2: "to open the print window.",
        },
      },
      avgCharts: {
        modalTitle: "Help - Laydownture Averages Charts",
        tabOverview: {
          title: "Overview",
          pageDescription:
            "This report shows in graphs the evolution of the averages and the coefficient of variation (CV) of each quality parameter in the laydowns according to the applied filters.",

          filterText: "Filter options:",
          filter1: "Production line",
          filterDescription1: "Select to filter by a specific production line.",
          filter2: "Yarn lot",
          filterDescription2:
            "Select to filter by a specific yarn lot. The yarn lot is entered when creating the pre-laydown.",
          filter3: "Period",
          filterDescription3:
            "Select to filter by a specific consumption period.",
          filter4: "Show by",
          filterDescription4:
            "Select 'Average' to have the graphs show the quality averages, or 'CV' to have the graphs show the coefficient of variation (CV) of the laydowns.",
          filter5: "Show tolerances of",
          filterDescription5:
            "Select 'Production' so that the tolerance shown is that of production, or 'Yarn Lot' so that the tolerance shown is that of the yarn lot. Tolerances are defined in the quality settings of the production line.",

          printText1: "Click ",
          printText2: " to open the print window.",
        },
      },
    },
  },
};

export { translation };
