const translation = {
  userGuiding: {
    common: {
      roleRequired:
        "Nivel de privilegio necesario para acceder a esta página: ",
      roleBasic: "Basic",
      roleAdvanced: "Advanced",
      roleManager: "Manager",
      cancel: "Cancelar / salir.",
      save: "Guardar.",
    },
    introduction: {
      modalTitle: "Descripción general de ESSENTIALbale",
      tabOverview: {
        title: "Visión general",
        description:
          "ESSENTIALbale es un sistema que controla con precisión las mezclas de pacas de algodón utilizando todos los parámetros de calidad proporcionados por HVI, además de otros parámetros proporcionados por la empresa.",
        description2:
          "El objetivo principal del sistema es eliminar las variaciones entre mezclas, asegurando la producción de hilos de alta calidad.El sistema controla el inventario y la calidad del algodón desde la llegada de las pacas hasta el consumo en la sala de apertura permitiendo así la producción de grandes lotes de hilo sin variación en la calidad.",
        description3:
          "Con una metodología única en el mercado, el sistema elimina la separación del inventario en clases. Sólo se necesitan los datos de HVI de cada paca, con esta información el software planifica y elabora las mezclas controlando la variación y manteniendo la uniformidad del proceso.",
        description4:
          "ESSENTIALbale es un sistema totalmente flexible. El sistema está diseñado para adaptarse a las más diversas formas de trabajo, tanto en lo que se refiere al proceso de mezcla como a la gestión de las inventario de algodón.",
      },
      tabOverflow: {
        title: "Flujo general del sistema",
        description: "Este es el flujo general del sistema:",
      },
      tabUserLevels: {
        title: "Niveles de privilegio de los usuarios",
        description:
          "Hay 3 niveles de privilegios que se pueden asignar a los usuarios en ESSENTIALbale:",
        field1: "Basic",
        fieldDescription1:
          "El usuario tiene acceso a la función de registro, como productores, proveedores, orígenes, tipos de algodón, pilas, diseños de archivos HVI. El usuario básico también puede gestionar el inventario y generar informes",
        field2: "Advanced",
        fieldDescription2:
          "Además de todas las funciones contenidas en el nivel 'Basic', el usuario 'Advanced' puede acceder al registro de parámetros, tipos de mezcla, líneas de producción, clases de líneas de producción además de gestionar todas las funciones del proceso de mezcla.",
        field3: "Manager",
        fieldDescription3:
          "Además de todas las funciones contenidas en los niveles 'Basic' y 'Advanced', el usuario 'Manager' puede crear y gestionar nuevas plantas, añadir nuevos usuarios, gestionar las advertencias de calidad así como comprobar los registros de acciones de los usuarios",
        description2:
          "Para crear un nuevo usuario, vaya a REGISTRO > USUARIOS.",
      },
      tabUserPreferences: {
        title: "Configuración regional y de idioma",
        description:
          "Cada usuario puede establecer su propia configuración de idioma y regio para optimizar la experiencia de usuario.",
        step1: "Haga clic en el icono ",
        step1_1:
          " situado en la esquina superior derecha de cualquier página y, a continuación, haga clic en la opción 'Preferencias'.",
        step2: "Defina los siguientes ajustes:",
        field1: "Idioma",
        fieldDescription1: "Idioma utilizado en las páginas.",
        field2: "Configuración regional",
        fieldDescription2:
          "Configuración de región para formatear valores numéricos y fechas.",
        field3: "Unidad de medida para el peso",
        fieldDescription3:
          "Unidad de medida utilizada para dar formato a los campos de peso.",
      },
    },
    plant: {
      modalTitle: "Ayuda - Unidades de producción",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Las unidades de producción son los diferentes sitios de producción. Una hilandería puede tener varias unidades de producción. Cada unidad de producción tiene sus propias líneas de producción y inventario. Cierta información, por otro lado, es común a todas las unidades de producción registradas, como registro de proveedores, parámetros de calidad, entre otros.",
        flow: "Este es el flujo de trabajo de la página de unidades de producción:",
      },
      tabAdd: {
        title: "Creación de una nueva unidad",
        step1:
          "En la página de registro de la unidad, haga clic en 'Nueva unidad'.",
        step2: "Rellena los campos.",
        fieldDescription1: "código de identificación de la unidad.",
        fieldDescription2: "Nombre o descripción de la unidad",
      },
      tabEdit: {
        title: "Alteración del nombre de la unidad",
        step1:
          "En la página de registro de la unidad, busque la unidad de producción deseada m haga clic en ",
        step2: "Introduzca el nuevo nombre de la unidad.",
      },
      tabSettings: {
        title: "Configuración de la unidad",
        step1:
          "En la página de registro de la unidad, busque la unidad de producción deseada y haga clic en ",
        step2: "Cambie la información como desee.",
        fieldDescription1:
          "Introduzca el peso mínimo permitido para una paca. El peso se puede definir en kilogramos o libras, según las opciones del usuario..",
        fieldDescription2:
          "Introduzca el peso máximo permitido para una paca. El peso se puede definir en kilogramos o libras, según las opciones del usuario.",
        fieldDescription3:
          "Marque esta opción si la unidad no prueba el 100 % de los fardos cuando llega el algodón.",
        fieldDescription4:
          "Marque esta opción, la calidad de los fardos que se encuentran en la premezcla se debe considerar al hacer la planificación de la calidad.",
      },
    },
    parameters: {
      modalTitle: "Ayuda - Parámetros de calidad",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los parámetros de calidad son las características físicas o químicas del algodón. Los parámetros más comunes son los medidos por una máquina HVI, sin embargo, cualquier parámetro que se mide en números puede ser registrado y controlado por ESSENTIALbale en las mezclas.",
        flow: "Este es el flujo de trabajo de la página de registro de parámetros de calidad:",
      },
      tabAdd: {
        title: "Crear un nuevo parámetro manualmente",
        step1:
          "En la página de registro de parámetros, haga clic en 'Nuevo parâmetro' y luego seleccione 'Manualmente'.",
        step2: "Rellene los campos:",
        fieldDescription1:
          "Nombre completo del parámetro. Por ejemplo: Micronaire",
        fieldDescription2:
          "Nombre abreviado del parámetro. Por ejemplo: micrófono. El nombre abreviado es lo que aparecerá siempre que se utilice el parámetro en ESSENTIALbale",
        fieldDescription3:
          "Valor mínimo permitido para el parámetro. Al agregar una paca al inventario, el sistema verificará si el valor del parámetro es válido.",
        fieldDescription4:
          "Valor maximo permitido para el parámetro. Al agregar una paca al inventario, el sistema verificará si el valor del parámetro es válido.",
        fieldDescription5:
          "La diferencia entre los valores mínimo y máximo más comunes.",
        fieldDescription6:
          "Establece el zoom aplicado al parámetro en los gráficos de mezcla promedio para una mejor experiencia de usuario. 1 es el valor más común. Sin embargo, el valor de la escala para los parámetros con valores mayores debería ser mayor en consecuencia.",
        fieldDescription7:
          "Factor de multiplicación de parámetros para el cálculo del CSP.",
        fieldDescription8:
          "Factor de multiplicación de parámetros para el cálculo del SCI.",
        fieldDescription9:
          "Marque esta opción para asignar una calidad promedio del inventario cuando la paca nueva no contiene valores definidos para este parámetro.",
        fieldDescription10:
          "Check this option if the quality results for this parameter are released manually by the user.",
        fieldDescription11:
          "Marque esta opción si se debe controlar el coeficiente de variación (CV) del parámetro para evitar variaciones en las mezclas.",
        fieldDescription12:
          "Marque esta opción en caso de que el parámetro sea CSP o SCI específicamente y su valor sea calculado automáticamente por el sistema.",
      },
      tabAddPreConfigured: {
        title: "Adición de un parámetro preconfigurado.",
        step1:
          "En la página de registro de parámetros, haga clic en 'Nuevo parâmetro' y luego seleccione 'Preconfigurado'.",
        step2:
          "Localice el parámetro en la lista de parámetros disponibles y haga clic en '+'.",
      },
      tabEdit: {
        title: "Edición de un parámetro",
        step1:
          "En la página de registro de parámetros, busque el parámetro en la tabla de parámetros registrados y haga clic en",
        step2: "Cambie los campos.",
      },
      tabReordering: {
        title: "Cambiando el orden de los parámetros",
        step1:
          "En la página de registro de la unidad, haga clic en 'Reordenar'",
        step2:
          "Haga clic en el parámetro y arrástrelo a la posición deseada. Los parámetros se mostrarán en el orden definido aquí en todas partes dentro de ESSENTIALbale.",
      },
    },
    mixTypes: {
      modalTitle: "Ayuda - Tipos de mezcla",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los tipos de mezcla abarcan un conjunto de configuraciones que se pueden aplicar a las líneas de producción. Una hilandería puede tener diferentes tipos de mezcla, y cada tipo de mezcla puede tener varias líneas de producción conectadas.",
        flow: "Este es el flujo de trabajo de la página de registro del tipo de mezcla:",
      },
      tabAdd: {
        title: "Crear nuevo tipo de mezcla",
        step1:
          "En la página de registro del tipo de mezcla, haga clic en 'Nuevo tipo de mezcla'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Nombre o descripción del tipo de mezcla.",
        fieldDescription2:
          "Código de la clase vinculada al tipo de mezcla. El código de clase se utiliza para definir qué pilas están disponibles para su uso en las líneas de producción vinculadas al tipo de mezcla.",
        fieldDescription3:
          "Número mínimo de lotes necesarios para el proceso de mezcla. Solo se utiliza cuando la opción 'Prueba parcial de pacas' en el registro de unidades de producción es verdadera.",
        fieldDescription4:
          "Inventario mínimo en días requerido para el proceso de mezcla.",
        fieldDescription5:
          "Consumo mensual estimado para el tipo de mezcla. Se puede configurar en kilogramos o libras.",
      },
      tabEdit: {
        title: "Cambiar un tipo de mezcla",
        step1:
          "En la página de registro de tipos di mezclas, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2: "Cambia los campos.",
      },
      tabDelete: {
        title: "Eliminar un tipo de mezcla",
        step1:
          "En la página de registro de tipos di mezclas, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2:
          "Será posible eliminar el tipo de mezcla siempre que no tenga conectadas líneas de producción.",
      },
      tabAddQualityRange: {
        title: "Añadir rangos de calidad",
        pageDescription:
          "Los rangos de calidad se utilizan para difinir la calidad aceptada para el stock tipo mitra en la distribución de algodón.",
        pageDescription2:
          "Cuando existe un rango definido para algún parámetro de calidad, los lotes o pacas con calidad fuera de este rango no serán aceptados en el stock del tipo mezcla.",
        step1:
          "En la página de registro de tipos de mezcla, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2: "Haga clic en 'Nuevo rango de calidad'",
        step3: "Rellene los campos:",
        fieldDescription1: "Seleccione el parámetro de calidad",
        fieldDescription2: "Valor mínimo aceptado para el parámetro",
        fieldDescription3: "Valor máximo aceptado para el parámetro.",
        fieldDescription4: "Tipo de clasificación. Las opciones son:",
        option0: "Media del lote",
        optionDescription0:
          "Considerar la calidad media del lote como referencia. En este caso, si la media está dentro del intervalo definido, todo el lote puede añadirse a las existencias del tipo de mezcla, aunque algunas pacas tengan valores fuera del intervalo definido",
        option1: "Paca por paca",
        optionDescription1:
          "Considerar la calidad individual de cada paca. En este caso, el lote puede fraccionarse y sólo las pacas que tengan una calidad dentro del rango definido pueden añadirse al stock del tipo de mezcla.",
      },
      tabEditQualityRange: {
        title: "Modificar rangos de calidad",
        step1:
          "En la página de registro de tipos de mezcla, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2:
          "En la ventana de rangos de calidad, busque el parámetro en la tabla de rangos de calidad y haga clic en ",
        step3: "Cambiar los campos",
        fieldDescription1:
          "Opcionalmente, se puede añadir una breve descripción en el campo 'Motivo del cambio' para crear un historial de cambios.",
      },
      tabDeleteQualityRange: {
        title: "Eliminar rangos de calidad",
        step1:
          "En la página de registro de tipos de mezcla, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2:
          "En la ventana de rangos de calidad, busque el parámetro en la tabla de rangos registrados y haga clic en ",
      },
    },
    productionLines: {
      modalTitle: "Ayuda - Líneas de producción",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Las líneas de producción son las mezclas o aperturas.",
        flow: "Este es el flujo de trabajo de la página de registro de líneas de producción:",
      },
      tabAdd: {
        title: "Creando nueva linea",
        step1:
          "En la página de registro de la línea de producción, haga clic en 'Nueva línea de producción'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Indica si la línea de producción está activa o no.",
        fieldDescription2:
          "Indica si la línea usa pre-laydown, es decir, tiene un paso de pre-laydown en el proceso de mezclado.",
        fieldDescription3: "Tipo de mezcla a la que se une la línea.",
        fieldDescription4: "Nombre o descripción de la línea de producción.",
        fieldDescription5: "Número de pacas utilizadas en cada depósito.",
        fieldDescription6:
          "Cantidad de pacas utilizadas en el pre-laydown. Por lo general, un 50% más que la cantidad de fardos definidos para depositar.",
        fieldDescription7:
          "Número de pacas en cada sub-mezcla. Los sub-mezclas son los pequeños grupos de pacas dentro de la mizma mezcla.",
        fieldDescription8:
          "Número de pacas consumidas por día en esta línea de producción.",
        fieldDescription9:
          "Número de días a considerar para controlar la variación de calidad de las mezclas.",
        fieldDescription10:
          "Código o identificador de la pre-mezcla. Las líneas de producción que comparten el mismo inventario deben tener los mismos códigos de pre-mezcla. De lo contrario, los códigos de pre-mezcla deben ser diferentes.",
        fieldDescription11:
          "Código o identificador del lote de producción. Las líneas de producción que producen el mismo tipo y calidad de hilo deben tener el mismo lote de producción. De lo contrario, los lotes de producción deben ser diferentes.",
      },
      tabEdit: {
        title: "Edición de una línea",
        step1:
          "En la página de registro de la línea de producción, busque la línea en la tabla de líneas registradas, haga clic en ",
        step2: "Edite los campos.",
      },
      tabSettings: {
        title: "Configurando la calidad",
        step1:
          "En la página de registro de la línea de producción, busque la línea en la tabla de líneas registradas, haga clic en ",
        step2:
          "Cambie los valores directamente en las celdas de la tabla. Los ajustes de calidad deben ser definidos por la gestión de calidad de la hilandería.",
        fieldDescription1:
          "Prioridad o peso asignado al parámetro en el proceso de mezcla.",
        fieldDescription2:
          "Variación aceptable de la calidad media del parámetro considerando las últimas mezclas. El número de mezclas en las que se controla la variación se determina multiplicando el consumo diario por el número de días de control de producción que se determinaron en el registro de línea. Por ejemplo, teniendo un consumo de 1 mezcla al día y un control de producción de 7 días, tendremos 7 mezclas. Por lo tanto, la variación de la calidad promedio en las últimas 7 mezclas debe estar dentro de la tolerancia aquí definida.",
        fieldDescription3:
          "Variación aceptable del parámetro, para determinar que se debe cambiar el lote del producto elaborado. Cuando la variación sea mayor a lo aceptable, el sistema generará una alerta para cambiar el lote de hilo.",
        fieldDescription4:
          "Porcentaje de variación aceptable del coeficiente de variación (CV) del parámetro entre una mezcla y otra.",
        fieldDescription5:
          "Coeficiente de variación (CV) máximo aceptable para el parámetro en las mezclas.",
        fieldDescription6:
          "Indica si el sistema debe generar una alerta cuando la calidad promedio del parámetro cae por debajo del rango aceptable en una determinada mezcla.",
        fieldDescription7:
          "Indica si el sistema debe generar una alerta cuando la calidad media del parámetro está por encima del rango aceptable en una determinada mezcla.",
        fieldDescription8:
          "Mvalor mínimo permitido para que una paca de algodón sea utilizado en las mezclas de esta línea de producción. No se aceptarán pacas con valores inferiores al aquí definido en la pre-mezcla.",
        fieldDescription9:
          "Valor máximo permitido para un fardo de algodón a ser utilizado en las mezclas de esta línea de producción. No se aceptarán pacas con valores superiores al aquí definido en la pre-mezcla.",
      },
      tabDelete: {
        title: "Eliminación de una línea",
        step1:
          "En la página de registro de líneas de producción, busque la línea en la tabla de líneas registradas y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        step3: "Las líneas de producción no se pueden eliminar si:",
        reason1:
          "La línea está ligada a pre-mezclas existentes. En este caso, se deben eliminar todos las pre-mezclas conectados a la línea.",
        reason2:
          "La línea está vinculada a alguna clase de línea de producción registrada. En este caso, las clases vinculadas a la línea deben ser excluidas.",
      },
      tabWaste: {
        title: "Definición de posiciones de resíduos",
        description:
          "Las posiciones de resíduos son espacios en la mezcla reservados para pacas de resíduos producidas internamente que se pueden incorporar a mezcla, siempre que sea en cantidades mínimas.",
        step1:
          "En la página de registro de líneas de producción, busque la línea en la tabla de líneas registradas y haga clic en ",
        step2:
          "Para agregar una nueva posición de resíduos, haga clic en 'Nueva posición' en la ventana.",
        step3:
          "Para eliminar una nueva posición de resíduos, búsquela en la tabla y haga clic en ",
      },
    },
    productionLineClasses: {
      modalTitle: "Ayuda - Clases de línea de producción",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Las clases de línea de producción sirven para vincular las pilas a una línea de producción. El primer carácter del nombre de las pilas registradas identifica la clase, por lo que el sistema identifica qué pilas forman parte del inventario de la línea de producción.",
        flow: "Este es el flujo de trabajo de la página de registro de clase de línea de producción:",
      },
      tabAdd: {
        title: "Creando nueva clase",
        step1:
          "En la página de registro de clases, haga clic en 'Nueva clase de línea de producción'.",
        step2: "Seleccione la línea de producción.",
        step3:
          "Informar a la clase. Puede ser una letra o un número. En el caso de letras, es recomendable utilizar mayúsculas.",
      },
      tabDelete: {
        title: "Eliminando una clase",
        step1:
          "En la página de registro de clases, busque la clase en la tabla de clases registradas y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
      },
    },
    producers: {
      modalTitle: "Ayuda - proveedores",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los proveedores pueden ser la persona o incluso la finca que cultiva el algodón. La identificación de proveedores permite filtrar consultas en informes así como dosificar el uso de algodón.",
        flow: "Este es el flujo de trabajo para la página de registro del productor:",
      },
      tabAdd: {
        title: "Creando un nuevo productor",
        step1:
          "En la página de registro del productor, haga clic en 'Nuevo productor'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Nombre o descripción del productor.",
      },
      tabEdit: {
        title: "Cambiar el nombre del productor",
        step1:
          "En la página de registro del productor, busque el productor en la tabla de proveedores registrados y haga clic en ",
        step2: "Cambia el nombre.",
      },
      tabDelete: {
        title: "Eliminar un productor",
        step1:
          "En la página de registro del productor, busque el productor en la tabla de productores registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si existen pacas en el inventario vinculado al productor, no será posible la exclusión.",
      },
    },
    suppliers: {
      modalTitle: "Ayuda - Proveedores",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los proveedores pueden ser la persona o incluso la finca que cultiva el algodón. La identificación de proveedores permite filtrar consultas en informes así como dosificar el uso de algodón.",
        flow: "Este es el flujo de trabajo para la página de registro del proveedor:",
      },
      tabAdd: {
        title: "Creando un nuevo proveedor",
        step1:
          "En la página de registro del proveedor, haga clic en 'Nuevo proveedor'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Nombre o descripción del proveedor.",
      },
      tabEdit: {
        title: "Cambiar el nombre del proveedor",
        step1:
          "En la página de registro del proveedor, busque el proveedor en la tabla de proveedores registrados y haga clic en ",
        step2: "Cambia el nombre.",
      },
      tabDelete: {
        title: "Eliminar un proveedor",
        step1:
          "En la página de registro del proveedor, busque el proveedor en la tabla de proveedores registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si existen pacas en el inventario vinculado al proveedor, no será posible la exclusión.",
      },
    },
    cottonTypes: {
      modalTitle: "Ayuda - Tipos de algodón",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los tipos de algodón pueden ser cualquier identificador que tipifique el algodón. Los nombres de los tipos de algodón varían según el país o la región.",
        flow: "Este es el flujo de trabajo para la página de registro del tipo de algodón:",
      },
      tabAdd: {
        title: "Creando un nuevo tipo de algodón",
        step1:
          "En la página de registro del tipo de algodón, haga clic en 'Nuevo tipo de algodón'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Nombre o descripción del tipo de algodón.",
      },
      tabEdit: {
        title: "Cambiar el nombre del tipo de algodón",
        step1:
          "En la página de registro del tipo de algodón, busque el tipo de algodón en la tabla de tipos de algodón registrados y haga clic en ",
        step2: "Cambia el nombre.",
      },
      tabDelete: {
        title: "Eliminar un tipo de algodón",
        step1:
          "En la página de registro del tipo de algodón, busque el tipo de algodón en la tabla de tipos de algodón registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si existen pacas en el inventario vinculado al tipo de algodón, no será posible la exclusión.",
      },
    },
    origins: {
      modalTitle: "Ayuda - Origens",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los orígenes pueden ser el país o la región de donde proviene el algodón.",
        flow: "Este es el flujo de trabajo para la página de registro del origen:",
      },
      tabAdd: {
        title: "Creando un nuevo origen",
        step1:
          "En la página de registro del origen, haga clic en 'Nuevo origen'.",
        step2: "Rellene los campos:",
        fieldDescription1: "Nombre o descripción del origen.",
      },
      tabEdit: {
        title: "Cambiar el nombre del origen",
        step1:
          "En la página de registro del origen, busque el origen en la tabla de origens registrados y haga clic en ",
        step2: "Cambia el nombre.",
      },
      tabDelete: {
        title: "Eliminar un origen",
        step1:
          "En la página de registro del origen, busque el origen en la tabla de origens registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si existen pacas en el inventario vinculado al origen, no será posible la exclusión.",
      },
    },
    piles: {
      modalTitle: "Ayuda - Pilas",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Las pilas sirven para dividir el inventario, e incluso pueden ser una representación del espacio físico del almacén de algodón.",
        pageDescription2:
          "Siempre que un lote esté disponible a través de la pagina 'Distribución de algodón', debe asignarse en una pila vinculada a la línea de producción para la que estará disponible el lote.",
        flow: "Este es el flujo de trabajo de la página de registro de pilas:",
      },
      tabAdd: {
        title: "Creación de nuevas pilas en inventario",
        step1:
          "En la página de registro de la pila, haga clic en 'Nueva pila'.",
        step2: "Rellene los campos:",
        fieldDescription1:
          "Identificación celular. Debe contener una letra o número que identifique la clase de línea de producción a la que se conecta la pila, seguido de un guión y un número correlativo o no. Por ejemplo: 'P-01', P-05, 'B-10', B-11', etc.",
        fieldDescription2:
          "Indica si el sistema debe crear automáticamente múltiples pilas, a partir de la identificación de la primera pila y generando una secuencia hasta la cantidad definida. De esta manera, no es necesario registrar todas las pilas manualmente.",
        fieldDescription3:
          "Número de pilas a generar automáticamente, si es el caso.",
      },
      tabDelete: {
        title: "Eliminación de una o varias pilas",
        step1:
          "En la página de registro de pilas, seleccione todas las pilas que desea eliminar y luego haga clic en 'Eliminar'.",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si hay pacas en inventario asignados en la pila, no será posible eliminarla.",
      },
    },
    hviLayouts: {
      modalTitle: "Ayuda - Diseño de archivos HVI",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Para poder importar archivos con resultados HVI o cualquier archivo que contenga la calidad de los fardos, es necesario informar al sistema del diseño de estos archivos.",
        pageDescription2:
          "Por lo general, se trata de hojas de cálculo de Excel o archivos de texto, y para que el sistema lea correctamente estos archivos, es necesario registrar los diseños.",
        pageDescription3:
          "En resumen, debe informar qué columnas del archivo contienen el número de paca, además de los valores de cada parámetro de calidad registrado.",
        flow: "Este es el flujo de trabajo de la página de registro de diseño:",
      },
      tabAdd: {
        title: "Adicionando nuevos diseños",
        step1:
          "En la página de registro de diseños, haga clic en 'Nuevo diseño'.",
        step2: "Introduzca el nombre o la descripción del diseño.",
      },
      tabEdit: {
        title: "Cambiar la descripción del diseño",
        step1:
          "En la página de registro de diseños, búsquelo en la tabla de diseños registrados y haga clic en ",
        step2: "Cambie la descripción del diseño.",
      },
      tabColumns: {
        title: "Configuración del diseño",
        step1:
          "En la página de registro de diseños, búsquelo en la tabla de diseños registrados y haga clic en ",
        step2: "Introduzca valores directamente en las celdas de la tabla.",
        fieldDescription1:
          "Número de columna que contiene el número de fardo o los valores de calidad del archivo.",
        fieldDescription2:
          "Multiplicador de conversión. Se utiliza para convertir valores de calidad de milímetros a pulgadas, por ejemplo.",
      },
      tabDelete: {
        title: "Eliminación de un diseño",
        step1:
          "En la página de registro del diseño, búsquelo en la tabla y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
      },
    },
    users: {
      modalTitle: "Ayuda - Usuarios",
      tabOverview: {
        title: "Visión general",
        pageDescription: "Página de registro y gestión de usuarios",
        flow: "Este es el flujo de trabajo para la página de registro de usuarios:",
      },
      tabAdd: {
        title: "Crear un nuevo usuario",
        step1:
          "En la página de registro de usuarios, haga clic en 'Nuevo usuario'.",
        step2: "Rellene los campos:",
        fieldDescription1:
          "ID de usuario para el inicio de sesión. Debe ser único y no puede contener espacios.",
        fieldDescription2: "Dirección de correo electrónico del usuario",
        fieldDescription3: "Nombre del usuario",
        fieldDescription4: "Apellido del usuario",
        fieldDescription5:
          "Nivel de privilegios del usuario. Los niveles se utilizan para determinar a qué páginas puede acceder el usuario. Las opciones son:",
        option1: "Basic",
        optionDescription1:
          "El usuario tiene acceso a la función de registro, como productores, proveedores, orígenes, tipos de algodón, esquejes, diseños de archivos HVI. El usuario 'Basic' también puede gestionar las existencias y generar informes.",
        option2: "Advanced",
        optionDescription2:
          "Además de todas las funciones contenidas en el nivel 'Basic', el usuario 'Advanced' puede acceder al registro de parámetros, tipos de mezcla, líneas de producción, clases de líneas de producción, así como gestionar todas las funciones del proceso de mezcla.",
        option3: "Manager",
        optionDescription3:
          "Además de todas las funciones contenidas en los niveles 'Basic' y 'Advanced', el usuario 'Manager' puede crear y gestionar nuevas plantas, añadir nuevos usuarios, gestionar los avisos de calidad, así como consultar los registros de acciones de los usuarios.",
        description1:
          "La contraseña por defecto para todos los nuevos usuarios es 'change_me'. Al iniciar sesión en la app por primera vez, se le pedirá al usuario que cambie la contraseña introduciendo esta contraseña por defecto.",
      },
      tabEdit: {
        title: "Modificar los datos de un usuario",
        step1:
          "En la página de registro de usuarios, busque el usuario en la tabla de usuarios registrados y haga clic en ",
        step2: "Cambiar los campos",
        descripción1:
          "Para cambiar la contraseña, utiliza la opción 'Olvidé la contraseña' en la pantalla de inicio de sesión.",
      },
      tabDelete: {
        title: "Eliminar un usuario",
        step1:
          "En la página de registro de usuarios, busque el usuario en la tabla de usuarios registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
      },
    },
    lotInput: {
      modalTitle: "Ayuda - Registro de lotes",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "pagina de lanzamiento de lotes de algodón en inventario. Los lotes también pueden ser facturas.",
        flow: "Este es el flujo de trabajo de la página de registro de lotes:",
      },
      tabAdd: {
        title: "Agregar nuevos lotes",
        step1:
          "En la página de registro de lotes, seleccione el proveedor en el cuadro de selección 'Proveedor' y luego haga clic en 'Nuevo lote'.",
        step2: "Introduzca los datos del nuevo lote.",
        fieldDescription1: "Número de pacas contenidas en el lote.",
        fieldDescription3:
          "Peso bruto del lote. Se puede configurar en kilogramos o libras según las preferencias del usuario.",
        fieldDescription4:
          "Peso neto del lote. Se puede configurar en kilogramos o libras según las preferencias del usuario.",
        fieldDescription5:
          "Nombre del productor. El productor debe estar previamente registrado.",
        fieldDescription6:
          "Tipo algodón. El tipo debe estar registrado previamente.",
        fieldDescription7:
          "Origen del algodón. El origen debe estar previamente registrado.",
        fieldDescription8: "Fecha de ingreso del lote a la unidad.",
        fieldDescription9:
          "Nombre del clasificador, es decir, el laboratorio que realizó la clasificación HVI de los fardos.",
        fieldDescription10: "Número de contrato de compra.",
        fieldDescription11:
          "Número de lote de compra. El lote HVI se puede introducir aquí, por ejemplo.",
      },
      tabEdit: {
        title: "Editando el lote",
        step1:
          "En la página de registro de lotes, búsquelo en la tabla de lotes registrados y haga clic en ",
        step2: "Cambia los campos.",
      },
      tabBales: {
        title: "Adición de pacas al lote",
        step1:
          "En la página de registro de lotes, busque el lote en la tabla de lotes registrados y haga clic en ",
        step2: "Las instrucciones están en la página de pacas.",
      },
      tabDelete: {
        title: "Eliminando un lote",
        step1:
          "En la página de registro de lotes, busque el lote en la tabla y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Si el lote contiene fardos registrados, no será posible excluirlo.",
      },
    },
    baleInput: {
      modalTitle: "Ayuda - Entrada de pacas",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Página para introducir las pacas de un lote. Las pacas pueden introducirse manualmente o mediante la importación de archivos.",
        flow: "Este es el flujo de trabajo para la página de entrada de pacas:",
      },
      tabAdd: {
        title: "Crear nuevas pacas manualmente",
        step1:
          "En la página de entrada de pacas, haga clic en 'Nueva paca' y seleccione 'Manualmente'.",
        step2: "Fill in the fields.",
        fieldDescription1: "Número de paca. Puede contener letras o números.",
        fieldDescription2:
          "Indica si el sistema debe crear múltiples pacas automáticamente, empezando por el número de la primera paca y generando una secuencia hasta la cantidad definida.",
        fieldDescription3:
          "Cantidad de pacas que se generarán automáticamente, si procede.",
      },
      tabImportation: {
        title: "Importar pacas de un archivo",
        step1:
          "En la página de entrada de pacas, haga clic en 'Nueva paca' y seleccione 'Desde archivo'. ",
        step2:
          "Las instrucciones para esta función se encuentran en la página de importación de archivos.",
      },
      tabDelete: {
        title: "Eliminando pacas",
        step1:
          "En la página de entrada de pacas, seleccione todas las pacas que desee eliminar y haga clic en 'Eliminar pacas'.",
        step2: "Confirme la eliminación en la ventana de confirmación.",
        reason1:
          "Sólo pueden eliminarse las pacas que se encuentran en el inventario. Las pacas que ya se han consumido no se pueden eliminar.",
      },
    },
    baleInputImportation: {
      modalTitle: "Ayuda - Importar archivo",
      tabOverview: {
        title: "Visión general",
        pageDescription: "Página para importar pacas desde un archivo. ",
        flow: "Este es el flujo de trabajo de la página de importación de pacas:",
      },
      tabAdd: {
        title: "Importar un archivo.",
        step1:
          "Seleccione el diseño correspondiente al fichero que se va a importar. El diseño debe estar previamente registrada y configurada.",
        step2:
          "Haga clic en 'Seleccionar archivo' y busque el archivo en su ordenador. Se aceptan archivos en formato de hoja de cálculo Excel o archivos de texto.",
        step3: "Vea a continuación un ejemplo de archivo a importar.",
        step4:
          "Tras seleccionar el archivo, los datos se mostrarán en la tabla:",
        step5:
          "ESSENTIALbale intentará identificar las pacas y su respectiva información de calidad en el archivo.",
        step6:
          "Las columnas resaltadas en rojo indican una divergencia entre el diseño registrado y el diseño del fichero, en cuyo caso no será posible importar los datos y deberán realizarse correcciones en el diseño registrado.",
        step7:
          "Las columnas resaltadas en verde indican que la columna se ajusta a la disposición registrada.",
        step8: "Haga clic en 'Incluir pacas'.",
      },
    },
    baleManagement: {
      modalTitle: "Ayuda - Gestión de pacas",
      tabOverview: {
        title: "Visióm general",
        pageDescription:
          "Página de gestión de pacas en inventario, donde es posible cambiar el estado de las pacas o incluso cambiar la pila en la que está asignada la paca.",
        pageDescription2:
          "Existen dos tipos de estado que pueden asignarse a las pacas:",
        field1: "En espera",
        fieldDescription1:
          "La paca se pone en modo de espera y, por lo tanto, no está disponible para su uso en mezclas.",
        field2: "Disponible",
        fieldDescription2:
          "La paca pasa del modo en espera al modo disponible, por lo que vuelve a estar disponible para su uso en la mezcla.",
        flow: "Este es el flujo de trabajo de la página de gestión de pacas:",
      },
      tabStatus: {
        title: "Cambiar el status",
        step1:
          "Seleccione la opción deseada en el cuadro de selección 'Status del inventario' para buscar las pacas en el inventario.",
        step2: "Seleccione en la tabla las pacas cuyo status desea modificar.",
        step3:
          "Haga clic en 'Poner en espera', para poner las pacas en modo de espera, o en 'Poner a disponible' para sacar las pacas del modo de espera y que vuelvan a estar disponibles para su uso.",
      },
      tabPile: {
        title: "Cambiar la pila",
        step1:
          "Seleccione la opción deseada en el cuadro de selección 'Status del inventario' para buscar las pacas en el inventario.",
        step2:
          "Seleccione en la tabla las pacas para las que desea cambiar la pila.",
        step3: "Haga clic en 'Cambiar pila'.",
        step4: "Seleccione la nueva pila que debe asignarse a las pacas.",
      },
    },
    lotManagement: {
      modalTitle: "Ayuda - Gestión de lotes",
      tabOverview: {
        title: "Visióm general",
        pageDescription:
          "Página de gestión de lotes en inventario, donde es posible cambiar el estado de los lotes o incluso cambiar la pila en la que está asignada al lote.",
        pageDescription2:
          "Existen dos tipos de estado que pueden asignarse a los lotes:",
        field1: "En espera",
        fieldDescription1:
          "El lote se pone en modo de espera y, por lo tanto, no está disponible para su uso en mezclas.",
        field2: "Disponible",
        fieldDescription2:
          "El lote pasa del modo en espera al modo disponible, por lo que vuelve a estar disponible para su uso en la mezcla.",
        flow: "Este es el flujo de trabajo de la página de gestión de lotes:",
      },
      tabStatus: {
        title: "Cambiar el status",
        step1:
          "Seleccione la opción deseada en el cuadro de selección 'Status del inventario' para buscar los lotes en el inventario.",
        step2: "Seleccione en la tabla los lotes cuyo status desea modificar.",
        step3:
          "Haga clic en 'Poner en espera', para poner los lotes en modo de espera, o en 'Poner a disponible' para sacar los lotes del modo de espera y que vuelvan a estar disponibles para su uso.",
      },
      tabPile: {
        title: "Cambiar la pila",
        step1:
          "Seleccione la opción deseada en el cuadro de selección 'Status del inventario' para buscar los lotes en el inventario.",
        step2:
          "Seleccione en la tabla los lotes para las que desea cambiar la pila.",
        step3: "Haga clic en 'Cambiar pila'.",
        step4: "Seleccione la nueva pila que debe asignarse a los lotes.",
      },
      tabPLant: {
        title: "Traspaso a otra planta",
        step1:
          "Seleccione la opción deseada en el cuadro de selección 'Status del inventario' para buscar los lotes en el inventario.",
        step2:
          "Seleccione en la tabla los lotes que desea transferir a otra planta.",
        step3: "Haga clic en 'Transferencia de planta'.",
        step4: "Seleccione la planta de destino de los lotes.",
      },
    },
    cottonDistribution: {
      modalTitle: "Ayuda - Distribución de algodón",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Página para dirigir los lotes de algodón al inventario de las líneas de producción. Cuando se introduce un nuevo lote en el sistema, la pila S-01 se asigna automáticamente a este lote. Esta pila no está vinculada a ninguna línea de producción y, por lo tanto, los lotes nuevos no forman parte del inventario de ninguna línea de producción cuando se introducen.",
        pageDescription2:
          "En la distribución, los lotes pasan a formar parte del inventario de una línea de producción y están disponibles para ser utilizados en las reposiciones. Básicamente, este paso consiste en asignar una nueva pila al lote, que pasará a componer el inventario de la línea a la que está vinculada la pila.",
        flow: "Este es el flujo de trabajo para la página de distribución de algodón:",
      },
      tabManual: {
        title: "Distribuir lotes manualmente",
        step0:
          "En la distribución manual, el usuario selecciona los lotes manualmente y les asigna nuevas pilas.",
        step1:
          "En la página de distribución de algodón, haga clic en 'Nueva distribución manual'.",
        step2:
          "Siga las instrucciones de la página de distribución del manual.",
      },
      tabAutomatic: {
        title: "Distribuir lotes automáticamente",
        step0:
          "En la distribución automática, ESSENTIALbale selecciona los lotes y asigna nuevas pilas automáticamente.",
        step1:
          "En la página de distribución de algodón, haga clic en 'Nueva distribución automática'.",
        step2:
          "Siga las instrucciones de la página de distribución automática.",
      },
      tabCancelAll: {
        title: "Anular una distribución",
        step1:
          "En la página de distribución del algodón, busque la distribución en la tabla de distribuciones realizadas y haga clic en ",
        step2:
          "Las pacas de todos los lotes de esta distribución que aún no se hayan utilizados en mezclas se devolverán a la pila S-01 y no estarán disponibles para su uso.",
      },
      tabCancel: {
        title: "Anular la distribución de lote único",
        step1:
          "En la página de distribución de algodón, haga clic en la línea de la distribución de la tabla de distribuciones realizadas.",
        step2:
          "En la tabla de lotes incluidos, busque el lote deseado y haga clic en ",
        step3:
          "Las pacas del referido lote que aún no se hayan utilizado en mezclas se devolverán a la pila S-01 y no estarán disponibles para su uso.",
      },
    },
    manualCottonDistribution: {
      modalTitle: "Ayuda - Distribución manual",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "En la distribución manual, el usuario elige manualmente los lotes que deben añadirse al inventario del tipo de mezcla seleccionado.",
        flow: "Este es el flujo de trabajo para la página de distribución manual:",
      },
      tabInfo: {
        title: "Comprender la información sobre la calidad",
        description:
          "En la página de distribución manual hay dos pestañas con datos de calidad.",
        table1: "Calidad por pila",
        tableDescription1:
          "Muestra la calidad media y la cantidad de pacas disponibles para cada tipo de mezcla en la pila seleccionada.",
        table2: "Calidad por tipo de mezcla",
        tableDescription2:
          "Muestra una visión general de la calidad media del inventario.",
        fieldDescription1:
          "Calidad media del inventario total, incluido el algodón disponible para otros tipos de mezcla.",
        fieldDescription2:
          "Calidad media del inventario en funcionamiento para el tipo de mezcla.",
        fieldDescription3:
          "Calidad media del inventario total disponible para la distribución.",
        fieldDescription4:
          "Calidad media del inventario en la pila seleccionada que se ajusta al tipo de mezcla seleccionado.",
        fieldDescription5:
          "Proyección del inventario si todos los lotes de la pila seleccionada se añaden al inventario del tipo de mezcla.",
      },
      tabAdd: {
        title: "Distribuir los lotes manualmente",
        step1: "Seleccione la pila que contiene los lotes para distribuir.",
        step2:
          "Seleccione el tipo de mezcla para el que desea distribuir los lotes.",
        step3:
          "En la pestaña 'Lotes de la pila seleccionada', seleccione los lotes.",
        step4: "Haga clic en 'Realizar distribución manual'.",
      },
    },
    autoCottonDistribution: {
      modalTitle: "Ayuda - Distribución automática",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "En la distribución automática, ESSENTIALbale selecciona automáticamente los lotes que deben añadirse al inventario para el tipo de mezcla que se esté seleccionando. Al observar siempre los tipos de algodón controlados en la mezcla, seleccionará los lotes en los que se necesite el tipo de algodón en ese momento.",
        pageDescription2:
          "Siempre que la cantidad de lotes disponibles para el proceso de mezcla sea inferior a la cantidad mínima necesaria informada en el registro del tipo de mezcla, ESSENTIALbale solicitará al usuario la puesta a disposición de nuevos lotes.",

        flow: "Este es el flujo de trabajo para la página de distribución automática:",
      },
      tabInfo: {
        title: "Comprender la información sobre la calidad",
        table1: "Tabla de calidad del inventario",
        tableDescription1:
          "Muestra una visión general de la calidad media del inventario, así como de la calidad media de los lotes propuestos para distribución.",
        fieldDescription1:
          "Calidad media del inventario total, incluido el algodón disponible para otros tipos de mezcla.",
        fieldDescription2:
          "Calidad media del inventario actualmente en uso para el tipo de mezcla.",
        fieldDescription3:
          "Calidad media del inventario disponible para la distribución.",
        fieldDescription4:
          "Calidad media de los lotes propuestos en la distribución automática. Se mostrará después del procesamiento.",
        fieldDescription5:
          "Proyección de inventario para el tipo de mezcla si se confirman todos los lotes sugeridos.",
        table2: "Tabla perspectiva de calidad para el tipo de mezclas",
        tableDescription2:
          "Muestra la perspectiva de calidad media para el tipo de mezcla seleccionado.",
        fieldDescription6: "Nombre del parámetro de calidad.",
        fieldDescription7:
          "Prioridad o peso dado al parámetro al procesar la distribución. Los parámetros con mayor prioridad tendrán el valor alcanzado más próximo al valor esperado.",
        fieldDescription8:
          "Valor mínimo aceptable. Si el valor alcanzado es inferior al valor mínimo aceptable, los lotes propuestos pueden modificar demasiado la calidad media del inventario del tipo de mezcla.",
        fieldDescription9:
          "Valor máximo aceptable. Si el valor alcanzado está por encima del valor máximo aceptable, los lotes sugeridos pueden cambiar demasiado la calidad media de inventario del tipo de mezcla.",
        fieldDescription10:
          "Valor esperado ideal al procesar la selección de lotes.",
        fieldDescription11:
          "Valor alcanzado en el proceso de selección de lotes.",
      },
      tabAdd: {
        title: "Generar la distribución automática",
        step1:
          "Seleccione el tipo de mezcla para el que desea generar una distribución automática",
        step2: "Haga clic en 'Generar distribución automática'.",
        step3:
          "Espere a que finalice el proceso. Este proceso dura 30 segundos. Al final del proceso, los lotes seleccionados por el sistema se mostrarán en la tabla 'Lotes sugeridos'.",
        step4:
          "Seleccione los lotes de la tabla y haga clic en 'Confirmar lotes propuestos'.",
      },
    },
    pileGroups: {
      modalTitle: "Ayuda - Grupos de pilas",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Los grupos de pilas se utilizan para controlar el uso del algodón en las mezclas.",
        pageDescription2:
          "Un grupo puede reunir varias pilas o incluso una sola pila que contenga algodón cuyo uso deba controlarse en las mezclas.",
        pageDescription3:
          "Es posible, por ejemplo, crear grupos de pilas por productor u origen y definir para cada grupo cuántas pacas se utilizarán de cada grupo en las mezclas.",
        flow: "Este es el flujo de trabajo para la página de grupos de pila:",
      },
      tabAdd: {
        title: "Crear un nuevo grupo",
        step1:
          "En la página de grupos de pilas, haga clic en 'Nuevo grupo de pilas'.",
        step2: "Rellene los campos.",
        fieldDescription1: "Nombre o descripción del grupo.",
        fieldDescription2:
          "Cantidad de pacas que deben utilizarse en cada mezcla.",
        fieldDescription3: "Indica si el grupo está activo o no.",
        step3:
          "En la tabla de pilas, seleccione todas las pilas que formarán parte del grupo.",
        fieldDescription5:
          "La leyenda 'Representación del grupo (%)' muestra cuánto representa el grupo en la mezcla y en el inventario.",
        fieldDescription6:
          "La leyenda 'Representación de las pilas fuera de grupo (%)' muestra cuánto representa en la mezcla y en el inventario el algodón que no forma parte de ningún grupo.",
      },
      tabEdit: {
        title: "Editar un grupo",
        step1:
          "En la página del grupo de la pila, búsquelo en la tabla de grupos registrados y haga clic en ",
        step2:
          "Cambia los campos o redefine las pilas que forman parte del grupo.",
      },
      tabDelete: {
        title: "Deleting a group",
        step1:
          "En la página del grupo de la pila, búsquelo en la tabla de grupos registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
      },
    },
    controlledCottonTypes: {
      modalTitle: "Ayuda - Tipo de algodón controlado",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "El control por tipo de algodón sirve para controlar el uso de algodón en las mezclas según el tipo de algodón.",
        pageDescription2:
          "Este método de control se utiliza habitualmente en los países asiáticos, que suelen dosificar el algodón en los acodos según el tipo.",
        flow: "Este es el flujo de trabajo para la página de tipos de algodón controlados:",
      },
      tabAdd: {
        title: "Anadir un tipo controlado",
        step1:
          "En la página de tipos de algodón controlados, seleccione el tipo de mezcla en el cuadro de selección 'Tipo de mezcla' y, a continuación, haga clic en 'Nuevo tipo de algodón controlado'.",
        step2: "Seleccione el tipo de algodón.",
        step3:
          "Introduzca en el campo 'Pacas por mezcla' cuántas pacas deben utilizarse en las mezclas.",
      },
      tabEdit: {
        title: "Cambiar un tipo controlado",
        step1:
          "En la página de tipos de algodón controlados, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2: "Cambia el número de pacas.",
      },
      tabDelete: {
        title: "Eliminar un tipo controlado",
        step1:
          "En la página de tipos de algodón controlados, busque el tipo en la tabla de tipos registrados y haga clic en ",
        step2: "Confirme la eliminación en la ventana de confirmación.",
      },
    },
    hviImportation: {
      modalTitle: "Ayuda - Importar resultados",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Página de importación de los resultados de HVI para asignar o actualizar los datos de calidad de las pacas. ",
        flow: "Este es el flujo de trabajo de la página de importación de resultados:",
      },
      tabAdd: {
        title: "Importar un archivo.",
        step1:
          "Seleccione el diseño correspondiente al fichero que se va a importar. El diseño debe estar previamente registrada y configurada.",
        step2:
          "Haga clic en 'Seleccionar archivo' y busque el archivo en su ordenador. Se aceptan archivos en formato de hoja de cálculo Excel o archivos de texto.",
        step3: "Vea a continuación un ejemplo de archivo a importar.",
        step4:
          "Tras seleccionar el archivo, los datos se mostrarán en la tabla:",
        step5:
          "ESSENTIALbale intentará identificar las pacas y su respectiva información de calidad en el archivo.",
        step6:
          "Las columnas resaltadas en rojo indican una divergencia entre el diseño registrado y el diseño del fichero, en cuyo caso no será posible importar los datos y deberán realizarse correcciones en el diseño registrado.",
        step7:
          "Las columnas resaltadas en verde indican que la columna se ajusta a la disposición registrada.",
        step8: "Haga clic en 'Incluir pacas'.",
      },
    },
    hviManualInput: {
      modalTitle: "Ayuda - Entrada manual de datos de calidad",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Página para la entrada manual de datos de calidad para los parámetros que tienen establecida la introducción manual en su registro.",
        flow: "Este es el flujo de trabajo para la página de introducción manual de resultados:",
      },
      tabAdd: {
        title: "Introducción de resultados",
        step1:
          "Seleccione el estado en la casilla 'Status' para buscar proveedores.",
        fieldDescription1:
          "Obtiene solo los proveedores que tienen algún lote de algodón cuyos resultados están pendientes.",
        fieldDescription2: "Obtiene todos los proveedores.",
        step2: "Seleccione el proveedor.",
        step3: "Seleccione el lote.",
        step4: "Escriba los resultados directamente en las celdas de la tabla.",
        fieldDescription3:
          "Marque esta opción para asignar el mismo valor a todas las pacas. En este caso, al escribir el valor en cualquier celda, se replicará el mismo valor a todas las pacas.",
        fieldDescription4:
          "Marque esta opción si cada paca tiene un valor individual.",
        step5: "Haga clic en 'Guardar'.",
      },
    },
    baleInfo: {
      modalTitle: "Ayuda - Información de paca",
      tabOverview: {
        title: "Visión general",
        pageDescription: "Página para consultar la información de una paca.",
      },
      tabSearch: {
        title: "Consultar una paca",
        step1:
          "Introduzca el número de paca en el campo y haga clic en 'Buscar'.",
      },
    },
    plannings: {
      modalTitle: "Ayuda - Planificación de la calidad",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "La planificación de la calidad es el primer paso del proceso de mezcla. En la planificación se define la calidad que servirá de base para la mezcla siguiente a punto.",

        flow: "Este es el flujo de trabajo para la página de planificación:",
      },
      tabAdd: {
        title: "Crear una nueva planificación",
        step1:
          "En la página de planificación, haga clic en 'Nueva planificación'.",
        step2: "Seleccione el tipo de mezcla para generar la planificación.",
        step3:
          "Si procede, marque la opción 'Cambiar lote de producción'. Esta opción debe utilizarse exclusivamente al iniciar un nuevo lote de producción. Debe utilizarse cuando se produzca un gran cambio en la calidad del inventario, como en los cambios de cosecha, por ejemplo. Cuando se produce un cambio de lote de producción, la calidad de las mezclas anteriores no se tiene en cuenta en el cálculo de la nueva calidad.",
      },
      tabEdit: {
        title: "Edición de los datos de planificación",
        step1:
          "En la página de planificación, busque la planificación en la tabla y haga clic en ",
        step2: "Cambia el tipo de mezcla.",
        step3:
          "Si es el caso, cambie la opción de cambio de lote de producción.",
      },
      tabQuality: {
        title: "Generar la planificación",
        step1: "Busque la planificación en la tabla y haga clic en ",
        step2: "Definir qué debe priorizarse en la planificación.",
        fieldDescription1:
          "(Recomendado) Seleccionar de forma que la calidad planificada se encuentre exactamente entre la calidad del inventario y la calidad de la última mezcla.",
        fieldDescription2:
          "Seleccione de modo que la calidad prevista se aproxime más a la calidad de inventario.",
        fieldDescription3:
          "Selecciónelo de modo que la calidad prevista se aproxime más a la calidad de la última mezcla.",
        step3: "Haga clic en 'Generar planificación'.",
        description1: "La tabla de calidad muestra los siguientes valores:",
        fieldDescription4: "Parámetro de calidad.",
        fieldDescription5:
          "Calidad media del inventario para el tipo de mezcla.",
        fieldDescription6: "Calidad media de la umtima mezcla.",
        fieldDescription7:
          "Calidad planificada. Aparecerá después de hacer clic en 'Generar planificación'.",
        fieldDescription8:
          "Diferencia entre la calidad planificada y la calidad de la última mezcla. Aparecerá después de hacer clic en 'Generar planificación'.",
        description2: "La tabla de consumo muestra los siguientes valores:",
        fieldDescription9:
          "Cantidad de pacas disponibles actualmente en el inventario para el tipo de mezcla.",
        fieldDescription10: "Cantidad de mezclas consumidas al día.",
        fieldDescription11: "Cantidad de pacas utilizadas en cada mezcla.",
        fieldDescription12: "Total de pacas consumidas al día.",
        fieldDescription13:
          "Cantidad de pacas que quedan después del consumo de la siguiente mezcla.",
      },
      tabViewQuality: {
        title: "Veer la calidad planificada",
        step1:
          "En la página de planificación, busque la planificación en la tabla y haga clic en ",
        step2:
          "La tabla mostrará la calidad prevista para cada parámetro de calidad.",
      },
      tabDelete: {
        title: "Excluir una planificación",
        step1:
          "En la página de planificación, busque la planificación en la tabla y haga clic en ",
        step2:
          "Confirme la eliminación en la ventana de confirmación. Si el icono no está visible, significa que no se puede eliminar la planificación.",
      },
    },
    preLaydowns: {
      modalTitle: "Ayuda - Premezcla",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "La premezcla es el segundo paso del proceso de mezcla. En este paso, ESSENTIALbale determina cuántas pacas deben utilizarse de cada pila para crear una premezcla.",
        pageDescription2:
          "En función de la calidad planificada en la fase de planificación, el sistema analiza la calidad de cada pila disponible y determina cuántas pacas utilizar. En esta fase, se utiliza una mayor cantidad de pacas en relación con la cantidad de pacas utilizadas en la mezcla. Por lo general, se utiliza un 50% más de pacas en la premezcla.",
        flow: "Este es el flujo de trabajo para la página de premezcla:",
      },
      tabAdd: {
        title: "Creación de nueva premezcla",
        step1: "En la página de premezcla, haga clic en 'Nuevo premezcla'.",
        step2: "Seleccione la línea de producción.",
        step3:
          "Opcionalmente, puede informar el número de lotes de hilo, o el número de lote de salida. Los lotes de hilo pueden utilizarse posteriormente para filtrar en los informes de calidad.",
      },
      tabEdit: {
        title: "Edición de un premezcla",
        step1:
          "En la página de premezcla, busque la premezcla en la tabla y haga clic en ",
        step2: "Seleccione la línea de producción.",
        step3: "Informar a los lotes de hilo.",
      },
      tabPiles: {
        title: "Generación del prepago",
        description0:
          "Al generar la premezcla, ESSENTIALbale analiza la calidad de las pilas y define cuántas pacas se utilizarán de cada una.",
        step1:
          "En la página de premezcla, busque la premezcla en la tabla y haga clic en ",
        description1:
          "La tabla 'Pilas disponibles' muestra todos las pilas disponibles. Para cada pile se muestran:",
        fieldDescription1: "Número de pacas disponibles en la pila.",
        fieldDescription2:
          "Cantidad mínima de pacas que se pueden utilizar en la premezcla. El sistema la define automáticamente, pero el usuario puede modificarla.",
        fieldDescription3:
          "Número máximo de pacas que se pueden utilizar en la premezcla. El sistema la define automáticamente, pero el usuario puede modificarla.",
        fieldDescription4:
          "Cantidad de pacas definida para la premezcla. Se muestra tras la preparación de la premezcla.",
        parameter: "Parámetro de calidad",
        fieldDescription5: "Calidad media del parámetro.",

        description2:
          "En la tabla 'Calidad', para cada parámetro de calidad tenemos los siguientes valores:",
        fieldDescription6:
          "Prioridad o peso del parámetro en la elaboración de la premezcla.",
        fieldDescription7: "Calidad media actual del inventario.",
        fieldDescription8:
          "Calidad definida en la fase de planificación y que sirve de referencia para el sistema.",
        fieldDescription9:
          "Calidad conseguida con la elaboración de la premezcla. Se muestra tras la elaboración de la premezcla y debe estar siempre entre los valores mínimo y máximo aceptados.",
        fieldDescription10:
          "Valor mínimo aceptable para el parámetro. El sistema lo calcula automáticamente.",
        fieldDescription11:
          "Valor máximo aceptable para el parámetro. El sistema lo calcula automáticamente.",
        step2: "Haga clic en 'Generar premezcla'.",
        description3:
          "Espere a que finalice el proceso. Este proceso dura 30 segundos. Al final del proceso, la calidad media alcanzada y la cantidad de pacas definidas para cada pila.",
      },
      tabViewPiles: {
        title: "Ver pilas usadas",
        step1:
          "En la página de premezcla, busque la premezcla en la tabla y haga clic en ",
        step2:
          "La tabla mostrará las pilas utilizadas en la precolada y la cantidad respectiva de pacas que se utilizará para cada una.",
      },
      tabDelete: {
        title: "Eliminar una premezcla",
        step1:
          "En la página de premezcla, busque la premezcla en la tabla y haga clic en ",
        step2:
          "Confirme la eliminación en la ventana de confirmación. Si el icono no está visible, significa que la premezcla no puede borrarse porque hay mezclas que se originan a partir de la premezcla.",
      },
    },
    laydowns: {
      modalTitle: "Ayuda - mezclas",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "Mezcla es el paso final del proceso de mezcla  en ESSENTIALbale. ",
        pageDescription2:
          "En este paso se recogen las pacas según las cantidades definidas en el paso de premezcla. A continuación, el sistema analiza la calidad de cada paca y selecciona las pacas que formarán parte de la mezcla a disposición para que la media de la mezcla se aproxime lo máximo posible a la calidad planificada en la primera etapa del proceso.",
        pageDescription3:
          "Tras analizar y seleccionar las pacas, el ESSENTIALbale define además la posición de cada paca en la línea de apertura",
        flow: "Este es el flujo de trabajo para crear la mezcla:",
        step1: "Se crea una nueva mezcla",
        step2:
          "Se recogen las pacas según la cantidad definida en la premezcla",
        step3:
          "Se prepara la mezcla. Se analiza la calidad de las pacas recogidas y el sistema elige las pacas adecuadas para la mezcla",
        step4:
          "Se define la disposición de la paca. El sistema clasifica las pacas seleccionadas para su ensamblaje en la línea de apertura",
        step5: "Se finaliza la mezcla",
      },
      tabAdd: {
        title: "Crear una nueva mezcla",
        step1:
          "En la casilla 'ID de premezcla', seleccione la premezcla que dará lugar a la nueva mezcla",
        step2: "Haga clic en 'Nueva mezcla'",
        step3:
          "Haga clic en 'pacas' para ir a la página de pacas donde se realizan los siguientes pasos.",
      },
      tabBales: {
        title: "Incluir las pacas",
        description1:
          "Esta página incluye las pacas en la premezcla según la cantidad definida para cada pila en el paso de elaboración de la premezcla, de forma que la selección de las pacas pueda realizarse posteriormente.",
        step1:
          "Haga clic en 'Incluir sobrantes' para incluir las pacas que se incluyeron en la premezcla anterior, pero que no se seleccionaron para la mezcla anterior. Las pacas aparecerán en la tabla 'Pacas añadidas a la premezcla'",
        step2:
          "Imprima el informe con la lista de las pacas que aún faltan de cada pila haciendo clic en ",
        description3:
          "Este listado debe pasarse al personal del almacén para que recoja la cantidad de pacas que aún faltan de cada pila y las deposite en la zona de premezcla junto con las pacas sobrantes de la mezcla anterior. Debe anotarse el número de pacas recogidas en las pilas",
        step3:
          "Introduzca los números de las pacas recogidas en el campo 'Número de paca'. También es posible leer un fichero que contenga las pacas en forma de lista. En este caso, haga clic en 'Elegir archivo' y busque el archivo en su ordenador",
        description4:
          "A medida que se vaya incluyendo cada paca, los datos se irán actualizando en la pantalla. En la tabla 'Cantidad necesaria de pacas por pila' se muestran para cada pila:",

        fieldDescription1:
          "Cantidad necesaria de pacas definida en la preparación de la premezcla",
        fieldDescription2: "Cantidad de pacas ya incluida",
        fieldDescription3:
          "Cantidad de pacas que quedan por incluir en la premezcla",

        description5:
          "En la tabla 'Calidad', para cada parámetro de calidad se muestran:",
        fieldDescription4: "Calidad prevista en la fase de planificación",
        fieldDescription5:
          "Calidad media de las a pacas añadidas a la premezcla",
        fieldDescription6:
          "Valor mínimo aceptable para el parámetro. El sistema lo calcula automáticamente",
        fieldDescription7:
          "Valor máximo aceptable para el parámetro. El sistema lo calcula automáticamente",
        fieldDescription8:
          "Coeficiente de variación (CV) de las pacas añadidas a la premezcla",

        step4:
          "Después de incluir la cantidad total de pacas necesarias se podrá proceder a la preparación de la mezcla en obra. Haga clic en 'Generar mezcla'",
      },
      tabGenerate: {
        title: "Generando la mezcla",
        description1:
          "Después de la inclusión de la cantidad necesaria de pacas para cada pila realizada en la página de inclusión de pacas, en esta página se realiza la generación de la propia disposición",
        step1:
          "Haga clic en 'Generar mezcla' y espere a que se complete el proceso",

        description2:
          "El sistema analizará la calidad individual de cada paca incluida en la premezcla y seleccionará las pacas que compondrán la mezcla",
        description3: "El procesamiento dura 30 segundos",
        description4:
          "Una vez finalizado el procesamiento, los datos se actualizarán en la página",

        description5:
          "En la tabla 'Pacas en la premezcla' se muestran todas las pacas incluidas en la premezcla. Las pacas que se hayan seleccionado se resaltarán en azul",

        description6:
          "En la tabla 'Calidad', para cada parámetro de calidad se muestran:",
        fieldDescription1:
          "Prioridad o peso del parámetro en la preparación de la paca. se establece en los ajustes de la línea de producción, pero se puede cambiar aquí si es necesario.",
        fieldDescription2: "Calidad prevista en la fase de planificación",
        fieldDescription3:
          "Calidad media alcanzada con la selección de las pacas",
        fieldDescription4:
          "Valor mínimo aceptable para el parámetro. Lo calcula automáticamente el sistema",
        fieldDescription5:
          "Valor máximo aceptable para el parámetro. El sistema lo calcula automáticamente",
        fieldDescription6:
          "Coeficiente de variación (CV) de las pacas añadidas a la premezcla",

        description7:
          "En la tabla 'Grupos controlados' se muestran los datos del control de uso del algodón. Puede ser de los grupos controlados de pilas o tipos de algodón",
        fieldDescription7: "ID del grupo de pilas o tipo de algodón",
        fieldDescription8: "Nombre del grupo de pilas o tipo de algodón",
        fieldDescription9: "Cantidad mínima de pacas prevista en la mezcla",
        fieldDescription10:
          "Cantidad máxima de pacas prevista para en la mezcla",

        fieldDescription11: "Cantidad de pacas alcanzada en la mezcla",
        fieldDescription12:
          "Cantidad de pacas que quedan en la precompactación",

        description8:
          "Si hay algún parámetro de calidad que tenga un coeficiente de variación (CV) controlado (establecido en los ajustes de la línea de produción), los datos se mostrarán en la tabla 'CV Control'. Para cada parámetro con CV controlado, se muestran los siguientes datos:",
        fieldDescription13:
          "CV mínimo aceptable para el parámetro. Lo calcula automáticamente el sistema",
        fieldDescription14:
          "CV máximo aceptable para el parámetro. El sistema lo calcula automáticamente",
        fieldDescription15: "CV alcanzado con la selección de las pacas",

        description9:
          "Una vez finalizado el proceso de elaboración, puede pasar al siguiente paso: definir las posiciones de cada paca seleccionada",

        step2:
          "Vuelva a la pantalla inicial de mezcla y haga clic en 'Disposición'",
      },
      tabLayout: {
        title: "Definir la disposición",
        description1:
          "Una vez preparada la mezcla, podrá definir la disposición, es decir, definir la posición de cada paca en la línea de apertura",
        step0: "Establezca la prioridad en la elaboración de la disposición",
        fieldDescription1:
          "(Recomendado) Seleccionar para que haya una distribución equitativa de las pacas según sus respectivos productores y orígenes.",
        fieldDescription2:
          "Seleccionar para que haya una mejor distribución de las pacas según el productor",
        fieldDescription3:
          "Seleccionar para que las pacas se distribuyan mejor según el origen",

        step1:
          "Haga clic en 'Generar distribución' y espere a que termine el proceso",

        description2:
          "El sistema analizará la calidad individual de cada paca seleccionada para la disposición y definirá la posición de cada paca en la línea de apertura.",
        description3:
          "El procesamiento dura 30 segundos. Una vez finalizado el procesamiento, los datos se actualizarán en la página",
        description5:
          "En la tabla 'Pacas seleccionadas', se muestran todas las pacas seleccionadas para la mezcla y sus respectivas posiciones definidas por el sistema.",
        description6:
          "Las pacas se agrupan en submezclas como si fueran pequeñas colocaciones dentro de la mezcla global. El número de pacas de cada sub-posada se define en el registro de la línea de producción.",
        description7:
          "La calidad media de cada submezclas será lo más cercana posible a la calidad media global de la misma",

        description8:
          "La calidad media global de la mezcla  se muestra en la tabla 'Calidad de la mezcla '",
        description9:
          "Mientras que la calidad de cada submezcla se muestra en la tabla 'Calidad de las submezclas'",

        description10:
          "Una vez realizada la disposición, la mezcla está completa",

        step2:
          "Vuelva a la pantalla inicial de mezclas, y haga click en 'Finalizar'.",
      },
      tabFinish: {
        title: "Terminando la mezcla",
        description1:
          "Una vez completados todos los pasos, si la mezcla no tiene advertencias de calidad, es decir, si la media alcanzada para todos los parámetros de calidad está dentro de la tolerancia, será posible finalizar la mezcla.",
        description2:
          "Si hay advertencias de calidad, vaya a la página de advertencias para analizarlas.",
        step1: "Haga clic en 'Finalizar'",
        step2: "Confirme la finalización en la ventana de confirmación.",
        step3:
          "En la ventana de confirmación de consumo de pacas, informe si desea registrar el consumo o no.",
        step4: "Pulse 'Finalizar'",
      },
      tabResume: {
        title: "Reabrir la mezcla",
        description1:
          "Reabrir la mezcla  consiste en hacerla editable de nuevo, es decir, es posible rehacer cualquiera de los pasos. Sólo se puede reabrir la última mezcla de cada línea de producción.",
        step1: "Haga clic en 'Reabrir'.",
        step2: "Confirme la finalización en la ventana de confirmación.",
      },
      tabDelete: {
        title: "Borrar la mezcla",
        description1: "Si se borra la mezcla, las pacas vuelven al inventario.",
        step1: "Haga clic en 'Eliminar'.",
        step2: "Confirme la finalización en la ventana de confirmación.",
      },
      reporting: {
        balesAndQuality: {
          modalTitle: "Ayuda - Informe de pacas y calidad de la mezcla",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra las pacas seleccionadas y el resumen de calidad de la mezcla.",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión",
          },
        },
        laydownCharts: {
          modalTitle: "Ayuda - Gráficos de las pacas de la mezcla",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra la representación gráfica de la calidad de las pacas de mezcla.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        layoutPositions: {
          modalTitle: "Ayuda - Layout de la mezcla",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra la posición de las pacas en la mezcla",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión.",
          },
        },
        leftoverBales: {
          modalTitle: "Ayuda - Pacas en premezcla",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra la lista de pacas que no han sido seleccionadas para la mezcla y permanecen en el área de premezcla.",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión.",
          },
          tabRemove: {
            title: "Retirar pacas de la premezcla",
            pageDescription:
              "Retirar una paca de la premezcla significa devolverla a la pila de origen. Físicamente, esta paca debe retirarse de la zona de premezcla y devolverse a la pila de origen",

            step1: "Seleccione las pacas que desea retirar de la premezcla.",
            step2: "Haga clic en 'Eliminar las pacas seleccionadas'.",
            step3: "Confirme la eliminación en la ventana de confirmación.",
          },
        },
        cottonTypeSummary: {
          modalTitle: "Ayuda - Resumen de mezclas por tipo de algodón",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra la representación porcentual de cada tipo de algodón en la mezcla",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión.",
          },
        },
        originSummary: {
          modalTitle: "Ayuda - Resumen de mezcla por origen",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe muestra la representación porcentual de cada origen en la mezcla",

            printText1: "Haga clic en ",
            printText2: " para abrir la ventana de impresión.",
          },
        },
        resumenMezcla: {
          modalTitle: "Ayuda - Resumen de la pila",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Esto informa de la representación porcentual de cada pila en la mezcla",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión.",
          },
        },
        producerSummary: {
          modalTitle: "Ayuda - Resumen de mezcla por productor",
          tabOverview: {
            title: "Visión general",
            pageDescription:
              "Este informe el porcentaje de representación de cada productor en la mezcla",

            printText1: "Haga clic en ",
            printText2: "para abrir la ventana de impresión.",
          },
        },
      },
    },
    consumptionControl: {
      modalTitle: "Ayuda - Control de consumo",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "En esta página puede establecer la fecha de consumo de las mezclas. Una mezcla sólo empieza a contar como consumida y a aparecer en los informes de consumo después de que se haya confirmado el consumo.",
        pageDescription2:
          "Cuando se finaliza una mezcla en el sistema, el usuario puede al mismo tiempo confirmar el consumo de la mezcla o, si desea establecer el consumo más tarde, puede hacerlo aquí.",
        flow: "Este es el flujo de trabajo de la página de registro de control de consumo.",
      },
      tabEdit: {
        title: "Confirmar o modificar el consumo de la mezcla",
        step1:
          "Seleccione la línea de producción en el campo 'Línea de producción'.",
        step2: "Seleccione el estado en el campo 'Estado'",
        fieldDescription1:
          "Buscar todas las mezclas de la línea de producción, incluyendo aquellas cuyo consumo ya ha sido confirmado para, si es el caso, modificar la fecha de consumo.",
        fieldDescription2:
          "Busca las mezclas de la línea de producción en las que está pendiente la confirmación de consumo",
        step3: "Busca la mezcla en la tabla 'Mezclas' y haga clic en ",
        step4: "Selecciona la fecha de consumo de la mezcla.",
      },
    },
    warnnings: {
      modalTitle: "Ayuda - Advertencias",
      tabOverview: {
        title: "Visión general",
        pageDescription:
          "En esta página puedes gestionar las advertencias lanzadas por ESSENTIABale cuando una mezcla presenta algún tipo de inconsistencia que requiere el análisis de un gestor.",
        pageDescription2:
          "Las advertencias se lanzan cuando un grupo con uso controlado no alcanza la cantidad esperada de pacas en la mezcla, o cuando la media alcanzada para algún parámetro de calidad está fuera de tolerancia.",
        flow: "Este es el flujo de trabajo para la página de advertencias.",
      },
      tabDetails: {
        title: "Analizar los detalles de la alerta",
        step1:
          "Busque la alerta en la tabla 'Advertencias de calidad' y haga clic en ",
        description1:
          "La tabla mostrará los parámetros de calidad o grupos con uso controlado que generaron la alerta. El responsable debe analizar si los cambios conllevan algún riesgo para la producción. Si se entiende que no hay riesgos, se puede autorizar el avance de la mezcla, de lo contrario se debe consultar al soporte técnico sobre las acciones que se pueden tomar para sortear el problema.",
      },
      tabAuthorize: {
        title: "Autorizar el uso de la mezcla",
        description1:
          "Si se entiende que los cambios no conllevan riesgos para la producción, se debe autorizar el uso de la mezcla para que ésta sea finalizada.",
        step1:
          "Busque la alerta en la tabla 'Advertencias de calidad' y haga clic en ",
        step2: "Confirme la autorización en la ventana de confirmación.",
      },
    },
    inventoryReports: {
      quality: {
        modalTitle: "Ayuda - Informe de calidad del inventario",
        tabOverview: {
          title: "visión general",
          pageDescription:
            "El informe de calidad del inventario muestra una visión general del inventario en términos de calidad y cantidad.",
          pageDescription2:
            "Además de la información de inventario total, el informe muestra la información de inventario disponible para cada línea de producción y el inventario de cada grupo con uso controlado.",
          filterText: "Opciones de filtrado:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccione una línea de producción específica para mostrar los datos solo de esta línea y de los grupos con uso controlado vinculados a ella.",
          legendDescription1:
            "La cantidad de pacas disponibles para cada línea es calculada por el sistema en base a los porcentajes de uso de cada grupo controlado.",
          legendDescription2:
            "Los grupos controlados que tienen pocas pacas en inventario pueden interferir en el cálculo del inventario disponible para cada línea de producción. El resultado es una reducción de la cantidad de pacas disponibles para la línea de producción",
          legendDescription3:
            "En este caso el grupo en cuestión se resaltará en amarillo, y se deberá corregir su porcentaje de uso en las mezclas.",
          printText1: "Haga clic en ",
          printText2: " para abrir la ventana de impresión.",
        },
      },
      bales: {
        modalTitle: "Ayuda - Informe de pacas",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra las pacas en inventario además del resumen de calidad según los filtros aplicados.",
          filterText: "Opciones de filtro:",
          filter1: "Situación",
          filterDescription1:
            "La situación de las pacas en inventario. Las opciones son:",
          option0: "Todos",
          optionDescription0: "Todas las pacas en el inventario",
          option1: "En uso",
          optionDescription1:
            "Pacas que están disponibles para ser utilizadas en las mezclas.",
          option2: "Disponibles para su distribución",
          optionDescription2:
            "Pacas que están disponibles para su distribución, es decir, que aún no están disponibles para las mezclas",
          option3: "Con calidad",
          optionDescription3: "Pacas que tienen todos los valores de calidad.",
          option4: "Sin calidad",
          optionDescription4: "Pacas que no tienen ningún valor de calidad",
          option5: "En espera",
          optionDescription5: "Pacas que están en espera",
          option6: "En las pilas",
          optionDescription6:
            "pacas que están en las pilas con excepción de las pacas que están en la premezcla",
          option7: "En la premezcla",
          optionDescription7: "Pacas que están actualmente en la premezcla",
          filter2: "Pila",
          filterDescription2: "Pila que contiene las pacas",
          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión.",
        },
      },
      general: {
        modalTitle: "Ayuda - Informe general de inventario",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra información general de inventario además del resumen de calidad según los filtros y opciones de agrupación aplicados.",
          filterText: "Opciones de filtro:",
          filter1: "Proveedor",
          filterDescription1:
            "Seleccione para filtrar por un proveedor específico.",
          filter2: "Productor",
          filterDescription2:
            "Seleccionar para filtrar por un productor específico.",
          filter3: "Lote",
          filterDescription3:
            "Seleccionar para filtrar por un lote específico.",
          filter4: "Pila",
          filterDescription4:
            "Seleccione para filtrar por una pila específica.",
          filter5: "Tipo de algodón",
          filterDescription5:
            "Seleccionar para filtrar por un tipo específico de algodón.",
          filter6: "Línea de producción",
          filterDescription6:
            "Seleccione para filtrar por una línea de producción específica.",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccionar para agrupar los resultados por proveedor.",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccionar para agrupar los resultados por productor.",
          grouping3: "Lote",
          groupingDescription3:
            "Seleccionar para agrupar los resultados por lote.",
          agrupación4: "Pila",
          groupingDescription4:
            "Seleccione para agrupar los resultados por pila.",
          grouping5: "Tipo de algodón",
          groupingDescription5:
            "Seleccionar para agrupar los resultados por tipo de algodón.",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión",
        },
      },
      composition: {
        modalTitle: "Ayuda - Informe de composición del inventario",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la composición porcentual del inventario según los filtros y opciones de agrupación aplicados.",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccionar para ver la composición del inventario por proveedor",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccionar para ver la composición del inventario por productor",
          grouping3: "Origen",
          groupingDescription3:
            "Seleccionar para ver la composición del inventario por origen",
          grouping4: "Tipo de algodón",
          groupingDescription4:
            "Seleccione para ver la composición del inventario por tipo de algodón",

          filterText: "Opciones de filtrado:",
          filter1: "Situación",
          filterDescription1:
            "Situación de las pacas en inventario. Las opciones son:",
          option1: "Todos",
          optionDescription1: "Todas las pacas en el inventario.",
          option2: "En espera",
          optionDescription2: "Pacas que están en espera.",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión.",
        },
      },
      lotEntry: {
        modalTitle: "Ayuda - Informe de batch input por periodo",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la entrada de lotes por periodo según los filtros aplicados.",
          filterText: "Opciones de filtro:",
          filter1: "Proveedor",
          filterDescription1:
            "Seleccione para filtrar por un proveedor específico",
          filter2: "Productor",
          filterDescription2:
            "Seleccione para filtrar por un productor específico",
          filter3: "Origen",
          filterDescription3:
            "Seleccionar para filtrar por un origen específico",
          filter4: "Tipo de algodón",
          filterDescription4:
            "Seleccionar para filtrar por un tipo específico de algodón",
          filter5: "Periodo",
          filterDescription5: "Introduzca el periodo para filtrar por fecha",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión.",
        },
      },
    },
    consumptionReports: {
      general: {
        modalTitle: "Ayuda - Informe general de consumo",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra información general de consumo además del resumen de calidad según los filtros aplicados y las opciones de agrupación.",
          filterText: "Opciones de filtro:",
          filter1: "Proveedor",
          filterDescription1:
            "Seleccionar para filtrar por un proveedor específico.",
          filter2: "Productor",
          filterDescription2:
            "Seleccionar para filtrar por un productor específico.",
          filter3: "Tipo de algodón",
          filterDescription3:
            "Seleccionar para filtrar por un tipo de algodón específico.",
          filter4: "Línea de producción",
          filterDescription4:
            "Seleccionar para filtrar por una línea de producción específica.",
          filter5: "Período",
          filterDescription5:
            "Seleccionar para filtrar por un periodo de consumo específico.",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccionar para agrupar los resultados por proveedor.",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccionar para agrupar los resultados por productor.",
          grouping3: "Línea de producción",
          groupingDescription3:
            "Seleccionar para agrupar los resultados por línea de producción.",
          grouping4: "Pila",
          groupingDescription4:
            "Seleccionar para agrupar los resultados por pila.",
          grouping5: "Tipo de algodón",
          groupingDescription5:
            "Seleccionar para agrupar los resultados por tipo de algodón.",
          grouping6: "Mezcla",
          groupingDescription6:
            "Seleccionar para agrupar los resultados por mezcla.",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión.",
        },
      },
      perDay: {
        modalTitle: "Ayuda - Informe de consumo por día",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la información de consumo de algodón por día según los filtros y opciones de agrupación aplicados.",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccione esta opción para ver la composición del inventario por proveedor",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccione para ver la composición del inventario por productor",
          grouping3: "Origen",
          groupingDescription3:
            "Seleccione para ver la composición del inventario por origen",
          grouping4: "Tipo de algodón",
          groupingDescription4:
            "Seleccione para ver la composición del inventario por tipo de algodón",

          filterText: "Opciones de filtro:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccione para filtrar por una línea de producción específica",
          filter2: "Período",
          filterDescription2:
            "Seleccione para filtrar por un período de consumo específico",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión",
        },
      },
      perMonth: {
        modalTitle: "Ayuda - Informe de consumo por mes",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la información de consumo de algodón por mes según los filtros y opciones de agrupación aplicados.",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccione esta opción para ver la composición del inventario por proveedor",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccione para ver la composición del inventario por productor",
          grouping3: "Origen",
          groupingDescription3:
            "Seleccione para ver la composición del inventario por origen",
          grouping4: "Tipo de algodón",
          groupingDescription4:
            "Seleccione para ver la composición del inventario por tipo de algodón",

          filterText: "Opciones de filtro:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccione para filtrar por una línea de producción específica",
          filter2: "Período",
          filterDescription2:
            "Seleccione para filtrar por un período de consumo específico",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión",
        },
      },
      perLaydown: {
        modalTitle: "Ayuda - Informe de consumo por mezcla",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la información de consumo de algodón por mezcla según los filtros aplicados.",

          filterText: "Opciones de filtro:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccionar para filtrar por una línea de producción específica.",
          filter2: "Lote de alambre",
          filterDescription2:
            "Seleccionar para filtrar por un lote de hilo específico. El lote de hilo se informa al crear la premezcla.",
          filter3: "Periodo",
          filterDescription3:
            "Seleccionar para filtrar por un periodo de consumo específico.",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión.",
        },
      },
      composition: {
        modalTitle: "Ayuda - Informe de composición de consumo",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra la composición porcentual del consumo según los filtros aplicados y las opciones de agrupación",

          groupingText: "Opciones de agrupación:",
          grouping1: "Proveedor",
          groupingDescription1:
            "Seleccione esta opción para ver la composición del consumo por proveedor",
          grouping2: "Productor",
          groupingDescription2:
            "Seleccione para ver la composición del consumo por productor",
          grouping3: "Origen",
          groupingDescription3:
            "Seleccione para ver la composición del consumo por origen",
          grouping4: "Tipo de algodón",
          groupingDescription4:
            "Seleccione para ver la composición del consumo por tipo de algodón",

          filterText: "Opciones de filtro:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccione para filtrar por una línea de producción específica",
          filter2: "Período",
          filterDescription2:
            "Seleccione para filtrar por un período de consumo específico",

          printText1: "Haga clic en ",
          printText2: "para abrir la ventana de impresión",
        },
      },
      avgCharts: {
        modalTitle: "Ayuda - Gráfico de promedios de mezclas",
        tabOverview: {
          title: "Visión general",
          pageDescription:
            "Este informe muestra en gráficos la evolución de los promedios y el coeficiente de variación (CV) de cada parámetro de calidad en las mezclas según los filtros aplicados.",

          filterText: "Opciones de filtro:",
          filter1: "Línea de producción",
          filterDescription1:
            "Seleccionar para filtrar por una línea de producción específica",
          filter2: "Lote de alambre",
          filterDescription2:
            "Seleccionar para filtrar por un lote de hilo específico. El lote de hilo se informa al crear la premezcla",
          filter3: "Periodo",
          filterDescription3:
            "Seleccionar para filtrar por un periodo de consumo específico",
          filter4: "Mostrar por",
          filterDescription4:
            "Seleccione 'Promedio' para que los gráficos muestren los promedios de calidad, o 'CV' para que los gráficos muestren el coeficiente de variación (CV) de las mezclas.",
          filter5: "Mostrar tolerancias de",
          filterDescription5:
            "Seleccione 'Producción' para que la tolerancia mostrada sea la de la producción, o 'Lote de hilo' para que la tolerancia mostrada sea la del lote de hilo. Las tolerancias se establecen en los ajustes de calidad de la línea de producción.",

          printText1: "Haga clic en ",
          printText2: " para abrir la ventana de impresión.",
        },
      },
    },
  },
};

export { translation };
