import axios from "axios";
import UserService from "./UserService";

const HttpMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
};

const _axios = axios.create({
  baseURL: `${
    window.location.origin === "http://localhost:3000"
      ? "https://eb.optcotton.com"
      : window.location.origin
  }/api/v1/`,
});

// const _axios = axios.create({
//   baseURL: `${process.env.REACT_APP_API_URL}/api/v1/`,
// });

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers["Authorization"] = `Bearer ${UserService.getToken()}`;

        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
