import { ScrollView } from "devextreme-react";
import { i18n } from "../../../service/i18n/i18n";

function TabSettings() {
  return (
    <>
      <ScrollView height={500}>
        <div className="help-modal-content-container">
          <p>{i18n.t("userGuiding.introduction.tabUserLevels.description")}</p>

          <dl className="help-fields-list">
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserLevels.field1")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserLevels.fieldDescription1"
                )}
              </span>
            </li>
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserLevels.field2")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserLevels.fieldDescription2"
                )}
              </span>
            </li>
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserLevels.field3")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserLevels.fieldDescription3"
                )}
              </span>
            </li>
          </dl>
          <p>{i18n.t("userGuiding.introduction.tabUserLevels.description2")}</p>
        </div>
      </ScrollView>
    </>
  );
}

export default TabSettings;
