const translations = {
  sidebar: {
    menu: {
      registers: "Registers",
      inventory: "Inventory",
      laydown: "Mixing",
      reports: "Reports",
      warning: "Warnings",
    },
    register: {
      parameters: "Quality parameters",
      prodline: "Production lines",
      producer: "Producers",
      supplier: "Suppliers",
      cottontype: "Cotton types",
      pile: "Piles",
      hvispreadsheet: "Testing file layouts",
      hvidatlayout: "HVI dat layout",
      origin: "Origins",
      plant: "Plants",
      prodlineclasses: "Production line classes",
      mixtype: "Mix types",
      hvi: "HVI file layout",
      user: "Users",
    },
    inventory: {
      cottonInput: "Cotton input",
      management: "Management",
      bales: "Bales",
      lots: "Lots",
      cottonDistribution: "Cotton distribution",
      cottonUsage: "Cotton usage control",
      pileGroup: "Pile groups",
      cottonTypes: "Cotton types",
      testingsResults: "Testing results input",
      importantion: "Importation",
      manualInputs: "Manual input",
      consultBale: "Bale info",
    },
    mixing: {
      planning: "Planning",
      pre: "Pre-laydown",
      laydown: "Laydown",
      control: "Consumption control",
    },
    reports: {
      inventory: "Inventory",
      bales: "Bales",
      general: "General",
      generalConsumption: "General",
      composition: "Inventory",
      lotsPerPeriod: "Entered lots",
      quality: "Quality overview",
      consumpition: "Consumption",
      perDay: "Per day",
      perMonth: "Per month",
      perLaydown: "Per laydown",
      perPeriod: "Composition",
      avg: "Laydown charts",
      usda: "USDA representation charts",
    },
  },
  E500page: {
    msg1: "Sorry, something went wrong.",
    msg2: "Try again. If the error persists, contact technical support.",
    btnHome: "Go to home page",
  },
  others: {
    tableAddBtn: "New",
    tableColumnActions: "Actions",
    deleteBtn: "Delete",
    dailyUnit: "Days",
    groupContinuedMessage: "Continues on the last page",
    groupContinuesMessage: "Continue on the next page",
    selectFile: "Select a file",
    noFileSelected: "No file selected",
  },
  alerts: {
    confirmDeletion: "Do you really want to delete this item?",
  },

  license: {
    title: "Licenses",
    terms:
      "This product contains software licensed under an open source license.For acknowledgements and license texts, see below. Maschinenfabrik Rieter AG (hereinafter referred to as “Rieter”) hereby offers to deliver, upon request, a copy of the complete corresponding source code for the copyrighted open source software packages used in this product for which such delivery is requested by the respective licenses. This offer is valid up to three years after purchase or software update to anyone in receipt of this information. To obtain source code, please write in English to “Maschinenfabrik Rieter AG, Attn: ESSENTIAL Open Source Team, Klosterstrasse 20, 8406 Winterthur, Switzerland”",
    licenses: "Declared license(s)",
  },

  register: {
    plant: {
      name: "Plants",
      title: "Registered plants",
      id: "ID",
      desc: "Description",
      btnNew: "New",
      hintEdit: "Edit this plant",
      delete: {
        title: "Delete this plant",
        text: "Do you really want to delete this plant?",
      },
      settings: {
        title: "Plant settings",
        minBale: "Minimum bale weight",
        minBaleHint: "Enter the minimum bale weight",
        maxBale: "Maximum bale weight",
        maxBaleHint: "Enter the maximum bale weight",
        printer: "Labels printer path",
        printerHint: "Enter the thermal printer path",
        asian: "Partial HVI testing",
        asianHint: "Check if inventory is not 100% tested on arrival",
        leftover: "Consider leftover quality",
        leftoverHint: "Check to consider the leftover quality",
      },
      modal: {
        id: "ID",
        idHint: "Plant ID",
        desc: "Description",
        descHint: "Plant description",
        update: "Plant update",
        new: "New plant",
      },
      alert: {
        empty: "Required fields are empty",
        saved: "Saved successfully!",
        deleted: "Deleted successfuy!",
      },
    },
    parameters: {
      name: "Quality parameters",
      title: "Registered quality parameters",
      btnNew: "New parameter",
      btnChange: "Change display order",
      byManually: "By manually",
      preConfigured: "Pre configured",
      editParameter: "Edit parameter",
      deleteParameter: "Delete parameter",
      desc: "Full name",
      shortName: "Short name",
      viewingOrder: "Viewing order",
      minimum: "Minimum value",
      miximum: "Maximum value",
      cv: "Controlled CV",
      amplitude: "Amplitude",
      graphicScale: "Chart scale",
      cspMultiplier: "CSP multiplier",
      sciMultiplier: "SCI multipler",
      setAvgWhenZero: "Set avg when zero",
      manualInput: "Manual release",
      calculated: "Calculated",
      sci: "SCI",
      csp: "CSP",
      delete: {
        title: "Delete this parameter",
        text: "Do you really want to delete this parameter?",
      },
      modal: {
        newParameter: "Add new parameter",
        updateParameter: "Parameter update",
        addConfigured: "Add pre configured parameter",
        changeParameter: "Change parameter display order",
        manually: {
          fullName: "Full name",
          fullNameHint: "Parameter name",
          shortName: "Short name",
          shortNameHint: "A short name to be shown on reports and tables",
          minValue: "Minimum value",
          minValueHint: "The minimum value accepted for the parameter",
          maxValue: "Maximum value",
          maxValueHint: "The maximun value accepted for the parameter",
          amplitude: "Amplitude",
          amplitudeHint:
            "The difference between the most common minimum value and the most common maximum value for the parameter",
          chartScale: "Chart scale",
          chartScaleHint:
            "The scale used to present the parameter in the quality graphs",
          csp: "CSP multiplier",
          cspHint: "Parameter multiplication factor in calculating the CSP",
          sci: "SCI multiplier",
          sciHint:
            "Parameter multiplication factor in calculating the SCI value",
          avg: "Set avg when zero",
          avgHint:
            "Indicates whether the system should assign the average quality for bales that do not have value for this parameter in the classification",
          manual: "Manual release",
          manualHint:
            "Indicates if the parameter values are manually input by the user",
          cv: "Controlled CV",
          cvHint:
            "Indicates whether the system should control the coefficient of variation of the parameter",
          calc: "Calculated",
          calcHint:
            "Indicates whether the parameter value is automatically calculated. Must be true only if the parameter is the SCI or CSP and the system should calculate the parameter value automatically based on the SCI/CSP multiplier of other parameters",
        },
        parameterConfigured: {
          text: "Click on  '+'  to add the parameter.",
        },
        viewingOrder: {
          text: "Click on the parameter and drag it to the desired position.",
          displayOrder: "Display order",
          parameter: "Parameter",
        },
      },
      alert: {
        required: "Required fields are empty.",
        saved: "Saved successfully!",
        deleted: "Deleted Successfuy!",
      },
    },
    mixtype: {
      name: "Mix types",
      title: "Registered mix types",
      newBtn: "New mix type",
      desc: "Description",
      classCode: "Class code",
      minDays: "Minimum inventory",
      consumpition: "Monthly consumption ",
      required: "Required lot qty",
      classification: "Classification priority",
      btnEdit: "Edit mix type",
      btnDel: "Delete mix type",
      btnQuanlityRange: "Quality range settings",
      delete: {
        title: "Delete this mix type",
        text: "Do you really want to delete this mix type?",
      },
      modal: {
        desc: "Description",
        descHint: "Mix type name",
        classCode: "Class code",
        classCodeHint: "Code of classes associated with the mix type",
        required: "Required qty of lots",
        requiredHint: "Minimum number of lots to be able to make laydowns",
        classification: "Classification prioriry",
        classificationHint:
          "Priority order in the distribution of bales in case of inventory shared by several mix type",
        minimum: "Minimum inventory",
        minimumHint: "Minimum inventory required for the mix type",
        days: "Days",
        monthly: "Monthly consumption",
        monthlyHint: "Approximate monthly consumption ",
      },
      qualityRangeModal: {
        title: "Quality range",
        btnNew: "New quality range",
        btnEdit: "Edit quality range",
        btnDelete: "Delete quality range",
        parameter: "Parameter",
        minimum: "Minimum",
        maximum: "maximum",
        classificationType: "Classification type",
        mixTypeName: "Mix type",
        newQualityRangeModal: {
          title: "Register new quality range",
          minimum: "Minimum value",
          maximum: "Maximum value",
          parameter: "Parameter",
          parameterHint: "Select the parameter",
          changeDate: "Change date",
          changeReason: "Reason for change",
          rangeChangeHistory: "Changes history",
          noDataHistory: "No changes",
          classificationType: "Rating form:",
          classificationByLot: "Lot average",
          classificationByBale: "Bale by bale",
        },
      },
      alert: {
        empty: "Required fields are empty.",
        saved: "Saved successfully!",
        deleted: "Deleted successfuy!",
      },
      new: "New mix type",
      update: "Mix type update",
    },
    prodline: {
      name: "Production lines",
      title: "Registered production lines",
      newBtn: "New production line",
      editBtn: "Edit production line",
      delBtn: "Delete production line",
      wasteBtn: "Waste positions",
      desc: "Description",
      mixtype: "Mix type",
      preLaydown: "Bales qty in pre-laydown",
      balesLaydown: "Bales qty per laydown",
      preLaydownCode: "Pre-laydown code",
      perDay: "Laydowns per day",
      lot: "Production lot",
      balesSubmix: "Sub-laydown bales qty",
      control: "Production control",
      active: "Active",
      premixCheck: "Uses pre-laydown",
      delete: {
        title: "Delete this production line",
        text: "Do you really want to delete this production line?",
      },
      modal: {
        active: "Active",
        activeHint: "Indicates whether the production line is active or not",
        preLaydown: "Uses pre-laydown",
        preLaydownHint:
          "Indicates whether the production line uses pre-laydown or not",
        mixtype: "Mix type",
        mixtypeHint: "The mix type assigned to the production line",
        desc: "Description",
        descHint: "Production line name or description",
        qtyPerLaydown: "Bales qty per laydown",
        qtyPerLaydownHint: "Amount of bales used in each laydown",
        qtyPreLaydown: "Bales qty in pre-laydown",
        qtyPreLaydownHint: "Amount of bales required for pre-laydown",
        qtySubLaydown: "Bales qty per sub-laydown",
        qtySubLaydownHint: "Amount of bales in each sub-laydown",
        dailyConsumption: "Daily consumption",
        dailyConsumptionHint:
          "Daily consumption (in laydown qty) for the production line",
        laydowns: "Laydowns",
        prodControl: "Production control",
        prodControlHint:
          "Number of production days to be controlled by the system",
        days: "Days",
        preLaydownCode: "Pre-laydown code",
        preLaydownCodeHint:
          "Any value that differentiates the pre-laydown from other pre-laydowns",
        productionLot: "Production lot code",
        productionLotHint:
          "Any value that identifies the production lot from other from other production lots",
      },
      newWaste: {
        title: "New waste position",
        position: "Position:",
        newBtn: "New position",
      },
      settings: {
        text: "Change values directly inside table cells",
        parameter: "Parameter",
        priority: "Priority",
        prodline: "Production tolerance",
        yarn: "Yarn lot tolerance",
        cvTolerance: "CV tolerance",
        maxCv: "Max CV",
        generateLower: "Generate alert lower",
        generateHigher: "Generate alert higher",
        minAllowed: "Minimum allowed",
        maxAllowed: "Maximum allowed",
      },
      wastePosition: {
        title: "Waste positions",
        registred: "Registered waste positions",
        noData: "There are no waste positions registered",
      },
      alert: {
        deleted: "Deleted successfuy!",
        saved: "Saved successfully!",
      },
      titleWaste: "Waste positions",
      newProd: "New production line",
      updateProd: "Production line update",
      prodSettings: "Production line quality settings",
    },
    prodlinecode: {
      name: "Production lines class",
      title: "Registered production lines class",
      newBtn: "New production line class",
      classCode: "Class code",
      prodLine: "Production line",
      delBtn: "Delete class",
      delete: {
        title: "Delete this production line class",
        text: "Do you really want to delete this production line classes?",
      },
      modal: {
        prodline: "Production line",
        prodlineHint: "Select production line",
        classCode: "Class code",
        classCodeHint: "The class code for the production line",
      },
      empty: "Required fields are empty.",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
      newProd: "New production line class",
    },
    producer: {
      name: "Producers",
      title: "Registered producers",
      newBtn: "New producer",
      desc: "Description",
      editBtn: "Edit producer",
      delBtn: "Delete producer",
      delete: {
        title: "Delete this producer",
        text: "Do you really want to delete this producer?",
      },
      modal: {
        name: "Name or description",
        nameHint: "Producer name",
      },
      empty: "Required fields are empty.",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
      newProducer: "New producer",
      updateProducer: "Producer update",
    },
    supplier: {
      name: "Suppliers",
      title: "Registered suppliers",
      newBtn: "New supplier",
      desc: "Description",
      editBtn: "Edit supplier",
      delBtn: "Delete supplier",
      delete: {
        title: "Delete this supplier",
        text: "Do you really want to delete this supplier?",
      },
      modal: {
        name: "Name or description",
        nameHint: "Supplier description",
      },
      empty: "Required fields are empty.",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
      new: "New supplier",
      update: "Supplier update",
    },
    cottonType: {
      name: "Cotton types",
      title: "Registered cotton types",
      newBtn: "New cotton type",
      desc: "Description",
      editBtn: "Edit cotton type",
      delBtn: "Delete cotton type",
      delete: {
        title: "Delete this cotton type",
        text: "Do you really want to delete this cotton type?",
      },
      modal: {
        desc: "Name or description",
        descHint: "Cotton type description",
      },
      empty: "Required fields are empty.",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
      new: "New cotton type",
      update: "Cotton type update",
    },
    pile: {
      name: "Piles",
      title: "Registered piles",
      newBtn: "New pile(s)",
      delBtn: "Delete",
      delete: {
        text: "Do you really want to delete this item?",
        yes: "Yes",
        no: "No",
        title: "Delete the pile(s)",
        really: "Do you really want to delete the selected pile(s)?",
      },
      modal: {
        id: "Pile ID",
        idHint: "Pile ID, ex: A-01",
        generate: "Generate sequence",
        quantity: "Quantity",
        quantityHint: "Tell how many piles you want to generate",
      },
      saved: "Saved successfully!",
      select: "Select cells to delete them",
      new: "New pile",
    },
    origin: {
      name: "Origins",
      title: "Registered origins",
      newBtn: "New origin",
      desc: "Description",
      actions: "",
      editBtn: "Edit origin",
      delBtn: "Delete origin",
      delete: {
        title: "Delete this origin",
        text: "Do you really want to delete this origin?",
      },
      modal: {
        desc: "Name or description",
        descHint: "Origin description",
      },
      empty: "Required fields are empty.",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
      new: "New origin",
      update: "Origin update",
    },
    hvi: {
      name: "HVI layouts",
      title: "Registered layouts",
      newBtn: "New HVI layout ",
      desc: "Description",
      columns: "Columns settings",
      edit: "Edit layout",
      del: "Delete layout",
      delete: {
        title: "Delete this HVI",
        text: "Do you really want to delete this HVI?",
      },
      columnsModal: {
        text: "Change values directly inside table cells",
        parameter: "Parameter",
        position: "Column position",
        multiplier: "Multiplier",
      },
      modal: {
        name: "Layout name",
        nameHint: "Layout name",
      },
      saved: "Saved successfully!",
      empty: "Required fields are empty.",
      deleted: "Deleted successfuy!",
      new: "New HVI layout",
      update: "HVI layout update",
      layoutSettings: "HVI layout settings",
    },
    user: {
      name: "Users",
      title: "Registered users",
      newBtn: "New user",
      fullname: "Name",
      editBtn: "Edit user",
      delBtn: "Delete user",
      delete: {
        title: "Delete this user",
        text: "Do you really want to delete this user?",
      },
      modal: {
        basic: "Basic",
        advanced: "Advanced",
        manager: "Manager",
        username: "Username",
        usernameHint: "Username for logon",
        email: "Email",
        emailHint: "User email",
        firstName: "First name",
        firstNameHint: "User's first name",
        lastName: "Last name",
        lastNameHint: "User's last name",
        user: "User privileges",
        important:
          "Important: The default password is 'change_me'. When logging into the application for the first time, the user will be required to change the password by entering this default password.",
      },
      new: "New user",
      update: "User update",
      saved: "Saved successfully!",
      deleted: "Deleted successfuy!",
    },
  },
  inventory: {
    cottonInput: {
      name: "Cotton input",
      supplier: "Supplier",
      select: "Select...",
      title: "Supplier's registered lots",
      newBtn: "New lot",
      balesBtn: "Bales of the lot",
      editBtn: "Edit lot information",
      delBtn: "Delete lot",
      lot: "Lot",
      baleQty: "Bales qty",
      issueDate: "Issue date",
      arrival: "Arrival date",
      avg: "Avg bale weight",
      net: "Net weight",
      cottonType: "Cotton type",
      origin: "Origin",
      producer: "Producer",
      weight: "Weight",
      selectASupplier: "Please select a supplier",
      delete: {
        title: "Delete this lot",
        text: "Do you really want to delete this lot?",
      },
      modal: {
        new: "New lot",
        update: "Lot update",
        lot: "Lot ID",
        lotHint: "Lot id",
        amount: "Qty of bales",
        amountHint: "Number of bales in the lot",
        gross: "Gross weight",
        grossHint: "Gross weight of the lot",
        net: "Net weight",
        netHint: "Net weight of the lot",
        producer: "Producer name",
        producerHint: "The producer assigned to the lot",
        cottonType: "Cotton type",
        cottonTypeHint: "The cotton type assigned to the lot",
        origin: "Origin",
        originHint: "The origin assigned to the lot",
        issue: "Issue date",
        issueHint: "Invoice issue date",
        arrival: "Arrival date",
        arrivalHint: "Lot entry date at the plant",
        name: "Selector name",
        nameHint: "Name or ID of the selector",
        purchase: "Purchase contract number",
        purchaseHint: "Purchase contract ID assigned to the lot",
        lotId: "Purchase lot ID",
        lotIdHint: "Purchase lot ID assigned to the lot",
      },
      falied: "Failed to load suppliers data",
      falied2: "Failed to load cotton type data",
      falied3: "Failed to load origins data",
      falied4: "Failed to load producers data",
      falied5: "Failed to load lots data",
      deleted: "Deleted successfully",
      empty: `Required fields are empty.`,
      lotSuccessfully: `Lot successfully registered`,
      lotAltered: `Saved successfully`,
    },
    balesInput: {
      lot: "Lot ID:",
      supplier: "Supplier:",
      file: "From file",
      manually: "Manually",
      registered: "Registered bales",
      add: "New bales",
      weight: "Weight",
      avg: "Assign avg quality",
      del: "Delete bales",
      delete: {
        title: "Delete bales",
        text: "Do you really want to delete selected bales?",
      },
      noBales: "No bales found",
      pile: "Pile ID",
      byAvg: {
        title: "Assign average quality to bales",
        text: "Do you really want to assign the average lot quality to bales that do not contain quality values?",
      },
      insert: {
        title: "Insert bales manually",
        bale: "Bale ID",
        generate: "Generate multiple bales automatically",
        quantity: "Quantity",
        quantityHint: "Amount of bales to be generated automatically",
      },
      selectedBales: "The selected bales have been deleted!",
      classified: "Bales classified by average",
      added: `Bale added successfully`,
      readFile: {
        readFile: "Read file",
        bales: "Bales - Importation",
        lot: "Lot ID:",
        supplier: "Supplier:",
        layout: "Layout",
        select: "Select matching layout",
        insert: "Insert bales",
        balesFile: "Bales in the file",
        within: "Value within the range for the parameter",
        out: "Value out of the range for the parameter",
        failed:
          "You have parameters with incorrect colunms, fix the HVI register",
        empty: "you have empty cell in colunm",
        registered: "Bales registered successfully",
      },
    },
    managementBales: {
      name: "Inventory management - Bales",
      filters: "Inventory status",
      select: "Select...",
      bales: "Bales",
      changePile: "Change pile",
      turn: "Turn available",
      put: "Put on hold",
      noBales: "No bales found",
      idBales: "Bale ID",
      status: "Status",
      statusAvailable: "Available",
      statusOnHold: "On hold",
      supplier: "Supplier",
      lotInvoiceID: "Lot ID",
      pileID: "Pile ID",
      weight: "Weight",
      arrivalDate: "Arrival date",
      producer: "Producer",
      cottontypeName: "Cotton type",
      originName: "Origin",
      modal: {
        text: "Select destination pile",
        textHint: "Select the destination pile to bales",
      },
      all: "All",
      running: "Running",
      distribution: "Available for distribution",
      tested: "Fully tested",
      quality: "Missing quality",
      hold: "On hold",
      failed: "Failed to load pile data",
      failed2: "Failed to load bales data",
      successfully: "Pile changed successfully!",
      forUse: "Bales on hold for use!",
      availableforUse: "Bales available for use for use!",
    },
    managementLots: {
      name: "Inventory management - Lots",
      lots: "Lots",
      change: "Change pile",
      turn: "Turn available",
      put: "Put on hold",
      transfer: "Transfer to other plant",
      lotId: "Lot ID",
      status: "Status",
      statusAvailable: "Available",
      statusOnHold: "On hold",
      baleQty: "Bales qty",
      pile: "Pile",
      origin: "Origin",
      producer: "Producer",
      arrivalDate: "Arrival date",
      netWeight: "Net weight",
      filters: "Inventory status",
      select: "Select...",
      selectPile: "Select destination pile",
      selectPileHint: "Select the destination pile to bales",
      selectPlant: "Select destination plant",
      selectPlantHint: "Select the destination pile to bales",
      changePile: "Change pile",
      changePlant: "Change plant",
      all: "All",
      running: "Running",
      distribution: "Available for distribution",
      tested: "Fully tested",
      quality: "Missing quality",
      hold: "On hold",
      failed: "Failed to load pile data",
      lot: "Lot of the pile was change",
      availableAlert: `Lot available for use`,
      holdAlert: "Lot on hold for use!",
    },
    distribution: {
      name: "Cotton distribution",
      new: "New manual distribution",
      new2: "New automatic distribution",
      mixTypeDescription: "Mix type",
      classificationPriority: "Classification priority",
      monthlyConsumption: "Monthly consumption",
      monthlyConsumptionPercentage: "Monthly consumption percentage",
      runningTonsQty: "Running qty ",
      minimumTonsRequired: "Minimum required ",
      minimumVsAvailableInventory: "Running vs minimum inventory percentage",
      date: "Date",
      originPileID: "Origin pile ID",
      last: "Last cotton distributions",
      lots: "Lots included",
      noData:
        "Click on 'Last cotton distributions' on the side to view the distributed lots",
      newPileID: "New pile ID",
      baleQty: "Bales qty",
      netWeight: "Net weight",
      currentStatusDescription: "Current status",
      inventory: "Inventory overview",
      delete: {
        title: "Delete this distribution",
        text: "Do you really want to revert this lot distributed?",
        text2: "Do you really want to delete this distribution?",
      },
      canceled: `Transfer canceled`,
      lotTransfer: `Lot transfer canceled`,
      byMixType: "Quality by mix type",
      byPile: "Quality by pile",
      theSelectedPile: "Lots in the selected pile",
      byOrigin: "Quantity by origin",
      byProducer: "Quantity by producer",
      statusAvailable: "Available",
      statusUsedInLaydown: "Used in laydown",
    },
    autoDistribution: {
      name: "Automatic distribution",
      generate: "Generate",
      confirmBtn: "Confirm selected lots",
      generateBtn: "Generate automatic distribution",
      mixTypeQuality: "Mix type quality perspective",
      inventoryQuality: "Inventory quality",
      parameter: "Parameter",
      priority: "Priority",
      minimum: "Minimum",
      maximum: "Maximum",
      expected: "Expected",
      achieved: "Achieved",
      itemQty: "Item qty",
      save: "Save",
      cancel: "Cancel",
      baleQty: "Bales Qty",
      lotID: "Lot ID",
      cottonTypeName: "Cotton type",
      originName: "Origin",
      producerName: "Producer",
      supplierName: "Supplier",
      weight: "Weight",
      alert: "You need to select the lots to be tranfer",
      transfered: `Lots transfered successfully`,
      totalInventory: "Total inventory",
      running: "Running",
      availableInventory: "Available for distribution",
      suggested: "Suggested lots",
      runningSuggested: "Running + suggested lots (projection)",
    },
    manualDistribution: {
      originPile: "Origin pile",
      mixType: "Mix type",
      name: "Manual distribution",
      makeBtn: "Make a manual distribution",
      lotID: "Lot ID",
      producerName: "Producer",
      destinationPile: "Destination pile",
      qty: "Qty",
      weight: "Weight",
      itemQty: "Bales qty",
      origin: "Origin",
      transferred: `Selected lot(s) have been transferred`,
      update: "Manual distribution",
      theSelectedPile: "Lots in the selected pile",
      byPile: "Quality by pile",
      byMixType: "Quality by mix type",
      byOrigin: "Quantity by origin",
      byProducer: "Quantity by producer",
      totalInventory: "Total inventory",
      running: "Running",
      availableInventory: "Available inventory",
      suggested: "Selected pile",
      runningSuggested: "Running + selected pile (projection)",
      selectPileMsg: "Select the destination pile for each lot in the list",
    },
    pileGroup: {
      name: "Cotton usage control - Pile groups",
      title: "Pile groups of the production line",
      newBtn: "New pile group",
      prodline: "Production line",
      select: "Select",
      balesPerLaydown: "Quantity of bales per laydown:",
      prodLine: "Please select a Production line",
      groupId: "Group ID",
      desc: "Description",
      active: "Active",
      qtyBales: "Bales qty",
      usage: "Usage percentage",
      delete: {
        title: "Delete this group pile",
        text: "Do you really want to delete this pile group?",
      },
      modal: {
        value: "Show only piles with bales",
        value2: "Show all piles",
        prodline: "Production line:",
        amount: "Amount of bales per laydown:",
        represents: "1 bale represents",
        of: "of the laydown",
        groupName: "Group name",
        groupNameHint: "Origin description",
        perLaydown: "Bales per laydown",
        active: "Active",
        representation: "Group representation (%)",
        laydown: "Laydown:",
        inventory: "Inventory:",
        piles: "Out-of-group piles representation (%)",
        pileID: "Pile ID",
        groupID: "Group ID",
        percentage: "Percentage",
        weight: "Weight",
        baleQty: "Bales qty",
      },
      saved: "Saved successfully!",
      greater: "Bale quantity sum is greater than 100%",
      deleted: `Successfully deleted`,
      new: "New pile group",
      update: "Pile group update",
    },
    cottonTypes: {
      name: "Cotton usage control - Cotton types",
      controlled: "Controlled cotton types",
      newBtn: "New controlled cotton type",
      mixType: "Mix type",
      select: "Select",
      quantity: "Quantity of bales per laydown:",
      sum: "Sum:",
      thePercentage: "(The percentage must be equal to 100%)",
      cottonTypeName: "Cotton type",
      baleQty: "Bales qty",
      usage: "Usage percentage",
      noData: "Please select a mix type",
      delete: {
        title: "Delete this cotton Ttype",
        text: "Do you really want to delete this cotton type?",
      },
      modal: {
        mixType: "Mix type:",
        amount: "Amount of bales per laydown:",
        represents: "1 bale represents of the laydown:",
        sum: "Sum of all controlled cotton types in the laydown:",
        cottonType: "Cotton type",
        select: "Select...",
        bales: "Bales per laydown",
        balesHint: "Bales Qty",
      },
      updated: "Cotton type successfully updated",
      quantitysum: "Bales quantity sum is greater than 100%",
      deleted: "Successfully deleted",
      new: "New cotton type",
      update: "Cotton type update",
      inserted: "Cotton type successfully inserted",
      registered: `Cotton type already registered`,
    },
    importantion: {
      name: "Testing results input - Importation",
      layout: "Layout",
      select: "Select matching layout",
      confirm: "Import results",
      file: "Bales in the file",
      within: "Value within the range for the parameter",
      out: "Value out of the range for the parameter",
      noFiles: "No files selected",
      haveParameters:
        "You have parameters with incorrect colunms, fix the HVI column register",
      empty: "you have empty cell in colunm",
      saved: `Saved successfully!`,
    },
    manualInputs: {
      name: "Testing results input - Manual release",
      same: "Same values for all",
      individual: "Individual values",
      status: "Status",
      delect: "Select...",
      supplier: "Supplier",
      lot: "Lot",
      bales: "Bales",
      confirm: "Save",
      pileID: "Pile ID",
      all: "All",
      pending: "Pending",
      saved: "Saved successfully!",
    },
    consultBale: {
      name: "Bale info",
      enter: "Enter Bale ID",
      search: "Search",
      data: "Bale data",
      status: "Status",
      bale: "Bale ID",
      cottonType: "Cotton Type",
      origin: "Origin",
      pile: "Pile",
      producer: "Producer",
      supplier: "Supplier",
      quality: "Bale quality",
      parameter: "Parameter",
      value: "Value",
      stock: "Bale not found in inventory",
    },
  },
  mixing: {
    planning: {
      name: "Quality plannings",
      title: "Last quality plannings",
      newBtn: "New planning",
      details: "View details",
      generate: "Generate planning",
      edit: "Edit planning",
      del: "Delete planning",
      number: "Planning number",
      date: "Planning date",
      mixtype: "Mix type",
      lotChange: "Lot change",
      statusDesription: "Status",
      statusUsed: "Used",
      statusCreated: "Created",
      delete: {
        title: "Delete this planning",
        text: "Do you really want to delete this planning?",
      },
      modal: {
        new: "New planning",
        update: "Planning update",
        mixtype: "Mix type",
        select: "Select...",
        lotChange: "Production lot change?",
        quality: "Planned quality",
        noDataText: "Plan has not yet been generated",
      },
      quality: {
        parameter: "Parameter",
        value: "Value",
      },
      elaboration: {
        name: "Quality plannings - Plan",
        elaboration: "Elaboration",
        planID: "Planning ID",
        mixType: "Mix type",
        quality: "Quality",
        noDataText: "No inventory available",
        parameterName: "Parameter",
        inventory: "Current inventory",
        lastLaydown: "Last laydown",
        planned: "Planned",
        difference: "Production difference",
        info: "Consumption info",
        generate: "Generate planning",
        no: "No priorities",
        inventoryContext: "Inventory",
        laydown: "Laydown",
        current: "Current inventory (Bales)",
        perDay: "Laydowns per day",
        perLaydown: "Bales per laydown",
        totalBales: "Total bales per day",
        remaining: "Remaining inventory (Bales)",
        failed: "Failed to generate plan",
        successfully: "Planning generated successfully!",
      },
      added: "New planning successfully added!",
      deleted: "Deleted Successfuy!",
    },
    pre: {
      name: "Pre-laydowns",
      title: "Last pre-laydowns",
      newBtn: "New pre-laydown",
      generate: "Generate pre-laydown",
      viewSelectedPilesHint: "View selected piles",
      editHint: "Edit pre-laydown",
      deleteHint: "Delete pre-laydown",
      id: "Pre-laydown ID",
      prodline: "Production line",
      planning: "Planning ID",
      uses: "Uses pre-laydown",
      baleQty: "Bales qty",
      creationDate: "Creation date",
      yarnLot: "Yarn lot",
      statusDesription: "Status",
      statusFinished: "Finished",
      statusInProcess: "In process",
      statusCreated: "Created",
      delete: {
        title: "Delete this pre-laydown",
        text: "Do you really want to delete this pre-laydown?",
      },
      select: "Select...",
      pileFor: "Piles for pre-laydown",
      noDataText: "Pre-laydown has not yet been generated",
      pileID: "Pile ID",
      necessaryBaleQty: "Necessary bales qty",
      elaboration: {
        name: "Pre-laydown - Generation",
        elaboration: "Elaboration",
        pre: "Pre-laydown ID",
        prodline: "Production line",
        generate: "Generate pre-laydown",

        available: "Available piles",
        pileID: "Pile ID",
        lotID: "Lot ID",
        pileGroupName: "Pile group",
        baleQty: "Bales qty",
        minimumBaleQty: "Minimum bales qty",
        maximumBaleQty: "Maximum bales qty",
        definedBaleQty: "Defined bales qty",
        quality: "Quality",
        parameterName: "Parameter",
        priority: "Priority",
        inventoryQuality: "Inventory",
        plannedQuality: "Planned",
        achievedQuality: "Achieved",
        minimumAllowed: "Minimum allowed",
        maximumAllowed: "Maximum allowed",
        tolerance: "Whitin tolerance",
        up: "Up to 50% out of tolerance",
        more: "More than 50% out of tolerance",
        selected: "Selected piles",
        necessaryBaleQty: "Necessary bales qty",
      },
      created: `Pre laydown created successfully`,
      saved: `Saved successfully`,
      generated: "Pre-laydown generated successfully!",
      new: "New pre-laydown",
      update: "Pre-laydown update",
    },
    laydown: {
      name: "Laydown",
      title: "Pre-laydown ID",
      select: "Select...",
      laydownID: "Laydown ID",
      thePriority: "Select the priority",
      prodLine: "Production line",
      generalIdentifier: "General ID",
      baleQty: "Bales qty",
      creationDate: "Creation date",
      changeDate: "Change date",
      consumptionDate: "Consumption date",
      status: "Status",
      newBtn: "New laydown",
      balesAndQuality: "Bales and quality",
      cottontypeSummary: "Cotton type summary",
      layoutPosition: "Layout position",
      position: "Position",
      leftoverBales: "Leftover bales",
      originSummary: "Origin summary",
      pileSummary: "Pile summary",
      producerSummary: "Producer summary",
      laydownCharts: "Laydown charts",
      balesBtn: "Bales",
      layoutBtn: "Layout",
      delBtn: "Delete",
      finishBtn: "Finish",
      resumeBtn: "Resume",
      laydownBales: "Laydown bales",
      bale: "Bale",
      pile: "Pile",
      laydownQuality: "Laydown quality",
      parameter: "Parameter",
      planned: "Planned",
      achieved: "Achieved",
      minimum: "Minimum",
      maximum: "Maximum",
      cv: "CV",
      whitin: "Whitin tolerance",
      out: "Out up to 50%",
      over: "More than 50% out",
      reporting: "Reporting",
      statusFinished: "Finished",
      statusInProcess: "In process",
      statusCreated: "Created",

      bales: {
        bales: "Bales",
        name: "Laydown - bale taking",
        preLaydown: "Pre-laydown ID",
        laydownId: "Laydown ID",
        prodLine: "Production line",
        generate: "Generate laydown",
        addSingle: "Bale ID",
        enterBale: "Enter bale ID...",
        includeBale: "Include bale",
        addBales: "Add bales from file to pre-laydown",
        select: "Select corresponding layout",
        balesAdded: "Bales added to pre-laydown",
        leftover: "Include leftover",
        leftoverhint:
          "Adds leftover bales from last pre-laydown to current pre-laydown.",
        remove: "Remove leftover",
        removeHint:
          "Removes all leftover bales from current pre-laydown, but keeps the bales in the leftover list.",
        removeAllocated: "Remove allocated",
        removeAllocated2: "Do you really want to remove allocated?",
        selected: "Remove selected",
        selectedHint:
          "Remove one or more bales from both current pre-laydown and leftover bales list.",
        excludeBales: "Exclude bales",
        excludeBales2: "Do you really want to exclude bales?",
        perPile: "Required bales qty per pile",
        print: "Print",
        pile: "Pile",
        allocated: "Allocated",
        necessary: "Necessary",
        missing: "Missing",
        sum: "Sum",
        required: "Required quantity reached",
        lotsInThePile: "Lots in the pile",
        laydownQuality: "Pre-laydown quality",
        parameter: "Parameter",
        planned: "Planned",
        achieved: "Achieved",
        minimum: "Minimum",
        maximum: "Maximum",
        cv: "CV",
        whitin: "Whitin tolerance",
        out: "Out up to 50%",
        over: "More than 50% out",
      },
      generate: {
        generateBtn: "Generate laydown",
        generation: "Generation",
        name: "Laydown - Generation",
        prodline: "Production line",
        laydown: "Laydown ID",
        prelaydown: "Pre-laydown bales qty",
        laydownBales: "Laydown bales qty",
        laydownQuality: "Laydown quality",
        parameter: "Parameter",
        planned: "Planned",
        priority: "Priority",
        achieved: "Achieved",
        minimum: "Minimum",
        maximum: "Maximum",
        cv: "CV",
        whitin: "Whitin tolerance",
        out: "Out up to 50%",
        over: "More than 50% out",
        controlled: "Controlled groups",
        notControlled: "Not controlled",
        cvControl: "CV control",
        noDataText: "There are no pile groups registered",
        noParametersCVControlled: "There are no parameters with controlled cv",
        pileGroupID: "ID",
        description: "Description",
        minimumExpected: "Minimum expected",
        maximumExpected: "Maximum expected",
        achievedQty: "Achieved qty",
        preLaydownQty: "Pre-laydown qty",
        notAchieved: "Not achieved",
        inThePrelaydown: "Bales in the pre-laydown",
        baleID: "Bale",
        pileID: "Pile",
        producer: "Producer",
        selectedToLaydown: "Bales selected to laydown",
      },
      layout: {
        layout: "Layout",
        name: "Laydown - Layout generation",
        prodline: "Production line",
        laydown: "Laydown ID",
        balesQty: "Laydown bales qty",
        balesQty2: "Sub-laydown bales qty",
        generateBtn: "Generate layout",
        selected: "Selected bales",
        baleID: "Bale",
        layoutPosition: "Layout position",
        subLaydownID: "Sub-laydown",
        pileID: "Pile",
        weight: "Weight",
        laydownQuality: "Laydown quality",
        parameter: "Parameter",
        achieved: "Achieved",
        sublaydownQuality: "Sub-laydowns quality",
        groupDescrption: "Group description",
        whitin: "Whitin tolerance",
        out: "Out up to 50%",
        over: "More than 50% out",
        noPriorities: "no-priorities",
        producer: "producer",
        origin: "origin",
      },
      confirm: {
        title: "Consumption laydown",
        text: "Do you want confirme the consumption?",
        title2: "Delete laydown",
        text2: "Do you really want to delete this laydown?",
        title3: "Finish laydown",
        text3: "Do you really want to finish this laydown?",
        title4: "Resume laydown",
        text4: "Do you really want to resume this laydown?",
      },
      alert1:
        "You have parameters with incorrect colunms, fix the HVI column register",
      alert2:
        "All bales have been removed from both current pre-laydown and leftover bales list",
      alert3:
        "All bales have been removed from current pre-laydown, but keeps the bales in the leftover list.",
      alert4: "Select bales to exclude them",
      alert5:
        "The selected bales have been removed from both current pre-laydown and leftover bales list.",
      alert6: `Bales included in the pre-laydown`,
      alert7:
        "Leftover bales from last pre-laydown have been added from current pre-laydown.",
      alert8: `Packing list of the laydown`,
      alert9: "Selected to laydown",
      alert10: "Laydown generated successfully!",
      alert11: "Generated successfuy!",
    },
    reports: {
      reporting: "Reporting",
      balesAndQuality: {
        name: "Laydown report - Bales and quality",
        balesAndQuality: "Bales and quality",
        laydown: "Laydown",
        preLaydown: "Prelaydown",
        laydownReport: `Laydown report - Quality`,
        parameter: "Parameter",
        avg: "Avg",
        cv: "CV",
        maximum: "Maximum",
        minimum: "Minimum",
        sd: "Sd",
        baleID: "Bale ID",
        cottonTypeName: "Cotton type",
        producerName: "Producer",
        lotID: "Lot ID",
        pileID: "Pile ID",
        weight: "Weight",
        export: "Export all",
      },
      cottonTypeSummary: {
        name: "Laydown report - Summary by cotton type",
        cottonTypeSummary: "Cotton type summary",
        cottonType: "Cotton type",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        nameDg: "Name",
        weight: "Weight",
        baleQty: "Bales qty",
        totalBales: "Total bales",
        totalWeight: "Total weight",
        laydown: "Laydown",
        preLaydown: "Pre Laydown",
        laydownReport: `Laydown report - Summary by cotton type`,
        percentage: "Percentage",
        compositionPercentage: "Composition percentage",
      },
      laydownCharts: {
        laydownChart: "Laydown chart",
        reporting: "Laydown report - Average charts",
        bale: "Bale",
        avg: "Laydown average",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        exportAll: "Export All",
      },
      layoutPosition: {
        layoutPosition: "Layout position",
        name: "Laydown report - Layout",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        baleID: "Bale ID",
        lotID: "Lot ID",
        pileID: "Pile ID",
        weight: "Weight",
        subLaydownID: "Sub-laydown ID",
        laydown: "Laydown",
        preLaydown: "Pre laydown",
        laydownReport: `Laydown report - Layout`,
      },
      leftoverBales: {
        leftoverBales: "Leftover bales",
        name: "Laydown report - Leftover bales",
        preLaydownId: "Pre-laydown ID",
        preLaydown: "Pre-laydown",
        laydownReport: `Laydown report - Leftover bales`,
        baleID: "Bale ID",
        lotID: "Lot ID",
        cottonType: "Cotton type",
        pileID: "Pile ID",
        weight: "Weight",
        laydownDate: "Laydown date",
        remove: "Remove selected bales",
        removeHint: "Remove all selected bales from leftover bales list.",
        removeMsg:
          "Do you really want to remove all selected bales from leftover list?",
        removeAll: "Remove all",
        removeAllHint: "Remove all bales from leftover bales list.",
        removeAllMsg:
          "Do you really want to remove all bales from leftover list?",
      },
      originSummary: {
        originSummary: "Origin summary",
        name: "Laydown report - Summary by origin",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        laydown: "Laydown",
        preLaydown: "Pre-laydown",
        laydownReport: `Laydown report - Summary by origin`,
        origin: "Origin",
        nameDg: "Name",
        weight: "Weight",
        baleQty: "Bales qty",
        totalBales: "Total bales",
        totalWeight: "Total weight",
        percentage: "Percentage",
        compositionPercentage: "Composition percentage",
      },
      pileSummary: {
        pileSummary: "Pile summary",
        name: "Laydown report - Summary by pile",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        laydown: "Laydown",
        preLaydown: "Pre-laydown",
        laydownReport: `Laydown report - Summary by pile`,
        pile: "Pile",
        nameDg: "Name",
        weight: "Weight",
        baleQty: "Bales qty",
        totalBales: "Total bales",
        totalWeight: "Total weight",
        percentage: "Percentage",
        compositionPercentage: "Composition percentage",
      },
      producerSummary: {
        producerSummary: "Producer summary",
        name: "Laydown report - Summary by producer",
        producer: "Producer",
        preLaydownId: "Pre-laydown ID",
        laydownId: "Laydown ID",
        laydown: "Laydown",
        preLaydown: "Pre-laydown",
        nameDg: "Name",
        weight: "Weight",
        baleQty: "Bales qty",
        totalBales: "Total bales",
        totalWeight: "Total weight",
        percentage: "Percentage",
        compositionPercentage: "Composition percentage",
      },
    },
    reportsContext: {
      alert1: "Failed to load lots entry data",
      alert2: `Laydown quality - Average graphic`,
      alert3: "Failed to load cotton type summary data",
      alert4: "Failed to load laydown Charts data",
      alert5: `Laydown quality - Graphic Of bales`,
      alert6: "Failed to load layout position data",
      alert7: "Failed to load layout position data",
      alert8: "Failed to load cotton type summary data",
      alert9: "Failed to load cotton type summary data",
      alert10: "Failed to load cotton type summary data",
    },
    consumptionControl: {
      modalTitle: "Select date",
      name: "Consumption Control",
      prodline: "Production line",
      select: "Select...",
      status: "Status",
      thePriority: "Select the priority",
      laydowns: "Laydowns",
      noDataText: "Please select a laydown and status",
      preLaydownID: "Pre-Laydown ID",
      id: "laydown ID",
      creationDate: "Creation Date",
      consumptionDate: "Consumption Date",
      statusDg: "Status",
      confirm: "Confirm consumption",
      consumed: `Laydown consumed`,
      all: "All",
      pending: "Pending",
    },
  },
  reports: {
    inventory: {
      bales: {
        name: "Bales in inventory",
        title: "Bales in inventory",
        status: "Status",
        pile: "Pile",
        bales: "Bales",
        baleID: "Bale ID",
        lotInvoiceID: "Lot ID",
        pileID: "Pile ID",
        cottontype: "Cotton type ID",
        weight: "Weight",
        arrivalDate: "Arrival date",
        all: "All",
        running: "Running",
        availablefordistribution: "Available for distribution",
        fullyTested: "Fully tested",
        missingQuality: "Missing quality",
        hold: "On hold",
        blocked: "Blocked",
        piles: "In piles",
        leftover: "Leftover",
        failed: "Failed to load bales data",
        qualitySummary: "Quality summary",
      },
      composition: {
        name: "Inventory composition",
        nameDg: "Name/description",
        bales: "Bales",
        weight: "Weight",
        percentage: "Percentage",
        total: "Total",
        grouping: "Grouping type",
        select: "Select...",
        status: "Status",
        cottonType: "Cotton type",
        producer: "Producer",
        supplier: "Supplier",
        origin: "Origin",
        all: "All",
        hold: "On hold",
        blocked: "Blocked",
        failed: "Failed to load Composition data",
      },
      general: {
        name: "General inventory",
        supplier: "Supplier",
        select: "Select...",
        producer: "Producer",
        lot: "Lot",
        pile: "Pile",
        cottonType: "Cotton Type",
        prodline: "Production line",
        bySupplier: "Group by supplier",
        byCottontype: "Group by cotton type",
        byLot: "Group by lot",
        byProducer: "Group by producer",
        byPile: "Group by pile",
        lotID: "Lot ID",
        cottonTypeName: "Cotton type",
        pileID: "Pile",
        bales: "Bales qty",
        failed: "Failed to load lots entry data",
        failed2: "Failed to load suppliers data",
        failed3: "Failed to load cotton type data",
        failed4: "Failed to load origins data",
        failed5: "Failed to load producers data",
        failed6: "Failed to load production line data",
        failed7: "Failed to load lots data",
        failed8: "Failed to load piles data",
        origin: "Origin",
        groupingType: "Grouping type",
        groupedBy: "Grouped by",
        startDate: "Start date",
        finalDate: "Final date",
        weight: "Weight",
        preLaydown: "Pre laydown ID",
        laydown: "Laydown ID",
        generalID: "General identifier",
      },
      lotsEntredPerPeriod: {
        name: "Receipt of lots per period",
        supplier: "Supplier",
        select: "Select...",
        producer: "Producer",
        origin: "Origin",
        cottonTypes: "Cotton type",
        period: "Period",
        to: "to",
        lotId: "Lot ID",
        baleQty: "Bales qty",
        plantWeight: "Weight",
        title: "Receipt of lots per period",
        failed: "Failed to load LotsEntry data",
        failed2: "Failed to load suppliers data",
        failed3: "Failed to load cotton type data",
        failed4: "Failed to load origins data",
        failed5: "Failed to load producers data",
      },
      quality: {
        name: "Inventory quality overview",
        prodline: "Production line",
        select: "Select...",
        baleQty: "Available bales qty",
        weight: "Weight",
        text: "It is limiting the inventory available for the production line.",
        title: "Inventory quality overview",
        failed: "Failed to load LotsEntry data",
        failed2: "Failed to load suppliers data",
        failed3: "Failed to load cotton type data",
        failed4: "Failed to load origins data",
        failed5: "Failed to load producers data",
        failed6: "Failed to load production line data",
        failed7: "Failed to load lots data",
        totalInventory: "Total inventory",
        availableInventory: "Available inventory",
        cottonOutsideGroups: "Cotton outside groups",
      },
    },
    consumpition: {
      avg: {
        name: "Laydowns average charts",
        prodline: "Production line",
        select: "Select...",
        yarnlot: "Yarn lot",
        period: "Period",
        to: "to",
        showBy: "Show by",
        showTolerances: "Show tolerances of",
        upperTolerance: "Upper tolerance",
        planned: "Planned",
        laydownAvg: "Laydown ID",
        lowerTolerance: "Lower tolerance",
        cv: "CV",
        average: "Average",
        production: "Production",
        failed: "Failed to load AvgCharts data",
        failed2: "Failed to load production line data",
        failed3: "Failed to load yarn lots data",
        inventoryAvg: "Current inventory average",
      },
      perPeriod: {
        name: "Consumption composition per period",
        groupingType: "Grouping type",
        select: "Select...",
        prodline: "Production line",
        period: "Period",
        to: "to",
        nameDg: "Name",
        bales: "Bales",
        weight: "Weight",
        percentage: "Percentage",
        cottonType: "Cotton type",
        producer: "Producer",
        supplier: "Supplier",
        origin: "Origin",
        failed: "Failed to load Composition data",
        failed2: "Failed to load production line data",
      },
      daily: {
        name: "Cotton consumption per day",
        groupingType: "Grouping type",
        select: "Select...",
        prodline: "Production line",
        period: "Period",
        day: "Day",
        bales: "Bales",
        running: "Running lots",
        totalWeight: "Weight",
        title: "Cotton consumption per day",
        cottonType: "Cotton type",
        producer: "Producer",
        supplier: "Supplier",
        origin: "Origin",
        failed: "Failed to load daily data",
        failed2: "Failed to load production line data",
        failed3: "Failed to load suppliers data",
        failed4: "Failed to load cotton type data",
        failed5: "Failed to load origins data",
        failed6: "Failed to load producers data",
      },
      general: {
        name: "Cotton consumption",
        cottonType: "Cotton type",
        producer: "Producer",
        supplier: "Supplier",
        select: "Select...",
        prodline: "Production line",
        period: "Period",
        to: "to",
        bySupplier: "Group by supplier",
        byCottonType: "Group by cotton type",
        byPile: "Group by pile",
        byProducer: "Group by producer",
        byProdline: "Group by production line",
        byLaydown: "Group by laydown",
        pile: "Pile",
        laydown: "Laydown",
        baleQty: "Bales qty",
        failed: "Failed to load lots entry data",
        failed2: "Failed to load suppliers data",
        failed3: "Failed to load cotton type data",
        failed4: "Failed to load origins data",
        failed5: "Failed to load producers data",
        failed6: "Failed to load production line data",
        failed7: "Failed to load lots data",
        failed8: "Failed to load piles data",
        weight: "Weight",
        allProdLines: "All",
      },
      perMonthly: {
        name: "Cotton consumption per month",
        groupingType: "Grouping type",
        select: "Select...",
        prodline: "Production line",
        period: "Period",
        to: "to",
        month: "Month",
        bales: "Bales",
        lotQty: "Running lots",
        totalWeight: "Weight",
        title: "Cotton consumption per month",
        cottonType: "Cotton type",
        producer: "Producer",
        supplier: "Supplier",
        origin: "Origin",
        failed: "Failed to load monthly data",
        failed2: "Failed to load production line data",
        failed3: "Failed to load suppliers data",
        failed4: "Failed to load cotton type data",
        failed5: "Failed to load origins data",
        failed6: "Failed to load producers data",
      },
      perLaydown: {
        name: "Cotton consumption per laydown",
        prodline: "Production line",
        select: "Select...",
        yarnLot: "Yarn lot",
        period: "Period",
        to: "to",
        consumptionDate: "Consumption date",
        preLaydownID: "Pre laydown ID",
        laydownID: "Laydown ID",
        baleQty: "Bales qty",
        totalWeight: "Weight",
        title: "Cotton consumption per laydown",
        failed: "Failed to load PerLaydown data",
        failed2: "Failed to load production line data",
        failed3: "Failed to load yarn lots data",
      },
      usda: {
        name: "Color grade - USDA",
        preLaydown: "Pre laydown",
        select: "Select...",
        laydown: "Laydown",
        failed: "Failed to load laydown data",
        failed2: "Failed to load pre laydown data",
        failed3: "Failed to load USDA charts data",
      },
    },
  },

  warnings: {
    path: "Warnings",
    name: "Quality warnings",
    status: "Status",
    select: "Select...",
    pending: "Pending",
    authorized: "Authorized",
    title: "Quality warnings",
    noDataText: "No warnings found",
    productionLineName: "Production line",
    preLaydownID: "Pre-laydown",
    laydownID: "Laydown",
    warningDate: "Warning date",
    authorizedUse: "Authorized use",
    infoHint: "See warning details",
    checkHint: "Authorize use",
    confirm: {
      title: "Authorize the use",
      text: "Do you want authorize the use of this laydown?",
    },
    modal: {
      title: "Warning details",
      itemName: "Parameter",
      minimum: "Minimum",
      maximum: "Maximum",
      achieved: "Achieved",
    },
  },
  home: {
    path: "Home",
    user: "User",
    access: "Quick access",
    cottonInput: "Cotton input",
    baleInfo: "Bale info",
    planning: "Planning",
    pre: "Pre-laydown",
    laydown: "Laydown",
    avg: "Avg. charts",
    cottonType: "Inventory composition per cotton type",
    producer: "Inventory composition per producer",
  },
  user: {
    title: "User Settings",
    username: "Username",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    logout: "Logout",
    profile: "Profile",
    settings: "Preferences",
    saved: "Saved successfully!",
    empty: `Required fields are empty.`,
    passwordMsg:
      "To change your password, use the 'Forgot password' option on the login screen.",
  },

  preferences: {
    title: "Preferences",
    language: "Language",
    countrySettings: "Country settings",
    alerts: {
      emptyLanguge: "Need to select a language to save",
      emptyCountry: "Need to select a country to save",
    },
    unitOfMesureKG: "Kilogram",
    unitOfMesurePD: "Pounds",
    unitOfMesure: "Unit of measure for weight",
  },
  logs: {
    path: "Action Logs",
    name: "User Logs",
    new: "New ORIGIN",
    date: "Date",
    userName: "Username",
    message: "Message",
  },
};

export { translations };
