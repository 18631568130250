import React, { useContext } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import "./styles.scss";
import { PlantSelectedContext } from "../../contexts/PlantSelectedContext";
import { PathContext } from "../../contexts/pathContext";
import SelectBox from "devextreme-react/select-box";

export default function HeaderPathing({ toggleMenu }) {
  const {
    plantSelected,
    organizationSelected,
    roles,
    plants,
    handleChangePlant,
  } = useContext(PlantSelectedContext);

  const { path, setCurrentMenu, setMenu, statusMenu } = useContext(PathContext);

  function expandMenu(e, item) {
    if (!item.url) {
      setCurrentMenu(e.target.id);
      setMenu("open");
    } else {
      setMenu("close");
    }
  }

  return (
    <div className={roles[0] === "owner" ? "printing-content" : "no-print"}>
      <header className={"header-pathing-component"}>
        <Toolbar className={"header-toolbar-bottom"}>
          <Item location={"before"}>
            <div className="bcca-breadcrumb">
              <div
                className={
                  statusMenu === 1
                    ? "bcca-breadcrumb-btn-item-opened"
                    : "bcca-breadcrumb-btn-item"
                }
              >
                <Button
                  className="bcca-breadcrumb-btn"
                  icon="menu"
                  onClick={toggleMenu}
                />
              </div>
              {path.map((item, index) => (
                <div className="bcca-breadcrumb-item" key={index}>
                  <a
                    onClick={(e) => expandMenu(e, item)}
                    href={item?.url}
                    id={item?.pathMenu}
                  >
                    {item.text ? item.text : item}
                  </a>
                </div>
              ))}
            </div>
          </Item>

          <Item location={"after"}>
            <span
              id="plant-company-id"
              className={
                roles[0] === "owner"
                  ? "header-plant-selected plant-company-display-none"
                  : "header-plant-selected"
              }
            >
              <span
                id="company-name-span"
                className={
                  roles[0] === "owner"
                    ? "header-plant-selected plant-company-display-none"
                    : "header-plant-selected"
                }
              >
                {organizationSelected
                  ? organizationSelected
                  : localStorage.getItem("organizationData")}
                -
              </span>
              <span
                className={
                  roles[0] === "owner" ? "plant-company-display-none" : ""
                }
                id="plant-selected-span"
              >
                <SelectBox
                  dataSource={plants}
                  onValueChanged={handleChangePlant}
                  defaultValue={plantSelected ? plantSelected : ""}
                  displayExpr="description"
                  placeholder="Select your plant"
                  valueExpr="id"
                  width={150}
                  height={30}
                />
              </span>
            </span>
          </Item>
        </Toolbar>
      </header>
    </div>
  );
}
