import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { messages } from "./messages";

i18n.use(languageDetector).init({
  debug: false,
  defaultNS: ["translations"],
  fallbackLng: "en",
  ns: ["translations"],
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (format === "number") {
        return new Intl.NumberFormat(lng).format(value);
      }
    },
  },
  resources: messages,
});

export { i18n };
