import { ScrollView } from "devextreme-react";
import { useState } from "react";
import { i18n } from "../../../service/i18n/i18n";
import preferencesIcon from "../../../img/screenshots/user_preferences_icon.png";
import SaveIcon from "../../../img/screenshots/save_button.png";
import CancelIcon from "../../../img/screenshots/cancel_button.png";

function TabSettings() {
  let language = localStorage.getItem("i18nextLng");

  const [userPreferencesEN, setUserPreferencesEN] = useState(null);
  const [userPreferencesPT, setUserPreferencesPT] = useState(null);
  const [userPreferencesES, setUserPreferencesES] = useState(null);

  import("../../../img/screenshots/en/user_preferences.png").then(
    (minhaImagem) => {
      setUserPreferencesEN(minhaImagem.default);
    }
  );
  import("../../../img/screenshots/pt/user_preferences.png").then(
    (minhaImagem) => {
      setUserPreferencesPT(minhaImagem.default);
    }
  );
  import("../../../img/screenshots/es/user_preferences.png").then(
    (minhaImagem) => {
      setUserPreferencesES(minhaImagem.default);
    }
  );

  return (
    <>
      <ScrollView height={500}>
        <div className="help-modal-content-container">
          <p>
            {i18n.t("userGuiding.introduction.tabUserPreferences.description")}
          </p>

          <p className="help-step">
            {i18n.t("userGuiding.introduction.tabUserPreferences.step1")}
            <img className="help-icons-screenshot" src={preferencesIcon}></img>
            {i18n.t("userGuiding.introduction.tabUserPreferences.step1_1")}
          </p>
          <div className="help-screenshot-container">
            <img
              className="help-screenshot-min"
              itemProp="logo"
              src={
                language.includes("en")
                  ? userPreferencesEN
                  : language.includes("pt")
                  ? userPreferencesPT
                  : language.includes("es")
                  ? userPreferencesES
                  : userPreferencesEN
              }
            ></img>
          </div>
          <p>{i18n.t("userGuiding.introduction.tabUserPreferences.step2")}</p>

          <dl className="help-fields-list">
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserPreferences.field1")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserPreferences.fieldDescription1"
                )}
              </span>
            </li>
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserPreferences.field2")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserPreferences.fieldDescription2"
                )}
              </span>
            </li>
            <li>
              <span className="help-field-name">
                {i18n.t("userGuiding.introduction.tabUserPreferences.field3")}
              </span>
              <span>
                {i18n.t(
                  "userGuiding.introduction.tabUserPreferences.fieldDescription3"
                )}
              </span>
            </li>
          </dl>

          <div style={{ marginTop: "40px" }} className="flex">
            <img className="help-save-screenshot" src={SaveIcon}></img>
            {i18n.t("userGuiding.common.save")}
          </div>
          <div className="flex">
            <img className="help-save-screenshot" src={CancelIcon}></img>
            {i18n.t("userGuiding.common.cancel")}
          </div>
        </div>
      </ScrollView>
    </>
  );
}

export default TabSettings;
