import React, { useMemo, useState } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import UserService from "../../service/keycloak/UserService";
import axios from "../../service/api/axios";
import TextBox from "devextreme-react/text-box";
import Configuration from "../../img/Icons/configuration.svg";
import Preferences from "../../img/Icons/preferences.svg";
import { Popup } from "devextreme-react/popup";
import Alert from "../../components/alerts/ActionsAlert";
import { i18n } from "../../service/i18n/i18n";
import SelectBox from "devextreme-react/select-box";
import RadioGroup from "devextreme-react/radio-group";
import Licenses from "./licenses";
import Consult from "../../img/Icons/consult.svg";

export default function UserPanel({ menuMode }) {
  const [unitOfMesure] = useState([
    { text: i18n.t("preferences.unitOfMesureKG"), value: "Kg" },
    { text: i18n.t("preferences.unitOfMesurePD"), value: "Lb" },
  ]);

  const [userName] = useState(UserService.getUsername);
  const [roles] = useState(UserService._kc.realmAccess.roles);
  const locales = require("locale-codes");
  const [allLocales] = useState(locales.all);
  const [local, setLocale] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedLocale, setSelectedLocale] = useState("");
  const [userData, setUserData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: localStorage.getItem("Language"),
  });
  const [languages] = useState([
    { value: "English", abbreviation: "en" },
    { value: "Español", abbreviation: "es" },
    { value: "Português", abbreviation: "pt" },
  ]);

  const [licenseModal, setLicenseModal] = useState({
    visible: false,
    onHiding: hidingLicenseModal,
    title: "",
    type: "",
  });

  const [modal, setModal] = useState({
    visible: false,
    onHiding: hidingModal,
    title: "",
    type: "",
  });

  const [alert, setAlert] = useState({
    visible: false,
    onHiding: hidingAlert,
    message: "",
    type: "success",
  });

  const [modalPreferences, setModalPreferences] = useState({
    visible: false,
    onHiding: hidingModalPreferences,
    title: "",
    type: "",
  });

  function handleUsername(e) {
    setUserData({ ...userData, username: e });
  }

  function handleFirstName(e) {
    setUserData({ ...userData, firstName: e });
  }

  function handleLastName(e) {
    setUserData({ ...userData, lastName: e });
  }

  function handleEmail(e) {
    setUserData({ ...userData, email: e });
  }

  function hidingModal() {
    setModal({
      ...modal,
      visible: false,
    });
    const fieldLabel = document.getElementsByClassName("required-label");
    try {
      fieldLabel[0].style.color = "#000000DE";
      fieldLabel[1].style.color = "#000000DE";
      fieldLabel[2].style.color = "#000000DE";
      fieldLabel[3].style.color = "#000000DE";
    } catch (error) {}
  }

  function hidingModalPreferences() {
    setModalPreferences({
      ...modalPreferences,
      visible: false,
    });
  }

  function showModal(type) {
    setModal({
      ...modal,
      visible: true,
      type,
    });
  }

  function showModalPreferences(type) {
    setModalPreferences({
      ...modalPreferences,
      visible: true,
      type,
    });
  }

  function hidingAlert() {
    setAlert({
      ...alert,
      visible: false,
    });
  }

  function showAlert(message, type) {
    setAlert({
      ...alert,
      visible: true,
      message,
      type,
    });
  }

  async function showModalUserSettings() {
    await axios
      .get(`users?exact=true&username=${UserService.getUsername()}`)
      .then(({ data }) => {
        setUserData(data[0]);
        showModal();
      })
      .catch(({ response }) => {
        showAlert(` ${response.data.message}`, "error");
      });
  }

  async function handleSubmit() {
    if (
      userData.username === "" ||
      userData.email === "" ||
      userData.firstName === "" ||
      userData.lastName === ""
    ) {
      const fieldLabel = document.getElementsByClassName("required-label");
      fieldLabel[0].style.color = "red";
      fieldLabel[1].style.color = "red";
      fieldLabel[2].style.color = "red";
      fieldLabel[3].style.color = "red";
      showAlert(i18n.t("user.empty"), "error");
      return;
    }
    let obj = {
      username: userData.username,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
    };

    await axios
      .put(`users/${userData.id}`, obj)
      .then(async () => {
        showAlert(i18n.t("user.settings"), "success");
        hidingModal();
      })
      .catch(({ response }) => {
        showAlert(` ${response.data.message}`, "error");
      });
  }

  function navigateToLogin() {
    window.history.replaceState(null, null, "/");
    UserService.doLogout();
  }

  const menuItems = useMemo(
    () => [
      {
        text: i18n.t("user.profile"),
        icon: Configuration,
        onClick: showModalUserSettings,
        disabled: roles[0] === "owner" ? true : false,
      },
      {
        text: i18n.t("user.settings"),
        icon: Preferences,
        onClick: showModalPreferences,
        disabled: roles[0] === "owner" ? true : false,
      },

      {
        text: i18n.t("license.title"),
        icon: Consult,
        onClick: showLicenseModal,
      },
      {
        text: i18n.t("user.logout"),
        icon: "runner",
        onClick: navigateToLogin,
      },
    ],
    []
  );

  function filterItems(arr, query) {
    return arr.filter((el) =>
      el.tag.toLowerCase().includes(query.toLowerCase())
    );
  }
  function onLanguageChanged(e) {
    setSelectedLanguage(e.selectedItem);

    let array = filterItems(
      allLocales,
      String(e.selectedItem?.abbreviation + "-")
    );
    const filterLocales = array.map((item) => {
      if (item.location != null && item.local !== null) {
        return {
          name: item.local,
          location: item.location,
          tag: item.tag,
          value: item.local + " (" + item.location + ")",
        };
      }
    });

    let finalArray = filterLocales.filter(function (i) {
      return i;
    });
    setLocale(finalArray);
  }

  function onLocaleChanged(e) {
    setSelectedLocale(e.selectedItem.tag);
  }

  async function savePreferences() {
    await axios
      .post(`/users/${userName}/preferences`, {
        language: selectedLanguage.value
          ? selectedLanguage.value
          : localStorage.getItem("Language"),
        countrySettings: selectedLocale
          ? selectedLocale
          : localStorage.getItem("i18nextLng"),
        weightMeasurementUnit: selectedUnit
          ? selectedUnit
          : localStorage.getItem("weightMeasurementUnit"),
      })
      .then(async () => {
        getPreferences();
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  function handleSubmitPreferences() {
    hidingModalPreferences();
    savePreferences();
    setSelectedLanguage({});
    setSelectedLocale("");
    setSelectedUnit("");
  }

  async function getPreferences() {
    await axios
      .get(`/users/${userName}/preferences`)
      .then(({ data }) => {
        if (
          localStorage.getItem("i18nextLng") != data.countrySettings ||
          (localStorage.getItem("weightMeasurementUnit") !=
            data.weightMeasurementUnit &&
            data.countrySettings &&
            data.weightMeasurementUnit)
        ) {
          localStorage.setItem("i18nextLng", data.countrySettings);
          localStorage.setItem("Language", data.language);
          localStorage.setItem(
            "weightMeasurementUnit",
            data.weightMeasurementUnit
          );
          window.location.reload();
        }
      })
      .catch(({}) => {});
  }

  function onChangeUnitOfMesure(e) {
    setSelectedUnit(e.value);
  }

  function showLicenseModal() {
    setLicenseModal({ ...licenseModal, visible: true });
  }

  function hidingLicenseModal() {
    setLicenseModal({
      ...licenseModal,
      visible: false,
    });
  }

  return (
    <div className={"user-panel"}>
      <Popup
        visible={licenseModal.visible}
        onHiding={licenseModal.onHiding}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={i18n.t("license.title")}
        container=".dx-viewport"
        width={900}
        height={650}
      >
        <Licenses></Licenses>
        <div className="modal-confimr-buttons-content">
          <div className="btn-modal-remove" onClick={hidingLicenseModal}>
            <i className="icon dx-icon-remove"></i>
          </div>
        </div>
      </Popup>
      <Popup
        visible={modal.visible}
        onHiding={modal.onHiding}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={i18n.t("user.title")}
        container=".dx-viewport"
        width={550}
        height={310}
      >
        <div className={"modal-data-content"}>
          <div className="text-fields-container">
            <div className="text-fields-container-item-small">
              <div className="flex">
                <div className="labels required-label">
                  {i18n.t("register.user.modal.username")} *
                </div>
              </div>
              <div>
                <TextBox
                  id="user-username-id"
                  maxLength={20}
                  value={userData?.username}
                  disabled={true}
                  onValueChange={handleUsername}
                  hint={i18n.t("register.user.modal.usernameHint")}
                ></TextBox>
              </div>
            </div>
            <div className="text-fields-container-item-34">
              <div className="flex">
                <div className="labels required-label">
                  {i18n.t("register.user.modal.email")} *
                </div>
              </div>
              <div>
                <TextBox
                  id="user-email-id"
                  name="email"
                  value={userData?.email}
                  onValueChange={handleEmail}
                  hint={i18n.t("register.user.modal.emailHint")}
                ></TextBox>
              </div>
            </div>
          </div>

          <div className="text-fields-container">
            <div className="text-fields-container-item-small">
              <div className="flex">
                <div className="labels required-label">
                  {i18n.t("register.user.modal.firstName")} *
                </div>
              </div>
              <div>
                <TextBox
                  id="user-firstName-id"
                  name="firstName"
                  value={userData?.firstName}
                  onValueChange={handleFirstName}
                  hint={i18n.t("register.user.modal.firstNameHint")}
                ></TextBox>
              </div>
            </div>
            <div className="text-fields-container-item-34">
              <div className="flex">
                <div className="labels required-label">
                  {i18n.t("register.user.modal.lastName")} *
                </div>
              </div>
              <div>
                <TextBox
                  id="user-lastName-id"
                  name="lastName"
                  value={userData?.lastName}
                  onValueChange={handleLastName}
                  hint={i18n.t("register.user.modal.lastNameHint")}
                ></TextBox>
              </div>
            </div>
          </div>
          <div className="notes-left-align">{i18n.t("user.passwordMsg")}</div>
        </div>
        <div className="modal-confimr-buttons-content">
          <div className="btn-modal-remove" onClick={hidingModal}>
            <i className="icon dx-icon-remove"></i>
          </div>
          <div className="btn-modal-check" onClick={handleSubmit}>
            <i className="icon dx-icon-check"></i>
          </div>
        </div>
      </Popup>

      <Popup
        visible={modalPreferences.visible}
        onHiding={modalPreferences.onHiding}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={i18n.t("preferences.title")}
        width={550}
        height={350}
      >
        <div className={"modal-data-content"}>
          <div className="text-fields-container">
            <div className="text-fields-container-item-small">
              <div className="labels">{i18n.t("preferences.language")}</div>
              <div>
                <SelectBox
                  dataSource={languages}
                  displayExpr="value"
                  valueExpr="value"
                  onSelectionChanged={onLanguageChanged}
                  defaultValue={localStorage.getItem("Language")}
                ></SelectBox>
              </div>
            </div>
          </div>
          <div className="text-fields-container">
            <div className="text-fields-container-item-half">
              <div className="labels">
                {i18n.t("preferences.countrySettings")}
              </div>
              <div>
                <SelectBox
                  dataSource={local}
                  displayExpr="value"
                  valueExpr="tag"
                  onSelectionChanged={onLocaleChanged}
                  defaultValue={localStorage.getItem("i18nextLng")}
                ></SelectBox>
              </div>
            </div>
          </div>
          <div className="text-fields-container">
            <div className="text-fields-container-item-half">
              <div className="labels">{i18n.t("preferences.unitOfMesure")}</div>
              <div>
                <RadioGroup
                  items={unitOfMesure}
                  displayExpr="text"
                  valueExpr="value"
                  layout="horizontal"
                  onValueChanged={onChangeUnitOfMesure}
                  value={
                    selectedUnit
                      ? selectedUnit
                      : localStorage.getItem("weightMeasurementUnit")
                  }
                ></RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-confimr-buttons-content">
          <div className="btn-modal-remove" onClick={hidingModalPreferences}>
            <i className="icon dx-icon-remove"></i>
          </div>
          <div className="btn-modal-check" onClick={handleSubmitPreferences}>
            <i className="icon dx-icon-check"></i>
          </div>
        </div>
      </Popup>
      <Alert
        visible={alert.visible}
        onHiding={alert.onHiding}
        message={alert.message}
        type={alert.type}
      />

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={100}
          cssClass={"user-menu"}
        >
          <Position my={"top right"} at={"bottom right"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
