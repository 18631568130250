import Drawer from "devextreme-react/drawer";
import React, { useState, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Header, SideNavigationMenu, Footer } from "../../components";
import { useScreenSize } from "../../utils/media-query";
import { Template } from "devextreme-react/core/template";
import { useMenuPatch } from "../../utils/patches";
import HeaderPathing from "../../components/headerPath";
import { PathContext } from "../../contexts/pathContext";

export default function SideNavOuterToolbar({ title, children }) {
  const history = useHistory();
  const { isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const { setCurrentMenu, menu, setMenu, setStatusMenu } =
    useContext(PathContext);

  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Opened
  );

  useEffect(() => {
    setMenuStatus(menu === "open" ? MenuStatus.Closed : MenuStatus.Opened);
  }, [menu]);

  useEffect(() => {
    if (menuStatus === 2) {
      setMenu("closed");
      setCurrentMenu("");
    }
    setStatusMenu(menuStatus);
  }, [menuStatus]);

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Opened
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Opened ? MenuStatus.Opened : prevMenuStatus
    );
  }, [isLarge]);

  const onNavigationChanged = useCallback(
    ({ itemData: { path, key }, event, node }) => {
      if (menuStatus === MenuStatus.Opened || key || node.selected) {
        event.preventDefault();
        return;
      }

      setMenuStatus(MenuStatus.Opened);

      window.sessionStorage.removeItem("currentPage");
      history.push(path);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Opened);
        event.stopPropagation();
      }
    },
    [history, menuStatus, isLarge]
  );

  return (
    <div id="container">
      <div id="header">
        <Header
          className={"layout-header"}
          menuToggleEnabled
          toggleMenu={toggleMenu}
          title={title}
        />

        <HeaderPathing
          menuToggleEnabled
          toggleMenu={toggleMenu}
          title={title}
        />
      </div>
      <div id={"content"}>
        <Drawer
          className={["drawer", patchCssClass].join(" ")}
          position={"before"}
          closeOnOutsideClick={onOutsideClick}
          openedStateMode={"shrink"}
          revealMode={"expand"}
          minSize={0}
          maxSize={250}
          shading={true}
          opened={menuStatus === MenuStatus.Closed ? true : false}
          template={"menu"}
        >
          <div>
            <div>
              {React.Children.map(children, (item) => {
                return item.type !== Footer && item;
              })}
            </div>
            <div>
              {React.Children.map(children, (item) => {
                return item.type === Footer && item;
              })}
            </div>
          </div>
          <Template name={"menu"}>
            <SideNavigationMenu
              compactMode={menuStatus === MenuStatus.Opened}
              selectedItemChanged={onNavigationChanged}
              openMenu={temporaryOpenMenu}
              onMenuReady={onMenuReady}
            ></SideNavigationMenu>
          </Template>
        </Drawer>
      </div>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};
