import React from "react";
import { Toast } from "devextreme-react/toast";
import { useHistory } from "react-router-dom";

export default function Alert({ visible, onHiding, message, type }) {
  const history = useHistory();

  if (message === 500) {
    history.push("/500");
  }
  return (
    <Toast
      visible={visible}
      message={message}
      type={type}
      onHiding={onHiding}
      displayTime={type === "error" ? 8000 : 2000}
    />
  );
}
