import { useState } from "react";
import { ScrollView } from "devextreme-react";
import { i18n } from "../../../service/i18n/i18n";

function TabOverview() {
  let language = localStorage.getItem("i18nextLng");
  const [homeEN, setHomeEN] = useState(null);
  const [homePT, setHomePT] = useState(null);
  const [homeES, setHomeES] = useState(null);

  import("../../../img/screenshots/en/home.png").then((image) => {
    setHomeEN(image.default);
  });
  import("../../../img/screenshots/pt/home.png").then((image) => {
    setHomePT(image.default);
  });
  import("../../../img/screenshots/es/home.png").then((image) => {
    setHomeES(image.default);
  });

  return (
    <>
      <ScrollView height={500}>
        <div className="help-modal-content-container">
          <p>{i18n.t("userGuiding.introduction.tabOverview.description")}</p>
          <p>{i18n.t("userGuiding.introduction.tabOverview.description2")}</p>
          <p>{i18n.t("userGuiding.introduction.tabOverview.description3")}</p>
          <p>{i18n.t("userGuiding.introduction.tabOverview.description4")}</p>
          <div className="help-screenshot-container-center">
            <img
              className="help-screenshot-large"
              itemProp="logo"
              src={
                language.includes("en")
                  ? homeEN
                  : language.includes("pt")
                  ? homePT
                  : language.includes("es")
                  ? homeES
                  : homeEN
              }
            ></img>
          </div>
        </div>
      </ScrollView>
    </>
  );
}

export default TabOverview;
