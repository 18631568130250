const translations = {
  sidebar: {
    menu: {
      registers: "Registros",
      inventory: "Inventario",
      laydown: "Mezcla",
      reports: "Informes",
      warning: "Adventencias",
    },
    register: {
      parameters: "Parámetros de calidad",
      prodline: "Líneas de Producción",
      producer: "Productores",
      supplier: "Proveedores",
      cottontype: "Tipos de algodón",
      pile: "Pilas",
      origin: "Origens",
      plant: "Plantas",
      hvispreadsheet: "Diseño del HVI del Proveedor",
      hvidatlayout: "Diseño del Archivo Dat",
      prodlineclasses: "Clases de Línea de Producción",
      mixtype: "Tipos de mezcla",
      hvi: "Diseño de archivos HVI",
      user: "Usuarios",
    },
    inventory: {
      cottonInput: "Entrada de Algodón",
      management: "Gestión",
      bales: "Pacas",
      lots: "Lotes",
      cottonDistribution: "Distribución de algodón",
      cottonUsage: "Control de uso",
      pileGroup: "Grupos de pilas",
      cottonTypes: "Tipos de algodón",
      testingsResults: "Entrada de resultados HVI",
      importantion: "Importación de resultados",
      manualInputs: "Entrada manual",
      consultBale: "Consultar paca",
    },
    mixing: {
      planning: "Planificación",
      pre: "Premezcla",
      laydown: "Mezcla",
      control: "Control de Consumo",
    },
    reports: {
      inventory: "Inventario",
      bales: "Pacas",
      general: "General ",
      generalConsumption: "General",
      composition: "Composición",
      lotsPerPeriod: "Entradas por período",
      quality: "Calidad",
      consumpition: "Consumo",
      perDay: "Por día",
      perMonth: "Por mes",
      perLaydown: "Por mezclas",
      perPeriod: "Composición por periodo",
      avg: "Gráficos promedio de mezclas",
      usda: "Gráficos del USDA",
    },
  },
  E500page: {
    msg1: "Perdón, algo salió mal.",
    msg2: "Por favor, inténtelo de nuevo. Si el error persiste, póngase en contacto con el servicio de asistencia técnica.",
    btnHome: "Ir a la página de inicio",
  },
  others: {
    tableAddBtn: "Nuevo",
    tableColumnActions: "Acción",
    deleteBtn: "Eliminar",
    dailyUnit: "Días",
    groupContinuedMessage: "Continúa en la última página",
    groupContinuesMessage: "Continuar en la página siguiente",
    selectFile: "seleccione un archivo",
    noFileSelected: "Ningún archivo seleccionado",
  },
  alerts: {
    confirmDeletion: "¿Realmente desea eliminar este item?",
  },

  license: {
    title: "Licencias",
    terms:
      "Este producto contiene software con licencia de código abierto. Para reconocimientos y textos de licencia, consulte a continuación. Maschinenfabrik Rieter AG (en lo sucesivo, 'Rieter') se ofrece a entregar, previa solicitud, una copia del código fuente correspondiente completo para los paquetes de software de código abierto protegidos por derechos de autor utilizados en este producto para los cuales dicha entrega es solicitada por las respectivas licencias. Esta oferta es válida hasta tres años después de la compra o actualización del software para cualquier persona que reciba esta información. Para obtener el código fuente, escriba en inglés a “Maschinenfabrik Rieter AG, Attn: ESSENTIAL Open Source Team, Klosterstrasse 20, 8406 Winterthur, Suiza”",
    licenses: "licencias declaradas",
  },

  register: {
    plant: {
      name: "Planta",
      title: "Registro de planta",
      id: "ID",
      desc: "Descripción",
      btnNew: "Nueva planta",
      hintEdit: "Editar planta",
      delete: {
        title: "Eliminar esta planta",
        text: "¿De verdad quieres eliminar esta planta?",
      },
      settings: {
        title: "Configuración de la planta",
        dateFormat: "Formato de fecha",
        dateFormatHint: "Seleccione su formato de fecha preferido",
        minBale: "Peso mínimo de paca",
        minBaleHint: "Introduzca el peso mínimo de paca",
        maxBale: "Peso máximo de paca",
        maxBaleHint: "Introduzca el peso máximo de paca",
        printer: "Ruta de la impresora de etiquetas",
        printerHint: "Introduzca la ruta de la impresora térmica",
        asian: "Prueba HVI parcial de pacas",
        asianHint: "Asegúrese de que el invetario no esté 100% probado",
        leftover: "Tenga en cuenta la calidad de las sobras",
        leftoverHint: "Comprobar para considerar la calidad restante",
      },
      modal: {
        id: "ID",
        idHint: "Identificación de la planta",
        desc: "Descripción",
        descHint: "Descripción de la planta",
        update: "Actualizar la planta",
        new: "Nueva planta",
      },
      alert: {
        empty: "Los campos obligatorios están vacíos",
        saved: "Guardado exitosamente!",
        deleted: "Eliminado con éxito!",
      },
    },
    parameters: {
      name: "Parámetros de calidad",
      title: "Parámetros de calidad registrados",
      btnNew: "Nuevo parámetro",
      btnChange: "Cambiar el orden de visualización",
      byManually: "Manualmente",
      preConfigured: "Preconfigurado",
      editParameter: "Editar parámetro",
      deleteParameter: "Eliminar parámetro",
      desc: "Nombre",
      shortName: "Abreviatura",
      viewingOrder: "Orden de visualización",
      minimum: "Mínimo",
      miximum: "Máximo",
      cv: "Control CV",
      amplitude: "Amplitud",
      graphicScale: "Escala gráfica",
      cspMultiplier: "Multiplicador de CSP",
      sciMultiplier: "Multiplicador SCI",
      setAvgWhenZero: "Establecer promedio cuando cero",
      manualInput: "Entrada manual",
      calculated: "Calculado",
      sci: "SCI",
      csp: "CSP",
      delete: {
        title: "Eliminar este parámetro",
        text: "¿Realmente desea eliminar este parámetro?",
      },
      modal: {
        newParameter: "Añadir nuevo parámetro",
        updateParameter: "Actualización de parámetros",
        addConfigured: "Agregar parámetro preconfigurado",
        changeParameter: "Cambiar el orden de visualización de los parámetros",
        manually: {
          fullName: "Nombre completo",
          fullNameHint: "Nombre del parámetro",
          shortName: "Abreviatura",
          shortNameHint: "Un nombre corto para mostrar en informes y tablas.",
          minValue: "Valor mínimo",
          minValueHint: "El valor mínimo aceptado para el parámetro.",
          maxValue: "Valor mínimo",
          maxValueHint: "El valor máximo aceptado para el parámetro.",
          amplitude: "Amplitud",
          amplitudeHint:
            "La diferencia entre el valor mínimo más común y el valor máximo más común para el parámetro",
          chartScale: "Escala de gráfico",
          chartScaleHint:
            "La escala utilizada para presentar el parámetro en los gráficos de calidad.",
          csp: "Multiplicador de CSP",
          cspHint:
            "Factor de multiplicación de parámetros en el cálculo del CSP",
          sci: "Multiplicador SCI",
          sciHint:
            "Factor de multiplicación de parámetros en el cálculo del valor SCI",
          avg: "Establecer promedio cuando cero",
          avgHint:
            "Indica si el sistema debe asignar la calidad media para pacas que no tienen valor para este parámetro en la clasificación",
          manual: "Entrada manual",
          manualHint:
            "Indica si los valores de los parámetros son ingresados ​​manualmente por el usuario",
          cv: "CV controlado",
          cvHint:
            "Indica si el sistema debe controlar el coeficiente de variación  del parámetro",
          calc: "Calculado",
          calcHint:
            "Indica si el valor del parámetro se calcula automáticamente. Debe ser verdadero solo si el parámetro es SCI o CSP y el sistema debe calcular el valor del parámetro automáticamente en función del multiplicador SCI/CSP de otros parámetros.",
        },
        parameterConfigured: {
          text: "Haga clic en '+' para agregar el parámetro.",
        },
        viewingOrder: {
          text: "Haga clic en el parámetro y arrástrelo a la posición deseada.",
          displayOrder: "Orden de visualización",
          parameter: "Parámetro",
        },
      },
      alert: {
        required: "Los campos obligatorios están vacíos.",
        saved: "¡Guardado con éxito!",
        deleted: "¡Eliminado con éxito!",
      },
    },
    mixtype: {
      name: "Tipos de mezcla",
      title: "Tipos de mezcla registrados",
      newBtn: "Nuevo tipo de mezcla",
      desc: "Descripción",
      classCode: "Código de clase",
      minDays: "Inventario mínimo ",
      consumpition: "Consumo Mensual ",
      required: "Cantidad de lote requerida",
      classification: "Prioridad de clasificación",
      btnEdit: "Editar tipo de mezcla",
      btnDel: "Eliminar tipo de mezcla",
      btnQuanlityRange: "Ajuste del rango de calidad",
      delete: {
        title: "Eliminar este tipo de mezcla",
        text: "¿Realmente desea eliminar este tipo de mezcla?",
      },
      modal: {
        desc: "Descripción",
        descHint: "Nombre del tipo de mezcla",
        classCode: "Código de clase",
        classCodeHint: "Código de clases asociadas al tipo de mezcla",
        required: "Cantidad requerida de lotes",
        requiredHint: "Número mínimo de lotes para poder hacer laydowns",
        classification: "Clasificación Prioritaria",
        classificationHint:
          "Orden de prioridad en la distribución de pacas en caso de existencias compartidas por varios tipos de mezcla",
        minimum: "Inventario mínimo",
        minimumHint:
          "Inventario mínimo (en días) requerido para el tipo de mezcla",
        days: "Días",
        monthly: "Consumo mensual ",
        monthlyHint: "Consumo mensual aproximado en kilogramos",
      },
      qualityRangeModal: {
        title: "Rango de calidad",
        btnNew: "Nuevo rango de calidad",
        btnEdit: "Editar rango",
        btnDelete: "Borrar rango",
        parameter: "Parámetro",
        minimum: "Mínimo",
        maximum: "Máximo",
        classificationType: "Tipo de clasificación",
        mixTypeName: "Tipo de mezcla",
        newQualityRangeModal: {
          title: "Registro de rango de calidad",
          minimum: "Valor mínimo",
          maximum: "Valor máximo",
          parameter: "Parámetro",
          parameterHint: "Seleccione el parámetro",
          changeDate: "Cambiar fecha",
          changeReason: "Motivo del cambio",
          rangeChangeHistory: "Historial de cambios",
          noDataHistory: "No se hicieron cambios",
          classificationType: "Tipo de clasificación:",
          classificationByLot: "Promedio del lote",
          classificationByBale: "Paca por paca",
        },
      },
      alert: {
        empty: "Los campos obligatorios están vacíos.",
        saved: "¡Guardado con éxito!",
        deleted: "¡Eliminado con éxito!",
      },
      new: "Nuevo tipo de mezcla",
      update: "Actualización del tipo de mezcla",
    },
    prodline: {
      name: "Líneas de producción",
      title: "Líneas de producción registradas",
      newBtn: "Nueva línea de producción",
      editBtn: "Editar línea de producción",
      delBtn: "Eliminar línea de producción",
      wasteBtn: "Posiciones de residuos",
      desc: "Descripción",
      mixtype: "Tipo de mezcla",
      preLaydown: "Cant. de fardos en premezcla",
      balesLaydown: "Cant. de fardos por mezcla",
      preLaydownCode: "Código de Premezcla",
      perDay: "Mezclas por día",
      lot: "Lote de producción",
      balesSubmix: "Cant. de pacas submezcla",
      control: "Control de Producción ",
      active: "Activo",
      premixCheck: "¿Hay premezcla?",
      delete: {
        title: "Eliminar esta línea de producción",
        text: "¿Realmente desea eliminar esta línea de producción?",
      },
      modal: {
        active: "Activo",
        activeHint: "Indica si la línea de producción está activa o no",
        preLaydown: "Utiliza premezcla",
        preLaydownHint: "Indica si la línea de producción usa premezcla o no",
        mixtype: "Tipo de mezcla",
        mixtypeHint: "El tipo de mezcla asignado a la línea de producción.",
        desc: "Descripción",
        descHint: "Nombre o descripción de la línea de producción",
        qtyPerLaydown: "Cant. paca por mezcla",
        qtyPerLaydownHint: "Cantidad de pacas utilizados en cada mezcla",
        qtyPreLaydown: "Cant. paca en premezcla",
        qtyPreLaydownHint: "Cantidad de pacas requeridas para premezcla",
        qtySubLaydown: "Cant. paca por sub-mezcla",
        qtySubLaydownHint: "Cantidad de pacas en cada submezcla",
        daylyConsumption: "Consumo diario",
        daylyConsumptionHint:
          "Consumo diario (en mezcla) para la línea de producción",
        laydowns: "mezclas",
        prodControl: "Control de producción",
        prodControlHint:
          "Número de días de producción a controlar por el sistema",
        days: "Días",
        preLaydownCode: "Código de premezcla",
        preLaydownCodeHint:
          "Cualquier valor que diferencie el Pre-Laydown de otros Pre-Laydowns",
        productionLot: "Código de lote de producción",
        productionLotHint:
          "Cualquier valor que identifique el lote de producción de otro de otros lotes de producción",
      },
      newWaste: {
        title: "Nueva posición",
        position: "Posición:",
        newBtn: "Nueva posición",
      },
      settings: {
        text: "Cambiar valores directamente dentro de las celdas de la tabla",
        parameter: "Parámetro",
        priority: "Prioridad",
        prodline: "Tolerancia de la línea de producción",
        yarn: "Tolerancia del lote de hilo",
        cvTolerance: "CV Tolerancia",
        maxCv: "% CV máx.",
        generateLower: "Generar alerta inferior",
        generateHigher: "Generar alerta superior",
        minAllowed: "Mínimo permitido",
        maxAllowed: "Máximo permitido",
      },
      wastePosition: {
        title: "Posiciones de residuos",
        registred: "Posiciones de residuos registrados",
        noData: "No hay puestos de desecho registrados",
      },
      alert: {
        deleted: "¡Eliminado con éxito!",
        saved: "¡Guardado con éxito!",
      },
      titleWaste: "Posiciones de residuos",
      newProd: "Nueva línea de producción",
      updateProd: "Actualización de la línea de producción",
      prodSettings: "Ajustes de calidad de la línea de producción",
    },
    prodlinecode: {
      name: "Clase de las líneas de producción",
      title: "Clase de líneas de producción registradas",
      newBtn: "Nueva clase de línea de producción",
      classCode: "Clase",
      prodLine: "Línea de producción",
      delBtn: "Eliminar clase",
      delete: {
        title: "Eliminar esta clase de línea de producción",
        text: "¿Realmente desea eliminar estas clases de línea de producción?",
      },
      modal: {
        prodline: "Línea de producción",
        prodlineHint: "Seleccionar línea de producción",
        classCode: "Clase",
        classCodeHint: "La clase para la línea de producción.",
      },
      empty: "Los campos obligatorios están vacíos.",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
      newProd: "Nueva clase de línea de producción",
    },
    producer: {
      name: "Productores",
      title: "Productores registrados",
      newBtn: "Nuevo productor",
      desc: "Descripción",
      editBtn: "Editar productor",
      delBtn: "Eliminar productor",
      delete: {
        title: "Eliminar este productor",
        text: "¿Realmente quieres eliminar a este productor?",
      },
      modal: {
        name: "Nombre o descripción",
        nameHint: "Nombre del productor",
      },
      empty: "Los campos obligatorios están vacíos.",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
      newProducer: "Nuevo productor",
      updateProducer: "Actualización del productor",
    },
    supplier: {
      name: "Proveedores",
      title: "Proveedores registrados",
      newBtn: "Nuevo proveedor",
      desc: "Descripción",
      editBtn: "Editar proveedor",
      delBtn: "Eliminar proveedor",
      delete: {
        title: "Eliminar este proveedor",
        text: "¿Realmente desea eliminar este proveedor?",
      },
      modal: {
        name: "Nombre o descripción",
        nameHint: "Descripción del proveedor",
      },
      empty: "Los campos obligatorios están vacíos.",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
      new: "Nuevo proveedor",
      update: "Actualización de proveedores",
    },
    cottonType: {
      name: "Tipos de algodón",
      title: "Tipos de algodón registrados",
      newBtn: "Nuevo tipo de algodón",
      desc: "Descripción",
      editBtn: "Editar tipo de algodón",
      delBtn: "Eliminar tipo de algodón",
      delete: {
        title: "Eliminar este tipo de algodón",
        text: "¿Realmente desea eliminar este tipo de algodón?",
      },
      modal: {
        desc: "Nombre o descripción",
        descHint: "Descripción del tipo de algodón",
      },
      empty: "Los campos obligatorios están vacíos.",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
      new: "Nuevo tipo de algodón",
      update: "Actualización del tipo de algodón",
    },
    pile: {
      name: "Pila",
      title: "Pilas registrados",
      newBtn: "Nueva(s) pila(s)",
      delBtn: "Eliminar",
      delete: {
        text: "¿Realmente desea eliminar este item?",
        yes: "Sí",
        no: "No",
        title: "Eliminar la(s) pila(s)",
        really: "¿Realmente desea eliminar la(s) pila(s) seleccionada(s)?",
      },
      modal: {
        id: "ID de la pila",
        idHint: "ID de pila, ej.: A-01",
        generate: "Generar secuencia",
        quantity: "Cantidad",
        quantityHint: "Indica cuantas pilas quieres generar",
      },
      saved: "¡Guardado con éxito!",
      select: "Seleccionar celdas para eliminarlos",
      new: "Nueva pila",
    },
    origin: {
      name: "Orígenes",
      title: "Orígenes registrados",
      newBtn: "Nuevo origen",
      desc: "Descripción",
      actions: "",
      editBtn: "Editar origen",
      delBtn: "Eliminar origen",
      delete: {
        title: "Eliminar este origen",
        text: "¿Realmente desea eliminar este origen?",
      },
      modal: {
        desc: "Nombre o descripción",
        descHint: "Descripción del origen",
      },
      empty: "Los campos obligatorios están vacíos.",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
      new: "Nuevo origen",
      update: "Actualización de origen",
    },
    hvi: {
      name: "Diseños de entrada HVI",
      title: "Diseños registrados",
      newBtn: "Nuevo diseño de HVI",
      desc: "Descripción",
      columns: "Configuración de columnas",
      edit: "Editar diseño",
      del: "Eliminar diseño",
      delete: {
        title: "Eliminar este HVI",
        text: "¿Realmente desea eliminar este HVI?",
      },
      columnsModal: {
        text: "Cambiar valores directamente dentro de las celdas de la tabla",
        parameter: "Parámetro",
        position: "Posición de la columna",
        multiplier: "Multiplicador",
      },
      modal: {
        name: "Nombre del diseño",
        nameHint: "Nombre del diseño",
      },
      saved: "¡Guardado con éxito!",
      empty: "Los campos obligatorios están vacíos.",
      deleted: "¡Eliminado con éxito!",
      new: "Nuevo diseño HVI",
      update: "Actualización de diseño HVI",
      layoutSettings: "Configuración de diseño HVI",
    },
    user: {
      name: "Usuarios",
      title: "Usuarios registrados",
      newBtn: "Nuevo usuario",
      fullname: "Nombre",
      editBtn: "Editar usuario",
      delBtn: "Eliminar usuario",
      delete: {
        title: "Eliminar este usuario",
        text: "Eliminar este usuario",
      },
      modal: {
        basic: "Básico",
        advanced: "Avanzado",
        manager: "Gerente",
        username: "Nombre de usuario",
        usernameHint: "Nombre de usuario para iniciar sesión",
        email: "Email",
        emailHint: "Usuario email",
        firstName: "Nombre",
        firstNameHint: "Nombre del usuario",
        lastName: "Apellido",
        lastNameHint: "Apellido del usuario",
        user: "Privilegios de usuario",
        important:
          "Importante: La contraseña predeterminada es 'change_me'. Al iniciar sesión en la aplicación por primera vez, se le pedirá al usuario que cambie la contraseña ingresando esta contraseña predeterminada.",
      },
      new: "Nuevo usuario",
      update: "Actualización de usuario",
      saved: "¡Guardado con éxito!",
      deleted: "¡Eliminado con éxito!",
    },
  },
  inventory: {
    cottonInput: {
      name: "Entrada de algodón",
      supplier: "Proveedor",
      select: "Seleccione...",
      title: "lots registrados para el proveedor",
      newBtn: "Nuevo lote",
      balesBtn: "Pacas del lote",
      editBtn: "Editar información del lote",
      delBtn: "Eliminar lote",
      lot: "Lote",
      baleQty: "Cantidad de pacas",
      issueDate: "Fecha de emisión",
      arrival: "Fecha de llegada",
      avg: "Peso medio de paca",
      net: "Peso neto",
      cottonType: "Tipo algodón",
      origin: "Origen",
      producer: "Productor",
      weight: "Peso",
      selectASupplier: "Por favor seleccione un fornecedor",
      delete: {
        title: "Eliminar este tipo de algodón",
        text: "¿Realmente quieres eliminar este lote?",
      },
      modal: {
        new: "Lote nuevo",
        update: "Actualización de lote",
        lot: "Lote ID",
        lotHint: "Lote id",
        amount: "Cantidad de pacas",
        amountHint: "Número de pacas en el lote",
        gross: "Peso bruto",
        grossHint: "Peso bruto del lote",
        net: "Peso neto",
        netHint: "Peso neto del lote",
        producer: "Nombre del productor",
        producerHint: "El productor asignado al lote",
        cottonType: "Tipo de algodón",
        cottonTypeHint: "El tipo de algodón asignado al lote",
        origin: "Origen",
        originHint: "El origen asignado al lote",
        issue: "Fecha de emisión",
        issueHint: "Fecha de emisión de la factura",
        arrival: "Fecha de llegada",
        arrivalHint: "Fecha de entrada del lote en la planta",
        name: "Nombre del selector",
        nameHint: "Nombre o ID del selector",
        purchase: "Número de contrato de compra",
        purchaseHint: "ID de contrato de compra asignado al lote",
        lotId: "ID de lote de compra",
        lotIdHint: "ID de lote de compra asignado al lote",
      },
      falied: "Error al cargar datos de proveedores",
      falied2: "Error al cargar los datos del tipo de algodón",
      falied3: "Error al cargar los datos de origen",
      falied4: "Error al cargar los datos de los productores",
      falied5: "Error al cargar datos de lotes",
      deleted: "Eliminado con éxito",
      empty: `Los campos obligatorios están vacíos.`,
      lotSuccessfully: `Lote registrado con éxito`,
      lotAltered: `Lote modificado con éxito`,
    },
    balesInput: {
      lot: "ID de lote:",
      supplier: "Proveedor:",
      file: "Desde archivo",
      manually: "Manualmente",
      registered: "Pacas registradas",
      add: "Nuevas pacas",
      avg: "Asignar calidad promedio",
      del: "Eliminar pacas",
      weight: "Peso",
      delete: {
        title: "Eliminar pacas",
        text: "¿Realmente desea eliminar las pacas seleccionadas?",
      },
      noBales: "No se encontraron pacas",
      pile: "ID de la pila",
      byAvg: {
        title: "Asignar calidad media a las pacas",
        text: "¿Realmente desea asignar la calidad de lote promedio a pacas que no contienen valores de calidad?",
      },
      insert: {
        title: "Insertar pacas manualmente",
        bale: "ID de pacas",
        generate: "Generar múltiples pacas automáticamente",
        quantity: "Cantidad",
        quantityHint: "Aantidad de pacas que se generarán automáticamente",
      },
      selectedBales: "¡Las pacas seleccionadas han sido eliminadas!",
      classified: "Pacas clasificadas por promedio",
      added: `Pacas agregados con éxito`,
      readFile: {
        readFile: "Leer archivo",
        bales: "Pacas - Importación",
        lot: "ID de lote:",
        supplier: "Proveedor:",
        layout: "Diseño",
        select: "Seleccionar diseño coincidente",
        insert: "Insertar pacas",
        balesFile: "Pacas en el archivo",
        within: "Valor dentro del rango del parámetro",
        out: "Valor fuera del rango del parámetro",
        failed:
          "Tiene parámetros con columnas incorrectas, corrija el registro HVI",
        empty: "tienes una celda vacía en la columna",
        registered: "Pacas registradas con éxito",
      },
    },
    managementBales: {
      name: "Gestión de inventario - Bales",
      filters: "Status del inventario",
      select: "Seleccionar...",
      bales: "Bales",
      changePile: "Cambiar pila",
      turn: "Poner disponible",
      put: "Poner en espera",
      noBales: "No se encontraron pacas",
      idBales: "ID Paca",
      status: "Status",
      statusAvailable: "Disponible",
      statusOnHold: "En espera",
      supplier: "Proveedor",
      lotInvoiceID: "ID de lote",
      pileID: "ID de la pila",
      weight: "Peso",
      arrivalDate: "Fecha de llegada",
      producer: "Productor",
      cottontypeName: "Tipo de algodón",
      originName: "Origen",
      modal: {
        text: "Seleccionar pila de destino",
        textHint: "Seleccione la pila de destino para pacas",
      },
      all: "Todo",
      running: "En uso",
      distribution: "Disponible para distribución",
      tested: "Totalmente clasificado",
      quality: "Sin calidad",
      hold: "En espera",
      failed: "Error al cargar los datos de la pila",
      failed2: "Error al cargar los datos de las pacas",
      successfully: "¡Pila cambiada con éxito!",
      forUse: "¡Pacas en espera para su uso!",
      availableforUse: "¡Pacas disponibles para usar para usar!",
    },
    managementLots: {
      name: "Gestión de inventario - Lotes",
      lots: "Lotes",
      change: "Cambiar pila",
      turn: "Poner disponible",
      put: "Poner en espera",
      transfer: "Transferir a otra planta",
      lotId: "ID de lote",
      status: "Status",
      statusAvailable: "Disponible",
      statusOnHold: "En espera",
      baleQty: "Cantidad de pacas",
      pile: "Pila",
      origin: "Origen",
      producer: "Productor",
      arrivalDate: "Fecha de llegada",
      netWeight: "Peso neto",
      filters: "Status del inventario",
      select: "Seleccionar...",
      selectPile: "Seleccionar pila de destino",
      selectPileHint: "Seleccione la pila de destino para pacas",
      selectPlant: "Seleccionar planta de destino",
      selectPlantHint: "Seleccione la pila de destino para pacas",
      changePile: "Cambiar pila",
      changePlant: "Cambiar planta",
      all: "Todo",
      running: "En uso",
      distribution: "Disponible para distribución",
      tested: "Totalmente clasificado",
      quality: "Sin calidad",
      hold: "En espera",
      failed: "Error al cargar los datos de la pila",
      lot: "Lote de la pila fue cambiado",
      availableAlert: `Lote disponible para usar`,
      holdAlert: "¡Lote en espera de uso!",
    },
    distribution: {
      name: "Distribución de algodón",
      new: "Nueva distribución manual",
      new2: "Nueva distribución automática",
      mixTypeDescription: "Tipo de mezcla",
      classificationPriority: "Prioridad de clasificación",
      monthlyConsumption: "Consumo mensual",
      monthlyConsumptionPercentage: "Porcentaje de consumo mensual",
      runningTonsQty: "Cantidad en uso ",
      minimumTonsRequired: "Mínimo requerido ",
      minimumVsAvailableInventory: "Porcentaje de inventario en uso vs mínimo",
      date: "Fecha",
      originPileID: "ID de pila de origen",
      last: "Últimas distribuciones de algodón",
      lots: "Lotes incluidos",
      noData:
        "Haga clic en 'Últimas distribuciones' al costado para ver los lotes distribuidos",
      newPileID: "Nueva pila",
      baleQty: "Cantidad de pacas",
      netWeight: "Peso neto",
      currentStatusDescription: "Descripción del status actual",
      inventory: "Resumen de inventario",
      delete: {
        title: "Eliminar esta distribución",
        text: "¿Realmente quieres revertir este lote distribuido?",
        text2: "¿Realmente quieres eliminar esta distribución?",
      },
      canceled: `Transferencia cancelada`,
      lotTransfer: `Transferencia de lote cancelada`,
      byMixType: "Calidad por tipo de mezcla",
      byPile: "Calidad por pila",
      theSelectedPile: "Lotes en ela pila seleccionada",
      byOrigin: "Cantidad por origen",
      byProducer: "Cantidad por productor",
      statusAvailable: "Disponible",
      statusUsedInLaydown: "Utilizado en la mezcla",
    },
    autoDistribution: {
      name: "Distribución automática",
      generate: "Genera",
      confirmBtn: "Confirmar lotes seleccionados",
      generateBtn: "Generar distribución automática",
      mixTypeQuality: "Perspectiva de calidad del tipo de mezcla",
      inventoryQuality: "Calidad del inventario",
      parameter: "Parámetro",
      priority: "Prioridad",
      minimum: "Mínimo",
      maximum: "Máximo",
      expected: "Esperado",
      achieved: "Logrado",
      itemQty: "Cantidad de pacas",
      save: "Guardar",
      cancel: "Cancelar",
      baleQty: "Cantidad de pacas",
      lotID: "ID del lote",
      cottonTypeName: "Tipo de algodón",
      originName: "Origen",
      producerName: "Productor",
      supplierName: "Proveedor",
      weight: "Peso [kg]",
      alert: "Es necesario seleccionar los lotes a transferir",
      transfered: `Lotes transferidos con éxito`,
      totalInventory: "Inventario total",
      running: "En uso",
      availableInventory: "Disponible para distribuir",
      suggested: "Lotes sugeridos",
      runningSuggested: "En uso + lotes sugeridos (proyección)",
    },
    manualDistribution: {
      originPile: "Pila de origen",
      mixType: "Tipo de mezcla",
      name: "Distribución manual",
      makeBtn: "Hacer una distribución manual",
      lotID: "ID del Lote",
      producerName: "Productor",
      destinationPile: "Pila de destino",
      qty: "Cantidad",
      weight: "Peso",
      itemQty: "Cantidad de pacas",
      origin: "Origen",
      transferred: `Se han transferido los lotes seleccionados`,
      update: "Distribución manual",
      theSelectedPile: "Lotes en la pila seleccionada",
      byPile: "Calidad por pila",
      byMixType: "Calidad por tipo de mezcla",
      byOrigin: "Cantidad por origen",
      byProducer: "Cantidad por productor",
      totalInventory: "Inventario total",
      running: "En uso",
      availableInventory: "Inventario disponible",
      suggested: "Pila seleccionada",
      runningSuggested: "En uso + pila seleccionada (proyección)",
      selectPileMsg:
        "Seleccione la pila de destino para cada lote en la lista.",
    },
    pileGroup: {
      name: "Control de uso de algodón - Grupos de pilas",
      title: "Grupos de pilas de la línea de producción",
      newBtn: "Nuevo grupo de pilas",
      prodline: "Línea de producción",
      select: "Seleccionar",
      balesPerLaydown: "Cantidad de pacas por mezcla:",
      prodLine: "Por favor seleccione una línea de producción",
      groupId: "ID del grupo",
      desc: "Descripción",
      active: "Activo",
      qtyBales: "Cantidad de pacas",
      usage: "Porcentaje de uso",
      delete: {
        title: "Eliminar este grupo de pilas",
        text: "¿Realmente desea eliminar este grupo de pilas?",
      },
      modal: {
        value: "Mostrar solo pilas con pacas",
        value2: "Mostrar todas las pilas",
        prodline: "Línea de producción:",
        amount: "Cantidad de pacas por mezcla:",
        represents: "1 fardo representa",
        of: "de la mezcla",
        groupName: "Nombre del grupo",
        groupNameHint: "Descripción del origen",
        perLaydown: "Pacas por mezcla",
        active: "Activo",
        representation: "Representación del grupo (%)",
        laydown: "Mezcla",
        inventory: "Inventario",
        piles: "Representación de pilas fuera de grupo (%)",
        pileID: "ID de la pila",
        groupID: "ID del grupo",
        percentage: "Porcentaje",
        weight: "Peso",
        baleQty: "Cantidad de pacas",
      },
      saved: "Guardado con éxito",
      greater: "La suma de la cantidad de pacas es mayor que 100%",
      deleted: `Eliminado con éxito`,
      new: "Nuevo grupo de pilas",
      update: "Actualización del grupo de pilas",
    },
    cottonTypes: {
      name: "Control de uso de algodón - Tipos de algodón",
      controlled: "Tipos de algodón controlado",
      newBtn: "Nuevo tipo de algodón controlado",
      mixType: "Tipo de mezcla",
      select: "Seleccionar",
      quantity: "Cantidad de pacas por mezcla:",
      sum: "Suma:",
      thePercentage: "(El porcentaje debe ser igual a 100%)",
      cottonTypeName: "Tipo de algodón",
      baleQty: "Cantidad de pacas",
      usage: "Porcentaje de uso",
      noData: "Por favor seleccione un tipo de mezcla",
      delete: {
        title: "Eliminar este tipo de algodón",
        text: "¿Realmente desea eliminar este tipo de algodón?",
      },
      modal: {
        mixType: "Tipo de mezcla:",
        amount: "Cantidad de pacas por mezcla:",
        represents: "1 fardo representa de la mezcla",
        sum: "Suma de todos los tipos de algodón controlado en la disposición:",
        cottonType: "Tipo de algodón",
        select: "Seleccionar...",
        bales: "Pacas por mezcla",
        balesHint: "Cantidad de pacas",
      },
      updated: "Tipo de algodón actualizado con éxito",
      quantitysum: "La suma de la cantidad de fardos es superior al 100 %",
      deleted: "Eliminado con éxito",
      new: "Nuevo tipo de algodón",
      update: "Actualización del tipo de algodón",
      inserted: "Tipo de algodón insertado con éxito",
      registered: `Tipo de algodón ya registrado`,
    },
    importantion: {
      name: "Entrada de resultados de HVI - Importación",
      layout: "Diseño",
      select: "Seleccionar diseño coincidente",
      confirm: "Importar resultados",
      file: "Pacas en el archivo",
      within: "Valor dentro del rango del parámetro",
      out: "Valor fuera del rango del parámetro",
      noFiles: "No hay archivos seleccionados",
      haveParameters:
        "Tiene parámetros con columnas incorrectas, corrija el registro de la columna HVI",
      empty: "tienes una celda vacía en la columna",
      saved: `Pacas salvadas`,
    },
    manualInputs: {
      name: "Entrada de resultados de prueba - Lanzamiento manual",
      same: "Los mismos valores para todos",
      individual: "Valores individuales",
      status: "Status",
      delect: "Seleccionar...",
      supplier: "Proveedor",
      lot: "Lote",
      bales: "pacas",
      confirm: "Guardar",
      pileID: "ID de la pila",
      all: "Todo",
      pending: "Pendiente",
      saved: "¡Guardado con éxito!",
    },
    consultBale: {
      name: "Información de paca",
      enter: "Ingrese ID de paca",
      search: "Buscar",
      data: "Datos de la paca",
      status: "Status",
      bale: "ID de la paca",
      cottonType: "Tipo de algodón",
      origin: "Origen",
      pile: "Pila",
      producer: "Productor",
      supplier: "Proveedor",
      quality: "Calidad de la paca",
      parameter: "Parámetro",
      value: "Valor",
      inventario: "Paca no encontrado en inventario",
    },
  },
  mixing: {
    planning: {
      name: "Planificaciones de calidad",
      title: "Última planificaciones de calidad",
      newBtn: "Nueva planificación",
      details: "Ver detalles",
      generate: "Generar planificación",
      edit: "Editar planificación",
      del: "Eliminar planificación",
      number: "Número de planificación",
      date: "Fecha de planificación",
      mixtype: "Tipo de mezcla",
      lotChange: "Cambio de lote",
      statusDesription: "Status",
      statusUsed: "Usó",
      statusCreated: "Creado",
      delete: {
        title: "Eliminar esta planificación",
        text: "¿Realmente desea eliminar esta planificación?",
      },
      modal: {
        new: "Nueva planificación",
        update: "Actualización de planificación",
        mixtype: "Tipo de mezcla",
        select: "Seleccionar...",
        lotChange: "¿Cambio de lote de producción?",
        quality: "Calidad planificada",
        noDataText: "El plan aún no se ha generado",
      },
      quality: {
        parameter: "Parámetro",
        value: "Valor",
      },
      elaboration: {
        name: "Planificaciones de calidad - Elaboración",
        elaboration: "Elaboración",
        planID: "ID de planificación",
        mixType: "Tipo de mezcla",
        quality: "Calidad",
        noDataText: "No hay inventario disponible",
        parameterName: "Parámetro",
        inventory: "Inventario actual",
        lastLaydown: "Última mezcla",
        planned: "Planificado",
        difference: "Diferencia de producción",
        info: "Información de consumo",
        generate: "Generar planificación",
        no: "Sin prioridades",
        inventoryContext: "Inventario",
        laydown: "Mezcla",
        current: "Inventario actual (Pacas)",
        perDay: "Mezclas por día",
        perLaydown: "Pacas por mezcla",
        totalBales: "Pacas totales por día",
        remaining: "Inventario restante (pacas)",
        failed: "Error al generar el plan",
        successfully: "¡Planificación generada con éxito!",
      },
      added: "¡Nueva planificación agregada con éxito!",
      deleted: "¡Eliminado con éxito!",
    },
    pre: {
      name: "Premezclas",
      title: "Últimas premezclas",
      newBtn: "Nueva premezcla",
      generate: "Generar premezcla",
      viewSelectedPilesHint: "Ver pilas seleccionadas",
      editHint: "Cambiar premezcla",
      deleteHint: "Eliminar premezcça",
      id: "ID de Premezcla",
      prodline: "Línea de producción",
      planning: "ID de planificación",
      uses: "Usa premezcla",
      baleQty: "Cantidad de pacas",
      creationDate: "Fecha de creación",
      yarnLot: "Lote de hilos",
      statusDesription: "Status",
      statusFinished: "Acabado",
      statusInProcess: "En proceso",
      statusCreated: "Creado",
      delete: {
        title: "Eliminar esta premezcla",
        text: "¿Realmente quieres eliminar esta premezcla?",
      },
      select: "Seleccionar...",
      pileFor: "Pilas para premezcla",
      noDataText: "Aún no se ha generado la premezcla",
      pileID: "ID de la pila",
      necessaryBaleQty: "Cantidad de pacas necesarias",
      elaboration: {
        name: "Premezcla - Generación",
        elaboration: "Elaboración",
        pre: "Identificación de Premezcla",
        prodline: "Línea de producción",
        generate: "Generar Premezcla",
        available: "Pilas disponibles",
        pileID: "ID de la pila",
        lotID: "ID de lote",
        pileGroupName: "Grupo de pilas",
        baleQty: "Cantidad de pacas",
        minimumBaleQty: "Cantidad mínima de pacas",
        maximumBaleQty: "Cantidad máxima de pacas",
        definedBaleQty: "Cantidad de pacas definida",
        quality: "Calidad",
        parameterName: "Parámetro",
        priority: "Prioridad",
        inventoryQuality: "Inventario",
        plannedQuality: "Planificado",
        achievedQuality: "Logrado",
        minimumAllowed: "Mínimo permitido",
        maximumAllowed: "Máximo permitido",
        tolerance: "Dentro de la tolerancia ",
        up: "Hasta 50% fuera de tolerancia",
        more: "Más del 50% fuera de tolerancia",
        selected: "Pilas seleccionadas",
        necessaryBaleQty: "Cantidad de pacas necesaria",
      },
      created: `Premezcla creada con éxito`,
      saved: `Guardado correctamente`,
      generated: "Premezcla generada con éxito!",
      new: "Nueva premezcla",
      update: "Actualización de Premezcla",
    },
    laydown: {
      name: "Mezcla",
      title: "ID de Premezcla",
      select: "Seleccionar...",
      laydownID: "ID de mezcla",
      thePriority: "Seleccione la prioridad",
      prodLine: "Línea de producción",
      generalIdentifier: "ID general",
      baleQty: "Cantidad de pacas",
      creationDate: "Fecha de creación",
      changeDate: "Fecha de cambio",
      consumptionDate: "Fecha de consumo",
      status: "Status",
      newBtn: "Nueva mezcla",
      balesAndQuality: "Pacas y calidad",
      cottontypeSummary: "Resumen por tipo de algodón",
      layoutPosition: "Disposición de la mezcla",
      position: "Posición de la paca",
      leftoverBales: "Pacas en premezcla",
      originSummary: "Resumen por origen",
      pileSummary: "Resumen por pila",
      producerSummary: "Resumen por productor",
      laydownCharts: "Gráficos de la mezcla",
      balesBtn: "Pacas",
      layoutBtn: "Disposición",
      delBtn: "Eliminar",
      finishBtn: "Finalizar",
      resumeBtn: "Reabrir",
      laydownBales: "Pacas de la mezcla",
      bale: "Paca",
      pile: "Pila",
      laydownQuality: "Calidad de la mezcla",
      parameter: "Parámetro",
      planned: "Planificado",
      achieved: "Logrado",
      minimum: "Mínimo",
      maximum: "Máximo",
      cv: "CV%",
      whitin: "Dentro de la tolerancia",
      out: "Fuera hasta 50%",
      over: "Fuera más del 50%",
      reporting: "Informes",
      statusDesription: "Status",
      statusFinished: "Acabado",
      statusInProcess: "En proceso",
      statusCreated: "Creado",
      bales: {
        bales: "Pacas",
        name: "Mezcla - Pacas",
        preLaydown: "ID de premezcla",
        laydownId: "ID de Mezcla",
        prodLine: "Línea de producción",
        generate: "Generar mezcla",
        addSingle: "Número de la paca",
        enterBale: "Ingrese ID de la paca...",
        includeBale: "Incluir paca",
        addBales: "Añadir pacas desde el archivo a la premezcla",
        select: "Seleccione el diseño correspondiente",
        balesAdded: "Pacas añadidas a la oremezcla",
        leftover: "Incluir sobrante",
        leftoverhint:
          "Agrega las pacas sobrantes de la última premezcla a la actual premezcla.",
        remove: "Eliminar pacas selecionadas",
        removeHint:
          "Elimina todas las pacas sobrantes de la premezcla actual, pero mantiene las pacas en la lista de sobrantes.",
        removeAllocated: "Eliminar sobrantes",
        removeAllocated2:
          "¿Realmente desea eliminar las pacas sobrantes de la premezcla atual?",
        selected: "Eliminar seleccionado",
        selectedHint:
          "Eliminar una o más pacas de la lista actual de pacas de la premezcla actual y de la lista de pacas sobrantes.",
        excludeBales: "Excluir pacas",
        excludeBales2: "¿Realmente desea excluir las pacas seleccionadas?",
        perPile: "Cantidad de pacas requeridas por pila",
        print: "Imprimir",
        pile: "Pila",
        allocated: "Incluido",
        necessary: "Necesario",
        missing: "Faltante",
        sum: "Suma",
        required: "Cantidad requerida alcanzada",
        lotsInThePile: "Lotes en la pila",
        laydownQuality: "Calidad de la premezcla",
        parameter: "Parámetro",
        planned: "Planificado",
        logrado: "Logrado",
        achieved: "Achieved",
        minimum: "Mínimo",
        maximum: "Máximo",
        cv: "CV",
        whitin: "Dentro de la tolerancia",
        out: "Fuera hasta 50%",
        over: "Fuera más del 50%",
      },
      generate: {
        generateBtn: "Generar mezcla",
        generation: "Generación",
        name: "Mezcla - Generación",
        prodline: "Línea de producción",
        laydown: "ID de Mezcla",
        prelaydown: "Cantidad de pacas en la premezcla",
        laydownBales: "Cantidad de pacas el na mezcla",
        laydownQuality: "Calidad de la mezcla",
        parameter: "Parameter",
        planned: "Planificado",
        priority: "Prioridad",
        achieved: "Logrado",
        minimum: "Mínimo",
        maximum: "Máximo",
        cv: "CV",
        whitin: "Dentro de la tolerancia",
        out: "Fuera hasta 50%",
        over: "Fuera más del 50%",
        controlled: "Grupos controlados",
        notControlled: "No controlado",
        cvControl: "Controle CV",
        noDataText: "No hay grupos de pilas registrados",
        noParametersCVControlled: "No hay parámetros con cv controlado",
        pileGroupID: "ID",
        description: "Descripción",
        minimumExpected: "Mínimo esperado",
        maximumExpected: "Máximo esperado",
        achievedQty: "Cantidad lograda",
        preLaydownQty: "Cantidad en la premezcla",
        notAchieved: "No logrado",
        inThePrelaydown: "Pacas en la premezcla",
        baleID: "Paca",
        pileID: "Pila",
        producer: "Productor",
        selectedToLaydown: "Pacas seleccionadas para la mezcla",
      },
      layout: {
        layout: "Disposición",
        name: "Mezcla - Generación de la disposición",
        prodline: "Línea de producción",
        laydown: "ID de la mezcla",
        balesQty: "Cantidad de pacas de la mezcla",
        balesQty2: "Cantidad de pacas de la submezcla",
        generateBtn: "Generar disposición",
        selected: "Pacas seleccionadas",
        baleID: "Paca",
        layoutPosition: "Posición en la mezcla",
        subLaydownID: "Submezcla",
        pileID: "Pila",
        weight: "Peso",
        laydownQuality: "Calidad de la mezcla",
        parameter: "Parámetro",
        achieved: "Logrado",
        sublaydownQuality: "Calidad de las submezclas",
        groupDescrption: "Descripción del grupo",
        whitin: "Dentro de la tolerancia",
        out: "Fuera hasta 50%",
        over: "Fuera más del 50%",
        noPriorities: "sin prioridades",
        producer: "productor",
        origin: "origen",
      },
      confirm: {
        title: "Consumo de la mezcla",
        text: "¿Quieres confirmar el consumo?",
        title2: "Eliminar la mezcla",
        text2: "¿Realmente quieres eliminar esta mezcla?",
        title3: "Finalizar la mezcla",
        text3: "¿Realmente quieres finalizar esta mezcla?",
        title4: "Reabrir la mezcla",
        text4: "¿Realmente quieres reabrir esta mezcla?",
      },
      alert1:
        "Tiene parámetros con columnas incorrectas, corrija el registro de la columna HVI",
      alert2:
        "Todas las pacas se han eliminado de la premezcla actual y de pacas sobrantes",
      alert3:
        "Todas las pacas se han eliminado de la premezcla actual, pero se mantienen en la lista de sobrantes.",
      alert4: "Seleccionar pacas para excluirlas",
      alert5:
        "Las pacas seleccionadas se han eliminado de la premezcla actual y de pacas sobrantes",
      alert6: `Pacas incluidas en ela premezcla`,
      alert7:
        "Los fardos sobrantes de la última premezcla se han agregado a la premezcla actual.",
      alert8: `Lista de pilas para la premezcla`,
      alert9: "Mezcla selecionada",
      alert10: "¡La mezcla se generó con éxito!",
      alert11: "¡Generado con éxito!",
    },
    reports: {
      reporting: "Informes",
      balesAndQuality: {
        name: "Informe de mezcla - Pacas y calidad",
        balesAndQuality: "Pacas y calidad",
        preLaydownId: "ID de premezcla",
        laydownId: "ID the mezcla",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        laydownReport: `Informe de mezcla - Calidad`,
        parameter: "Parámetro",
        avg: "Promedio",
        cv: "CV",
        maximum: "Máximo",
        minimum: "Mínimo",
        sd: "Sd",
        baleID: "ID de paca",
        cottonTypeName: "Tipo de algodón",
        producerName: "Productor",
        lotID: "ID del Lot",
        pileID: "ID de Pila",
        weight: "Peso",
        export: "Exportar todo",
      },
      cottonTypeSummary: {
        name: "Informe de mezcla - Resumen por tipo de algodón",
        cottonTypeSummary: "Resumen por tipo de algodón",
        cottonType: "Tipo de algodón",
        preLaydownId: "ID de Premezcla",
        laydownId: "ID de Mezcla",
        nameDg: "Nombre",
        weight: "Peso",
        baleQty: "Cantidad de pacas",
        totalBales: "Totales de Pacas",
        totalWeight: "Peso total",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        laydownReport: `Informes - Resumen por tipo de algodón`,
        percentage: "Porcentaje",
        compositionPercentage: "Porcentaje de composición",
      },
      laydownCharts: {
        laydownChart: "Gráfico de mecla",
        reporting: "Informe de mezcla - Gráficos de promedio",
        bale: "Paca",
        avg: "Promedio de la mezcla",
        preLaydownId: "ID de premezcla",
        laydownId: "ID de mezcla",
        exportAll: "Exportar todo",
      },
      layoutPosition: {
        layoutPosition: "Disposición",
        name: "Informe de mezcla - Disposición",
        preLaydownId: "ID de premezcla",
        laydownId: "ID de mezcla",
        baleID: "ID de paca",
        lotID: "ID de lote",
        pileID: "ID de pila",
        weight: "Peso",
        subLaydownID: "ID Submezcla",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        laydownReport: "Informe de mezcla - Disposición",
      },
      leftoverBales: {
        leftoverBales: "Pacas en premezcla",
        name: "Informe de mezcla - Sobra de pacas",
        preLaydownId: "ID de premezcla",
        preLaydown: "Premezcla",
        laydownReport: `Informe de mezcla - Sobra de pacas`,
        baleID: "ID de fardo",
        lotID: "ID de lote",
        cottonType: "Tipo de algodón",
        pileID: "ID de pilha",
        weight: "Peso",
        laydownDate: "Fecha de la mezcla",
        remove: "Eliminar seleccionadas",
        removeHint:
          "Eliminar las pacas seleccionadas de la lista de pacas sobrantes.",
        removeMsg:
          "¿Realmente desea eliminar las pacas selecionadas de la lista de sobrantes?",
        removeAll: "Eliminar todo",
        removeAllHint:
          "Eliminar todas las pacas de la lista de pacas sobrantes.",
        removeAllMsg:
          "¿Realmente desea eliminar todos las pacas de la lista de sobrantes?",
      },
      originSummary: {
        originSummary: "Resumen por origem",
        name: "Informe de mezcla - Resumen por origem",
        preLaydownId: "ID de premezcla",
        laydownId: "ID de mezcla",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        laydownReport: `Informe de mezcla - Resumen por origem`,
        origin: "Origen",
        nameDg: "Nombre",
        weight: "Peso",
        baleQty: "Cantidad de pacas",
        totalBales: "Totales de Pacas",
        totalWeight: "Peso total",
        percentage: "Porcentaje",
        compositionPercentage: "Porcentaje de composición",
      },
      pileSummary: {
        pileSummary: "Resumen por pila",
        name: "Informe de mezcla - Resumen por pila",
        preLaydownId: "ID de premezcla",
        laydownId: "ID de mezcla",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        laydownReport: `Informe de mezcla - Resumen por pila`,
        pile: "Pila",
        nameDg: "Nombre",
        weight: "Peso",
        baleQty: "Cantidad de pacas",
        totalBales: "Totales de Pacas",
        totalWeight: "Peso total",
        percentage: "Porcentaje",
        compositionPercentage: "Porcentaje de composición",
      },
      producerSummary: {
        producerSummary: "Resumen por productor",
        name: "Informe de mezcla - Resumen por productor",
        producer: "Productor",
        preLaydownId: "ID de premezcla",
        laydownId: "ID de mezcla",
        laydown: "Mezcla",
        preLaydown: "Premezcla",
        nameDg: "Nombre",
        weight: "Peso",
        baleQty: "Cantidad de pacas",
        totalBales: "Totales de Pacas",
        totalWeight: "Peso total",
        percentage: "Porcentaje",
        compositionPercentage: "Porcentaje de composición",
      },
    },
    reportsContext: {
      alert1: "Error al cargar los datos de entrada de lotes",
      alert2: `Calidad de la mezcla - Gráfico de promedio`,
      alert3: "Error al cargar datos de resumen de tipo de algodón",
      alert4: "Error al cargar los datos de gráfico de la mezcla",
      alert5: `Calidad de la mezcla - Gráfico de pacas`,
      alert6: "Error al cargar los datos de disposición",
      alert7: "Error al cargar los datos de disposición",
      alert8: "Error al cargar datos de resumen de tipo de algodón",
      alert9: "Error al cargar datos de resumen de tipo de algodón",
      alert10: "Error al cargar datos de resumen de tipo de algodón",
    },
    consumptionControl: {
      modalTitle: "Seleccione fecha",
      name: "Control de consumo",
      prodline: "Línea de producción",
      select: "Seleccionar...",
      status: "Status",
      thePriority: "Seleccione la prioridad",
      laydowns: "Mezclas",
      noDataText: "Seleccione una mezcla y status",
      preLaydownID: "ID the Premezcla",
      id: "ID de la mezcla",
      creationDate: "Fecha de creación",
      consumptionDate: "Fecha de consumo",
      statusDg: "Status",
      confirm: "Confirmar consumo",
      consumed: `Mezcla consumida`,
      all: "todo",
      pending: "pendiente",
    },
  },
  reports: {
    inventory: {
      bales: {
        name: "Pacas en inventario",
        tile: "Pacas en inventario",
        status: "Status",
        pile: "Pila",
        bales: "Pacas",
        baleID: "ID de paca",
        lotInvoiceID: "ID de lote",
        pileID: "Pila ID",
        cottontype: "Tipo de algodón",
        weight: "Peso",
        arrivalDate: "Fecha de llegada",
        all: "Todo",
        running: "En uso",
        availablefordistribution: "Disponible para distribución",
        fullyTested: "Con caliqad",
        missingQuality: "Sin calidad",
        hold: "En espera",
        blocked: "Bloqueado",
        piles: "En las pilas",
        leftover: "En premezcla",
        failed: "Error al cargar los datos de pacas",
        qualitySummary: "Resumen de calidad",
      },
      composition: {
        name: "Composición del inventario",
        nameDg: "Nombre/descripción",
        bales: "Pacas",
        weight: "Peso",
        percentage: "Porcentaje",
        total: "Total",
        grouping: "Tipo de agrupación",
        select: "Seleccionar...",
        status: "Status",
        cottonType: "Tipo de algodón",
        producer: "Productor",
        supplier: "Proveedor",
        origin: "Origen",
        all: "Todo",
        hold: "En espera",
        blocked: "Bloqueado",
        failed: "Error al cargar los datos de composición",
      },
      general: {
        name: "Inventario general",
        supplier: "Proveedor",
        select: "Seleccionar...",
        producer: "Productor",
        lot: "Lote",
        pile: "Pila",
        cottonType: "Tipo de algodón",
        prodline: "Línea de producción",
        bySupplier: "Agrupar por proveedor",
        byCottontype: "Agrupar por tipo de algodón",
        byLot: "Agrupar por lote",
        byProducer: "Agrupar por productor",
        byPile: "Agrupar por pila",
        lotID: "ID de Lote",
        cottonTypeName: "Tipo de algodón",
        pileID: "Pila",
        bales: "Cantidad de pacas",
        failed: "Error al cargar los datos de entrada de lotes",
        failed2: "Error al cargar datos de proveedores",
        failed3: "Error al cargar los datos del tipo de algodón",
        failed4: "Error al cargar los datos de origen",
        failed5: "Error al cargar los datos de los productores",
        failed6: "Error al cargar los datos de la línea de producción",
        failed7: "Error al cargar datos de lotes",
        failed8: "Error al cargar los datos de las pilas",
        origin: "Origen",
        groupingType: "Tipo de agrupación",
        groupedBy: "Agrupado por",
        startDate: "Fecha de inicio",
        finalDate: "Fecha final",
        weight: "Peso",
      },
      lotsEntredPerPeriod: {
        name: "Entrada de lotes por período",
        supplier: "Proveedor",
        select: "Seleccionar...",
        producer: "Productor",
        origin: "Origen",
        cottonTypes: "Tipo de algodón",
        period: "Período",
        to: "a",
        lotId: "ID de lote",
        baleQty: "Cantidad de pacas",
        plantWeight: "Peso",
        title: "Entrada de lotes por período",
        failed: "Error al cargar los datos de entrada de lotes",
        failed2: "Error al cargar datos de proveedores",
        failed3: "Error al cargar los datos del tipo de algodón",
        failed4: "Error al cargar los datos de origen",
        failed5: "Error al cargar los datos de los productores",
      },
      quality: {
        name: "Resumen de la calidad del inventario",
        prodline: "Línea de producción",
        select: "Seleccionar...",
        baleQty: "Cantidad de pacas disponibles",
        weight: "Peso",
        text: "Está limitando el inventario disponible para la línea de producción.",
        title: "Resumen de la calidad del inventario",
        failed: "Error al cargar los datos de entrada de lotes",
        failed2: "Error al cargar datos de proveedores",
        failed3: "Error al cargar los datos del tipo de algodón",
        failed4: "Error al cargar los datos de origen",
        failed5: "Error al cargar los datos de los productores",
        failed6: "Error al cargar los datos de la línea de producción",
        failed7: "Error al cargar datos de lotes",
        totalInventory: "Inventario total",
        availableInventory: "Inventario disponible",
        cottonOutsideGroups: "Algodón fuera de grupos",
      },
    },
    consumpition: {
      avg: {
        name: "Gráficos de promedios de las mezclas",
        prodline: "Línea de producción",
        select: "Seleccionar...",
        yarnlot: "Lote de hilos",
        period: "Período",
        to: "a",
        showBy: "Mostrar por",
        showTolerances: "Mostrar tolerancias de",
        upperTolerance: "Tolerancia superior",
        planned: "Planificado",
        laydownAvg: "ID de la mezcla",
        lowerTolerance: "Tolerancia más baja",
        cv: "CV",
        average: "Promedio",
        production: "Producción",
        failed: "Error al cargar los datos de los gráficos de promedios",
        failed2: "Error al cargar los datos de la línea de producción",
        failed3: "Error al cargar datos de lotes de hilo",
        inventoryAvg: "Promedio de inventario actual",
      },
      perPeriod: {
        name: "Composición del consumo por período",
        groupingType: "Tipo de agrupación",
        select: "Seleccionar...",
        prodline: "Línea de producción",
        period: "Período",
        to: "a",
        nameDg: "Nombre",
        bales: "Pacas",
        weight: "Peso",
        percentage: "Porcentaje",
        cottonType: "Tipo de algodón",
        producer: "Productor",
        supplier: "Proveedor",
        origin: "Origen",
        failed: "Error al cargar los datos de composición",
        failed2: "Error al cargar los datos de la línea de producción",
      },
      daily: {
        name: "Consumo de algodón por día",
        groupingType: "Tipo de agrupación",
        select: "Seleccionar...",
        prodline: "Línea de producción",
        period: "Período",
        day: "Día",
        bales: "Pacas",
        running: "Lotes en uso",
        totalWeight: "Peso",
        title: "Consumo de algodón por día",
        cottonType: "Tipo de algodón",
        producer: "Productor",
        supplier: "Proveedor",
        origin: "Origen",
        failed: "Error al cargar los datos diarios",
        failed2: "Error al cargar los datos de la línea de producción",
        failed3: "Error al cargar datos de proveedores",
        failed4: "Error al cargar los datos del tipo de algodón",
        failed5: "Error al cargar los datos de origen",
        failed6: "Error al cargar los datos de los productores",
      },
      general: {
        name: "Consumo de algodón",
        cottonType: "Tipo de algodón",
        producer: "Productor",
        supplier: "Proveedor",
        select: "Seleccionar...",
        prodline: "Línea de producción",
        period: "Período",
        to: "a",
        bySupplier: "Agrupar por proveedor",
        byCottonType: "Agrupar por tipo de algodón",
        byPile: "Agrupar por pila",
        byProducer: "Agrupar por productor",
        byProdline: "Agrupar por línea de producción",
        byLaydown: "Agrupar por mezcla",
        pile: "Pila",
        laydown: "Mezcla",
        baleQty: "Cantidad de pacas",
        failed: "Error al cargar los datos de entrada de lotes",
        failed2: "Error al cargar datos de proveedores",
        failed3: "Error al cargar los datos del tipo de algodón",
        failed4: "Error al cargar los datos de origen",
        failed5: "Error al cargar los datos de los productores",
        failed6: "Error al cargar los datos de la línea de producción",
        failed7: "Error al cargar datos de lotes",
        failed8: "Error al cargar los datos de las pilas",
        weight: "Peso",
        allProdLines: "Todo",
      },
      perMonthly: {
        name: "Consumo de algodón por mes",
        groupingType: "Tipo de agrupación",
        select: "Seleccionar...",
        prodline: "Línea de producción",
        period: "Período",
        to: "a",
        month: "Mes",
        bales: "Pacas",
        lotQty: "Lotes en uso",
        totalWeight: "Peso",
        title: "Consumo de algodón por mes",
        cottonType: "Tipo de algodón",
        producer: "Productor",
        supplier: "Proveedor",
        origin: "Origen",
        failed: "Error al cargar los datos mensuales",
        failed2: "Error al cargar los datos de la línea de producción",
        failed3: "Error al cargar datos de proveedores",
        failed4: "Error al cargar los datos del tipo de algodón",
        failed5: "Error al cargar los datos de origen",
        failed6: "Error al cargar los datos de los productores",
      },
      perLaydown: {
        name: "Consumo de algodón por mezcla",
        prodline: "Línea de producción",
        select: "Seleccionar...",
        yarnLot: "Lote de hilos",
        period: "Período",
        to: "a",
        consumptionDate: "Fecha de consumo",
        preLaydownID: "ID de premezcla",
        laydownID: "ID de mezcla",
        baleQty: "Cantidad de pacas",
        totalWeight: "Peso",
        title: "Consumo de algodón por mezcla",
        failed: "Error al cargar los datos de mezcla",
        failed2: "Error al cargar los datos de la línea de producción",
        failed3: "Error al cargar datos de lotes de hilo",
      },
      usda: {
        name: "Color grade - USDA",
        preLaydown: "Premezcla",
        select: "Seleccionar...",
        laydown: "Mezcla",
        failed: "Error al cargar los datos de mezcla",
        failed2: "Error al cargar los datos de premezcla",
        failed3: "Error al cargar datos de gráficos USDA",
      },
    },
  },
  warnings: {
    path: "Advertencias",
    name: "Advertencias de calidad",
    status: "Status",
    select: "Seleccionar...",
    pending: "Pendiente",
    authorized: "Autorizado",
    title: "Advertencias de calidad",
    noDataText: "No se encontraron advertencias",
    productionLineName: "Línea de producción",
    preLaydownID: "Premezcla",
    laydownID: "Mezcla",
    warningDate: "Fecha de advertencia",
    authorizedUse: "Uso autorizado",
    infoHint: "Ver detalles de advertencia",
    checkHint: "Autorizar uso",
    confirm: {
      title: "Autorizar uso",
      text: "¿Quieres autorizar el uso de este laydown?",
    },
    modal: {
      title: "Detalles de la advertencia",
      itemName: "Parámetro",
      minimum: "Mínimo",
      maximum: "Máximo",
      achieved: "Logrado",
    },
  },
  home: {
    path: "Inicio",
    user: "Usuario",
    access: "Acceso rápido",
    cottonInput: "Entrada de algodón",
    baleInfo: "Información de la paca",
    planning: "Planificación",
    pre: "Premezcla",
    laydown: "Mezcla",
    avg: "Gráficos de promedio",
    cottonType: "Composición del inventario por tipo de algodón",
    producer: "Composición del inventario por productor",
  },
  user: {
    title: "Configuración de usuario",
    username: "Username",
    email: "Correo electrónico",
    firstName: "Nombre",
    lastName: "Apellido",
    logout: "Cerrar sesión",
    settings: "Configuración",
    profile: "Perfil",
    saved: "¡Guardado con éxito!",
    empty: `Los campos obligatorios están vacíos.`,
    passwordMsg:
      "Para cambiar su contraseña, utilice la opción 'Contraseña olvidada' de la pantalla de acceso.",
  },
  preferences: {
    title: "Preferencias",
    language: "Idioma",
    countrySettings: "Configuración de región",
    alerts: {
      emptyLanguage: "Es necesario seleccionar el idioma para guardar",
      emptyCountry: "Es necesario seleccionar el región para guardar",
    },
    unitOfMesureKG: "Kilogramo",
    unitOfMesurePD: "Libras",
    unitOfMesure: "Unidad de medida de peso",
  },
  logs: {
    path: "Registros de acciones",
    name: "Acciones del usuario",
    new: "Nuevo",
    date: "Fecha",
    userName: "ID del usuario",
    message: "Mensaje",
  },
};

export { translations };
