const translations = {
  sidebar: {
    menu: {
      registers: "Cadastros",
      inventory: "Estoque",
      laydown: "Mistura",
      reports: "Relatórios",
      warning: "Avisos",
    },
    register: {
      parameters: "Parâmetros de qualidade",
      prodline: "Linhas de produção",
      producer: "Produtores",
      supplier: "Fornecedores",
      cottontype: "Tipos de algodão",
      pile: "Pilhas",
      origin: "Origens",
      plant: "Unidades de produção",
      hvispreadsheet: "Layouts HVI",
      hvidatlayout: "Layout dat. HVI",
      prodlineclasses: "Classes de linhas de produção",
      mixtype: "Tipos de mistura",
      hvi: "Layout arquivo HVI",
      user: "Usuários",
    },
    inventory: {
      cottonInput: "Entrada de algodão",
      management: "Gerenciamento",
      bales: "Fardos",
      lots: "Lotes",
      cottonDistribution: "Distribuição de algodão",
      cottonUsage: "Controle de uso",
      pileGroup: "Grupos de pilhas",
      cottonTypes: "Tipos de algodão",
      testingsResults: "Lançamento HVI",
      importantion: "Importação de resultados",
      manualInputs: "Lançamento manual",
      consultBale: "Consultar fardo",
    },
    mixing: {
      planning: "Planejamento",
      pre: "Pré-mistura",
      laydown: "Mistura",
      control: "Controle de Consumo",
    },
    reports: {
      inventory: "Estoque",
      bales: "Fardos",
      general: "Geral",
      generalConsumption: "Geral",
      composition: "Composição",
      lotsPerPeriod: "Entrada de lotes",
      quality: "Qualidade",
      consumpition: "Consumo",
      perDay: "Por dia",
      perMonth: "Por mês",
      perLaydown: "Por mistura",
      perPeriod: "Composição",
      avg: "Gráficos de médias das misturas",
      usda: "Gráficos de USDA",
    },
  },
  E500page: {
    msg1: "Desculpe, algo saiu errado.",
    msg2: "Tente novamente. Se o erro persistir,  contate o suporte técnico.",
    btnHome: "Ir para página inicial",
  },
  others: {
    tableAddBtn: "Novo",
    tableColumnActions: "Ações",
    deleteBtn: "Excluir",
    dailyUnit: "Dias",
    groupContinuedMessage: "Continuou da última página",
    groupContinuesMessage: "Continua na próxima página",
    selectFile: "Selecione um arquivo",
    noFileSelected: "Nenhum arquivo selecionado",
  },
  alerts: {
    confirmDeletion: "Realmente deseja excluir este item?",
  },
  license: {
    title: "Licenças",
    terms:
      "Este produto contém software licenciado sob uma licença de código aberto. Para reconhecimentos e textos de licença, veja abaixo. A Maschinenfabrik Rieter AG (denominada 'Rieter') se oferece para entregar, mediante solicitação, uma cópia do código fonte completo correspondente para os pacotes de software de código aberto protegidos por direitos autorais usados neste produto para os quais tal entrega é solicitada pelas respectivas licenças. Esta oferta é válida até três anos após a compra ou atualização do software para qualquer pessoa que receba esta informação. Para obter o código-fonte, escreva em inglês para “Maschinenfabrik Rieter AG, Attn: ESSENTIAL Open Source Team, Klosterstrasse 20, 8406 Winterthur, Switzerland”",
    licenses: "Licença(s) declrada(s)",
  },

  register: {
    plant: {
      name: "Unidades",
      title: "Unidades de produção cadastradas",
      id: "ID",
      desc: "Descrição",
      btnNew: "Novo",
      hintPlant: "Editar unidade",
      delete: {
        title: "Deletar essa unidade",
        text: "Você realmente deseja excluir esta unidade?",
      },
      settings: {
        title: "Configurações da unidade",
        dateFormat: "Formato da data",
        dateFormatHint: "Selecione seu formato de data preferido",
        minBale: "Peso mínimo do fardo",
        minBaleHint: "Insira o peso mínimo do fardo",
        maxBale: "Peso máximo do fardo",
        maxBaleHint: "Insira o peso máximo do fardo",
        printer: "Caminho da impressora de etiquetas",
        printerHint: "Digite o caminho da impressora térmica",
        asian: "Testagem parcial de fardos",
        asianHint: "Verifique se o estoque não é 100% testado",
        leftover: "Considerar a qualidade da pré-mistura",
        leftoverHint: "Verifique para considerar a qualidade restante",
      },
      modal: {
        id: "ID",
        idHint: "ID da unidade",
        desc: "Descrição",
        descHint: "Descrição da unidade",
        update: "Atualizar unidade",
        new: "Nova unidade",
      },
      alert: {
        empty: "Os campos obrigatórios estão vazios",
        saved: "Salvo com sucesso!",
        deleted: "Excluído com sucesso!",
      },
    },
    parameters: {
      name: "Parâmetros de qualidade",
      title: "Parâmetros de qualidade registrados",
      btnNew: "Adicionar",
      btnChange: "Reordenar",
      byManually: "Manualmente",
      preConfigured: "Pré configurado",
      editParameter: "Editar parâmetro",
      deleteParameter: "Excluir parâmetro",
      desc: "Nome",
      shortName: "Abreviação",
      viewingOrder: "Ordem de visualização",
      minimum: "Valor mínimo",
      miximum: "Valor máximo",
      cv: "Controle CV",
      amplitude: "Amplitude",
      graphicScale: "Escala nos gráficos",
      cspMultiplier: "Multiplicador CSP",
      sciMultiplier: "Multiplicador SCI",
      setAvgWhenZero: "Atribuir média do estoque quando zero",
      manualInput: "Entrada manual",
      calculated: "Calculado",
      sci: "SCI",
      csp: "CSP",
      delete: {
        title: "Excluir este parâmetro",
        text: "Deseja realmente excluir este parâmetro?",
      },
      modal: {
        newParameter: "Adicionar novo parâmetro",
        updateParameter: "Atualizar parâmetro",
        addConfigured: "Adicionar parâmetro pré-configurado",
        changeParameter: "Alterar a ordem de exibição dos parâmetros",
        manually: {
          fullName: "Nome completo",
          fullNameHint: "Nome do parâmetro",
          shortName: "Abreviação",
          shortNameHint:
            "Um nome curto para ser mostrado em relatórios e tabelas",
          minValue: "Valor mínimo",
          minValueHint: "O valor mínimo aceito para o parâmetro",
          maxValue: "Valor máximo",
          maxValueHint: "O valor máximo aceito para o parâmetro",
          amplitude: "Amplitude",
          amplitudeHint:
            "A diferença entre o valor mínimo mais comum e o valor máximo mais comum para o parâmetro",
          chartScale: "Escala nos gráficos",
          chartScaleHint:
            "A escala usada para apresentar o parâmetro nos gráficos de qualidade",
          csp: "Multiplicador CSP",
          cspHint: "Fator de multiplicação de parâmetros no cálculo do CSP",
          sci: "Multiplicador SCI",
          sciHint:
            "Fator de multiplicação de parâmetros no cálculo do valor SCI",
          avg: "Atribuir média do estoque quando zero",
          avgHint:
            "Indica se o sistema deve atribuir qualidade média para fardos que não possuem valor para este parâmetro na classificação",
          manual: "Entrada manual",
          manualHint:
            "Indica se os valores dos parâmetros são inseridos manualmente pelo usuário",
          cv: "Controle CV",
          cvHint:
            "Indica se o sistema deve controlar o coeficiente de variação (CV) do parâmetro",
          calc: "Calculado",
          calcHint:
            "Indica se o valor do parâmetro é calculado automaticamente. Deve ser verdadeiro apenas se o parâmetro for o SCI ou CSP e o sistema deve calcular o valor do parâmetro automaticamente com base no multiplicador SCI/CSP de outros parâmetros",
        },
        parameterConfigured: {
          text: "Clique em '+' para adicionar o parâmetro.",
        },
        viewingOrder: {
          text: "Clique no parâmetro e arraste-o para a posição desejada.",
          displayOrder: "Ordem de exibição",
          parameter: "Parâmetro",
        },
      },
      alert: {
        required: "Os campos obrigatórios estão vazios.",
        saved: "Salvo com sucesso!",
        deleted: "Deletado com sucesso!",
      },
    },
    mixtype: {
      name: "Tipos de mistura",
      title: "Tipos de mistura registrados",
      newBtn: "Novo tipo de mistura",
      desc: "Descrição",
      classCode: "Código da classe",
      minDays: "Estoque mínimo",
      consumpition: "Consumo mensal",
      required: "Qtde. mínima de lotes",
      classification: "Prioridade de classificação",
      btnEdit: "Editar tipo de mistura",
      btnDel: "Excluir tipo de mistura",
      btnQuanlityRange: "Configuração de range de qualidade",
      delete: {
        title: "Excluir este tipo de mistura",
        text: "Você realmente deseja excluir este tipo de mistura?",
      },
      modal: {
        desc: "Descrição",
        descHint: "Nome do tipo de mistura",
        classCode: "Código da classe",
        classCodeHint: "Código das classes associadas ao tipo mistura",
        required: "Qtde. mínima de lotes",
        requiredHint: "Número mínimo de lotes para poder fazer misturas",
        classification: "Prioridade de classificação",
        classificationHint:
          "Ordem de prioridade na distribuição dos fardos em caso de estoque compartilhado por vários tipos de mistura",
        minimum: "Estoque mínimo",
        minimumHint: "Estoque mínimo necessário para o tipo de mistura",
        days: "dias",
        monthly: "Consumo mensal",
        monthlyHint: "Consumo mensal aproximado em quilogramas",
      },
      qualityRangeModal: {
        title: "Ranges de qualidade",
        btnNew: "Novo range de qualidade",
        btnEdit: "Editar range",
        btnDelete: "Deletar range",
        parameter: "Parâmetro",
        minimum: "Valor mínimo",
        maximum: "Valor máximo",
        classificationType: "Tipo de classificação",
        mixTypeName: "Tipo de mistura",
        newQualityRangeModal: {
          title: "Cadastro de range de qualidade",
          minimum: "Valor mínimo",
          maximum: "Valor máximo",
          parameter: "Parâmetro",
          parameterHint: "Selecione o parâmetro",
          changeDate: "Data de alteração",
          changeReason: "Motivo da mudança",
          rangeChangeHistory: "Histórico de alterações",
          noDataHistory: "Nenhuma mudança foi feita",
          classificationType: "Tipo de classificação:",
          classificationByLot: "Média do lote",
          classificationByBale: "Fardo à fardo",
        },
      },
      alert: {
        empty: "Os campos obrigatórios estão vazios.",
        saved: "Salvo com sucesso!",
        deleted: "Excluído com sucesso!",
      },
      new: "Novo tipo de mistura",
      update: "Atualizar tipo de mistura",
    },
    prodline: {
      name: "Linhas de produção",
      title: "Linhas de produção cadastradas",
      newBtn: "Nova linha de produção",
      editBtn: "Editar linha de produção",
      delBtn: "Excluir linha de produção",
      wasteBtn: "Posições de resíduo",
      desc: "Descrição",
      mixtype: "Tipo de mistura",
      preLaydown: "Quantidade de fardos por pré-mistura",
      balesLaydown: "Quantidade de fardos por mistura",
      preLaydownCode: "Código de pré-mistura",
      perDay: "Consumo diário",
      lot: "Lote de produção",
      balesSubmix: "Quantidade de fardos por sub-mistura",
      control: "Controle de produção",
      active: "Ativo",
      premixCheck: "Usa pré-mistura",
      delete: {
        title: "Excluir esta linha de produção",
        text: "Você realmente deseja excluir esta linha de produção?",
      },
      modal: {
        active: "Ativo",
        activeHint: "Indica se a linha de produção está ativa ou não",
        preLaydown: "Usa pré-mistura",
        preLaydownHint:
          "Indica se a linha de produção utiliza pré-mistura ou não",
        mixtype: "Tipo de mistura",
        mixtypeHint: "O tipo de mistura atribuído à linha de produção",
        desc: "Descrição",
        descHint: "Nome ou descrição da linha de produção",
        qtyPerLaydown: "Qtde. fardos por mistura",
        qtyPerLaydownHint: "Quantidade de fardos utilizados em cada mistura",
        qtyPreLaydown: "Qtde. fardos por pré-mistura",
        qtyPreLaydownHint: "Quantidade de fardos necessária para pré-mistura",
        qtySubLaydown: "Qtde. fardos por sub-mistura",
        qtySubLaydownHint: "Quantidade de fardos em cada sub-mistura",
        daylyConsumption: "Consumo diário",
        daylyConsumptionHint:
          "Consumo diário (quantidade de mistura) para a linha de produção",
        laydowns: "Misturas",
        prodControl: "Controle de produção",
        prodControlHint:
          "Número de dias de produção a serem controlados pelo sistema",
        days: "Dias",
        preLaydownCode: "Código de pré-mistura",
        preLaydownCodeHint:
          "Qualquer valor que diferencie o pré-mistura de outras pre-misturas",
        productionLot: "Código do lote de produção",
        productionLotHint:
          "Qualquer valor que identifique o lote de produção de outros lotes de produção",
      },
      newWaste: {
        title: "Nova posição de resíduo",
        position: "Posição:",
        newBtn: "Nova posição",
      },
      settings: {
        text: "Alterar valores diretamente dentro das células da tabela",
        parameter: "Parâmetro",
        priority: "Prioridade",
        prodline: "Tolerância para produção",
        yarn: "Tolerância para lote de fios",
        cvTolerance: "Tolerância CV",
        maxCv: "CV máximo",
        generateLower: "Gerar alerta inferior",
        generateHigher: "Gerar alerta superior",
        minAllowed: "Mínimo permitido",
        maxAllowed: "Máximo permitido",
      },
      wastePosition: {
        title: "Posições de resíduos",
        registred: "Posições de resíduos registradas",
        noData: "Não há posições de resíduos cadastradas",
      },
      alert: {
        deleted: "Excluído com sucesso!",
        saved: "Salvo com sucesso!",
      },
      titleWaste: "Posições de resíduos",
      newProd: "Nova linha de produção",
      updateProd: "Atualizar linha de produção",
      prodSettings: "Configurações de qualidade da linha de produção",
    },
    prodlinecode: {
      name: "Classes de linhas de produção",
      title: "Classes de linhas de produção registradas",
      newBtn: "Nova classe de linha de produção",
      classCode: "Código da classe",
      prodLine: "Linha de produção",
      delBtn: "Excluir classe",
      delete: {
        title: "Excluir esta classe de linha de produção",
        text: "Você realmente deseja excluir esta classe da linha de produção?",
      },
      modal: {
        prodline: "Linha de produção",
        prodlineHint: "Selecione a linha de produção",
        classCode: "Código da classe",
        classCodeHint: "O código de classe para a linha de produção",
      },
      empty: "Os campos obrigatórios estão vazios.",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
      newProd: "Nova classe de linha de produção",
    },
    producer: {
      name: "Produtores",
      title: "Produtores registrados",
      newBtn: "Novo produtor",
      desc: "Nome",
      editBtn: "Editar produtor",
      delBtn: "Excluir produtor",
      delete: {
        title: "Excluir este produtor",
        text: "Deseja realmente excluir este produtor?",
      },
      modal: {
        name: "Nome",
        nameHint: "Nome do produtor",
      },
      empty: "Os campos obrigatórios estão vazios.",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
      newProducer: "Novo produtor",
      updateProducer: "Atualizar produtor",
    },
    supplier: {
      name: "Fornecedores",
      title: "Fornecedores cadastrados",
      newBtn: "Novo fornecedor",
      desc: "Nome",
      editBtn: "Editar fornecedor",
      delBtn: "Excluir fornecedor",
      delete: {
        title: "Excluir este fornecedor",
        text: "Você realmente deseja excluir este fornecedor?",
      },
      modal: {
        name: "Nome",
        nameHint: "Descrição do fornecedor",
      },
      empty: "Os campos obrigatórios estão vazios.",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
      new: "Novo fornecedor",
      update: "Atualizar fornecedor",
    },
    cottonType: {
      name: "Tipos de algodão",
      title: "Tipos de algodão registrados",
      newBtn: "Novo tipo de algodão",
      desc: "Descrição",
      editBtn: "Editar tipo de algodão",
      delBtn: "Excluir tipo de algodão",
      delete: {
        title: "Excluir este tipo de algodão",
        text: "Você realmente deseja excluir este tipo de algodão?",
      },
      modal: {
        desc: "Nome ou descrição",
        descHint: "Descrição do tipo de algodão",
      },
      empty: "Os campos obrigatórios estão vazios.",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
      new: "Novo tipo de algodão",
      update: "Atualizar tipo de algodão",
    },
    pile: {
      name: "Pilhas",
      title: "Pilhas registradas",
      newBtn: "Nova(s) pilha(s)",
      delBtn: "Excluir",
      delete: {
        text: "Deseja realmente excluir este item?",
        yes: "Sim",
        no: "Não",
        title: "Excluir a(s) pilha(s)",
        really: "Você realmente deseja excluir a(s) pilha(s) selecionada(s)?",
      },
      modal: {
        id: "ID da pilha",
        idHint: "ID da pilha",
        generate: "Gerar sequência",
        quantity: "Quantidade",
        quantityHint: "Diga quantas pilhas você deseja gerar",
      },
      saved: "Salvo com sucesso!",
      select: "Selecione as células para excluí-las",
      new: "Nova Pilha",
    },
    origin: {
      name: "Origens",
      title: "Origens registradas",
      newBtn: "Nova origem",
      desc: "Descrição",
      actions: "Ações",
      editBtn: "Editar origem",
      delBtn: "Excluir origem",
      delete: {
        title: "Excluir esta origem",
        text: "Deseja realmente excluir esta origem?",
      },
      modal: {
        desc: "Nome ou descrição",
        descHint: "Descrição da origem",
      },
      empty: "Os campos obrigatórios estão vazios.",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
      new: "Nova origem",
      update: "Atualização de origem",
    },
    hvi: {
      name: "Layouts de arquivo HVI",
      title: "Layouts cadastrados",
      newBtn: "Novo",
      desc: "Descrição",
      columns: "Layout das colunas",
      edit: "Editar layout",
      del: "Excluir layout",
      delete: {
        title: "Excluir este layout de arquivo HVI",
        text: "Você realmente deseja excluir este layout de arquivo HVI?",
      },
      columnsModal: {
        text: "Alterar valores diretamente dentro das células da tabela",
        parameter: "Parâmetro",
        position: "Posição da coluna",
        multiplier: "Multiplicador",
      },
      modal: {
        name: "Nome do layout",
        nameHint: "Nome do layout",
      },
      saved: "Salvo com sucesso!",
      empty: "Os campos obrigatórios estão vazios.",
      deleted: "Excluído com sucesso!",
      new: "Novo layout HVI",
      update: "Atualização de Layout HVI",
      layoutSettings: "Configurações de Layout HVI",
    },
    user: {
      name: "Usuários",
      title: "Usuários registrados",
      newBtn: "Novo usuário",
      fullname: "Nome",
      editBtn: "Editar usuário",
      delBtn: "Deletar usuário",
      delete: {
        title: "Excluir este usuário",
        text: "Você realmente deseja excluir este usuário?",
      },
      modal: {
        basic: "Básico",
        advanced: "Avançado",
        manager: "Gerente",
        username: "Username",
        usernameHint: "Nome de usuário para o login",
        email: "E-mail",
        emailHint: "E-mail do usuário",
        firstName: "Nome",
        firstNameHint: "Nome do usuário",
        lastName: "Sobrenome",
        lastNameHint: "Sobrenome do usuário",
        user: "Privilégios do usuário",
        important:
          "Importante: A senha padrão é 'change_me'. Ao fazer login no aplicativo pela primeira vez, o usuário será solicitado a alterar a senha inserindo esta senha padrão.",
      },
      new: "Novo usuário",
      update: "Atualizar usuário",
      saved: "Salvo com sucesso!",
      deleted: "Excluído com sucesso!",
    },
  },
  inventory: {
    cottonInput: {
      name: "Entrada de algodão",
      supplier: "Fornecedor",
      select: "Selecionar...",
      title: "Lotes cadastrados do fornecedor",
      newBtn: "Novo lote",
      balesBtn: "Fardos do lote",
      editBtn: "Editar informações do lote",
      delBtn: "Excluir o lote",
      lot: "Lote",
      baleQty: "Quantidade de fardos",
      issueDate: "Data de emissão",
      arrival: "Data de chegada",
      avg: "Peso médio do fardo",
      net: "Peso líquido",
      cottonType: "Tipo de algodão",
      origin: "Origem",
      producer: "Produtor",
      weight: "Peso",
      selectASupplier: "Por favor, selecione um fornecedor",
      delete: {
        title: "Excluir lote",
        text: "Deseja mesmo deletar este lote?",
      },
      modal: {
        new: "Novo lote",
        update: "Atualizar lote",
        lot: "ID do lote",
        lotHint: "ID do lote",
        amount: "Quantidade de fardos",
        amountHint: "Número de fardos no lote",
        gross: "Peso bruto",
        grossHint: "Número de fardos no lote",
        net: "Peso líquido",
        netHint: "Número de fardos no lote",
        producer: "Nome do produtor",
        producerHint: "O produtor atribuído ao lote",
        cottonType: "Tipo de algodão",
        cottonTypeHint: "O tipo de algodão atribuído ao lote",
        origin: "Origem",
        originHint: "A origem atribuída ao lote",
        issue: "Data de emissão",
        issueHint: "Data de emissão da nota fiscal",
        arrival: "Data de chegada",
        arrivalHint: "Data de entrada do lote na fábrica",
        name: "Nome do classificador",
        nameHint: "Nome ou ID do classificador do algodão antes da compra",
        purchase: "Número do contrato de compra",
        purchaseHint: "ID do contrato de compra atribuído ao lote",
        lotId: "ID do lote de compra",
        lotIdHint: "ID do lote de compra atribuído ao lote",
      },
      falied: "Falha ao carregar os dados dos fornecedores",
      falied2: "Falha ao carregar dados do tipo algodão",
      falied3: "Falha ao carregar dados de origem",
      falied4: "Falha ao carregar os dados dos produtores",
      falied5: "Falha ao carregar os dados do lote",
      deleted: "Excluído com sucesso",
      empty: `Os campos obrigatórios estão vazios.`,
      lotSuccessfully: `Lote cadastrado com sucesso`,
      lotAltered: `Lote alterado com sucesso`,
    },
    balesInput: {
      lot: "ID do lote:",
      supplier: "Fornecedor:",
      file: "Do arquivo",
      manually: "Manualmente",
      registered: "Fardos registados",
      add: "Novos fardos",
      avg: "Atribuir qualidade média",
      del: "Excluir fardos",
      weight: "Peso",
      delete: {
        title: "Excluir fardos",
        text: "Você realmente deseja excluir os fardos selecionados?",
      },
      noBales: "Nenhum fardo encontrado",
      pile: "ID da pilha",
      byAvg: {
        title: "Atribuir qualidade média aos fardos",
        text: "Deseja realmente atribuir a qualidade média do lote aos fardos que não contenham valores de qualidade?",
      },
      insert: {
        title: "Inserir fardos manualmente",
        bale: "ID do fardo",
        generate: "Gerar vários fardos automaticamente",
        quantity: "Quantidade",
        quantityHint: "Quantidade de fardos a serem gerados automaticamente",
      },
      selectedBales: "Os fardos selecionados foram excluídos!",
      classified: "Fardos classificados por média",
      added: `Fardo adicionado com sucesso`,
      readFile: {
        readFile: "Ler arquivo",
        bales: "Fardos - Importação",
        lot: "ID do lote:",
        supplier: "Fornecedor:",
        layout: "Layout",
        select: "Selecionar layout correspondente",
        insert: "Inserir fardos",
        balesFile: "Fardos no arquivo",
        within: "Valor dentro do intervalo do parâmetro",
        out: "Valor fora da faixa do parâmetro",
        failed:
          "Você tem parâmetros com colunas incorretas, corrija o registro Layout de HVI",
        empty: "você tem uma célula vazia na coluna",
        registered: "Fardos cadastrados com sucesso",
      },
    },
    managementBales: {
      name: "Gerenciamento do estoque - Fardos",
      filters: "Situação",
      select: "Selecionar...",
      bales: "Fardos",
      changePile: "Alterar pilha",
      turn: "Tornar disponível",
      put: "Colocar em espera",
      noBales: "Nenhum fardo encontrado",
      idBales: "Fardo",
      status: "Status",
      statusAvailable: "Disponível",
      statusOnHold: "Em espera",
      supplier: "Fornecedor",
      lotInvoiceID: "Lote",
      pileID: "Pilha",
      weight: "Peso",
      arrivalDate: "Data de entrada",
      producer: "Produtor",
      cottontypeName: "Tipo de algodão",
      originName: "Origem",
      modal: {
        text: "Selecionar pilha de destino",
        textHint: "Selecione a pilha de destino para os fardos",
      },
      all: "Todos",
      running: "Em uso",
      distribution: "Disponível para distribuição",
      tested: "Totalmente classificado",
      quality: "Sem qualidade",
      hold: "Em espera",
      failed: "Falha ao carregar os dados da pilha",
      failed2: "Falha ao carregar os dados dos fardos",
      successfully: "Pilha alterada com sucesso!",
      forUse: "Fardos em espera para uso!",
      availableforUse: "Fardos disponíveis para uso!",
    },
    managementLots: {
      name: "Gerenciamento do estoque - Lotes",
      lots: "Lotes",
      change: "Alterar pilha",
      turn: "Tornar disponível",
      put: "Colocar em espera",
      transfer: "Transferência de fábrica",
      lotId: "Lote",
      status: "Status",
      statusAvailable: "Disponível",
      statusOnHold: "Em espera",
      baleQty: "Quantidade de fardos",
      pile: "Pilha",
      origin: "Origem",
      producer: "Produtor",
      arrivalDate: "Data de chegada",
      netWeight: "Peso líquido",
      filters: "Situação",
      select: "Selecionar...",
      selectPile: "Selecione a pilha de destino",
      selectPileHint: "Selecione a pilha de destino para os fardos",
      selectPlant: "Selecione a fábrica de destino",
      selectPlantHint: "Selecione a pilha de destino para os fardos",
      changePile: "Alterar pilha",
      changePlant: "Alterar fábrica",
      all: "Todos",
      running: "Em uso",
      distribution: "Disponível para distribuição",
      tested: "Totalmente classificado",
      quality: "Sem qualidade",
      hold: "Em espera",
      failed: "Falha ao carregar os dados da pilha",
      lot: "O lote da pilha foi trocado",
      availableAlert: `Lote disponível para uso`,
      holdAlert: "Lote em espera para uso!",
    },
    distribution: {
      name: "Distribuição de algodão",
      new: "Nova distribuição manual",
      new2: "Nova distribuição automática",
      mixTypeDescription: "Tipo de mistura",
      classificationPriority: "Prioridade de classificação",
      monthlyConsumption: "Consumo mensal",
      monthlyConsumptionPercentage: "Porcentagem de consumo mensal",
      runningTonsQty: "Quantidade em uso",
      minimumTonsRequired: "Mínimo necessário",
      minimumVsAvailableInventory: "Porcentagem de estoque em uso vs mínimo",
      date: "Data",
      originPileID: "ID da pilha de origem",
      last: "Últimas distribuições de algodão",
      lots: "Lotes incluídos",
      noData:
        "Clique em 'Últimas distribuições' ao lado para visualizar os lotes distribuídos",
      newPileID: "Nova pilha",
      baleQty: "Quantidade de fardos",
      netWeight: "Peso líquido",
      currentStatusDescription: "Descrição do status atual",
      inventory: "Visão geral do estoque",
      delete: {
        title: "Excluir esta distribuição",
        text: "Deseja mesmo reverter este lote distribuído?",
        text2: "Você realmente deseja excluir esta distribuição?",
      },
      canceled: `Transferência cancelada`,
      lotTransfer: `Transferência de lote cancelada`,
      byMixType: "Qualidade por tipo de mistura",
      byPile: "Qualidade por pilha",
      theSelectedPile: "Lotes na pilha selecionada",
      byOrigin: "Quantidade por origem",
      byProducer: "Quantidade por produtor",
      statusAvailable: "Disponível",
      statusUsedInLaydown: "Uado na mistura",
    },
    autoDistribution: {
      name: "Distribuição automática",
      generate: "Elaboração",
      confirmBtn: "Confirmar lotes selecionados",
      generateBtn: "Gerar distribuição automática",
      mixTypeQuality: "Perspectiva de qualidade do tipo de mistura",
      inventoryQuality: "Qualidade do estoque",
      parameter: "Parâmetro",
      priority: "Prioridade",
      minimum: "Mínimo",
      maximum: "Máximo",
      expected: "Esperado",
      achieved: "Alcançado",
      itemQty: "Quantidade de fardos",
      save: "Salvar",
      cancel: "Cancelar",
      baleQty: "Quantidade de fardos",
      loteID: "ID do lote",
      cottonTypeName: "Tipo de algodão",
      originName: "Origem",
      producerName: "Produtor",
      supplierName: "Fornecedor",
      weight: "Peso [kg]",
      alert: "Você precisa selecionar os lotes a serem transferidos",
      transfered: `Lotes transferidos com sucesso`,
      totalInventory: "Estoque total",
      running: "Em uso",
      availableInventory: "Disponível para distribuição",
      suggested: "Lotes sugeridos",
      runningSuggested: "Em uso + lotes sugeridos (projeção)",
    },
    manualDistribution: {
      originPile: "Pilha de origem",
      mixType: "Tipo de mistura",
      name: "Distribuição manual",
      makeBtn: "Fazer distribuição manual",
      lotID: "ID do lote",
      producerName: "Produtor",
      destinationPile: "Pilha de destino",
      qty: "Quantidade de fardos",
      weight: "Peso",
      itemQty: "Quantidade de fardos",
      origin: "Origem",
      transferred: `Lotes selecionados foram transferidos`,
      update: "Distribuição manual",
      theSelectedPile: "Lotes na pilha selecionada",
      byPile: "Qualidade por pilha",
      byMixType: "Qualidade por tipo de mistura",
      byOrigin: "Quantidade por origem",
      byProducer: "Quantidade por produtor",
      totalInventory: "Estoque total",
      running: "Em uso",
      availableInventory: "Estoque disponível",
      suggested: "Pilha selecionada",
      runningSuggested: "Em uso + pilha selecionada (projeção)",
      selectPileMsg: "Informe a pilha de destino para cada lote na lista.",
    },
    pileGroup: {
      name: "Controle de uso de algodão - Grupos de pilha",
      title: "Grupos de pilhas da linha de produção",
      newBtn: "Novo grupo de pilhas",
      prodline: "Linha de produção",
      select: "Selecionar",
      balesPerLaydown: "Quantidade de fardos por mistura:",
      prodLine: "Por favor, selecione uma linha de produção",
      groupId: "ID do grupo",
      desc: "Descrição",
      active: "Ativo",
      qtyBales: "Quantidade de fardos",
      usage: "Porcentagem de uso",
      delete: {
        title: "Excluir esta pilha de grupo",
        text: "Você realmente deseja excluir este grupo de pilhas?",
      },
      modal: {
        value: "Mostrar apenas pilhas com fardos",
        value2: "Mostrar todas as pilhas",
        prodline: "Linha de produção:",
        amount: "Quantidade de fardos por mistura:",
        represents: "1 fardo representa",
        of: "da mistura",
        groupName: "Nome do grupo",
        groupNameHint: "Descrição da origem",
        perLaydown: "Fardos por mistura",
        active: "Ativo",
        representation: "Representação do grupo (%)",
        laydown: "Mistura",
        inventory: "Estoque",
        piles: "Representação de pilhas fora do grupo (%)",
        pileID: "ID da pilha",
        groupID: "ID do grupo",
        percentage: "Porcentagem",
        weight: "Peso",
        baleQty: "Quantidade de fardos",
      },
      saved: "Salvo com sucesso",
      greater: "A soma da quantidade de fardos é maior que 100%",
      deleted: `Excluído com sucesso`,
      new: "Novo grupo de pilhas",
      update: "Atualizar grupo de pilhas",
    },
    cottonTypes: {
      name: "Controle de uso de algodão - Tipos de algodão",
      controlled: "Tipos de algodão controlados",
      newBtn: "Novo tipo de algodão controlado",
      mixType: "Tipo de mistura",
      select: "Selecionar",
      quantity: "Quantidade de fardos por mistura:",
      sum: "Soma:",
      thePercentage: "(O percentual deve ser igual a 100%)",
      cottonTypeName: "Tipo de algodão",
      baleQty: "Quantidade de fardos",
      usage: "Porcentagem de uso",
      noData: "Por favor, selecione um tipo de mistura",
      delete: {
        title: "Excluir este tipo de algodão",
        text: "Você realmente deseja excluir esse tipo de algodão?",
      },
      modal: {
        mixType: "Tipo de mistura:",
        amount: "Quantidade de fardos por mistura:",
        represents: "1 fardo representa na mistura",
        sum: "Soma de todos os tipos de algodão controlados ma mistura:",
        cottonType: "Tipo de algodão",
        select: "Selecionar...",
        bales: "Fardos por mistura",
        balesHint: "Quantidade de fardos",
      },
      updated: "Tipo de algodão atualizado com sucesso",
      quantitysum: "A soma da quantidade de fardos é maior que 100%",
      deleted: "Excluído com sucesso",
      new: "Novo tipo de algodão",
      update: "Atualizar tipo de algodão",
      inserted: "Tipo de algodão inserido com sucesso",
      registered: `Tipo de algodão já cadastrado`,
    },
    importantion: {
      name: "Entrada de resultados de HVI - Importação",
      layout: "Layout",
      select: "Selecionar layout correspondente",
      confirm: "Importar resultados",
      file: "Fardos no arquivo",
      within: "Valor dentro do intervalo do parâmetro",
      out: "Valor fora da faixa do parâmetro",
      noFiles: "Nenhum arquivo selecionado",
      haveParameters:
        "Você tem parâmetros com colunas incorretas, corrija o registro da coluna HVI",
      empty: "você tem uma célula vazia na coluna",
      saved: `Fardos salvos`,
    },
    manualInputs: {
      name: "Entrada de resultados de teste - Lançamento manual",
      same: "Mesmos valores para todos",
      individual: "Valores individuais",
      status: "Status",
      select: "Selecionar...",
      supplier: "Fornecedor",
      lot: "Lote",
      bales: "Fardos",
      confirm: "Salvar",
      pileID: "ID da pilha",
      all: "Todos",
      pending: "Pendente",
      saved: "Salvo com sucesso!",
    },
    consultBale: {
      name: "Informações do fardo",
      enter: "Digite o ID do fardo",
      search: "Pesquisar",
      data: "Dados do fardo",
      status: "Status",
      bale: "ID do fardo",
      cottonType: "Tipo de algodão",
      origin: "Origem",
      pile: "Pilha",
      producer: "Produtor",
      supplier: "Fornecedor",
      quality: "Qualidade do fardo",
      parameter: "Parâmetro",
      value: "Valor",
      stock: "Fardo não encontrado no estoque",
    },
  },
  mixing: {
    planning: {
      name: "Planejamentos de qualidade",
      title: "Últimos planejamentos de qualidade",
      newBtn: "Novo planejamento",
      details: "Ver detalhes",
      generate: "Gerar planejamento",
      edit: "Editar planejamento",
      del: "Excluir planejamento",
      number: "Número do planejamento",
      date: "Data de planejamento",
      mixtype: "Tipo de mistura",
      lotChange: "Troca de lote",
      statusDesription: "Status",
      statusUsed: "Usado",
      statusCreated: "Criado",
      delete: {
        title: "Excluir este planejamento",
        text: "Deseja realmente excluir este planejamento?",
      },
      modal: {
        new: "Novo planejamento",
        update: "Atualizar planejamento",
        mixtype: "Tipo de mistura",
        select: "Selecionar...",
        lotChange: "Troca de lote de produção?",
        quality: "Qualidade planejada",
        noDataText: "Planejamento ainda não foi gerado",
      },
      quality: {
        parameter: "Parâmetro",
        value: "Valor",
      },
      elaboration: {
        name: "Planejamento de qualidade - Planejar",
        elaboration: "Elaboração",
        planID: "ID do planejamento",
        mixType: "Tipo de mistura",
        quality: "Qualidade",
        noDataText: "Nenhum estoque disponível",
        parameterName: "Parâmetro",
        inventory: "Estoque atual",
        lastLaydown: "Última mistura",
        planned: "Planejado",
        difference: "Diferença de produção",
        info: "Informações de consumo",
        generate: "Gerar planejamento",
        no: "Sem prioridades",
        inventoryContext: "Estoque",
        laydown: "Mistura",
        current: "Estoque atual (fardos)",
        perDay: "Misturas por dia",
        perLaydown: "Fardos por mistura",
        totalBales: "Total de fardos por dia",
        remaining: "Estoque restante (fardos)",
        failed: "Falha ao gerar planejamento",
        successfully: "Planejamento gerado com sucesso!",
      },
      added: "Novo planejamento adicionado com sucesso!",
      deleted: "Excluído com sucesso!",
    },
    pre: {
      name: "Pré-misturas",
      title: "Últimas pré-misturas",
      newBtn: "Nova pré-mistura",
      generate: "Gerar pré-mistura",
      viewSelectedPilesHint: "Ver as pilhas selecionadas",
      editHint: "Alterar pré mistura",
      deleteHint: "Deletar pré mistura",
      id: "ID da pré-mistura",
      prodline: "Linha de produção",
      planning: "ID do planejamento",
      uses: "Usa pré-mistura",
      baleQty: "Quantidade de fardos",
      creationDate: "Data de criação",
      yarnLot: "Lote de fios",
      statusDesription: "Status",
      statusFinished: "Finalizado",
      statusInProcess: "Em processo",
      statusCreated: "Criado",
      delete: {
        title: "Excluir esta pré-mistura",
        text: "Você realmente deseja excluir esta pré-mistura?",
      },
      select: "Selecionar...",
      pileFor: "Pilhas para pré-mistura",
      noDataText: "Pré-mistura ainda não foi gerada",
      pileID: "ID da pilha",
      necessaryBaleQty: "Quantidade de fardos necessária",
      elaboration: {
        name: "Pré-mistura - Elaboração",
        elaboration: "Elaboração",
        pre: "ID da pré-mistura",
        prodline: "Linha de produção",
        generate: "Gerar pré-mistura",
        available: "Pilhas disponíveis",
        pileID: "ID da pilha",
        lotID: "ID do lote",
        pileGroupName: "Grupo de pilhas",
        baleQty: "Quantidade de fardos",
        minimumBaleQty: "Quantidade mínima de fardos",
        maximumBaleQty: "Quantidade máxima de fardos",
        definedBaleQty: "Quantidade de fardos definida",
        quality: "Qualidade",
        parameterName: "Parâmetro",
        priority: "Prioridade",
        inventoryQuality: "Estoque",
        plannedQuality: "Planejada",
        achievedQuality: "Alcançada",
        minimumAllowed: "Mínimo permitido",
        maximumAllowed: "Máximo permitido",
        tolerance: "Dentro da tolerância",
        up: "Up to 50% out of tolerance",
        more: "Até 50% fora da tolerância",
        selected: "Pilhas selecionadas",
        necessaryBaleQty: "Quantidade de fardos necessária",
      },
      created: `Pré-mistura criada com sucesso`,
      saved: `Salvo com sucesso`,
      generated: "Pré-mistura gerada com sucesso!",
      new: "Nova pré-mistura",
      update: "Atualizar pré-mistura",
    },

    laydown: {
      name: "Mistura",
      title: "ID da pré-mistura",
      select: "Selecionar...",
      laydownID: "ID da mistura",
      thePriority: "Selecione a prioridade",
      prodLine: "Linha de produção",
      generalIdentifier: "ID geral",
      baleQty: "Quantidade de fardos",
      creationDate: "Data de criação",
      changeDate: "Data de alteração",
      consumptionDate: "Data de consumo",
      status: "Status",
      newBtn: "Nova mistura",
      balesAndQuality: "Fardos e qualidade",
      cottontypeSummary: "Resumo por tipo de algodão",
      layoutPosition: "Layout da mistura",
      position: "Posição",
      leftoverBales: "Fardos na pré mistura",
      originSummary: "Resumo por origem",
      pileSummary: "Resumo por pilha",
      producerSummary: "Resumo por produtor",
      laydownCharts: "Gráficos da mistura",
      balesBtn: "Fardos",
      layoutBtn: "Layout",
      delBtn: "Excluir",
      finishBtn: "Finalizar",
      resumeBtn: "Reabrir",
      laydownBales: "Fardos da mistura",
      bale: "Fardo",
      pile: "Pilha",
      laydownQuality: "Qualidade da mistura",
      parameter: "Parâmetro",
      planned: "Planejado",
      achieved: "Alcançado",
      minimum: "Mínimo",
      maximum: "Máximo",
      cv: "CV",
      whitin: "Dentro da tolerancia",
      out: "Fora até 50%",
      over: "Fora mais de 50%",
      reporting: "Relatórios",
      statusFinished: "Finalizado",
      statusInProcess: "Em processo",
      statusCreated: "Criado",
      bales: {
        bales: "Fardos",
        name: "Mistura - Fardos",
        preLaydown: "ID da pré-mistura",
        laydownId: "ID da mistura",
        prodLine: "Linha de produção",
        generate: "Gerar msitura",
        addSingle: "Número do fardo",
        enterBale: "Digite o ID do fardo...",
        includeBale: "Incluir fardo",
        addBales: "Adicionar fardos via arquivo",
        select: "Selecionar o layout correspondente",
        balesAdded: "Fardos adicionados à pré-mistura",
        leftover: "Incluir sobra",
        leftoverhint:
          "Adiciona sobras de fardos da última pré-mistura à pré-mistura atual.",
        remove: "Remover sobra",
        removeHint:
          "Remove todos os fardos restantes da pré-mistura atual, mas mantém os fardos na lista de sobras.",
        removeAllocated: "Remover alocado",
        removeAllocated2: "Você realmente deseja remover alocado?",
        selected: "Remover selecionado",
        selectedHint:
          "Remover um ou mais fardos da lista atual de pré-mistura e também da pré-mistura.",
        excludeBales: "Excluir fardos",
        excludeBales2: "Você realmente deseja excluir fardos?",
        perPile: "Quantidade necessária de fardos por pilha",
        print: "Imprimir",
        pile: "Pilha",
        allocated: "Alocado",
        necessary: "Necessário",
        missing: "Faltando",
        sum: "Soma",
        required: "Quantidade necessária alcançada",
        lotsInThePile: "Lotes na pilha",
        laydownQuality: "Qualidade da pré-mistura",
        parameter: "Parâmetro",
        planned: "Planejado",
        achieved: "Alcançado",
        minimum: "Mínimo",
        maximum: "Máximo",
        cv: "CV%",
        whitin: "Dentro da tolerancia",
        out: "Fora até 50%",
        over: "Fora mais de 50%",
      },
      generate: {
        generateBtn: "Gerar Mistura",
        generation: "Elaboração",
        name: "Mistura - Elaboração",
        prodline: "Linha de produção",
        laydown: "ID da mistura",
        prelaydown: "Quantidade de fardos na pré-mistura",
        laydownBales: "Quantidade de fardos na mistura",
        laydownQuality: "Qualidade da mistura",
        priority: "Prioridade",
        parameter: "Parâmetro",
        planned: "Planejado",
        achieved: "Alcançado",
        minimum: "Mínimo",
        maximum: "Máximo",
        cv: "CV%",
        whitin: "Dentro da tolerancia",
        out: "Fora até 50%",
        over: "Fora mais de 50%",
        controlled: "Grupos controlados",
        notControlled: "Não controlado",
        cvControl: "Controle CV",
        noDataText: "Não há grupos de pilhas cadastrados",
        noParametersCVControlled: "Não há parametros com CV controlado",
        pileGroupID: "ID",
        description: "Descrição",
        minimumExpected: "Mínimo esperado",
        maximumExpected: "Máximo esperado",
        achievedQty: "Quantidade alcançada",
        preLaydownQty: "Quantidade na pré-mistura",
        notAchieved: "Não alcançado",
        inThePrelaydown: "Fardos no pré-mistura",
        baleID: "Fardo",
        pileID: "Pilha",
        producer: "Produtor",
        selectedToLaydown: "Fardos selecionados para a mistura",
      },
      layout: {
        layout: "Layout",
        name: "Mistura - Geração de layout",
        prodline: "Linha de produção",
        laydown: "ID da mistura",
        balesQty: "Quantidade de fardos da mistura",
        balesQty2: "Quantidade de fardos de sub-mistura",
        generateBtn: "Gerar layout",
        selected: "Fardos selecionados",
        baleID: "Fardo",
        layoutPosition: "Posição na mistura",
        subLaydownID: "Sub-mistura",
        pileID: "Pilha",
        weight: "Peso",
        laydownQuality: "Qualidade da mistura",
        parameter: "Parâmetro",
        achieved: "Alcançado",
        sublaydownQuality: "Qualidade das sub-misturas",
        groupDescrption: "Descrição do grupo",
        whitin: "Dentro da tolerancia",
        out: "Fora até 50%",
        over: "Fora mais de 50%",
        noPriorities: "sem prioridades",
        producer: "produtor",
        origin: "origem",
      },
      confirm: {
        title: "Consumo da mistura",
        text: "Deseja confirmar o consumo?",
        title2: "Excluir o layout",
        text2: "Deseja realmente excluir esta mistura?",
        title3: "Finalizar a mistura",
        text3: "Você realmente quer finalizar esta mistura?",
        title4: "Reabrir a mistura",
        text4: "Deseja mesmo reabrir esta mistura?",
      },
      alert1:
        "Você tem parâmetros com colunas incorretas, corrija o registro da coluna HVI",
      alert2:
        "Todos os fardos foram removidos da pré-mistura atual e sobra da mistura",
      alert3:
        "Todos os fardos foram removidos da pré-mistura atual, mas mantém os fardos na sobra da mistura.",
      alert4: "Selecionar fardos para excluí-los",
      alert5:
        "Os fardos selecionados foram removidos tanto da pré-mistura atual quanto da sobra da mistura.",
      alert6: `Fardos incluídos na pré-mistura`,
      alert7:
        "Os fardos restantes da última pré-mistura foram adicionados da pré-mistura atual.",
      alert8: `Lista de pilhas para a mistura`,
      alert9: "Mistura selecionada",
      alert10: "Mistura gerada com sucesso!",
      alert11: "Gerado com sucesso!",
    },
    reports: {
      reporting: "Relatórios",
      balesAndQuality: {
        name: "Relatório de mistura - Fardos e qualidade",
        balesAndQuality: "Fardos e qualidade",
        preLaydownId: "ID da pré-mistura",
        laydownId: "ID da mistura",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Qualidade`,
        parameter: "Parâmetro",
        avg: "Média",
        cv: "CV",
        maximum: "Máximo",
        minimum: "Mínimo",
        sd: "Sd",
        baleID: "ID do fardo",
        cottonTypeName: "Tipo de algodão",
        producerName: "Produtor",
        lotID: "ID do lote",
        pileID: "ID da pilha",
        weight: "Peso",
        export: "Exportar tudo",
      },
      cottonTypeSummary: {
        name: "Relatório de mistura - Resumo por tipo de algodão",
        cottonTypeSummary: "Resumo por tipo de algodão",
        cottonType: "Tipo de algodão",
        preLaydownId: "ID da pré-mistura",
        laydownId: "ID da mistura",
        nameDg: "Nome",
        weight: "Peso",
        baleQty: "Quantidade de fardos",
        totalBales: "Total de Fardos",
        totalWeight: "Peso Total",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Resumo por tipo de algodão`,
        percentage: "Porcentagem",
        compositionPercentage: "Porcentagem de composição",
      },
      laydownCharts: {
        laydownChart: "Gráfico de Mistura",
        reporting: "Gráfico de Mistura - Gráficos de médias",
        bale: "Fardo",
        avg: "Média da mistura",
        preLaydownId: "ID da pré-mistura",
        laydownId: "ID da mistura",
        exportAll: "Exportar tudo",
      },
      layoutPosition: {
        layoutPosition: "Posição do layout",
        name: "Relatório de mistura - Layout",
        preLaydownId: "ID da Pré-mistura",
        laydownId: "ID da mistura",
        baleID: "ID do fardo",
        lotID: "ID do lote",
        pileID: "ID da pilha",
        weight: "Peso",
        subLaydownID: "ID da sub-mistura",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Layout`,
      },
      leftoverBales: {
        leftoverBales: "Fardos na pré mistura",
        name: "Relatório de mistura - Sobra de fardos",
        preLaydownId: "Id da pré-mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Sobra de fardos`,
        baleID: "ID do fardo",
        lotID: "ID do lote",
        cottonType: "Tipo de algodão",
        pileID: "ID da pilha",
        weight: "Peso",
        laydownDate: "Data da mistura",
        remove: "Remover fardos selecionados",
        removeHint: "Remover fardos selecionados da lista de fardos restantes.",
        removeMsg:
          "Deseja realmente remover os fardos selecionados da lista de sobras?",
        removeAll: "Remover tudo",
        removeAllHint: "Remover todos os fardos da lista de fardos restantes.",
        removeAllMsg:
          "Você realmente deseja remover todos os fardos da lista de sobras?",
      },
      originSummary: {
        originSummary: "Resumo por origem",
        name: "Relatório de mistura - Resumo por origem",
        preLaydownId: "Id da pré-mistura",
        laydownId: "ID da mistura",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Resumo por origem`,
        origin: "Origem",
        nameDg: "Nome",
        weight: "Peso",
        baleQty: "Quantidade de fardos",
        totalBales: "Total de fardos",
        totalWeight: "Peso total",
        percentage: "Porcentagem",
        compositionPercentage: "Porcentagem de composição",
      },
      pileSummary: {
        pileSummary: "Resumo por pilha",
        name: "Relatório de mistura - Resumo por pilha",
        preLaydownId: "Id da pré-mistura",
        laydownId: "ID da mistura",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        laydownReport: `Relatório de mistura - Resumo por pilha`,
        pile: "Pilha",
        nameDg: "Nome",
        weight: "Peso",
        baleQty: "Quantidade de fardos",
        totalBales: "Total de fardos",
        totalWeight: "Peso total",
        percentage: "Porcentagem",
        compositionPercentage: "Porcentagem de composição",
      },
      producerSummary: {
        producerSummary: "Resumo por produtor",
        name: "Relatório de mistura - Resumo por produtor",
        producer: "Produtor",
        preLaydownId: "Id da pré-mistura",
        laydownId: "ID da mistura",
        laydown: "Mistura",
        preLaydown: "Pré-mistura",
        nameDg: "Nome",
        weight: "Peso",
        baleQty: "Quantidade de fardos",
        totalBales: "Total de fardos",
        totalWeight: "Peso total",
        percentage: "Porcentagem",
        compositionPercentage: "Porcentagem de composição",
      },
    },
    reportsContext: {
      alert1: "Falha ao carregar dados da entrada de lotes",
      alert2: `Qualidade da mistura - Gráfico de Médias`,
      alert3: "Falha ao carregar dados de resumo do tipo algodão",
      alert4: "Falha ao carregar os dados do gráfico de mistura",
      alert5: `Qualidade da mistura - Gráfico de Fardos`,
      alert6: "Falha ao carregar os dados de posição do layout",
      alert7: "Falha ao carregar os dados de posição do layout",
      alert8: "Falha ao carregar dados de resumo do tipo algodão",
      alert9: "Falha ao carregar dados de resumo do tipo algodão",
      alert10: "Falha ao carregar dados de resumo do tipo algodão",
    },
    consumptionControl: {
      modalTitle: "Selecione a data",
      name: "Controle de consumo",
      prodline: "Linha de produção",
      select: "Selecionar...",
      status: "Status",
      thePriority: "Selecione a prioridade",
      laydowns: "Misturas",
      noDataText: "Selecione uma mistura e status",
      preLaydownID: "ID da pré-mistura",
      id: "ID da msitura",
      creationDate: "Data de criação",
      consumptionDate: "Data de consumo",
      statusDg: "Status",
      confirm: "Confirmar consumo",
      consumed: `Mistura consumida`,
      all: "Todos",
      pending: "Pendente",
    },
  },
  reports: {
    inventory: {
      bales: {
        name: "Fardos em estoque",
        title: "Fardos em estoque",
        status: "Situação",
        pile: "Pilha",
        bales: "Fardos",
        baleID: "ID do fardo",
        lotInvoiceID: "ID do lote",
        pileID: "Pilha ID",
        cottontype: "Tipo de algodão",
        weight: "Peso",
        arrivalDate: "Data de chegada",
        all: "Todos",
        running: "Em uso",
        availablefordistribution: "Disponível para distribuição",
        fullyTested: "Com qualidade",
        missingQuality: "Sem qualidade",
        hold: "Em espera",
        blocked: "Bloqueado",
        piles: "Nas pilhas",
        leftover: "Na pré mistura",
        failed: "Erro ao carregar os dados do fardo",
        qualitySummary: "Resumo da Qualidade",
      },
      composition: {
        name: "Composição do estoque",
        nameDg: "Nome/descrição",
        bales: "Fardos",
        weight: "Peso",
        percentage: "Porcentagem",
        total: "Total",
        grouping: "Tipo de agrupamento",
        select: "Selecionar...",
        status: "Situação",
        cottonType: "Tipo de algodão",
        producer: "Produtor",
        supplier: "Fornecedor",
        origin: "Origem",
        all: "Todos",
        hold: "Em espera",
        blocked: "Bloqueado",
        failed: "Erro ao carregar dados de composição",
      },
      general: {
        name: "Estoque geral",
        supplier: "Fornecedor",
        select: "Selecionar...",
        producer: "Produtor",
        lot: "Lote",
        pile: "Pilha",
        cottonType: "Tipo de algodão",
        prodline: "Linha de produção",
        bySupplier: "Agrupar por fornecedor",
        byCottontype: "Agrupar por tipo de algodão",
        byLot: "Agrupar por lote",
        byProducer: "Agrupar por produtor",
        byPile: "Agrupar por pilha",
        lotID: "ID do lote",
        cottonTypeName: "Tipo de algodão",
        pileID: "Pilha",
        bales: "Quantidade de fardos",
        failed: "Erro ao carregar dados de entrada de lotes",
        failed2: "Erro ao carregar os dados do fornecedor",
        failed3: "Erro ao carregar os dados do tipo algodão",
        failed4: "Erro ao carregar os dados de origem",
        failed5: "Erro ao carregar os dados do produtor",
        failed6: "Erro ao carregar os dados da linha de produção",
        failed7: "Erro ao carregar os dados do lote",
        failed8: "Erro ao carregar os dados da pilha",
        origin: "Origem",
        groupingType: "Tipo de agrupamento",
        groupedBy: "Agrupado por",
        startDate: "Data de início",
        finalDate: "Data final",
        weight: "Peso",
      },
      lotsEntredPerPeriod: {
        name: "Entrada de lotes por período",
        supplier: "Fornecedor",
        select: "Selecionar...",
        producer: "Produtor",
        origin: "Origem",
        cottonTypes: "Tipo de algodão",
        period: "Período",
        to: "a",
        lotId: "ID de lote",
        baleQty: "Quantidade de fardos",
        plantWeight: "Peso",
        title: "Entrada de lotes por período",
        failed: "Erro ao carregar os dados de entrada de lotes",
        failed2: "Erro ao carregar os dados do fornecedor",
        failed3: "Erro ao carregar os dados do tipo algodão",
        failed4: "Erro ao carregar os dados de origem",
        failed5: "Erro ao carregar os dados do produtor",
      },
      quality: {
        name: "Resumo da qualidade do estoque",
        prodline: "Linha de produção",
        select: "Selecionar...",
        baleQty: "Quantidade de fardos disponíveis",
        weight: "Peso",
        text: "Está limitando o estoque disponível para a linha de produção.",
        title: "Resumo da qualidade do estoque",
        failed: "Erro ao carregar os dados de entrada de lotes",
        failed2: "Erro ao carregar os dados do fornecedor",
        failed3: "Erro ao carregar os dados do tipo algodão",
        failed4: "Erro ao carregar os dados de origem",
        failed5: "Erro ao carregar os dados do produtor",
        failed6: "Erro ao carregar os dados da linha de produção",
        failed7: "Erro ao carregar os dados do lote",
        totalInventory: "Estoque total",
        availableInventory: "Estoque disponível",
        cottonOutsideGroups: "Fordos fora de grupos",
      },
    },
    consumpition: {
      avg: {
        name: "Gráficos de médias das misturas",
        prodline: "Linha de produção",
        select: "Selecionar...",
        yarnlot: "Lote de fios",
        period: "Período",
        to: "a",
        showBy: "Mostrar por",
        showTolerances: "Mostrar tolerâncias de",
        upperTolerance: "Tolerância superior",
        planned: "Planejado",
        laydownAvg: "ID da mistura",
        lowerTolerance: "Tolerência inferior",
        cv: "CV",
        average: "Média",
        production: "Producão",
        failed: "Erro ao carregar os dados dos gráficos de médias",
        failed2: "Erro ao carregar os dados da linha de produção",
        failed3: "Erro ao carregar os dados do lote de fios",
        inventoryAvg: "Média do estoque atual",
      },
      perPeriod: {
        name: "Composição do consumo por período",
        groupingType: "Tipo de agrupamento",
        select: "Selecionar...",
        prodline: "Linha de produção",
        period: "Período",
        to: "a",
        nameDg: "Nome",
        bales: "Fardos",
        weight: "Peso",
        percentage: "Porcentagem",
        cottonType: "Tipo de algodão",
        producer: "Produtor",
        supplier: "Fornecedor",
        origin: "Origem",
        failed: "Erro ao carregar os dados de composição",
        failed2: "Erro ao carregar os dados da linha de produção",
      },
      daily: {
        name: "Consumo de algodão por dia",
        groupingType: "Tipo de agrupamento",
        select: "Selecionar...",
        prodline: "Linha de produção",
        period: "Período",
        day: "Dia",
        bales: "Fardos",
        running: "Lotes em uso",
        totalWeight: "Peso",
        title: "Consumo de algodão por dia",
        cottonType: "Tipo de algodão",
        producer: "Productor",
        supplier: "Fornecedor",
        origin: "Origem",
        failed: "Erro ao carregar os dados diários",
        failed2: "Erro ao carregar os dados da linha de produção",
        failed3: "Erro ao carregar os dados do fornecedor",
        failed4: "Erro ao carregar os dados do tipo algodão",
        failed5: "Erro ao carregar os dados de origem",
        failed6: "Erro ao carregar os dados do produtor",
      },
      general: {
        name: "Consumo de algodão",
        cottonType: "Tipo de algodão",
        producer: "Produtor",
        supplier: "Fornecedor",
        select: "Selecionar...",
        prodline: "Linha de produção",
        period: "Período",
        to: "a",
        bySupplier: "Agrupar por fornecedor",
        byCottonType: "Agrupar por tipo de algodão",
        byPile: "Agrupar por pilha",
        byProducer: "Agrupar por produtor",
        byProdline: "Agrupar por linha de produção",
        byLaydown: "Agrupar por mistura",
        pile: "Pilha",
        laydown: "Mistura",
        baleQty: "Quantidade de fardos",
        failed: "Erro ao carregar os dados de entrada de lotes",
        failed2: "Erro ao carregar os dados do fornecedor",
        failed3: "Erro ao carregar os dados do tipo algodão",
        failed4: "Erro ao carregar os dados de origem",
        failed5: "Erro ao carregar os dados do produtor",
        failed6: "Erro ao carregar os dados da linha de produção",
        failed7: "Erro ao carregar os dados dos lotes",
        failed8: "Erro ao carregar os dados das pilhas",
        weight: "Peso",
        allProdLines: "Todos",
      },
      perMonthly: {
        name: "Consumo de algodão por mês",
        groupingType: "Tipo de agrupamento",
        select: "Selecionar...",
        prodline: "Linha de produção",
        period: "Período",
        to: "a",
        month: "Mês",
        bales: "Fardos",
        lotQty: "Lotes em uso",
        totalWeight: "Peso",
        title: "Consumo de algodão por mês",
        cottonType: "Tipo de algodão",
        producer: "Produtor",
        supplier: "Fornecedor",
        origin: "Origem",
        failed: "Erro ao carregar os dados mensais",
        failed2: "Erro ao carregar os dados da linha de produção",
        failed3: "Erro ao carregar os dados do fornecedor",
        failed4: "Erro ao carregar os dados do tipo algodão",
        failed5: "Erro ao carregar os dados de origem",
        failed6: "Erro ao carregar os dados do produtor",
      },
      perLaydown: {
        name: "Consumo de algodão por mistura",
        prodline: "Linha de produção",
        select: "Selecionar...",
        yarnLot: "Lote de fios",
        period: "Período",
        to: "a",
        consumptionDate: "Data de consumo",
        preLaydownID: "ID da pré-mistura",
        laydownID: "ID da mistura",
        baleQty: "Quantidade de fardos",
        totalWeight: "Peso",
        title: "Consumo de algodão por mistura",
        failed: "Erro ao carregar os dados da mistura",
        failed2: "Erro ao carregar os dados da linha de produção",
        failed3: "Erro ao carregar os dados dos lotes de fios",
      },
      usda: {
        name: "Color grade - USDA",
        preLaydown: "Pré-mistura",
        select: "Selecionar...",
        laydown: "Mistura",
        failed: "Erro ao carregar dados da mistura",
        failed2: "Erro ao carregar os dados da pré-mistura",
        failed3: "Erro ao carregar os dados do gráfico USDA",
      },
    },
  },
  warnings: {
    path: "Alertas",
    name: "Alertas de qualidade",
    status: "Status",
    select: "Selecionar...",
    pending: "Pendente",
    authorized: "Autorizado",
    title: "Alertas de qualidade",
    noDataText: "Nenhum alerta encontrado",
    productionLineName: "Linha de produção",
    preLaydownID: "Pré-mistura",
    laydownID: "Mistura",
    warningDate: "Data do alerta",
    authorizedUse: "Uso autorizado",
    infoHint: "Ver detalhes do alerta",
    checkHint: "Autorizar uso",
    confirm: {
      title: "Autorizar uso",
      text: "Deseja autorizar o uso deste laydown?",
    },
    modal: {
      title: "Detalhes do alerta",
      itemName: "Parâmetro",
      minimum: "Mínimo",
      maximum: "Máximo",
      achieved: "Alcançado",
    },
  },
  home: {
    path: "Início",
    user: "Usuário",
    access: "Acesso rápido",
    cottonInput: "Entrada de algodão",
    baleInfo: "Informações do fardo",
    planning: "Planejamento",
    pre: "Pré-mistura",
    laydown: "Mistura",
    avg: "Gráficos de médias",
    cottonType: "Composição do estoque por tipo de algodão",
    producer: "Composição do estoque por produtor",
  },
  user: {
    title: "Configurações do usuário",
    username: "Código d acesso",
    email: "E-mail",
    firstName: "Primeiro Nome",
    lastName: "Sobrenome",
    logout: "Sair",
    settings: "Configurações",
    profile: "Perfil",
    saved: "Salvo com sucesso!",
    empty: `Os campos obrigatórios estão vazios.`,
    passwordMsg:
      "Para alterar a senha, utilize a opção 'Esquecia a senha' na tela de login.",
  },
  preferences: {
    title: "Configurações",
    language: "Idioma",
    countrySettings: "Configurações de região",
    alerts: {
      emptyLanguage: "Selecione um idioma para salvar",
      emptyCountry: "Selecione uma região para salvar",
    },
    unitOfMesureKG: "Quilogramas",
    unitOfMesurePD: "Libras",
    unitOfMesure: "Unidade de medida para peso",
  },
  logs: {
    path: "Registros de ações",
    name: "Logs do usuário",
    new: "Novo",
    date: "Data",
    userName: "ID do usuário",
    message: "Mensagem",
  },
};

export { translations };
