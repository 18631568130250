/* eslint-disable no-sparse-arrays */
import { lazy } from "react";
import { withNavigationWatcher } from "./contexts/navigation";

const HomePage = lazy(() => import("./pages/Home"));
const PilePage = lazy(() => import("./pages/Register/Pile"));
const CottontypePage = lazy(() => import("./pages/Register/Cottontype"));
const OriginPage = lazy(() => import("./pages/Register/Origin"));
const SupplierPage = lazy(() => import("./pages/Register/Supplier"));
const ProducerPage = lazy(() => import("./pages/Register/Producer"));
const ProductionlinePage = lazy(() =>
  import("./pages/Register/Productionline")
);
const MixtypePage = lazy(() => import("./pages/Register/Mixtype"));
const ParametersPage = lazy(() => import("./pages/Register/Parameters"));
const ProductionlineclassesPage = lazy(() =>
  import("./pages/Register/Productionlineclasses")
);
const PlantPage = lazy(() => import("./pages/Register/Plant"));
const HVISpreadsheetPage = lazy(() =>
  import("./pages/Register/Hvispreadsheet")
);
const CottoninputPage = lazy(() => import("./pages/Inventory/cottoninput"));
const BaleinputPage = lazy(() =>
  import("./pages/Inventory/cottoninput/balesinput")
);
const LotsmanagementPage = lazy(() =>
  import("./pages/Inventory/management/lotsmanagement")
);
const PilegroupsPage = lazy(() =>
  import("./pages/Inventory/cottonusagecontrol/pilegroups")
);
const CottontypesPage = lazy(() =>
  import("./pages/Inventory/cottonusagecontrol/cottontypes")
);
const ReadfilePage = lazy(() =>
  import("./pages/Inventory/cottoninput/balesinput/readfile")
);
const BalemanagementPage = lazy(() =>
  import("./pages/Inventory/management/balemanagement")
);
const ImportationPage = lazy(() =>
  import("./pages/Inventory/testingresultsinput/importation")
);
const ManualinputPage = lazy(() =>
  import("./pages/Inventory/testingresultsinput/manualinput")
);
const AutocottondistributionPage = lazy(() =>
  import("./pages/Inventory/distribution/autocottondistribution")
);
const ConsultBalePage = lazy(() => import("./pages/Inventory/consultbale"));
const PlanningPage = lazy(() => import("./pages/Laydown/planning"));
const LaydownPage = lazy(() => import("./pages/Laydown/laydown"));
const PreLaydownPage = lazy(() => import("./pages/Laydown/prelaydown"));
const PlanningElaborationPage = lazy(() =>
  import("./pages/Laydown/planning/Elaboration")
);
const PreLaydownElaborationPage = lazy(() =>
  import("./pages/Laydown/prelaydown/Elaboration")
);
const LaydownBalesPage = lazy(() => import("./pages/Laydown/laydown/bales"));
const LaydownGeneratePage = lazy(() =>
  import("./pages/Laydown/laydown/generate")
);
const LaydownLayoutPage = lazy(() => import("./pages/Laydown/laydown/layout"));
const ConsumptionControlPage = lazy(() =>
  import("./pages/Laydown/consumptionControl")
);

const OwnerManagementPage = lazy(() => import("./pages/OwnerPage"));
const PerLaydownPage = lazy(() =>
  import("./pages/Reports/consumptionReports/perLaydown")
);
const AvgchartsPage = lazy(() =>
  import("./pages/Reports/consumptionReports/avgCharts")
);
const CompositionperPeriodPage = lazy(() =>
  import("./pages/Reports/consumptionReports/compositionPerPeriod")
);
const DailyPage = lazy(() =>
  import("./pages/Reports/consumptionReports/daily")
);
const LaydowChartsPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/laydownCharts")
);
const MonthlyPage = lazy(() =>
  import("./pages/Reports/consumptionReports/monthly")
);
const BalesPage = lazy(() => import("./pages/Reports/inventoryReports/bales"));
const CompositionPage = lazy(() =>
  import("./pages/Reports/inventoryReports/composition")
);
const GeneralConsumptionPage = lazy(() =>
  import("./pages/Reports/consumptionReports/general")
);
const GeneralInventoryPage = lazy(() =>
  import("./pages/Reports/inventoryReports/general")
);
const LotEntredPerPeriodPage = lazy(() =>
  import("./pages/Reports/inventoryReports/lotsEntredPerPeriod")
);
const QualityPage = lazy(() =>
  import("./pages/Reports/inventoryReports/quality")
);
const BalesAndQualityPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/balesAndQuality")
);
const CottontypeSummaryPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/cottotypeSummary")
);
const LayoutPositionPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/layoutPosition")
);
const LeftoverBalesPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/leftoverBales")
);
const OriginSummaryPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/originSummary")
);
const PileSummaryPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/pileSummary")
);
const ProducerSummaryPage = lazy(() =>
  import("./pages/Laydown/laydown/reports/producerSummary")
);
const UsdaRepresentationPage = lazy(() =>
  import("./pages/Reports/consumptionReports/usdaRepresentation")
);
const UserPage = lazy(() => import("./pages/Register/User"));
const LogsPage = lazy(() => import("./pages/Logs/logs"));
const DistributionPage = lazy(() => import("./pages/Inventory/distribution"));
const ManualCottonDistributionPage = lazy(() =>
  import("./pages/Inventory/distribution/manualcottondistribution")
);
const E500Page = lazy(() => import("./pages/Errors/500"));
const WarningsPage = lazy(() => import("./pages/Warnings"));

/*const E500Page = lazy(() => import("./pages"));
const ManualCottonDistributionPage = lazy(() => import("./pages")); */

const routes = [
  // Error router

  {
    path: "/500",
    component: E500Page,
  },
  // Home
  {
    path: "/Home",
    component: HomePage,
  },
  {
    path: "/",
    component: HomePage,
  },
  // owner mamagement
  {
    path: "/Ownermanagement",
    component: OwnerManagementPage,
  },

  //register routes
  {
    path: "/register/Pile",
    component: PilePage,
  },
  {
    path: "/register/Cottontype",
    component: CottontypePage,
  },
  {
    path: "/register/Origin",
    component: OriginPage,
  },
  {
    path: "/register/Supplier",
    component: SupplierPage,
  },
  {
    path: "/register/Producer",
    component: ProducerPage,
  },
  {
    path: "/register/Productionline",
    component: ProductionlinePage,
  },
  ,
  {
    path: "/register/Mixtype",
    component: MixtypePage,
  },
  {
    path: "/register/Parameters",
    component: ParametersPage,
  },
  {
    path: "/register/Productionlineclasses",
    component: ProductionlineclassesPage,
  },
  ,
  {
    path: "/register/Plant",
    component: PlantPage,
  },
  ,
  {
    path: "/register/Hvispreadsheet",
    component: HVISpreadsheetPage,
    role: "basic",
  },

  {
    path: "/register/User",
    component: UserPage,
    role: "manager",
  },

  //invnetory routes
  {
    path: "/inventory/Cottoninput",
    component: CottoninputPage,
  },
  {
    path: "/inventory/cottoninput/Baleinput",
    component: BaleinputPage,
  },
  {
    path: "/inventory/cottoninput/balesinput/Readfile",
    component: ReadfilePage,
  },
  {
    path: "/inventory/management/BaleManagement",
    component: BalemanagementPage,
  },
  {
    path: "/inventory/management/LotsManagement",
    component: LotsmanagementPage,
  },
  {
    path: "/inventory/Distribution",
    component: DistributionPage,
  },
  {
    path: "/inventory/distribution/ManualCottonDistribution",
    component: ManualCottonDistributionPage,
  },

  {
    path: "/inventory/distribution/autocottondistribution",
    component: AutocottondistributionPage,
  },

  {
    path: "/inventory/cottonusagecontrol/PileGroups",
    component: PilegroupsPage,
  },
  {
    path: "/inventory/cottonusagecontrol/CottonTypes",
    component: CottontypesPage,
  },
  {
    path: "/inventory/testingresultsinput/Importation",
    component: ImportationPage,
  },
  {
    path: "/inventory/testingresultsinput/manualInput",
    component: ManualinputPage,
  },
  {
    path: "/inventory/consultBale",
    component: ConsultBalePage,
  },

  // laydown routes

  {
    path: "/laydown/Planning",
    component: PlanningPage,
  },
  {
    path: "/laydown/planning/Elaboration",
    component: PlanningElaborationPage,
  },
  {
    path: "/laydown/preLaydown",
    component: PreLaydownPage,
  },
  {
    path: "/laydown/prelaydown/Elaboration",
    component: PreLaydownElaborationPage,
  },
  {
    path: "/laydown/Laydown",
    component: LaydownPage,
  },
  {
    path: "/laydown/consumptionControl",
    component: ConsumptionControlPage,
  },
  {
    path: "/laydown/laydown/Bales",
    component: LaydownBalesPage,
  },
  {
    path: "/laydown/laydown/Generate",
    component: LaydownGeneratePage,
  },
  {
    path: "/laydown/laydown/Layout",
    component: LaydownLayoutPage,
  },
  {
    path: "/Warnings",
    component: WarningsPage,
  },

  {
    path: "/laydown/laydown/reports/balesAndQuality",
    component: BalesAndQualityPage,
  },
  {
    path: "/laydown/laydown/reports/cottontypeSummary",
    component: CottontypeSummaryPage,
  },
  {
    path: "/laydown/laydown/reports/layoutPosition",
    component: LayoutPositionPage,
  },
  {
    path: "/laydown/laydown/reports/leftoverBales",
    component: LeftoverBalesPage,
  },
  {
    path: "/laydown/laydown/reports/originSummary",
    component: OriginSummaryPage,
  },
  {
    path: "/laydown/laydown/reports/pileSummary",
    component: PileSummaryPage,
  },
  {
    path: "/laydown/laydown/reports/producerSummary",
    component: ProducerSummaryPage,
  },
  {
    path: "/laydown/laydown/reports/laydownCharts",
    component: LaydowChartsPage,
  },

  //// REPORTS ROUTES

  {
    path: "/reports/consumptionReports/perLaydown",
    component: PerLaydownPage,
  },
  {
    path: "/reports/consumptionReports/avgCharts",
    component: AvgchartsPage,
  },
  {
    path: "/reports/consumptionReports/compositionPerPeriod",
    component: CompositionperPeriodPage,
  },
  {
    path: "/reports/consumptionReports/daily",
    component: DailyPage,
  },
  {
    path: "/reports/consumptionReports/general",
    component: GeneralConsumptionPage,
  },

  {
    path: "/reports/consumptionReports/monthly",
    component: MonthlyPage,
  },
  {
    path: "/reports/consumptionReports/usdaRepresentation",
    component: UsdaRepresentationPage,
  },

  {
    path: "/reports/inventoryReports/bales",
    component: BalesPage,
  },
  {
    path: "/reports/inventoryReports/composition",
    component: CompositionPage,
  },
  {
    path: "/reports/inventoryReports/general",
    component: GeneralInventoryPage,
  },
  {
    path: "/reports/inventoryReports/lotsEnteredPerPeriod",
    component: LotEntredPerPeriodPage,
  },
  {
    path: "/reports/inventoryReports/quality",
    component: QualityPage,
  },

  ///// LOG ROUTES

  {
    path: "/Logs",
    component: LogsPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
    role: route.role,
  };
});
