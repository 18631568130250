const translation = {
  userGuiding: {
    common: {
      roleRequired: "Nível de privilégio necessário para acessar esta página: ",
      roleBasic: "Basic",
      roleAdvanced: "Advanced",
      roleManager: "Manager",
      cancel: "Cancelar / sair.",
      save: "Salvar.",
    },

    introduction: {
      modalTitle: "Visão geral do ESSENTIALbale",
      tabOverview: {
        title: "Visão geral",
        description:
          "ESSENTIALbale é um sistema que controla com precisão as misturas de fardos de algodão utilizando todos os parâmetros de qualidade fornecidos pela HVI, além de outros parâmetros fornecidos pela empresa.",
        description2:
          "O principal objetivo do sistema é eliminar as variações entre as misturas, garantindo a produção de fios de alta qualidade.O sistema controla o inventário e a qualidade do algodão desde a chegada dos fardos até o consumo na sala de abertura permitindo, desta forma, a produção de grandes lotes de fios sem variação na qualidade.",
        description3:
          "Com uma metodologia única no mercado, o sistema elimina a separação do estoque em classes. Somente os dados HVI de cada fardo são necessários, com estas informações o software planeja e elabora as misturas controlando a variação e mantendo a uniformidade do processo.",
        description4:
          "ESSENTIALbale é um sistema totalmente flexível. O sistema foi projetado para se adaptar às mais diversas formas de trabalho, tanto no que diz respeito ao processo de mistura quanto ao gerenciamento dos estoques de algodão.",
      },
      tabOverflow: {
        title: "Fluxo geral do sistema",
        description: "Este é o fluxo geral do sistema:",
      },
      tabUserLevels: {
        title: "Níveis de privilégio de usuários",
        description:
          "Há 3 níveis de privilégios que podem ser atribuídos aos usuários no ESSENTIALbale:",
        field1: "Basic",
        fieldDescription1:
          "O usuário tem acesso à função de cadastro como produtores, fornecedores, origens, tipos de algodão, estacas, layouts de arquivos HVI. O usuário 'Basic' também pode gerenciar o estoque e gerar relatórios.",
        field2: "Advanced",
        fieldDescription2:
          "Além de todas as funções contidas no nível 'Basic', o usuário 'Advanced' pode acessar o registro de parâmetros, tipos de mistura, linhas de produção, classes de linha de produção, além de gerenciar todas as funções do processo de mistura.",
        field3: "Manager",
        fieldDescription3:
          "Além de todas as funções contidas nos níveis 'Basic' e 'Advanced', o usuário 'Manager' pode criar e gerenciar novas plantas, adicionar novos usuários, gerenciar avisos de qualidade, bem como verificar os registros de ação do usuário.",
        description2: "Para criar um novo usuário, acesse CADASTRO > USUÁRIOS.",
      },
      tabUserPreferences: {
        title: "Idioma e configurações regionais",
        description:
          "Cada usuário pode definir suas próprias configurações de idioma e regioão para aperfeiçoar a experiência de uso.",
        step1: "Clique no ícone ",
        step1_1:
          " no canto superior direito de qualquer página, e em seguida clique na opção 'Preferências'.",
        step2: "Defina as seguintes configurações:",
        field1: "Idioma",
        fieldDescription1: "Idioma usado nas páginas.",
        field2: "Configurações de região",
        fieldDescription2:
          "Configurações regionais para formatação de valores numéricos e datas.",
        field3: "Unidade de medida para peso",
        fieldDescription3:
          "Unidade de medida usada para formatação dos campos de peso.",
      },
    },

    plant: {
      modalTitle: "Ajuda - Unidades de produção",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Unidades de produção são os diferentes locais de produção. Uma fiação pode ter várias unidades de produção. Cada unidade de produção tem suas próprias linhas de produção e estoque. Algumas informações, por outro lado, são comuns a todas as unidades de produção cadastradas, como cadastro do produtor, parâmetros de qualidade, entre outros.",
        flow: "Este é o fluxo de trabalho da página de unidades de produção:",
      },
      tabAdd: {
        title: "Criando uma nova planta",
        step1: "Na página de cadastro de unidades, clique em 'Nova unidade'.",
        step2: "Preencha os campos",
        fieldDescription1: "Código da unidade.",
        fieldDescription2: "Nome ou descrição da unidade",
      },
      tabEdit: {
        title: "Alterando o nome da unidade",
        step1:
          "Na página de cadastro de unidades, encontre a unidade de produção desejada m clique em ",
        step2: "Insira o novo nome para a unidade.",
      },
      tabSettings: {
        title: "Definindo as configurações da unidade",
        step1:
          "Na página de cadastro de unidades, encontre a unidade de produção desejadaem clique em ",
        step2: "Altere as informações conforme desejado.",
        fieldDescription1:
          "Informe o peso mínimo permitido para um fardo. O peso pode ser difinido em quilos ou libras, dependendo das opções do usuário.",
        fieldDescription2:
          "Informe o peso máximo permitido para um fardo. O peso pode ser difinido em quilos ou libras, dependendo das opções do usuário.",
        fieldDescription3:
          "Marque esta opção se a unidade não testa 100% dos fardos na chegada do algodão.",
        fieldDescription4:
          "Marque esta opção a qualidade dos fardos que estão na pré mistura devem ser considerada ao fazer o planejamento de qualidade.",
      },
    },
    parameters: {
      modalTitle: "Ajuda - Parâmetros de qualidade",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Parâmetros de qualidade são as características físicas ou químicas do algodão. Os parâmetros mais comuns são aqules medidos por uma máquina HVI, contudo qualquer parâmetro que seja medido em números pode ser cadastrado e controlado pelo ESSENTIALbale nas misturas.",
        flow: "Este é o fluxo de trabalho da página de cadastro de parâmetros de qualidade:",
      },
      tabAdd: {
        title: "Criando um novo parâmetro manualmente",
        step1:
          "Na página de cadastro de parâmetros, clique em 'Adicionar', e em seguida selecione em 'Manualmente'.",
        step2: "Preencha os campos:",
        fieldDescription1:
          "Nome completo do parâmetro. Por exemplo: Micronaire",
        fieldDescription2:
          "Nome abreviado para o parâmetro. Por exemplo: Mic. O nome abreviado é o que aparecerá em qualquer lugar que o parâmetro seja usado no ESSENTIALbale",
        fieldDescription3:
          "Valor mínimo permitido para o parâmetro. Ao adicionar um fardo ao estoque, o sistema verificará se o valor do parâmetro é válido.",
        fieldDescription4:
          "Valor máximo permitido para o parâmetro. Ao adicionar um fardo ao estoque, o sistema verificará se o valor do parâmetro é válido.",
        fieldDescription5:
          "A amplitude é a diferença entre os valores mínimo e máximo mais comuns.",
        fieldDescription6:
          "Define o zoom aplicado para o parâmetro nos gráficos de média das misturas para uma melhor experiência do usuário. 1 é o valor mais comum. Contudo, o valor da escala para parâmetros com valores maiores deve ser consequentemente maior.",
        fieldDescription7:
          "Fator de multiplicação do parâmetro para cálculo do CSP.",
        fieldDescription8:
          "Fator de multiplicação do parâmetro para cálculo do SCI.",
        fieldDescription9:
          "Marque esta opção para atribuir qualidade média do estoque quando um fardo não conter valores definidos para este parâmetro na entrada.",
        fieldDescription10:
          "Marque esta opção se os resultados de qualidade deste parãmetro são inseridos manualmente pelo usuário.",
        fieldDescription11:
          "Marque esta opção se o coeficiente de variação (CV) do parâmetro deve ser controlado para evitar variações nas misturas.",
        fieldDescription12:
          "Marque esta opção no caso de o parâmetro ser o CSP ou SCI especificamente e sua valor ser calculado automaticamente pelo sistema.",
      },
      tabAddPreConfigured: {
        title: "Adicionando um parâmetro pré configurado.",
        step1:
          "Na página de cadastro de parâmetros, clique em 'Adicionar', e em seguida selecione 'Pré-configurado'.",
        step2:
          "Localize o parâmetro na lista de parâmetros disponíveis e clique em ' + '.",
      },
      tabEdit: {
        title: "Alterando um parâmetro",
        step1:
          "Na página de cadastro de parâmetros, localize o parâmetro na tabela de parâmetros cadastrados e clique em ",
        step2: "Altere os campos necessários.",
      },
      tabReordering: {
        title: "Definindo a ordem de visualização dos parâmetros",
        step1: "Na página de cadastro de unidades clique em 'Reordenar'",
        step2:
          "Clique sobre o parâmetros e arraste para a posição desejada. Os parâmetros serão serão exibidos na ordem definida aqui em todos os lugares dentro do ESSENTIALbale.",
      },
    },
    mixTypes: {
      modalTitle: "Ajuda - Tipos de mistura",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Os tipos de mistura englobam um conjunto de configurações que podem ser aplicados às linhas de produção. Uma fiação pode ter ter diferentes tipos de mistura, e cada tipo de mistura pode ter várias linhas de produção ligadas e ele.",
        flow: "Este é o fluxo de trabalho da página de cadastro de tipos de misturas:",
      },
      tabAdd: {
        title: "Criando novo tipo de mistura",
        step1:
          "Na página de cadastro de tipos de misturas, clique em 'Novo tipo de mistura'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Nome ou descrição do tipo de mistura.",
        fieldDescription2:
          "Código da classe ligada ao tipo de mistura. O código da classe é usado para definir quais pilhas estão disponíveis para uso nas linhas de produção ligadas ao tipo de mistura.",
        fieldDescription3:
          "Quantidade de lotes mínima necessárias para o processo de misturas. É usado apenas quando a opção 'Testagem parcial de fardos' no cadastro de unidades de produção for verdadeira.",
        fieldDescription4:
          "Estoque mínimo em dias necessário para o processo de misturas.",
        fieldDescription5:
          "Consumo mensal estimado para o tipo de mistura. Pode ser definido em quilogramas ou libras.",
      },
      tabEdit: {
        title: "Alterando tipo de mistura",
        step1:
          "Na página de cadastro de tipos de mistura, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2: "Altere os campos.",
      },
      tabDelete: {
        title: "Excluindo tipo de mistura",
        step1:
          "Na página de cadastro de tipos de mistura, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2:
          "Será possível excluir o tipo de mistura desde que nenhuma linha de produção esteja ligada à ele.",
      },
      tabAddQualityRange: {
        title: "Adicionando ranges de qualidade",
        pageDescription:
          "Os ranges de qualidade servem para difinir a qualidade aceita para o estoque do tipo de mitura na distribuição do algodão.",
        pageDescription2:
          "Quando há um range definido para algum parâmetro de qualidade, os lotes ou fardos com qualidade fora deste range não serão aceitos no estoque do tipo de mistura.",
        step1:
          "Na página de cadastro de tipos de mistura, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2: "Clique em 'Novo range de qualidade'.",
        step3: "Preencha os campos:",
        fieldDescription1: "Selecione o parâmetro de qualidade.",
        fieldDescription2: "Valor mínimo aceito para o parãmetro.",
        fieldDescription3: "Valor máximo aceito para o parãmetro.",
        fieldDescription4: "Tipo de classificação. As opções são:",
        option0: "Média do lote",
        optionDescription0:
          "Para considerar a qualidade média do lote como referência. Neste caso, se a média estiver dentro do range definido, o lote inteiro poderá ser adicionado ao estoque do tipo de mistura, mesmo que alguns fardos tenham valores fora do range definido.",
        option1: "Fardo à fardo",
        optionDescription1:
          "Para considerar a qualidade individual de cada fardo. Neste caso, o lote poderá ser fracionado e apenas os fardos que tiverem qualidade dentro do range definido poderão ser adicionados ao estoque do tipo de mistura.",
      },
      tabEditQualityRange: {
        title: "Alterando ranges de qualidade",
        step1:
          "Na página de cadastro de tipos de mistura, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2:
          "Na janela de ranges de qualidade, encontre o parâmetro na tabela de ranges cadastrados e clique em ",
        step3: "Altere os campos",
        fieldDescription1:
          "Opcionalmente, uma descrição curta pode ser adicionado no campo 'Motivo da mudança' para criação de um histórico de alterações.",
      },
      tabDeleteQualityRange: {
        title: "Excluindo ranges de qualidade",
        step1:
          "Na página de cadastro de tipos de mistura, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2:
          "Na janela de ranges de qualidade, encontre o parâmetro na tabela de ranges cadastrados e clique em ",
      },
    },
    productionLines: {
      modalTitle: "Ajuda - Linhas de produção",
      tabOverview: {
        title: "Visão geral",
        pageDescription: "As linhas de produção são as misturas ou aberturas.",
        flow: "Este é o fluxo de trabalho da página de cadastro de linhas de produção:",
      },
      tabAdd: {
        title: "Criando nova linha",
        step1:
          "Na página de cadastro de linhas de produção, clique em 'Nova linha de produção'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Indica se a linha de produção está ativa ou não.",
        fieldDescription2:
          "Indica se a linha usa pré mistura, ou seja, possui a etapa de pré mistura no processo de misturas.",
        fieldDescription3: "Tipo de mistura ao qual e linha está ligada.",
        fieldDescription4: "Nome ou descrição da linha de produção.",
        fieldDescription5: "Quantidade de fardos usada em cada mistura",
        fieldDescription6:
          "Quantidade de fardos usada na pré mistura. Geralmente 50% a mais em relação à quantidade de fardos definida para mistura",
        fieldDescription7:
          "Quantidade de fardos em cada sub-mistura. Sub-misturas são os pequenos grupos de fardos dentro de uma mesma mistura.",
        fieldDescription8:
          "Quantidade de misturas consumidas por dia nesta linha de produção.",
        fieldDescription9:
          "Quantidade de dias à considerar para controle da variação de qualidade das misturas.",
        fieldDescription10:
          "Código ou identificador da pré-mistura. Linhas de produção que comparitlham o mesmo estoque devem ter códigos de pré mistura iguais.  Caso contrário, o códigos de pré mistura devem ser diferentes.",
        fieldDescription11:
          "Código ou identificador do lote de produção. Linhas de produção que produzem o mesmo tipo de fio e qualidade devem ter o mesmo lote de produção.  Caso contrário, os lotes de produção devem ser diferentes.",
      },
      tabEdit: {
        title: "Alterando uma linha",
        step1:
          "Na página de cadastro de linhas de produção, encontre a linha na tabela de linhas cadastradas clique em ",
        step2: "Altere os campos.",
      },
      tabSettings: {
        title: "Configurando a qualidade",
        step1:
          "Na página de cadastro de linhas de produção, encontre a linha na tabela de linhas cadastradas clique em ",
        step2:
          "Altere os valores diretamente na células da tabela. As configurações de qualidade da linha devem ser definidas pela gerência de qualidade da fiação.",
        fieldDescription1:
          "Prioridade ou peso atribuído ao parâmetro no processo de misturas.",
        fieldDescription2:
          "Variação aceitável na qualidade média para o parâmetro considerando as últimas misturas. A quantidade de misturas em que a variação é controlada é determinada pela multiplicação do consumo diário pela quantidade de dias do controle de produção que foram determinados no cadastro da linha. Por exemplo, tendo um consumo de 1 mistura por dia e um controle de produção de 7 dias, teremos 7 misturas. Logo a variação da média de qualidade nas últimas 7 misturas deve estar dentro da tolerância definida aqui.",
        fieldDescription3:
          "Variação aceitável para o parãmetro, para determinar que o lote do foi produzido dever ser alterado. Quando a variação for maior que o aceitável o sistema gerará um alerta para alteração do lote e fio.",
        fieldDescription4:
          "Percentual de variação aceitável no coeficente de variação (CV) do parâmetro entre uma mistura e outra.",
        fieldDescription5:
          "Coeficente de variação (CV) máximo aceitável para o parâmetro na mistura.",
        fieldDescription6:
          "Indica se o sistema deve gerar um alerta quando a qualidade média do parâmetro ficar abaixo da variação aceitável.",
        fieldDescription7:
          "Indica se o sistema deve gerar um alerta quando a qualidade média do parâmetro ficar acima da variação aceitável.",
        fieldDescription8:
          "Valor mínimo permitido para um fardo de algodão à ser usado nas misturas desta linha de produção. Fardos com valores inferiores ao definido aqui não serão aceitos na pré mistura.",
        fieldDescription9:
          "Valor máximo permitido para um fardo de algodão à ser usado nas misturas desta linha de produção. Fardos com valores superiores ao definido aqui não serão aceitos na pré mistura.",
        fieldDescription10:
          "Código ou identificador da pré-mistura. Linhas de produção que comparitlham o mesmo estoque devem ter códigos de pré mistura iguais.  Caso contrário, o códigos de pré mistura devem ser diferentes.",
        fieldDescription11:
          "Código ou identificador do lote de produção. Linhas de produção que produzem o mesmo tipo de fio e qualidade devem ter o mesmo lote de produção.  Caso contrário, os lotes de produção devem ser diferentes.",
      },
      tabDelete: {
        title: "Excluindo uma linha",
        step1:
          "Na página de cadastro de linhas de produção, encontre a linha na tabela de linhas cadastradas e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        step3: "Não será possível excluir a linha de produção quando:",
        reason1:
          "A linha for usada em alguma pré mistura existente. Neste caso, todas as pré misturas ligadas à linha devem ser excluidas.",
        reason2:
          "A linha estiver ligada à alguma classe de linha de produção cadastrada. Neste caso, as classes ligadas à linha devem ser excluidas.",
      },
      tabWaste: {
        title: "Definindo posições de resíduos",
        description:
          "Posições de resíduos são espaços na mistura reservados para fardos de resíduos gerados internamente e que podem ser incorporados na mistura, desde que em mínimas quantidades.",
        step1:
          "Na página de cadastro de linhas de produção, encontre a linha na tabela de linhas cadastradas e clique em ",
        step2:
          "Para adicionar uma nova posição de resíduo, clique em 'Nova posição' na janela.",
        step3:
          "Para excluir uma nova posição de resíduo, encontre-a na tabela e clique em ",
      },
    },
    productionLineClasses: {
      modalTitle: "Ajuda - Classes de linhas de produção",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "As classes de linhas de produção servem para ligar as pilhas do estoque à uma linha de produção. O primeiro caractere do nome das pilhas cadastradas identifica a classe, desta forma o sistema identifica quais pilhas fazem parte do estoque da linha de produção.",
        flow: "Este é o fluxo de trabalho da página de cadastro de classes de linhas de produção:",
      },
      tabAdd: {
        title: "Criando nova classe",
        step1:
          "Na página de cadastro de classes, clique em 'Nova classe de linha de produção'.",
        step2: "Selecione a linha de produção.",
        step3:
          "Informe a classe. Pode ser uma letra ou número. No caso de letra, é aconselhável usar maiúsculas.",
      },
      tabDelete: {
        title: "Excluindo uma classe",
        step1:
          "Na página de cadastro de classes, encontre a classe na tabela de classes cadastradas e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
      },
    },
    producers: {
      modalTitle: "Ajuda - Produtores",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Produtores podem ser a pessoa ou mesmo a fazenda que produziu o algodão. A identificação dos produtor permite filtrar consultas nos relatórios bem como fazer dosagem do uso de algodão.",
        flow: "Este é o fluxo de trabalho da página de cadastro de produtores:",
      },
      tabAdd: {
        title: "Criando um novo produtor",
        step1:
          "Na página de cadastro de produtores, clique em 'Novo produtor'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Nome ou descrição do produtor.",
      },
      tabEdit: {
        title: "Alterando o nome do produtor",
        step1:
          "Na página de cadastro de produtores, encontre o produtor na tabela de produtores cadastrados e clique em ",
        step2: "Altere o nome.",
      },
      tabDelete: {
        title: "Excluindo um produtor",
        step1:
          "Na página de cadastro de produtores, encontre o produtor na tabela de produtores cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso existam fardos no estoque ligados ao produtor, a exclusão não será possível",
      },
    },
    suppliers: {
      modalTitle: "Ajuda - Fornecedores",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Fornecedores são pessoas ou empresas que fornececem o algodão, ou seja, de quem o algodão foi comprado. Cada lote de algodão que entra no estoque é vinculado à um fornecedor.",
        flow: "Este é o fluxo de trabalho da página de cadastro de fornecedores:",
      },
      tabAdd: {
        title: "Criando um novo fornecedor",
        step1:
          "Na página de cadastro de fornecedores, clique em 'Novo fornecedor'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Nome ou descrição do fornecedor.",
      },
      tabEdit: {
        title: "Alterando o nome do fornecedor",
        step1:
          "Na página de cadastro de fornecedores, encontre o fornecedor na tabela de fornecedores cadastrados e clique em ",
        step2: "Altere o nome.",
      },
      tabDelete: {
        title: "Excluindo um fornecedor",
        step1:
          "Na página de cadastro de fornecedores, encontre o fornecedor na tabela de fornecedores cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso existam fardos no estoque ligados ao fornecedor, a exclusão não será possível",
      },
    },
    cottonTypes: {
      modalTitle: "Ajuda - tipos de algodão",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Tipos de algodão podem ser qualquer identificador que tipifica o algodão. Os nomes de tipos de algodão varian de acordo com o país ou região.",
        flow: "Este é o fluxo de trabalho da página de cadastro de tipos de algodão:",
      },
      tabAdd: {
        title: "Criando um novo tipo",
        step1:
          "Na página de cadastro de tipos de algodão, clique em 'Novo tipo de algodão'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Nome ou descrição do tipo.",
      },
      tabEdit: {
        title: "Alterando o nome do tipo",
        step1:
          "Na página de cadastro de tipos de algodão, encontre o tipo na tabela de tipos de algodão cadastrados e clique em ",
        step2: "Altere o nome.",
      },
      tabDelete: {
        title: "Excluindo um tipo",
        step1:
          "Na página de cadastro de tipos de algodão, encontre o tipo na tabela de tipos de algodão cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso existam fardos no estoque ligados ao tipo, a exclusão não será possível",
      },
    },
    origins: {
      modalTitle: "Ajuda - Origens",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Origens podem ser o país ou região de onde vem o algodão.",
        flow: "Este é o fluxo de trabalho da página de cadastro de origens:",
      },
      tabAdd: {
        title: "Criando uma nova origem",
        step1: "Na página de cadastro de origens, clique em 'Nova origem'.",
        step2: "Preencha os campos:",
        fieldDescription1: "Nome ou descrição da origem.",
      },
      tabEdit: {
        title: "Alterando o nome da origem",
        step1:
          "Na página de cadastro de origens, encontre a origem na tabela de origens cadastrados e clique em ",
        step2: "Altere o nome.",
      },
      tabDelete: {
        title: "Excluindo uma origem",
        step1:
          "Na página de cadastro de origens, encontre a origem na tabela de origens cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso existam fardos no estoque ligados a origem, a exclusão não será possível",
      },
    },
    piles: {
      modalTitle: "Ajuda - Pilhas",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Pilhas servem para dividir o estoque, podendo inclusive ser  uma representação do espaço físico do depósito de algodão.",
        pageDescription2:
          "Sempre que um lote de algodão é disponibilizado através da página 'Distribuição de algodão', ele deve ser alocado em uma pilha ligada à linha de produção para a qual o lote estará disponível.",
        flow: "Este é o fluxo de trabalho da página de cadastro de origens:",
      },
      tabAdd: {
        title: "Criando novas pilhas no estoque",
        step1: "Na página de cadastro de pilhas, clique em 'Nova pilha'.",
        step2: "Preencha os campos:",
        fieldDescription1:
          "Identificação da pilha. Deve conter uma letra ou número que identifica a classe de linha de produção ao qual a pilha está ligada, seguido de traço e um número sequencial ou não. Por exemplo: 'P-01', P-05, 'B-10', B-11' etc.",
        fieldDescription2:
          "Indica se o sistema deve criar múltiplas pilhas automaticamente, partindo da identificação da primeira pilha e gerando uma sequência até a quantidade definida. Desta forma não é necessário cadastras todas as pilhas manualmente.",
        fieldDescription3:
          "Quantidade pilhas à serem geradas automaticamente, se for o caso.",
      },
      tabDelete: {
        title: "Excluindo uma ou várias pilhas",
        step1:
          "Na página de cadastro de pilhas, selecione todas as pilhas que deseja excluir e em seguida clique em 'Excluir' ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso existam fardos no estoque alocados na pilha, a exclusão não será possível.",
      },
    },
    hviLayouts: {
      modalTitle: "Ajuda - Layout de arquivos HVI",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Para que seja possível importar arquivos com resultados de HVI ou quaisquer arquivos que contenham a qualidade dos fardos, é necessário informar ao sistema qual o layout destes arquivos.",
        pageDescription2:
          "Geralmente, estes são arquivos são planilhas excel ou arquivos de texto, e para que o sistema faça a leitura correta deste arquivos, é necessário cadastrar os layouts.",
        pageDescription3:
          "Em resumo, deve-se informar quais as colunas do arquivo que contém o número do fardo, além dos valores de cada parâmetro de qualidade cadastrado.",
        flow: "Este é o fluxo de trabalho da página de cadastro de layouts:",
      },
      tabAdd: {
        title: "Adicionando novos layouts",
        step1: "Na página de cadastro de layouts, clique em 'Novo layout'.",
        step2: "Informe o nome ou descrição do layout.",
      },
      tabEdit: {
        title: "Alterando a descrição do layout",
        step1:
          "Na página de cadastro de layouts, encontre-o na tabela de layouts cadastrados e clique em ",
        step2: "Altere a descrição do layout.",
      },
      tabColumns: {
        title: "Configurando o layout",
        step1:
          "Na página de cadastro de layouts, encontre-o na tabela de layouts cadastrados e clique em ",
        step2: "Digite os valores diretamente nas celulas da tabela.",
        fieldDescription1:
          "Número da coluna que contém o numero do fardo ou os valores de qualidade no arquivo.",
        fieldDescription2:
          "Multiplicador de converão. Usado para converter os valores de qualidade de milímetros para polegadas, por exemplo.",
      },
      tabDelete: {
        title: "Excluindo um layout",
        step1:
          "Na página de cadastro de layouts, encontre-o na tabela e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
      },
    },
    users: {
      modalTitle: "Ajuda - Usuários",
      tabOverview: {
        title: "Visão geral",
        pageDescription: "Página para cadastro e gerenciamento dos usuários.",
        flow: "Este é o fluxo de trabalho da página de cadastro de usuários:",
      },
      tabAdd: {
        title: "Criando um novo usuário",
        step1: "Na página de cadastro de usuários, clique em 'Novo uauário'.",
        step2: "Preencha os campos:",
        fieldDescription1:
          "Identificação do usuário para login. Deve ser único e não pode conter espaços.",
        fieldDescription2: "Endereço de e-mail do usuário.",
        fieldDescription3: "Nome do usuário",
        fieldDescription4: "Sobrenome do usuário.",
        fieldDescription5:
          "Nivel de privilégio do usuário. Os níveis servem para determinar quais páginas o usuário pode acessar. As opções são:",
        option1: "Basic",
        optionDescription1:
          "O usuário tem acesso à função de cadastro como produtores, fornecedores, origens, tipos de algodão, estacas, layouts de arquivos HVI. O usuário 'Basic' também pode gerenciar o estoque e gerar relatórios.",
        option2: "Advanced",
        optionDescription2:
          "Além de todas as funções contidas no nível 'Basic', o usuário 'Advanced' pode acessar o registro de parâmetros, tipos de mistura, linhas de produção, classes de linha de produção, além de gerenciar todas as funções do processo de mistura.",
        option3: "Manager",
        optionDescription3:
          "Além de todas as funções contidas nos níveis 'Basic' e 'Advanced', o usuário 'Manager' pode criar e gerenciar novas plantas, adicionar novos usuários, gerenciar avisos de qualidade, bem como verificar os registros de ação do usuário.",
        description1:
          "A senha padrão para todos os novos usuários é 'change_me'. Ao fazer login no aplicativo pela primeira vez, o usuário será solicitado a alterar a senha inserindo esta senha padrão.",
      },
      tabEdit: {
        title: "Alterando os dados de um usuário",
        step1:
          "Na página de cadastro de usuários, encontre o usuário na tabela de usuários cadastrados e clique em ",
        step2: "Altere os campos.",
        description1:
          "Para alterar a senha, use a opção 'Esqueci a senha' na tela de login.",
      },
      tabDelete: {
        title: "Excluindo um usuário",
        step1:
          "Na página de cadastro de usuários, encontre o usuário na tabela de usuários cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
      },
    },
    lotInput: {
      modalTitle: "Ajuda - Lançamento de lotes",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para lançamento dos lotes de algodão no estoque. Os lotes também podem ser as notas fiscais ou romaneios.",
        flow: "Este é o fluxo de trabalho da página de cadastro de lotes:",
      },
      tabAdd: {
        title: "Adicionando novos lotes",
        step1:
          "Na página de cadastro de lotes, selecione o fornecedor na caixa de seleção 'Fornecedor', e então clique em 'Novo lote'.",
        step2: "Informe os dados do novo lote.",
        fieldDescription1: "Quantidade de fardos contida no lote.",
        fieldDescription3:
          "Peso bruto do lote. Pode ser definido em quilogramas ou libras dependendo das preferências do usuário.",
        fieldDescription4:
          "Peso líquido do lote. Pode ser definido em quilogramas ou libras dependendo das preferências do usuário.",
        fieldDescription5:
          "Nome do  produtor. O produtor deve estar cadastrado previamente.",
        fieldDescription6:
          "Tipo do algodão. O tipo deve estar cadastrado previamente.",
        fieldDescription7:
          "Origem do algodão. A origem deve estar cadastrada previamente.",
        fieldDescription8: "Data de entrada do lote na unidade.",
        fieldDescription9:
          "Nome do classificador, ou seja, o laboratório que fez a classificação HVI dos fardos.",
        fieldDescription10: "Número do contrato de compra.",
        fieldDescription11:
          "Número do lote de compra. O lote HVI pode ser informado aqui, por exemplo.",
      },
      tabEdit: {
        title: "Editando um lote",
        step1:
          "Na página de cadastro de lotes, encontre-o na tabela de lotes cadastrados e clique em ",
        step2: "Altere os campos.",
      },
      tabBales: {
        title: "Adicionando os fardos no lote",
        step1:
          "Na página de cadastro de lotes, encontre o lote na tabela de lotes cadastrados e clique em ",
        step2: "Instruções estão na página de fardos.",
      },
      tabDelete: {
        title: "Excluindo um lote",
        step1:
          "Na página de cadastro de lotes, encontre o lote na tabela e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Caso o lote contenha fardos cadastrados, não será possível e exclusão.",
      },
    },
    baleInput: {
      modalTitle: "Ajuda - Lançamento de fardos",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para lançamento dos fardos de um lote. Os fardos podem ser cadastrados manualmente ou através da importação de arquivo.",
        flow: "Este é o fluxo de trabalho da página de lançamento de fardos:",
      },
      tabAdd: {
        title: "Adicionando novos fardos manualmente",
        step1:
          "Na página de lançamento de fardos, clique em 'Novos Fardos' e em seguida selecione 'Manualmente'.",
        step2: "Preencha os campos.",
        fieldDescription1: "Número do fardo. Pode conter letras ou números.",
        fieldDescription2:
          "Indica se o sistema deve criar múltiplas fardos automaticamente, partindo do número do primeiro fardo e gerando uma sequência até a quantidade definida.",
        fieldDescription3:
          "Quantidade de fardos à serem gerados automaticamente, se for o caso.",
      },
      tabImportation: {
        title: "Adicionando novos fardos por arquivo",
        step1:
          "Na página de lançamento de fardos, clique em 'Novos Fardos' e em seguida selecione 'Do arquivo'. ",
        step2:
          "As instruções para esta função estão na página de importação de arquivos.",
      },
      tabDelete: {
        title: "Excluindo fardos",
        step1:
          "Na página de lançamento de fardos, selecione todos os fardos que deseja excluir e em seguida clique em 'Excluir fardos' ",
        step2: "Confirme a exclusão na janela de confirmação.",
        reason1:
          "Somente fardos que estão em estoque podem ser excluidos. Fardos que já foram consumidos não podem ser excluídos.",
      },
    },
    baleInputImportation: {
      modalTitle: "Ajuda - Importar fardos do arquivo",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para importação de fardos a partir de um arquivo. ",
        flow: "Este é o fluxo de trabalho da página de importação de fardos:",
      },
      tabAdd: {
        title: "Importando um arquivo.",
        step1:
          "Selecione o layout correspondente ao arquivo que será importado. O layout deve estar previamente cadastrado e configurado.",
        step2:
          "Clique em 'Selecionar arquivo' e busque pelo arquivo no computador. Arquivos no formato de planilha excel ou arquivos texto são aceitos.",
        step3: "Veja abaixo um exemplo de arquivo para importação.",
        step4: "Após selecionar o arquivo, os dados serão mostrados na tabela:",
        step5:
          "O ESSENTIALbale tentará identificar os fardos e suas respectivas informações de qualidade no arquivo.",
        step6:
          "Colunas destacadas em vermelho indicam divergência entre o layout cadastrado e o layout do arquivo, neste caso não será possível importar os dados e correçãoes devem ser faitas no layout cadastrado.",
        step7:
          "Colunas destacadas em verde indicam que a coluna está de acordo com o layout cadastrado.",
        step8: "Clique em 'Incluir fardos'.",
      },
    },
    baleManagement: {
      modalTitle: "Ajuda - Gerenciamento de fardos",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para gerenciamento dos fardos do estoque, onde é possível alterar o status dos fardos ou mesmo trocar a pilha em que o fardo está alocado.",
        pageDescription2:
          "Há dois tipos tipos de status que podem ser atribuídos aos fardos:",
        field1: "Em espera",
        fieldDescription1:
          "Onde o fardo é colocado em modo de espera, não estando assim disponível para uso nas misturas.",
        field2: "Disponível",
        fieldDescription2:
          "Onde o fardo passa do modo de espera para disponível, estando assim novamente disponpivel para uso nas misturas.",
        flow: "Este é o fluxo de trabalho da página de gerenciamento de fardos:",
      },
      tabStatus: {
        title: "Alterando o status",
        step1:
          "Selecione a opção desejada na caixa de seleção 'Situação' para buscar os fardos no estoque.",
        step2:
          "Selecione na tabela os fardos para os quais deseja alterar o status.",
        step3:
          "Clique em 'Em espera', para colocar os fardos em modo de espera, ou em 'Tornar disponível' para tirar os fardos do modo de espera tornando-os disponíveis para uso novamente. ",
      },
      tabPile: {
        title: "Alterando a pilha",
        step1:
          "Selecione a opção desejada na caixa de seleção 'Situação' para buscar os fardos no estoque.",
        step2:
          "Selecione na tabela os fardos para os quais deseja alterar a pilha.",
        step3: "Clique em 'Alterar pilha'.",
        step4: "Selecione a nova pilha que deve ser atribuída aos fardos.",
      },
    },
    lotManagement: {
      modalTitle: "Ajuda - Gerenciamento de lotes",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para gerenciamento dos lotes do estoque, onde é possível alterar o status dos lotes ou mesmo trocar a pilha em que o lote está alocado.",
        pageDescription2:
          "Há dois tipos tipos de status que podem ser atribuídos aos lotes:",
        field1: "Em espera",
        fieldDescription1:
          "Onde o lote é colocado em modo de espera, não estando assim disponível para uso nas misturas.",
        field2: "Disponível",
        fieldDescription2:
          "Onde o lote passa do modo de espera para disponível, estando assim novamente disponpivel para uso nas misturas.",
        flow: "Este é o fluxo de trabalho da página de gerenciamento de lotes:",
      },
      tabStatus: {
        title: "Alterando o status",
        step1:
          "Selecione a opção desejada na caixa de seleção 'Situação' para buscar os lotes no estoque.",
        step2:
          "Selecione na tabela os lotes para os quais deseja alterar o status.",
        step3:
          "Clique em 'Em espera', para colocar os lotes em modo de espera, ou em 'Tornar disponível' para tirar os lotes do modo de espera tornando-os disponíveis para uso novamente. ",
      },
      tabPile: {
        title: "Alterando a pilha",
        step1:
          "Selecione a opção desejada na caixa de seleção 'Situação' para buscar os lotes no estoque.",
        step2:
          "Selecione na tabela os lotes para os quais deseja alterar a pilha.",
        step3: "Clique em 'Alterar pilha'.",
        step4: "Selecione a nova pilha que deve ser atribuída aos lotes.",
      },
      tabPlant: {
        title: "Transferindo para outra unidade",
        step1:
          "Selecione a opção desejada na caixa de seleção 'Situação' para buscar os lotes no estoque.",
        step2:
          "Selecione na tabela os lotes que deseja transferir para outra unidade.",
        step3: "Clique em 'Transferência de fábrica'.",
        step4: "Selecione a unidade de destino para os lotes.",
      },
    },
    cottonDistribution: {
      modalTitle: "Ajuda - Distribuição de algodão",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para direcionamento de lotes de algodão para o estoque das linhas de produção. Quando um novo lote é inserido no sistema, a pilha S-01 é autoamticamente atribuída à esse lote. Esta pilha não está ligada à nenhuma linha de produção e, portanto, os lotes novos não faz parte do estoque de nehuma linha de produção quando é inserido.",
        pageDescription2:
          "Na distribuição, os lotes passam a compor o estoque de uma linha de produção e estar disponíveis para serem utilizados nas misturas. Basicamente, esta etapa consiste em atribuir uma nova pilha ao lote, que passará a compor o estoque da linha à qual a pilha está vinculada.",
        flow: "Este é o fluxo de trabalho da página de distribuição de algodão:",
      },
      tabManual: {
        title: "Distribuindo lotes manualmente",
        step0:
          "Na distribuição manual, o usuário seleciona os lotes manualmente e atribui novas pilhas aos lotes.",
        step1:
          "Na página de distribuição de algodão, clique em 'Nova distribuição manual'.",
        step2: "Siga as instruções na página de distribuição manual.",
      },
      tabAutomatic: {
        title: "Distribuindo lotes automaticamente",
        step0:
          "Na distribuição automática, ESSENTIALbale seleciona os lotes e atribui novas pilhas automaticamente.",
        step1:
          "Na página de distribuição de algodão, clique em 'Nova distribuição automática'.",
        step2: "Siga as instruções na página de distribuição automática.",
      },
      tabCancelAll: {
        title: "Cancelando uma distribuição",
        step1:
          "Na página de distribuição de algodão, encontre a distribuição na tabela de distribuições realizadas e clique em ",
        step2:
          "Os fardos de todos os lotes nesta distribuição que ainda não foram usados em mistura retornarão para a pilha S-01 e não estarão diponíveis para uso.",
      },
      tabCancel: {
        title: "Cancelando a distribuição de um lote apenas",
        step1:
          "Na página de distribuição de algodão, clique sobre a linha da distribuição na tabele de distribuições realizadas.",
        step2:
          "Na tabela de lotes incluídos, encontre o lote desejado e clique em ",
        step3:
          "Os fardos do lote em questão que ainda não foram usados em mistura retornarão para a pilha S-01 e não estarão diponíveis para uso.",
      },
    },
    manualCottonDistribution: {
      modalTitle: "Ajuda - Distribuição manual",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Na distribuição manual, o usuário escolhe manualmente os lotes que devem ser adicionados ao estoque do tipo de mistura selecionado.",
        flow: "Este é o fluxo de trabalho da página de distribuição manual:",
      },
      tabInfo: {
        title: "Entendendo as informações de qualidade",
        description:
          "Na página de distribuição manual há duas abas com dados de qualidade.",
        table1: "Qualidade por pilha",
        tableDescription1:
          "Mostra a qualidade média e quantidade de fardos disponíveis para cada tipo de mistura na pilha selecionada.",
        table2: "Qualidade por tipo de mistura",
        tableDescription2:
          "Mostra uma visão geral da qualidade média do estoque.",
        fieldDescription1:
          "Qualidade média do estoque total, incluindo algodão disponpivel para outros tipos de mistura.",
        fieldDescription2:
          "Qualidade média do estoque em uso atualmente para o tipo de mistura.",
        fieldDescription3:
          "Qualidade média de todo o estoque disponível para distribuição.",
        fieldDescription4: "Qualidade média do estoque na pilha selecionada.",
        fieldDescription5:
          "Projeção do estoque caso todos os lotes na pilha selecionada sejam adicionados ao estoque do tipo de mistura.",
      },
      tabAdd: {
        title: "Distribuindo os lotes manualmente",
        step1: "Selecione a pilha que contém os lotes para distribuição.",
        step2:
          "Selecione o tipo de mistura para o qual deseja distribuir os lotes.",
        step3: "Na aba 'Lotes na pilha selecionada', selecione os lotes.",
        step4: "Clique em 'Fazer distribuição manual'.",
      },
    },
    autoCottonDistribution: {
      modalTitle: "Ajuda - Distribuição automática",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Na distribuição automática, o ESSENTIALbale seleciona automaticamente os lotes que devem ser adicionados ao estoque para o tipo de mistura selecioando. Observando sempre os tipos de algodão controlados na mistura, serão selecionados lotes nos quais o tipo de algodão é necessário naquele momento.",
        pageDescription2:
          "Sempre que a quantidade de lotes disponíveis para o processo misturas for menor que a quantidade mínima necessária informada no cadastro do tipo de mistura, o ESSENTIALbale solicitará ao usuário a disponibilização de novos lotes.",

        flow: "Este é o fluxo de trabalho da página de distribuição automática:",
      },
      tabInfo: {
        title: "Entendendo as informações de qualidade",
        table1: "Tabela qualidade do estoque",
        tableDescription1:
          "Mostra uma visão geral da qualidade média do estoque, além da qualidade média dos lotes sugeridos para distrubuição.",
        fieldDescription1:
          "Qualidade média do estoque total, incluindo algodão disponpivel para outros tipos de mistura.",
        fieldDescription2:
          "Qualidade média do estoque em uso atualmente para o tipo de mistura.",
        fieldDescription3:
          "Qualidade média do estoque disponível para distribuição.",
        fieldDescription4:
          "Qualidade média dos lotes sugeridos na distribuição automática. Será mostrada após o processamento.",
        fieldDescription5:
          "Projeção do estoque  para o tipo de mistura caso todos os lotes sugeridos sejam confirmados.",
        table2: "Tabela perspectica de qualidade do tipo de mistura",
        tableDescription2:
          "Mostra a perspectiva da qualidade média para o tipo de msitura selecionado.",
        fieldDescription6: "Nome do parâmetro de qualidade.",
        fieldDescription7:
          "Proiridade ou peso dado ao parâmetro no processamento da distribuição. Parâmetros com prioridade maior terão o valor alcançado mais próximo do valor esperado.",
        fieldDescription8:
          "Valor mínimo aceitável. Caso o valor alcançado fique abaixo do míinimo aceitável, os lotes sugeridos podem alterar demasiadamente a qualidade média do estoque do tipo de mistura.",
        fieldDescription9:
          "Valor máximmo aceitável. Caso o valor alcançado fique acima do máximo aceitável, os lotes sugeridos podem alterar demasiadamente a qualidade média do estoque do tipo de mistura.",
        fieldDescription10:
          "Valor ideal esperado no processamento da seleção dos lotes.",
        fieldDescription11:
          "Valor alcançado no processamento da seleção dos lotes.",
      },
      tabAdd: {
        title: "Gerando distribuição automática",
        step1:
          "Selecione o tipo de mistura para o qual deseja gerar uma distribuição automática",
        step2: "Clique em 'Gerar distribuição automática'.",
        step3:
          "Aguarde a conclusão do processamento. Este processo leva 30 segundos. Ao final do processo, o lotes selecionados pelo sistema serão mostrados na tabela 'Lotes sugeridos'.",
        step4:
          "Selecione os lotes na tabela e clique em 'Confirmar lotes sugeridos'.",
      },
    },
    pileGroups: {
      modalTitle: "Ajuda - Grupos de pilhas",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Grupos de pilhas são usados para controlar o uso de algodão nas misturas.",
        pageDescription2:
          "Um grupo pode reunir várias pilhas ou mesmo uma única pilha que contém algodão cujo uso deve ser controlado nas misturas.",
        pageDescription3:
          "Pode-se, por exemplo, criar grupos de pilhas por produtor ou origem e definir para cada grupo quantos fardos serão usados de cada grupo nas misturas.",
        flow: "Este é o fluxo de trabalho da página de grupos de pilhas:",
      },
      tabAdd: {
        title: "Criando novo grupo",
        step1:
          "Na página de grupos de pilhas, clique em 'Novo grupo de pilhas'.",
        step2: "Preencha os campos.",
        fieldDescription1: "Nome ou descrição do grupo.",
        fieldDescription2:
          "Quantidade de fardos que devem ser usados em cada mistura.",
        fieldDescription3: "Indica se o grupo está ativo ou não.",
        step3:
          "Na tabela de pilhas, selecione todas as pilhas que farão parte do grupo.",
        fieldDescription5:
          "A legenda 'Representação do grupo (%)' mostra o quanto o grupo representa na mistura e no estoque.",
        fieldDescription6:
          "A legenda 'Representação das pilas fora de grupo (%)' Mostra o quanto o algodão que não faz parte de nenhum grupo representa na mistura e no estoque.",
      },
      tabEdit: {
        title: "Editando um grupo",
        step1:
          "Na página de grupo de pilhas, encontre-o na tabela de grupos cadastrados e clique em ",
        step2:
          "Altere os campos ou redefina as pilhas que fazer parte do grupo.",
      },
      tabDelete: {
        title: "Excluindo um grupo",
        step1:
          "Na página de grupo de pilhas, encontre-o na tabela de grupos cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
      },
    },
    controlledCottonTypes: {
      modalTitle: "Ajuda - Tipo de algodão controlados",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "O controle por tipo de algodão serve para controlar o uso do algodão nas misturas de acordo com o tipo de algodão.",
        pageDescription2:
          "Este método de controle é comumente usado em países asiáticos, que costumam dosar o algodão nas misturas de acordo com o tipo.",
        flow: "Este é o fluxo de trabalho da página de tipos de algodão controlados:",
      },
      tabAdd: {
        title: "Inserindo um tipo controlado",
        step1:
          "Na página de tipos de algodão controlados, selecione o tipo de mistura na caixa de seleção 'Tipo de mistura' e em seguida clique em 'Novo tipo de algodão controlado'.",
        step2: "Selecione o tipo de algodão.",
        step3:
          "Informe no campo 'Fardos por mistura' quantos fardos devem ser usados na mistura.",
      },
      tabEdit: {
        title: "Alterando um tipo controlado",
        step1:
          "Na página de tipos de algodão controlados, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2: "Altere a quantidade de fardos.",
      },
      tabDelete: {
        title: "Excluindo um tipo controlado",
        step1:
          "Na página de tipos de algodão controlados, encontre o tipo na tabela de tipos cadastrados e clique em ",
        step2: "Confirme a exclusão na janela de confirmação.",
      },
    },
    hviImportation: {
      modalTitle: "Ajuda - Importando arquivo",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para importação de resultados HVI para atribuição ou atualização dos dados de qualidade dos fardos.",
        flow: "Este é o fluxo de trabalho da página de importação de resultados HVI:",
      },
      tabAdd: {
        title: "Importando um arquivo.",
        step1:
          "Selecione o layout correspondente ao arquivo que será importado. O layout deve estar previamente cadastrado e configurado.",
        step2:
          "Clique em 'Selecionar arquivo' e busque pelo arquivo no computador. Arquivos no formato de planilha excel ou arquivos texto são aceitos.",
        step3: "Veja abaixo um exemplo de arquivo para importação.",
        step4: "Após selecionar o arquivo, os dados serão mostrados na tabela:",
        step5:
          "O ESSENTIALbale tentará identificar os fardos e suas respectivas informações de qualidade no arquivo.",
        step6:
          "Colunas destacadas em vermelho indicam divergência entre o layout cadastrado e o layout do arquivo, neste caso não será possível importar os dados e correçãoes devem ser faitas no layout cadastrado.",
        step7:
          "Colunas destacadas em verde indicam que a coluna está de acordo com o layout cadastrado.",
        step8: "Clique em 'Incluir fardos'.",
      },
    },
    hviManualInput: {
      modalTitle: "Ajuda - Lançamento manual de resultados de qualidade",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para lançamento manual de dados de qualidade para os parâmetros que tem o lançamento manual setado no cadastro.",
        flow: "Este é o fluxo de trabalho da página de lançamento manual de resultados:",
      },
      tabAdd: {
        title: "Inserindo resultados",
        step1:
          "Selecione o situação na caixa de situação 'Status' para buscar os fornecedores.",
        fieldDescription1:
          "Busca apenas os fornecedores que têm algum lote de algodão cujo lançamento dos resultados está pendente.",
        fieldDescription2: "Busca todos os fornecedores.",
        step2: "Selecione o fornecedor.",
        step3: "Selecione o lote.",
        step4: "Digite os resultados diretamente nas células da tabela.",
        fieldDescription3:
          "Marque esta opção para atribuir o mesmo valor para todos os fardos. Neste caso, ao digitar o valor em qualquer célula, o mesmo valor será replicado para todos os fardos.",
        fieldDescription4:
          "Marque esta opção se cada fardo tem valor individual.",
        step5: "Clique em 'Salvar'.",
      },
    },
    baleInfo: {
      modalTitle: "Ajuda - Consulta de fardo",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Página para consulta de informações de uma fardo do estoque.",
      },
      tabSearch: {
        title: "Consultando um fardo",
        step1: "Digite o número do fardo no campo e clique em 'Pesquisar'.",
      },
    },
    plannings: {
      modalTitle: "Ajuda - Planejamento de qualidade",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "O planejamento de qualidade é a primeira etapa no processo de mistura. No planejamento é definida a qualidade que servirá como base para a próxima mistura.",

        flow: "Este é o fluxo de trabalho da página de planekamento:",
      },
      tabAdd: {
        title: "Criando um novo planejamento",
        step1: "Na página de planejamentos, clque em 'Novo planejamento'.",
        step2:
          "Selecione o tipo de mistura para o qual deseja gerar o planejamento.",
        step3:
          "Se for o caso, marque a opção 'Troca de lotede produção'. Esta opção deve ser usada exclusivamente quando se inicia um novo lote de produção. Deve ser usada quando há uma grande mudança na qualidade do estoque como em trocas de safra, por exemplo. Quando há uma troca de lote de produção, a qualidade das misturas anteriores não é considereada no cálculo da nova qualidade.",
      },
      tabEdit: {
        title: "Editando os dados do planejamento",
        step1:
          "Na página de planejamento, encontre o planejamento na tabela e clique em ",
        step2: "Altere o tipo de mistura.",
        step3: "Se for o caso, mude a opção de troca de lote de produção.",
      },
      tabQuality: {
        title: "Gerando o planejamento",
        step1: "Encontre o planejamento na tabela e clique em ",
        step2: "Defina o que deve ser priorizado no planejamento",
        fieldDescription1:
          "(Recomendado) Selecione para que a qualidade planejada fique exatamwente entre a qualidade do estoque e a qualidade da última mistura.",
        fieldDescription2:
          "Selecione para que a qualidade planejada fique mais próxima da qualidade do estoque.",
        fieldDescription3:
          "Selecione para que a qualidade planejada fique mais próxima da qualidade da última mistura.",
        step3: "Clique em 'Gerar planejamento'.",
        description1: "A tabela de qualidade mostra os seguintes valores:",
        fieldDescription4: "Parâmtro de qualidade.",
        fieldDescription5: "Qualidade média do estoque para o tipo de mistura.",
        fieldDescription6: "Qualidade média da última mistura.",
        fieldDescription7:
          "Qualidade planejada. Será mostrada após clicar em 'Gerar planejamento'.",
        fieldDescription8:
          "Diferença entre a qualidade planejada e a qualidade da última mistura. Será mostrada após clicar em 'Gerar planejamento'.",
        description2: "A tabela de consumo mostra os seguintes valores:",
        fieldDescription9:
          "Quantidade de fardos disponíveisno estoque para o tipo de mistura atualmente.",
        fieldDescription10: "Quantidade de misturas consumidas por dia.",
        fieldDescription11: "Quantidade de fardos usados em cada mistura.",
        fieldDescription12: "Quantidade de fardos consumida por dia.",
        fieldDescription13:
          "Quantidade de fardos restantes após o consumo da próxima mistura.",
      },
      tabViewQuality: {
        title: "Visualizando a qualidade planejada",
        step1:
          "Na página de planejamento, encontre o planejamento na tabela e clique em ",
        step2:
          "Na tabela será mostrada a qualidade planejada para cada parâmetro de qualidade.",
      },
      tabDelete: {
        title: "Excluindo um planejamento",
        step1:
          "Na página de planejamento, encontre o planejamento na tabela e clique em ",
        step2:
          "Confirme a exclusão na janela de confirmação. Caso o ícone não esteja vísivel, significa que o planejamento não pode ser excluído.",
      },
    },
    preLaydowns: {
      modalTitle: "Ajuda - Pré misturas",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "A pré msitura é a segunda etapa no processo de misturas. Nesta etapa, o ESSENTIALbale determina quantos fardos devem ser usados de cada pilha para criação de uma pré mistura.",
        pageDescription2:
          "Baseado na qualidade planejada na etapa de planejamento, o sistema analisa a qualidade de cada pilha disponível, e determina a quantidade de fardos a ser utilizada. Nesta etapa é utilizada uma quantidade maior de fardos em relação à quantidade de fardos usada na mistura. Geralmente usa-se 50% a mais de fardos na pré mistura.",
        flow: "Este é o fluxo de trabalho da página de pré mistura:",
      },
      tabAdd: {
        title: "Criando nova pré mistura",
        step1: "Na página de pré misturas, clque em 'Nova pré mistura'.",
        step2: "Selecione a linha de produção.",
        step3:
          "Opcionalmente você pode informar o lote de fio, ou lote de saída. O lote de fio pode ser usado para filtragem nos relatórios de qualidade posteriormente.",
      },
      tabEdit: {
        title: "Editando uma pré mistura",
        step1:
          "Na página de pré misturas, encontre a pré mistura na tabela e clique em ",
        step2: "Selecione a linha de produção.",
        step3: "Informe o lote de fio.",
      },
      tabPiles: {
        title: "Gerando a pré mistura",
        description0:
          "Na geração da pré mistura, O ESSENTIALbale analisa a qualidade das pilhas e define quantos fardos serão usados de cada uma.",
        step1:
          "Na página de pré misturas, encontre a pré mistura na tabela e clique em ",
        description1:
          "Na tabela 'Pilhas disponíveis' são mostradas todas as pilhas que estão disponiveis. Para cada pilha são mostrados:",
        fieldDescription1: "Quantidade de fardos disponível na pilha.",
        fieldDescription2:
          "Quantidade mínima de fardos que pode ser utilizada na pré mistura. È definida pelo sistema automaticamente, mas pode ser alterada pelo usuário.",
        fieldDescription3:
          "Quantidade máxima de fardos que pode ser utilizada na pré mistura. È definida pelo sistema automaticamente, mas pode ser alterada pelo usuário.",
        fieldDescription4:
          "Quantidade de fardos definida para a pré mistura. É mostrada após a elaboração da pré mistura.",
        parameter: "Parâmetro de qualidade",
        fieldDescription5: "Qualidade média da pilha para o parâmetro.",

        description2:
          "Na tabela 'Qualidade', para cada parâmetro de qualidade temos os seguintes valores:",
        fieldDescription6:
          "Prioridade ou peso do parâmetro na elaboração da pré mistura.",
        fieldDescription7: "Qualidade média atual do estoque.",
        fieldDescription8:
          "Qualidade planejada na etapa de planejamento e que serve de referência para o sistema.",
        fieldDescription9:
          "Qualidade alcançada com a elaboração da pré mistura. É mostrada após a elaboração da pré mistura e deve sempre ficar entre os valores mínnimo e máximo aceitos.",
        fieldDescription10:
          "Valor mínimo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription11:
          "Valor máximo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        step2: "Clique em 'Gerar pré mistura'.",
        description3:
          "Aguarde a conclusão do processamento. Este processo leva 30 segundos. Ao final do processo a qualidade média alcançada e a quantidade de fardos defenida para cada pilha.",
      },
      tabViewPiles: {
        title: "Visualizando as pilhas utilizadas",
        step1:
          "Na página de pré misturas, encontre o a pré mistura na tabela e clique em ",
        step2:
          "Na tabela serão mostradas as pilhas utilizadas na prá mistura e a respectiva quantiade de fardos à ser usada de cada uma.",
      },
      tabDelete: {
        title: "Excluindo uma pré mistura",
        step1:
          "Na página de pré misturas, encontre o a pré mistura na tabela e clique em ",
        step2:
          "Confirme a exclusão na janela de confirmação. Caso o ícone não esteja vísivel, significa que a pré mistura não pode ser excluída porque ha misturas originadas a partir da pré mistura.",
      },
    },
    laydowns: {
      modalTitle: "Ajuda - Misturas",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Mistura é a etapa final do processo de misturas no ESSENTIALbale. ",
        pageDescription2:
          "Nesta etapa são coletados os fardos de acordo com as quantidades definidas na etapa de pré mistura. O sistema então analisa a qualidade de cada fardo e escolhe os fardos que vão fazer parte da mistura de maneira que a média da mistura fique o mais próximo possível da qualidade planejada na primeira etapa do processo..",
        pageDescription3:
          "Após analisar e selecionar os fardos o ESSENTIALbale define ainda a posição de cada fardo na linha de abertura.",
        flow: "Este é o fluxo de trabalho para elaboração da mistura:",
        step1: "Nova mistura é criada.",
        step2:
          "Os fardos são coletados de acordo com a quantidade defenida na pré mistura.",
        step3:
          "A mistura é elaborada. A qualidade dos fardos coletados é analisada e o sistema escolhe os fardos adequados para a mistura.",
        step4:
          "O layout da mistura é definido. O sistema ordena os fardos selecionados para montagem da linha de abertura.",
        step5: "A mistura é finalizada.",
      },
      tabAdd: {
        title: "Criando nova mistura",
        step1:
          "Na caixa 'ID pré msitura', selecione a pré-mistura que dará origem à nova mistura.",
        step2: "Clique em 'Nova Mistura'.",
        step3:
          "Clique em 'Fardos' para ir para a página de fardos onde são feitos os próximos passos.",
      },
      tabBales: {
        title: "Incluíndo os fardos",
        description1:
          "Nesta página são incluidos os fardos na pré mistura de acordo com a quantidade definida para cada pilha na etapa de elaborção da pré mistura para que seja feita posteriormente a seleção dos fardos.",
        step1:
          "Clique em 'Incluir sobra' para incluir os fardos que foram incluídos na pré mistura anterior, mas qua não foram selecionados para a mistura anterios. Os fardos aparecerão na tabela 'Fardos adicionados à pré mistura'.",
        step2:
          "Imprima o relatório com a relação de fardos ainda necessários de cada pilha clicando em ",
        description3:
          "Esta relação deve ser repassada ao pessoal do depósito para que eles coletem a quantidade de fardos ainda necessária de cada pilha e os coloquem na área de pré mistura juntamento com os fardos que sobraram da mistura anterior. O número dos fardos coletados nas pilhas deve ser anotado.",
        step3:
          "Digite os números do fardos coletados no campo 'Número do fardo'. Também á possível ler um arquivo que contenha os fardos em forma de lista. Se for o caso, clique em 'Escolher arquivo e busque o arquivo no computador.",
        description4:
          "À medida que cada fardo é incluídos, os dados vão sendo atualizados na tela. Na tabela 'Quantidade necessária de fardos por pilha' são mostrados para cada pilha:",

        fieldDescription1:
          "Quantidade de fardos necessária definida na elaboração pré mistura.",
        fieldDescription2: "Quantidade de fardos já incluída.",
        fieldDescription3:
          "Quantidade de fardos qua ainda falta ser incluída na pré mistura.",

        description5:
          "Na tabela 'Qualidade', para cada parâmetro de qualidade são mostrados:",
        fieldDescription4: "Qualidade planejada na etapa de planejamento.",
        fieldDescription5:
          "Qualidade média dos a fardos adicionados à pré mistura.",
        fieldDescription6:
          "Valor mínimo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription7:
          "Valor máximo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription8:
          "Coeficiente de variação (CV) dos fardos adicionados à pré mistura.",

        step4:
          "Após a inclusão da quantidade total de fardos necessária será possível prosseguir para a elaboração da mistura. Clique em 'Gerar mistura'.",
      },
      tabGenerate: {
        title: "Gerando a mistura",
        description1:
          "Após a inclusão da quantidade de fardos necessária para cada pilha feita na página de inclusão de fardos, nesta página é feita a geração da mistura em si.",
        step1:
          "Clique em 'Gerar mistura' e aguarde a conclusão do processamento.",

        description2:
          "O sistema analizará a qualidade indivudual de cada fardo incluído na pré mistura e selecionará os fardos para compor a mistura.",
        description3: "O processamento leva 30 segundos.",
        description4:
          "Após a conclusão do processamento, os dados serão atualizados na página.",

        description5:
          "Na tabela 'Fardos na pré mistura' são mostrados todos os fardos incluídos na pré nistura. Os fardos que foram selecionados estarão destacados em azul.",

        description6:
          "Na tabela 'Qualidade', para cada parâmetro de qualidade são mostrados:",
        fieldDescription1:
          "Prioridade ou peso do parâmetro do parâmetro na elaboração da mistura. è definida nas configurações da linha de produção, mas pode ser alterada aqui, se necessário.",
        fieldDescription2: "Qualidade planejada na etapa de planejamento.",
        fieldDescription3:
          "Qualidade média alcançada com a seleção dos a fardos.",
        fieldDescription4:
          "Valor mínimo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription5:
          "Valor máximo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription6:
          "Coeficiente de variação (CV) dos fardos adicionados à pré mistura.",

        description7:
          "Na tabela 'Grupos controlados', são mostrados os dados do controle de uso do algodão. Pode ser dos grupos de pilhas ou tipos de algodão controlados.",
        fieldDescription7: "ID do grupo de pilhas ou tipo de algodão.",
        fieldDescription8: "Nome do grupo de pilhas ou tipo de algodão.",
        fieldDescription9:
          "Quantidade mínima de fardos esperada para na mistura.",
        fieldDescription10:
          "Quantidade máxima de fardos esperada para na mistura.",
        fieldDescription11: "Quantidade de fardos alcançada na mistura.",
        fieldDescription12: "Quantidade de fardos que restaram na pré-mistura.",

        description8:
          "Casa haja algum parâmetro de qualidade que tenha o coeficiente de variação (CV) controlado (definido nas configurações da pré mistura), os dados serão mostrados na tabela 'Controle CV'. Para cada parâmetro com CV controlado são mostrados:",
        fieldDescription13:
          "CV mínimo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription14:
          "CV máximo aceitável para o parâmetro. É calculado automaticamente pelo sistema.",
        fieldDescription15: "CV alcançado com a seleção dos fardos.",

        description9:
          "Após a conclusão do processamento da mistura, será possível serguir para o próximo passo: a definição das posições de cada fardo selecionado.",

        step2: "Volte à tela inicial de misturas, e clique em 'Layout'.",
      },
      tabLayout: {
        title: "Definindo o layout",
        description1:
          "Após a elaboração das misturas será possível definir o layout da mistura, ou seja, definir a posição de cada fardo na linha de abertura.",
        step0: "Defina a prioridade na elaboração do layout.",
        fieldDescription1:
          "(Recomendado) Selecione para que haja distribuição igual dos fardos de acordo seus respectivos produtores e origens.",
        fieldDescription2:
          "Selecione para que os fardos sejam melhor distribuídosde acordo com o produtor.",
        fieldDescription3:
          "Selecione para que os fardos sejam melhor distribuídosde acordo com a origen.",

        step1:
          "Clique em 'Gerar layout' e aguarde a conclusão do processamento.",

        description2:
          "O sistema analizará a qualidade indivudual de cada fardo selecionado para a mistura e definirá a posição de cada um deles na linha de abertura.",
        description3:
          "O processamento leva 30 segundos. Após a conclusão do processamento, os dados serão atualizados na página.",
        description5:
          "Na tabela 'Fardos selecionados' são mostrados todos os fardos selacionados para a nistura e suas respectivas posições definidas pelo sistema.",
        description6:
          "Os fardos são agrupados em sub misturas como se fossem pequenas misturas dentro da mistura geral. A quantiade de fardos em cada sub mistura é definida no cadastro de linhas de produção.",
        description7:
          "A qualidade média de cada sub mistura será a mais próxima possível da qualidade média geral da mistura.",

        description8:
          "A qualidade média geral da mistura é mostrada na tabela 'Qualidade da mistura'.",
        description9:
          "Já a qualidade de cada sub mistura é mostrada na tabela 'Qualidade das sub misturas'.",

        description10:
          "Após a elaboração do layout, a mistura estará concluída.",

        step2: "Volte à tela inicial de misturas, e clique em 'Finalizar'.",
      },
      tabFinish: {
        title: "Finalizando a mistura",
        description1:
          "Após a conclusão de todos os passos, caso a mistura não tenha nenhum alerta de qualidade, ou seja, se a média alcançada para todos os parâmetros de qualidade estiver dentro da tolerância, será possível finalizar a mistura.",
        description2:
          "Caso haja alertas de qualidade, acesse a página de alertas  para análise.",
        step1: "Clique em 'Finalizar'.",
        step2: "Confirme a finalização na janela de confirmação.",
        step3:
          "Na janela de confirmação do consumo dos fardos, informe se deseja registrar o consumo ou não.",
      },
      tabResume: {
        title: "Reabrindo a mistura",
        description1:
          "A reabertura da mistura consiste em torná-la editável novamente, ou seja é possível refazer algum dos passos. Somente a última mistura de cada linha de produção pode ser reaberta.",
        step1: "Clique em 'Reabrir'.",
        step2: "Confirme a finalização na janela de confirmação.",
      },
      tabDelete: {
        title: "Excluindo a mistura",
        description1:
          "Caso a mistura seja excluída, os fardos retornam para o estoque.",
        step1: "Clique em 'Excluir'.",
        step2: "Confirme a finalização na janela de confirmação.",
      },
      reporting: {
        balesAndQuality: {
          modalTitle: "Ajuda - relatório de fardos e qualidade da mistura",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra os fardos selecionados e o resumo de qualidade da mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        laydownCharts: {
          modalTitle: "Ajuda - Gráficos dos fardos da mistura",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a representação gráfica da qualidade dos fardos da mistura. ",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        layoutPositions: {
          modalTitle: "Ajuda - Layout de mistura",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra o posicionamento dos fardos da mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        leftoverBales: {
          modalTitle: "Ajuda - Fardos na pré mistura",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a relação de fardos que não foram selecionados para a mistura e que permanecem na área de pré mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
          tabRemove: {
            title: "Removendo fardos da pré mistura",
            pageDescription:
              "Remover um fardo da pré mistura significa devolvê-lo para a pilha de origem. Fisicamente, este fardo deve ser retirado da área de pré mistura e devolvido para a pilha de origem.",

            step1: "Selecione os fardos que deseja remover da pré mistura.",
            step2: "Clique em 'Remover fardos selecionados'.",
            step3: "Confirme a exclusão na janela de confirmação.",
          },
        },
        cottonTypeSummary: {
          modalTitle: "Ajuda - Resumo da mistura por tipo de algodão",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a representação percentual de cada tipo de algodão na mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        originSummary: {
          modalTitle: "Ajuda - Resumo da mistura por origem",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a representação percentual de cada origem na mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        pileSummary: {
          modalTitle: "Ajuda - Resumo da mistura por pilha",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a representação percentual de cada pilha na mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
        producerSummary: {
          modalTitle: "Ajuda - Resumo da mistura por produtor",
          tabOverview: {
            title: "Visão geral",
            pageDescription:
              "Este relatório mostra a representação percentual de cada produtor na mistura.",

            printText1: "Clique em ",
            printText2: " para abrir a janela de impressão.",
          },
        },
      },
    },
    consumptionControl: {
      modalTitle: "Ajuda - Controle de consumo",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Nesta página é possível definir a data de consumo das misturas. Uma mistura só passa a contar como consumida e constar nos relatórios de consumo após a confirmação do consumo.",
        pageDescription2:
          "Quando uma mistura é finalizada no sistema, o usuário pode ao mesmo tempo confirmar o consumo da mistura, ou, caso queira definiar o consumo depois, pode ser feito aqui.",
        flow: "Este é o fluxo de trabalho da página de cadastro de controle de consumo.",
      },
      tabEdit: {
        title: "Confirmando ou alterando o consumo da mistura",
        step1: "Selecione a linha de produção no campo 'Linha de produção'.",
        step2: "Selecione o status no campo 'Status'.",
        fieldDescription1:
          "Busca todas as misturas da linha de produção, inclusive aquelas cujo consumo já foi confirmado para, se for o caso, alterar a data de consumo.",
        fieldDescription2:
          "Busca as misturas da linha de produção em que a confirmação do consumo está pendente.",
        step3: "Encontre a mistura na tabela 'Misturas' e clique em ",
        step4: "Selecione a data de consumo da mistura.",
      },
    },
    warnnings: {
      modalTitle: "Ajuda - Alertas",
      tabOverview: {
        title: "Visão geral",
        pageDescription:
          "Nesta página é possivel gerenciar os alertas lançados pelo ESSENTIABale quando uma mistura apresenta algum tipo de inconsistência que requer a análise de uma gerente.",
        pageDescription2:
          "Os alertas são lançados quando um grupo com uso controlado não atinge a quantidade de fardos esperada na mistura, ou quando a média alcançada para algum parâmetro de qualidade fica fora da tolerância.",
        flow: "Este é o fluxo de trabalho da página de alertas.",
      },
      tabDetails: {
        title: "Analisando os detalhes do alerta",
        step1:
          "Encontre o alerta na tabela 'Alertas de qualidade' e clique em ",
        description1:
          "Na tabela serão mostrados os parâmetros de qualidade ou grupos com uso controlado que geraram o alerta. O responsável deve analisar se as alterações trazem algum risco para a produção. Caso seja do entendimento que não há riscos, pode-se autorizar o andamento da mistura, caso contrário o suporte técnico deve ser consultado sobre ações que podem ser tomadas para contornar o problema.",
      },
      tabAuthorize: {
        title: "Autorizando o uso das misturas",
        description1:
          "Caso seja do entendimento que as alterações não trazem riscos à produção, o uso da mistura deve ser autorizado para que a mistura seja finalizada.",
        step1:
          "Encontre o alerta na tabela 'Alertas de qualidade' e clique em ",
        step2: "Confirme a autorização na janela de confirmação.",
      },
    },
    inventoryReports: {
      quality: {
        modalTitle: "Ajuda - Relatório qualidade do estoque",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "O relatório de qualidade do estoque mostra uma visão geral do estoque em termos de qualidade e quantidade.",
          pageDescription2:
            "Além de informações do estoque total, o relatório mostra as informações do estoque disponível para cada linha de produção e o estoque de cada grupo com uso controlado.",
          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione uma linha de produção específica para mostrar os dados apenas desta linha e dos grupos com uso controlado ligados à ela.",
          legendDescription1:
            "A quantidade de fardos disponível para cada linha é calculada pelo sistema baseado nas porcentagens de uso de cada grupo controlado.",
          legendDescription2:
            "Grupos controlados que tenham poucos fardos em estoque podem interferir no cálculo do estoque disponível para cada linha de produção. O resultado é a redução na quantidade de fardos disponpiveis para a linha de produção.",
          legendDescription3:
            "Neste caso o grupo em questão será destacado em amarelo, e sua porcentagem de uso nas misturas deve ser corrigido.",
          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      bales: {
        modalTitle: "Ajuda - Relatório de fardos em estoque",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra os fardos em estoque, além do resumo de qualidade de acordo com filtros aplicados.",
          filterText: "Opções de filtro:",
          filter1: "Situação",
          filterDescription1: "Situação dos fardos no estoque. As opções são:",
          option0: "Todos",
          optionDescription0: "Todos os fardos do estoque.",
          option1: "Em uso",
          optionDescription1:
            "Fardos que estão disponíveis para uso nas misturas.",
          option2: "Disponpivel para distribuição",
          optionDescription2:
            "Fardos que estão disponíveis distribuição, ou seja que ainda não foram disponibilizados para misturas.",
          option3: "Com qualidade",
          optionDescription3: "Fardos que têm todos os valores de qualidade.",
          option4: "Sem qualidade",
          optionDescription4: "Fardos que não têm algum valor de qualidade.",
          option5: "Em espera",
          optionDescription5: "Fardos que estão em situação de espera.",
          option6: "Nas pilhas",
          optionDescription6:
            "Fardos que estão nas pilhas com exceção dos fardos que estão na pré mistura.",
          option7: "Na pré mistura",
          optionDescription7: "Fardos que estão atualmente na pré mistura",
          filter2: "Pilha",
          filterDescription2: "Pilha que contém os fardos.",
          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      general: {
        modalTitle: "Ajuda - Relatório geral de estoque",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra informações gerais do estoque além do resumo de qualidade de acordo com os filtros e opções de agrupamento aplicados.",
          filterText: "Opções de filtro:",
          filter1: "Fornecedor",
          filterDescription1:
            "Selecione para filtrar por um fornecedor específico.",
          filter2: "Produtor",
          filterDescription2:
            "Selecione para filtrar por um produtor específico.",
          filter3: "Lote",
          filterDescription3: "Selecione para filtrar por um lote específico.",
          filter4: "Pilha",
          filterDescription4:
            "Selecione para filtrar por uma pilha espefífica.",
          filter5: "Tipo de algodão",
          filterDescription5:
            "Selecione para filtrar por um tipo de algodão específico.",
          filter6: "Linha de produção",
          filterDescription6:
            "Selecione para filtrar por uma linha de produção específica.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para agrupar os resultados por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para agrupar os resultados por produtor.",
          grouping3: "Lote",
          groupingDescription3:
            "Selecione para agrupar os resultados por lote.",
          grouping4: "Pilha",
          groupingDescription4:
            "Selecione para agrupar os resultados por pilha.",
          grouping5: "Tipo de algodão",
          groupingDescription5:
            "Selecione para agrupar os resultados por tipo de algodão.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      composition: {
        modalTitle: "Ajuda - Relatório de composição do estoque",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra a composição percentual do estoque de acordo com os filtros e opções de agrupamento aplicados.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para ver a composição do estoque por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para ver a composição do estoque por produtor.",
          grouping3: "Origem",
          groupingDescription3:
            "Selecione para ver a composição do estoque por origem.",
          grouping4: "Tipo de algodão",
          groupingDescription4:
            "Selecione para ver a composição do estoque por tipo de algodão.",

          filterText: "Opções de filtro:",
          filter1: "Situação",
          filterDescription1: "Situação dos fardos no estoque. As opções são:",
          option1: "Todos",
          optionDescription1: "Todos os fardos do estoque.",
          option2: "Em espera",
          optionDescription2: "Fardos que estão em situação de espera.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      lotEntry: {
        modalTitle: "Ajuda - Relatório de entrada de lotes por período",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra a entrada de lotes por período de acordo com os filtros aplicados.",
          filterText: "Opções de filtro:",
          filter1: "Fornecedor",
          filterDescription1:
            "Selecione para filtrar por um fornecedor específico.",
          filter2: "Produtor",
          filterDescription2:
            "Selecione para filtrar por um produtor específico.",
          filter3: "Origem",
          filterDescription3:
            "Selecione para filtrar por uma origem específica.",
          filter4: "Tipo de algodão",
          filterDescription4:
            "Selecione para filtrar por um tipo de algodão específico.",
          filter5: "Período",
          filterDescription5: "Informe o período para filtrar por data.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
    },
    consumptionReports: {
      general: {
        modalTitle: "Ajuda - Relatório geral de consumo",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra informações gerais de consumo além do resumo de qualidade de acordo com os filtros e opções de agrupamento aplicados.",
          filterText: "Opções de filtro:",
          filter1: "Fornecedor",
          filterDescription1:
            "Selecione para filtrar por um fornecedor específico.",
          filter2: "Produtor",
          filterDescription2:
            "Selecione para filtrar por um produtor específico.",
          filter3: "Tipo de algodão",
          filterDescription3:
            "Selecione para filtrar por um tipo de algodão específico.",
          filter4: "Linha de produção",
          filterDescription4:
            "Selecione para filtrar por uma linha de produção específica.",
          filter5: "Período",
          filterDescription5:
            "Selecione para filtrar por um período de consumo específico.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para agrupar os resultados por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para agrupar os resultados por produtor.",
          grouping3: "Linha de produção",
          groupingDescription3:
            "Selecione para agrupar os resultados por linha de produção.",
          grouping4: "Pilha",
          groupingDescription4:
            "Selecione para agrupar os resultados por pilha.",
          grouping5: "Tipo de algodão",
          groupingDescription5:
            "Selecione para agrupar os resultados por tipo de algodão.",
          grouping6: "Mistura",
          groupingDescription6:
            "Selecione para agrupar os resultados por mistura.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      perDay: {
        modalTitle: "Ajuda - Relatório de consumo por dia",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra informações de consumo de algodão por dia de acordo com os filtros e opções de agrupamento aplicados.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para ver agrupar os resultador por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para ver agrupar os resultador por produtor.",
          grouping3: "Origem",
          groupingDescription3:
            "Selecione para ver agrupar os resultador por origem.",
          grouping4: "Tipo de algodão",
          groupingDescription4:
            "Selecione para ver agrupar os resultador por tipo de algodão.",

          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione para filtrar por uma linha de produção específica.",
          filter2: "Período",
          filterDescription2:
            "Selecione para filtrar por um período de consumo específico.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      perMonth: {
        modalTitle: "Ajuda - Relatório de consumo por mês",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra informações de consumo de algodão por mês de acordo com os filtros e opções de agrupamento aplicados.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para ver agrupar os resultador por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para ver agrupar os resultador por produtor.",
          grouping3: "Origem",
          groupingDescription3:
            "Selecione para ver agrupar os resultador por origem.",
          grouping4: "Tipo de algodão",
          groupingDescription4:
            "Selecione para ver agrupar os resultador por tipo de algodão.",

          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione para filtrar por uma linha de produção específica.",
          filter2: "Período",
          filterDescription2:
            "Selecione para filtrar por um período de consumo específico.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      perLaydown: {
        modalTitle: "Ajuda - Relatório de consumo por mistura",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra informações de consumo de algodão por mistura de acordo com os filtros aplicados.",

          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione para filtrar por uma linha de produção específica.",
          filter2: "Lote de fio",
          filterDescription2:
            "Selecione para filtrar por um lote de fio específico. O lote de fio é informado na criação da pré mistura.",
          filter3: "Período",
          filterDescription3:
            "Selecione para filtrar por um período de consumo específico.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      composition: {
        modalTitle: "Ajuda - Relatório de composição do consumo",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra a composição percentual do consumo de algodão de acordo com os filtros e opções de agrupamento aplicados.",

          groupingText: "Opções de agrupamento:",
          grouping1: "Fornecedor",
          groupingDescription1:
            "Selecione para ver a composição do estoque por fornecedor.",
          grouping2: "Produtor",
          groupingDescription2:
            "Selecione para ver a composição do estoque por produtor.",
          grouping3: "Origem",
          groupingDescription3:
            "Selecione para ver a composição do estoque por origem.",
          grouping4: "Tipo de algodão",
          groupingDescription4:
            "Selecione para ver a composição do estoque por tipo de algodão.",

          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione para filtrar por uma linha de produção específica.",
          filter2: "Período",
          filterDescription2:
            "Selecione para filtrar por um período de consumo específico.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
      avgCharts: {
        modalTitle: "Ajuda - Gráficos de médias da misturas",
        tabOverview: {
          title: "Visão geral",
          pageDescription:
            "Este relatório mostra em gráficos a evolução das médias e do coeficiente de variação (CV) de cada parâmetro de qualidade nas misturas de acordo com os filtros aplicados.",

          filterText: "Opções de filtro:",
          filter1: "Linha de produção",
          filterDescription1:
            "Selecione para filtrar por uma linha de produção específica.",
          filter2: "Lote de fio",
          filterDescription2:
            "Selecione para filtrar por um lote de fio específico. O lote de fio é informado na criação da pré mistura.",
          filter3: "Período",
          filterDescription3:
            "Selecione para filtrar por um período de consumo específico.",
          filter4: "Mostrar por",
          filterDescription4:
            "Selecione 'Média' para que os gráficos mostrem as médias de qualidade, ou 'CV' para que os gráficos mostrem o coeficiente de variação (CV) das misturas.",
          filter5: "Mostrar tolerâncias de",
          filterDescription5:
            "Selecione 'Produção' para que a tolerância mostrada seja a da produção, ou 'Lote de fio' para que a tolerância mostrada seja a do lote de fio. As tolerâncias são definidas nas configurações de qualidade da linha de produção.",

          printText1: "Clique em ",
          printText2: " para abrir a janela de impressão.",
        },
      },
    },
  },
};

export { translation };
