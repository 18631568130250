const translation = {
  backendErrors: {
    40002:
      "A solicitação tem um formato de data inválido na lista de parâmetros de consulta",
    40901: "Recurso já existe",
    42201: "Há fardos cadastrados nesta planta",
    42203: "Formato do ID da pilha é inválido",
    42204: "Há fardos cadastrados a esta pilha no estoque",
    42205: "Há fardos cadastrados a este tipo de algodão no estoque",
    42206: "Tipo de mistura está registrado em uma linha de produção",
    42207: "Há fardos registrados com essa origem no estoque",
    42208: "Há fardos registrados com esse parâmetro no estoque",
    42209: "Somente o último parâmetro registrado pode ser deletado",
    42210: "Há fardos registrados a esse produtor no estoque",
    42211: "A linha de produção está presente em uma pré-mistura existente",
    42212: "Há fardos registrado a esse fornecedor no estoque",
    42213: "Há fardos atribuídos ao lote",
    42214: "Quantidade de fardos e peso do lote não correspondem",
    42215: "Fardo {{arg1}} tem um peso inválido",
    42216: "Fardo {{arg1}} tem um parâmetro desconhecido <parameterNAme>.",
    42217: "<Parameter> valor para o fardo {{arg1}} está fora do aceitável",
    42218: "Não foi possível encontrar dados do lot para o fardo {{arg1}} .",
    42219: "Pilha {{arg1}} já faz parte do grupo {{arg2}}",
    42220: "A soma das porcentagens de uso dos grupos execede 100%",
    42221: "Parâmetro {{arg1}} não existe.",
    42222: "A lista contém parâmetro desconhecido {{arg1}}",
    42223: "Já existe um planejamento aberto para esse tipo de mistura",
    42224:
      "A soma das pocentagens de uso dos grupo ligados a linha de produção {{arg1}} exede 100%.",
    42225: "Não há invnetário para o grupo de pilha {{arg1}}.",
    42226: "Não há inventário para o tipo de algodão <cottonTypeName>.",
    42227: "Planejamento está em processo",
    42228:
      "Não é possível atualizar os dados de planejamento, a qualidade já foi planejada",
    42229: "Não há qualidade planejada para a linha de produção {{arg1}}",
    42230: "Há uma mistura aberta para a linha de produção {{arg1}}",
    42231: "Há uma mistura pendente para esta pré-mistura",
    42232:
      "Não é possível atualizar os dados de pre-mistura. A pre-mistura está em processo ou finalizada",
    42233:
      "Não é possível prosseguir com a pré-mistura. somente {{arg1}} pilhas disponíveis e {{arg2}} são necessárias",
    42234:
      "Não é possível prosseguir com a pré-mistura. O peso disponível no estoque é menor do que o peso neecessário",
    42235: "Não há fardos suficiente na pilha {{arg1}}",
    42236:
      "A soma da quantidade minima de pilhas é maior do que a quantidade de pilhas necessárias na mistura",
    42237:
      "A soma da quantidade máxima de pilhas é maior do que a quantidade de pilhas necessárias na mistura",
    42238: "Há uma mistura aberta para essa pré-mistura",
    42239:
      "Todas as misturas planejadas para essa pré-mistura estão finalizadas",
    42240: "A pré-mistura não foi preparada",
    42241: "A pilha {{arg1}} não tem fardos",
    42242: "O layout para pré-mistura {{arg1}} precisa ser definido",
    42243: "A mistura está finalizada, não é possível deletar",
    42244:
      "O sistema está configurado para não permetir que a mistura seja reaberta",
    42245: "Laydown is not the last one for the leftover code",
    42246: "A mistura não foi finalizada",
    42247:
      "A quantidade de fardos registrados na mistura é menor do que a quantidade necessária",
    42248: "A mistura tem fardos fora da posição definida",
    42249:
      "A mistura teve alerta e precisa ser autorizada pela supervisão. Por favor, vá para a página 'Alertas'",
    42250: "Fardo não existe no estoque",
    42251: "Fardo {{arg1}} está bloqueado",
    42252: "Fardo {{arg1}} está em espera",
    42253: "Fardo {{arg1}} já está em uso na mistura",
    42254: "Fardo {{arg1}} não testado ou não disponível para uso",
    42255:
      "A quantida de fardos necessárias da pilha {{arg1}} já foi alcançada",
    42256: "A pilha {{arg1}} não é necessária nessa pré-mistura",
    42257:
      "{{arg1}} valor é menor que o permetido para a linha de produção. Fardo {{arg2}} foi movido para a pilha {{arg3}}",
    42258: "{{arg1}} valor é menor que o permetido para a linha de produção",
    42259:
      "{{arg1}} valor é maior que o permetido para a linha de produção. Fardo {{arg2}} foi movido para a pilha {{arg3}}",
    42260: "{{arg1}} valor é maior que o permetido para a linha de produção",
    42261: "Fardo {{arg1}} já foi adionado a pré-mistura atual",
    42262: "Fardo {{arg1}} já foi alocado na pré-mistura {{arg2}}",
    42263: "O planajamento já foi usado",
    42264:
      "A soma das porcentagens dos tipos de algodão controlados no tipo de mistura {{arg1}} não corresponde a 100%",
    42265: "Pilha {{arg1}} não está registrada",
    42266: "Um ou mais fardos dessa transfeência já foram usados em mistura",
    42267: "Um ou mais fardos desse lote já foi usado em mistura",
    42268: "Lote {{arg1}} já foi transferido",
    42269: "Não há linhas de produção ligada ao tipo de mistura {{arg1}}",
    42270:
      "Não há lotes suficientes em estoque para o tipo de algodão {{arg1}}. Quantidade necessária: {{arg2}} - Quantidade disponível: {{arg3}}",
    42271: "Não há lotes em estoque para o tipo de mistura {{arg1}}.",
    42272:
      "Não é necessário adicionar novos lote para o tipo de mistura {{arg1}}.",
    42273: "Não foi encontrado pilhas vazias para alocar novos",
    42274:
      "Nenhuma dos tipos de mistura cadastrados está conectado a uma linha de produção",
    42275: "Lote de fio deve ser específicado",
    42276: "Production line is linked to some class",
    42277: "O valor máximo é menor que o valor mínimo",
    42278: "Parâmetro não encontrado na lista de parâmetros pré-configurados",
    42279: "Não foram encontrados tipos de algodão controlados",
    42280:
      "A posição deve ser menor ou igual a quantidade de fardos por mistura {{arg1}}",
    42281: "Não encontrado estoque para o planejamento",
    42282:
      "Há uma pré-mistura aberta para esse tipo de mistura. É necessário concluir essa pré-mistura antes",
  },
};

export { translation };
