import React, { createContext, useEffect, useState } from "react";
import axios from "../service/api/axios";
import UserService from "../service/keycloak/UserService";
import { locale } from "devextreme/localization";

export const PlantSelectedContext = createContext();

export function PlantSelectedContextProvider({ children }) {
  const [plantSelected, setPlantSelected] = useState(
    localStorage.getItem("plantID")
  );
  const [organizationSelected, setOrganiztionSelected] = useState(
    localStorage.getItem("organizationData")
  );
  const [roles] = useState(UserService._kc.realmAccess.roles);
  const [userName] = useState(UserService.getUsername);

  const [plants, setPlants] = useState([]);

  useEffect(() => {
    if (roles[0] !== "owner") {
      getOrganizationData();
      handlePlant();
      getSettings();
      getPreferences();
    }
  }, []);

  locale(localStorage.getItem("i18nextLng"));

  async function handlePlant() {
    await axios.get(`plants`).then(({ data }) => {
      setPlants(data);
    });
  }

  async function getSettings() {
    await axios
      .get(`plants/${localStorage.getItem("plantID")}/settings`)
      .then(({ data }) => {
        localStorage.setItem("settings", JSON.stringify(data));
      });
  }

  async function getPreferences() {
    await axios
      .get(`/users/${userName}/preferences`)
      .then(({ data }) => {
        if (data) {
          if (
            localStorage.getItem("i18nextLng") != data.countrySettings ||
            (localStorage.getItem("weightMeasurementUnit") !=
              data.weightMeasurementUnit &&
              data.countrySettings &&
              data.weightMeasurementUnit)
          ) {
            localStorage.setItem("i18nextLng", data.countrySettings);
            localStorage.setItem("Language", data.language);
            localStorage.setItem(
              "weightMeasurementUnit",
              data.weightMeasurementUnit
            );
            locale(data.countrySettings);
            window.location.reload();
          }
        } else if (localStorage.getItem("i18nextLng") === "undefined") {
          localStorage.setItem("i18nextLng", "en-US");
          localStorage.setItem("Language", "English");
          localStorage.setItem("weightMeasurementUnit", "Kg");
          locale("en-US");
          /* window.location.reload(); */
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  async function handleChangePlant(e) {
    await axios
      .get(`plants/${e.value}`)
      .then(({ data }) => {
        setPlantSelected(data[0]);
        localStorage.setItem("plantID", data[0].id);
        localStorage.setItem("plantName", data[0].description);
        window.location.reload();
      })
      .catch(({ response }) => {
        console.log(response.data);
      });
  }

  async function getOrganizationData() {
    await axios.get(`organizations/data`).then(({ data }) => {
      setOrganiztionSelected(data.name);
      localStorage.setItem("organizationData", data.name);
      localStorage.setItem("organizationId", data.id);
    });
  }

  return (
    <PlantSelectedContext.Provider
      value={{
        plantSelected,
        handleChangePlant,
        organizationSelected,
        roles,
        plants,
      }}
    >
      {children}
    </PlantSelectedContext.Provider>
  );
}
