import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
  useContext,
} from "react";
import TreeView from "devextreme-react/tree-view";
import { navigation } from "../../app-navigation";
import { navigationOwner } from "../../app-navigationOwner";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import RenderOnRole from "../../components/keycloak/RenderOnRole";
import UserService from "../../service/keycloak/UserService";
import "./styles.scss";
import axios from "../../service/api/axios";
import * as events from "devextreme/events";
import { PathContext } from "../../contexts/pathContext";

export default function SideNavigationMenu(props) {
  const { currentMenu } = useContext(PathContext);

  const [role] = useState(UserService._kc.realmAccess.roles);
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;
  const { isLarge } = useScreenSize();
  const [userName] = useState(UserService.getUsername);

  const treeViewRef = useRef();

  const wrapperRef = useRef();

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }
    treeView.expandItem(currentMenu);
  }, [currentMenu]);

  useEffect(() => {
    if (role[0] !== "owner") {
      getPreferences();
    }
  }, []);

  async function getPreferences() {
    await axios
      .get(`/users/${userName}/preferences`)
      .then(({ data }) => {
        localStorage.setItem("i18nextLng", data.countrySettings);
        localStorage.setItem("Language", data.language);
      })
      .catch(({}) => {});
  }

  function normalizePath() {
    return navigation.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }

      // role verification to disable menu button

      item.disabled = role.indexOf(item.role) === -1;

      if (item.items) {
        item.items.map((pos) => {
          pos.disabled = role.indexOf(pos.role) === -1;

          if (pos.path === "/register/user" && role.indexOf("manager") === -1) {
            pos.visible = false;
          }

          if (pos.items) {
            pos.items.map((pos2) => {
              pos2.disabled = role.indexOf(pos2.role) === -1;
              if (pos2.items) {
                pos2.items.map((pos3) => {
                  pos3.disabled = role.indexOf(pos3.role) === -1;
                });
              }
            });
          }
        });
      }

      return { ...item };
    });
  }

  function normalizePathOwner() {
    return navigationOwner.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const itemsOwner = useMemo(
    normalizePathOwner,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <RenderOnRole roles={["basic", "manager", "advanced"]}>
        <div className={"menu-container"}>
          <TreeView
            ref={treeViewRef}
            items={items}
            dataStructure="tree"
            keyExpr="path"
            selectionMode={"single"}
            focusStateEnabled={false}
            expandEvent={"click"}
            onItemClick={selectedItemChanged}
            onContentReady={onMenuReady}
            width={"100%"}
          />
        </div>
      </RenderOnRole>
      <RenderOnRole roles={["owner"]}>
        <div className={"menu-container"}>
          <TreeView
            ref={treeViewRef}
            items={itemsOwner}
            keyExpr={"path"}
            selectionMode={"single"}
            focusStateEnabled={false}
            expandEvent={"click"}
            onItemClick={selectedItemChanged}
            onContentReady={onMenuReady}
            width={"100%"}
          />
        </div>
      </RenderOnRole>
    </div>
  );
}
