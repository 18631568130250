import { ScrollView } from "devextreme-react";
import IconLink from "../../img/Icons/link.png";
import "./styles.scss";
import { i18n } from "../../service/i18n/i18n";

export default function Licenses() {
  return (
    <>
      <ScrollView height={500}>
        <p id="licence-text">{i18n.t("license.terms")}</p>
        <div id="license-view">
          <div className="card" id="axios">
            <h5>
              <a href="https://www.npmjs.com/package/axios">
                @axios (0.24.0) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/cross-env">
                @cross-env (7.0.3) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/devextreme">
                @devextreme (22.1.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/devextreme-cli">
                @devextreme-cli (1.2.21) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/devextreme-react">
                @devextreme-react (21.2.12) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/devextreme-themebuilder">
                @devextreme-themebuilder (22.1.6){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: unknown
            </div>

            <div></div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/dotenv">
                @dotenv (16.0.3) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: BSD-2-Clause
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@emotion/react">
                @emotion/react (11.10.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@emotion/styled">
                @emotion/styled (11.10.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/i18next">
                @i18next (22.4.10) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/i18next-browser-languagedetector">
                @i18next-browser-languagedetector (6.1.8){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/jspdf">@jspdf (2.5.1)</a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT, APAFML OR BSD-3-Clause OR MIT,
              BSD-3-Clause OR MIT{" "}
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/jspdf-autotable">
                @jspdf-autotable (3.5.28) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/keycloak-js">
                @keycloak-js (19.0.1) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0, MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/locale-codes">
                @locale-codes (1.3.1) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT, libgd-2018{" "}
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@material-ui/core">
                @material-ui/core (5.0.0-beta.5){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card" id="%40mui%2Fmaterial">
            <h5>
              <a href="https://www.npmjs.com/package/@mui/material">
                @mui/material (5.11.10) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react">@react (17.0.2)</a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-app-polyfill">
                @react-app-polyfill (1.0.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-dom">
                @react-dom (17.0.2) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-error-overlay">
                @react-error-overlay (6.0.9) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-pdf">
                @react-pdf (6.2.2) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT, Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-router-dom">
                @react-router-dom (5.3.4) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/react-scripts">
                @react-scripts (5.0.1) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/read-excel-file">
                @read-excel-file (5.6.1) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/sass">
                @sass (1.58.3) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT, BSD-2-Clause, BSD-3-Clause,
              Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/styled-components">
                @styled-components (5.3.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@testing-library/jest-dom">
                @testing-library/jest-dom (5.16.5){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@testing-library/react">
                @testing-library/react (11.2.7){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT OR WTFPL
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/@testing-library/user-event">
                @testing-library/user-event (12.8.3){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/txt-file-to-json">
                @txt-file-to-json (3.0.6) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: MIT, ISC
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/web-vitals">
                @web-vitals (1.1.2) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://www.npmjs.com/package/xlsx">
                @xlsx (0.18.5) <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-agroal">
                Quarkus - Agroal - Runtime (LATEST){" "}
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-arc">
                Quarkus - ArC - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-jdbc-postgresql">
                Quarkus - JDBC - PostgreSQL - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-junit5">
                Quarkus - Test Framework - JUnit 5 (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-logging-gelf">
                Quarkus - Logging - GELF - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-oidc">
                Quarkus - OpenID Connect Adapter - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-rest-client">
                Quarkus - REST Client - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-rest-client-jackson">
                Quarkus - REST Client - Jackson - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-resteasy">
                Quarkus - RESTEasy - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-resteasy-jsonb">
                Quarkus - RESTEasy - JSON-B - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-smallrye-health">
                Quarkus - SmallRye Health - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>

          <div className="card">
            <h5>
              <a href="https://repo1.maven.org/maven2/io/quarkus/quarkus-smallrye-metrics">
                Quarkus - SmallRye Metrics - Runtime (LATEST)
                <img src={IconLink} alt="icon" />
              </a>
            </h5>

            <div className="dependency__heading">
              {i18n.t("license.licenses")}: Apache-2.0
            </div>
          </div>
        </div>
      </ScrollView>
    </>
  );
}
