import React, { useState } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import UserPanel from "../user-panel/index";
import "./styles.scss";
import { Popup } from "devextreme-react/popup";
import { Template } from "devextreme-react/core/template";
import Logo from "../../img/Rieter_logo.svg";
import Intermed from "../../img/Icons/intermed.svg";
import Help from "../../img/Icons/help.svg";
import { useHistory } from "react-router-dom";
import TabPanel from "devextreme-react/tab-panel";
import TabOverview from "./help/TabOveview";
import TabOverflow from "./help/TabOverflow";
import TabUserLevels from "./help/TabUserLevels";
import TabUserPreferences from "./help/TabUserPreferences";
import { i18n } from "../../service/i18n/i18n";
import UserService from "../../service/keycloak/UserService";

export default function Header() {
  const history = useHistory();
  const [roles] = useState(UserService._kc.realmAccess.roles);
  function redirectToHome() {
    history.push("/home");
  }
  const [tab, setTab] = useState(0);
  const multiViewItems = [
    {
      ID: 1,
      tabName: i18n.t("userGuiding.introduction.tabOverview.title"),
    },
    {
      ID: 2,
      tabName: i18n.t("userGuiding.introduction.tabOverflow.title"),
    },
    {
      ID: 3,
      tabName: i18n.t("userGuiding.introduction.tabUserLevels.title"),
    },
    {
      ID: 4,
      tabName: i18n.t("userGuiding.introduction.tabUserPreferences.title"),
    },
  ];

  const [modal, setModal] = useState({
    visible: false,
    onHiding: hidingModal,
    title: "",
    type: "",
  });

  function hidingModal() {
    setModal({
      ...modal,
      visible: false,
    });
  }

  function showModal() {
    setModal({ ...modal, visible: true });
  }

  function itemTitleRender(item) {
    return <span>{item.tabName}</span>;
  }
  function onTabChange(e) {
    setTab(e.itemIndex);
  }

  return (
    <div className="no-print">
      <Popup
        visible={modal.visible}
        onHiding={modal.onHiding}
        dragEnabled={true}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={i18n.t("userGuiding.introduction.modalTitle")}
        container=".dx-viewport"
        width={1100}
        height={650}
      >
        <TabPanel
          items={multiViewItems}
          itemTitleRender={itemTitleRender}
          onTitleClick={onTabChange}
        ></TabPanel>
        {tab === 0 && <TabOverview />}
        {tab === 1 && <TabOverflow />}
        {tab === 2 && <TabUserLevels />}
        {tab === 3 && <TabUserPreferences />}

        <div className="modal-confimr-buttons-content">
          <div className="btn-modal-remove" onClick={hidingModal}>
            <i className="icon dx-icon-remove"></i>
          </div>
        </div>
      </Popup>

      <header className={"header-component"}>
        <Toolbar className={"header-toolbar-top"}>
          <Item location={"before"} cssClass={"header-title"}>
            <img
              width="100%"
              height="35px"
              itemProp="logo"
              id="rieter-logo"
              src={Logo}
              alt="Logo Rieter"
              onClick={redirectToHome}
            ></img>
          </Item>
          <Item
            location={"before"}
            cssClass={"header-title"}
            onClick={redirectToHome}
          >
            <div id="application-name">ESSENTIALbale</div>
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            menuItemTemplate={"userPanelTemplate"}
          >
            <div className="header-icons-container">
              <div
                className={
                  roles[0] === "owner"
                    ? "header-icons printing-content"
                    : "header-icons"
                }
              >
                <i onClick={(e) => showModal()}>
                  <img className="icon" src={Help}></img>
                </i>
              </div>

              <div className="header-icons user-button authorization">
                <i>
                  <img className="icon" src={Intermed}></img>
                  <UserPanel menuMode={"context"} />
                </i>
              </div>
              <Template name={"userPanelTemplate"}>
                <UserPanel menuMode={"list"} />
              </Template>
            </div>
          </Item>
        </Toolbar>
      </header>
    </div>
  );
}
