import { i18n } from "./service/i18n/i18n";
import Lot from "./img/Icons/lot.svg";
import Warning from "./img/Icons/Warning_error.svg";
import Reporting from "./img/Icons/reporting.svg";
import Tasks from "./img/Icons/tasks.svg";

import Info from "./img/Icons/info.svg";
import processIcon from "./img/Icons/production step.svg";

export const navigation = [
  {
    key: 1,
    text: i18n.t("sidebar.menu.registers"),
    icon: Tasks,
    role: "basic",
    path: "/register",
    items: [
      {
        text: i18n.t("sidebar.register.plant"),
        path: "/register/plant",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.register.parameters"),
        path: "/register/parameters",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.register.mixtype"),
        path: "/register/mixtype",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.register.prodline"),
        path: "/register/productionline",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.register.prodlineclasses"),
        path: "/register/productionlineclasses",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.register.producer"),
        path: "/register/producer",
        role: "basic",
      },
      {
        text: i18n.t("sidebar.register.supplier"),
        path: "/register/supplier",
        role: "basic",
      },
      {
        text: i18n.t("sidebar.register.cottontype"),
        path: "/register/cottontype",
        role: "basic",
      },
      {
        text: i18n.t("sidebar.register.pile"),
        path: "/register/pile",
        role: "basic",
      },
      {
        text: i18n.t("sidebar.register.origin"),
        path: "/register/origin",
        role: "basic",
      },
      {
        text: i18n.t("sidebar.register.hvi"),
        role: "basic",
        path: "/register/hvispreadsheet",
      },
      {
        text: i18n.t("sidebar.register.user"),
        path: "/register/user",
        role: "manager",
      },
    ],
  },
  {
    key: 2,
    text: i18n.t("sidebar.menu.inventory"),
    icon: Lot,
    role: "basic",
    path: "/inventory",
    id: 1.2,
    items: [
      {
        text: i18n.t("sidebar.inventory.cottonInput"),
        path: "/inventory/cottoninput",
        role: "basic",
      },
      {
        key: 2_2,
        text: i18n.t("sidebar.inventory.management"),
        role: "basic",
        path: "/inventory/management",
        items: [
          {
            text: "       " + i18n.t("sidebar.inventory.bales"),
            path: "/inventory/management/balemanagement",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.inventory.lots"),
            path: "/inventory/management/lotsmanagement",
            role: "basic",
          },
        ],
      },
      {
        text: i18n.t("sidebar.inventory.cottonDistribution"),
        path: "/inventory/distribution",
        role: "advanced",
      },
      {
        key: 2_4,
        text: i18n.t("sidebar.inventory.cottonUsage"),
        role: "advanced",
        path: "/inventory/cottonusagecontrol",
        items: [
          {
            text: "       " + i18n.t("sidebar.inventory.pileGroup"),
            path: "/inventory/cottonusagecontrol/pilegroups",
            role: "advanced",
          },
          {
            text: "       " + i18n.t("sidebar.inventory.cottonTypes"),
            path: "/inventory/cottonusagecontrol/cottontypes",
            role: "advanced",
          },
        ],
      },
      {
        key: 2_5,
        text: i18n.t("sidebar.inventory.testingsResults"),
        role: "basic",
        path: "/inventory/testingresults",
        items: [
          {
            text: "       " + i18n.t("sidebar.inventory.importantion"),
            path: "/inventory/testingresultsinput/importation",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.inventory.manualInputs"),
            path: "/inventory/testingresultsinput/manualinput",
            role: "basic",
          },
        ],
      },
      {
        text: i18n.t("sidebar.inventory.consultBale"),
        path: "/inventory/consultbale",
        role: "basic",
      },
    ],
  },
  {
    key: 3,
    text: i18n.t("sidebar.menu.laydown"),
    icon: processIcon,
    role: "advanced",
    path: "/laydown",
    items: [
      {
        text: i18n.t("sidebar.mixing.planning"),
        path: "/laydown/planning",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.mixing.pre"),
        path: "/laydown/prelaydown",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.mixing.laydown"),
        path: "/laydown/laydown",
        role: "advanced",
      },
      {
        text: i18n.t("sidebar.mixing.control"),
        path: "/laydown/consumptioncontrol",
        role: "manager",
      },
    ],
  },
  {
    text: i18n.t("warnings.path"),
    path: "/warnings",
    icon: Warning,
    role: "manager",
  },
  {
    key: 5,
    text: i18n.t("sidebar.menu.reports"),
    icon: Reporting,
    path: "/reports",
    role: "basic",
    items: [
      {
        key: 5_1,
        text: i18n.t("sidebar.menu.inventory"),
        role: "basic",
        path: "/reports/inventoryreports",
        items: [
          {
            text: "       " + i18n.t("sidebar.reports.quality"),
            path: "/reports/inventoryreports/quality",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.bales"),
            path: "/reports/inventoryreports/bales",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.general"),
            path: "/reports/inventoryreports/general",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.composition"),
            path: "/reports/inventoryreports/composition",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.lotsPerPeriod"),
            path: "/reports/inventoryreports/lotsenteredperperiod",
            role: "basic",
          },
        ],
      },

      {
        key: 5_2,
        text: i18n.t("sidebar.reports.consumpition"),
        role: "basic",
        path: "/reports/consumptionreports",
        items: [
          {
            text: "       " + i18n.t("sidebar.reports.generalConsumption"),
            path: "/reports/consumptionreports/general",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.perDay"),
            path: "/reports/consumptionreports/daily",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.perMonth"),
            path: "/reports/consumptionreports/monthly",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.perLaydown"),
            path: "/reports/consumptionreports/perlaydown",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.perPeriod"),
            path: "/reports/consumptionreports/compositionperperiod",
            role: "basic",
          },
          {
            text: "       " + i18n.t("sidebar.reports.avg"),
            path: "/reports/consumptionreports/avgcharts",
            role: "basic",
          },

          /* {
            
            text: i18n.t("sidebar.reports.usda"),
            path: "/reports/consumptionreports/usdarepresentation",
            role: "basic",
          }, */
        ],
      },
    ],
  },
  {
    text: i18n.t("logs.path"),
    path: "/logs",
    icon: Info,
    role: "manager",
  },
];
