import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import HttpService from "./service/keycloak/HttpService";
import UserService from "./service/keycloak/UserService";

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

UserService.initKeycloak(renderApp);
HttpService.configure();
