import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import RenderOnRole from "./components/keycloak/RenderOnRole";

export default function Content() {
  return (
    <SideNavBarLayout title={""}>
      <Switch>
        <Suspense fallback={<h1></h1>}>
          {routes.map(({ path, component }) => (
            <Route exact key={path} path={path} component={component} />
          ))}
        </Suspense>
        <RenderOnRole roles={["manager", "advanced", "basic"]}>
          <Redirect to={"/home"} />
        </RenderOnRole>
      </Switch>
    </SideNavBarLayout>
  );
}
