//general tranlations
import { translations as englishTranslation } from "./languages/en";
import { translations as portugueseTranslation } from "./languages/pt";
import { translations as spanishTranslation } from "./languages/es";

//user guide translations
import { translation as userGuideEnglishTranslation } from "./languages/userguiding/en";
import { translation as userGuidePortugueseTranslation } from "./languages/userguiding/pt";
import { translation as userGuideSpanishTranslation } from "./languages/userguiding/es";

//backenderros tranlations
import { translation as backendErrosEN } from "./languages/backendErros/en";
import { translation as backendErrorsES } from "./languages/backendErros/es";
import { translation as backendErrorsPT } from "./languages/backendErros/pt";

const messages = {
  //english
  en: {
    translations: {
      ...englishTranslation,
      ...userGuideEnglishTranslation,
      ...backendErrosEN,
    },
  },

  //portuguese
  pt: {
    translations: {
      ...portugueseTranslation,
      ...userGuidePortugueseTranslation,
      ...backendErrorsPT,
    },
  },

  //spanish
  es: {
    translations: {
      ...spanishTranslation,
      ...userGuideSpanishTranslation,
      ...backendErrorsES,
    },
  },
};

export { messages };
