import React from "react";
import Content from "./Content";
import { HashRouter as Router } from "react-router-dom";
import { NavigationProvider } from "./contexts/navigation";
import RenderOnAuthenticated from "./components/keycloak/RenderOnAuthenticated";
import RenderOnRole from "./components/keycloak/RenderOnRole";
import { Redirect } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./main-style.scss";
import { PlantSelectedContextProvider } from "./contexts/PlantSelectedContext";
import { PathContextProvider } from "./contexts/pathContext";

export default function App() {
  return (
    <RenderOnAuthenticated>
      <Router>
        <NavigationProvider>
          <PathContextProvider>
            <div id="app">
              <PlantSelectedContextProvider>
                <Content />
                <RenderOnRole roles={["owner"]}>
                  <Redirect to={"/ownermanagement"} />
                </RenderOnRole>
              </PlantSelectedContextProvider>
            </div>
          </PathContextProvider>
        </NavigationProvider>
      </Router>
    </RenderOnAuthenticated>
  );
}
