const translation = {
  backendErrors: {
    40901: "Resource already exists.",
    42201: "There are bales linked to this plant in inventory.",
    42203: "Pile ID format is invalid.",
    42204: "There are bales linked to the pile in inventory.",
    42205: "There are bales linked to the cotton type in inventory.",
    42206: "Mix type is linked to a production line.",
    42207: "There are bales linked to the origin in inventory.",
    42208: "There are bales linked to the parameter in inventory",
    42209: "Only last registered parameter can be deleted",
    42210: "There are bales linked to the producer in inventory",
    42211: "Production line is present in an existing pre-laydown.",
    42212: "There are bales linked to the supplier in inventory.",
    42213: "There are bales assigned to the lot.",
    42214: "Bales qty and lot weight do not match.",
    42215: "Bale {{arg1}} has an invalid weight.",
    42216: "Bale {{arg1}} has an unknown parameter <parameterNAme>.",
    42217: "Value for bale {{arg1}} is out of the acceptable range",
    42218: "Could not find lot data for the bale {{arg1}} .",
    42219: "Pile {{arg1}} is already part of the group <group decription>",
    42220: "The sum of the usage percentage of the groups exceeds 100%",
    42221: "Parameter {{arg1}} does not exist.",
    42222: "List contains an unknown parameter {{arg1}}",
    42223: "There is already an opened planning for the mix type",
    42224:
      "The sum of the usage percentage of the groups linked to the line {{arg1}} exceeded 100%.",
    42225: "There is no inventory for the pile group {{arg1}}.",
    42226: "There is no inventory for the cotton type <cottonTypeName>.",
    42227: "Planning is i process.",
    42228: "Can't update planning data. Quality has already been planned.",
    42229: "There is no planned quality for the production line {{arg1}}",
    42230: "There is an opened pre laydown for the production line {{arg1}}",
    42231: "There are pending laydowns linked to this pre laydown.",
    42232:
      "Can't update pre laydown data. Pre laydown is in process or finished",
    42233:
      "It is not possible to proceed with the pre-laydown. Only 10 piles are available and 20 are required.",
    42234:
      "It is not possible to proceed with the pre laydown. The weight of the available inventory is less than the minimum required weight.",
    42235: "Not enough inventory in the pile {{arg1}}",
    42236:
      "The sum of the lower range of the piles is greater than the amount of bales needed for pre laydown",
    42237:
      "The sum of the upper range of the piles is less than the amount of bales needed for pre laydown",
    42238: "There is already a open laydown for the given pre-laydown",
    42239:
      "All planned laydowns for this pre-laydown have already been finalized",
    42240: "The pre laydown has not been prepared.",
    42241: "The pile {{arg1}} does not have enough inventory.",
    42242: "The layout for pre-laydown {{arg1}} must be defined.",
    42243: "Laydown is finished. Can't delete.",
    42244: "System is configured not to allow the user to resume laydown",
    42245: "Laydown is not the last one for the leftover code",
    42246: "Laydown has not been finished",
    42247:
      "The amount of bales registered in the laydown is less than the required amount",
    42248: "Laydown has bales without a defined position.",
    42249:
      "Laydown has warnnings that need to be addressed by supervision. Please go to 'Warnnings' section.",
    42250: "Bale does not exist",
    42251: "Bale {{arg1}} is blocked",
    42252: "Bale {{arg1}} is on hold",
    42253: "Bale {{arg1}} has already been used in laydown",
    42254: "Bale {{arg1}} not tested or not available for use",
    42255: "Required bale qty from pile {{arg1}} has already been reached",
    42256: "Pile {{arg1}} is not required in this pre-laydown",
    42257:
      "{{arg1}} value is less than allowed for this production line. Bale {{arg2}} has been moved to pile {{arg3}}",
    42258: "{{arg1}} value is less than allowed for this production line.",
    42259:
      "{{arg1}} value is higher than allowed for this production line. Bale {{arg2}} has been moved to pile {{arg3}}",
    42260: "{{arg1}} value is higher than allowed for this production line.",
    42261: "Bale {{arg1}} has already been added to current pre-laydown",
    42262: "Bale {{arg1}} has already allocated in pre-laydown {{arg2}}",
    42263: "Planning has already been used in pre-laydown.",
    42264:
      "The sum of the percentages of the controlled cotton types in the mix type {{arg1}} does not match 100%",
    42265: "Pile {{arg1}} is not registered.",
    42266:
      "One or more bales linked to this transfer have already been used in laydown.",
    42267: "One or more bales in this lot have already been used in laydown.",
    42268: "Lot {{arg1}} has already been transfered",
    42269: "There is no production lines linked to the mix type {{arg1}}",
    42270:
      "There are not enough lots in inventory for the cotton type {{arg1}}. Necessary qty: {{arg2}} - Available qty: {{arg3}}",
    42271:
      "There are no available lots in inventory for the mix type {{arg1}}.",
    42272: "It is not necessary to add new lots for mix type {{arg1}}.",
    42273: "There are not enough empty piles to allocate the new lots.",
    42274: "None of the registered mix types is connected to a production line",
    42275: "Yarn lot must be specified.",
    42276: "Production line is linked to some class",
    42277: "Maximum value is lower than minimum value.",
    42278: "Parameter not found in pre configured parameters list",
    42279: "No controlled cotton types were found.",
    42280:
      "Position must be less or equal the quantity of bales per laydown {{arg1}}",
    42281: "Not enough bales in stock for planning.",
    42282:
      "There is a pre-laydown in process for the mix type. Conclude or delete pre-laydown before",
  },
};

export { translation };
