import React, { createContext, useState } from "react";

export const PathContext = createContext();

export function PathContextProvider({ children }) {
  const [path, setPath] = useState(["Home"]);
  const [currentMenu, setCurrentMenu] = useState("");
  const [menu, setMenu] = useState("closed");
  const [statusMenu, setStatusMenu] = useState();

  function handlePath(path) {
    setPath(path.reverse());
  }

  return (
    <PathContext.Provider
      value={{
        path,
        setPath,
        handlePath,
        currentMenu,
        setCurrentMenu,
        menu,
        setMenu,
        setStatusMenu,
        statusMenu,
      }}
    >
      {children}
    </PathContext.Provider>
  );
}
