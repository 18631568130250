const translation = {
  backendErrors: {
    40002:
      "La solicitud tiene un formato de cierre no válido en la lista de parámetros de consulta",
    40901: "El recurso ya existe",
    42201: "Hay pacas registrados en esta planta",
    42203: "El formato de ID de pila no es válido",
    42204: "Hay pacas registradas a esta pila en el stock",
    42205: "Hay pacas registradas para este tipo de algodón en el stock",
    42206: "El tipo de mezcla está registrado en una línea de producción",
    42207: "Hay pacas registradas con este origen en el stock",
    42208: "Hay pacas registradas con este parámetro en el stock",
    42209: "Solo se puede borrar el último parámetro registrado",
    42210: "Hay pacas registradas a nombre de este productor en el inventario",
    42211: "La línea de producción está presente en una premezcla existente",
    42212: "Hay pacas registradas a nombre de este proveedor en el inventario",
    42213: "Hay pacas asignados al lote",
    42214: "Número de pacas y peso del lote no coinciden",
    42215: "El saldo {{arg1}} tiene un peso no válido",
    42216: "paca {{arg1}} tiene un parámetro desconocido <parameterNAme>.",
    42217: "El valor de <parámetro> para paca {{arg1}} no es aceptable",
    42218: "No se pudieron encontrar los datos del lote para paca {{arg1}}",
    42219: "La pila {{arg1}} ya forma parte del grupo {{arg2}}",
    42220: "La suma de los porcentajes de uso de los grupos supera el 100%",
    42221: "El parámetro {{arg1}} no existe.",
    42222: "La lista contiene un parámetro desconocido {{arg1}}",
    42223: "Ya hay una planificación abierta para este tipo de mezclas",
    42224:
      "La suma de los porcentajes de uso de los grupos vinculados a la línea de producción {{arg1}} supera el 100%",
    42225: "No hay inventario para el grupo de pila {{arg1}}",
    42226: "No hay inventario para el tipo de algodón <cottonTypeName>",
    42227: "La planificación está en proceso",
    42228:
      "No se pueden actualizar los datos de planificación, la calidad ya se ha planificado",
    42229: "No hay calidad planeada para la línea de producción {{arg1}}",
    42230: "Hay una mezcla abierta para la línea de producción {{arg1}}",
    42231: "Hay una mezcla pendiente para esta premezcla",
    42232:
      "No se pueden actualizar los datos de la premezcla. La premezcla está en proceso o finalizada",
    42233:
      "No se puede continuar con la premezcla. Solo se necesitan {{arg1}} baterías disponibles y {{arg2}}",
    42234:
      "No es posible proceder con el premezclado. El peso disponible en stock es inferior al peso requerido",
    42235: "No hay suficientes pacas en la pila {{arg1}}",
    42236:
      "La suma de la cantidad mínima de acumulaciones es mayor que la cantidad de acumulaciones necesarias en la mezcla",
    42237:
      "La suma de la cantidad máxima de acumulaciones es mayor que la cantidad de acumulaciones necesarias en la mezcla",
    42238: "Hay una mezcla abierta para esta premezcla",
    42239: "Todas las mezclas previstas para esta premezcla están finalizadas",
    42240: "No se preparó la premezcla",
    42241: "La pila {{arg1}} no tiene pacas",
    42242: "Se necesita definir el diseño para la premezcla {{arg1}}",
    42243: "La mezcla está terminada, no se puede eliminar",
    42244:
      "El sistema está configurado para no permitir la reapertura de la mezcla",
    42245: "Laydown no es el último para el código sobrante",
    42246: "La mezcla no ha terminado",
    42247:
      "La cantidad de pacas registrados en la mezcla es menor a la requerida",
    42248: "La mezcla tiene pacas fuera de posición definida",
    42249:
      "La mezcla tiene una alerta y necesita ser autorizada por la supervisión. Por favor, vaya a la página de 'Alertas'",
    42250: "paca no existe en stock",
    42251: "paca {{arg1}} está bloqueado",
    42252: "Baldo {{arg1}} está en espera",
    42253: "Bald {{arg1}} ya está en uso en la mezcla",
    42254: "pacance {{arg1}} no probado o no disponible para su uso",
    42255: "Ya se alcanzó la cantidad requerida de pacas de la pila {{arg1}}",
    42256: "La pila {{arg1}} no es necesaria en esta premezcla",
    42257:
      "{{arg1}} el valor es inferior al permitido para la línea de producción. paca {{arg2}} se movió a la pila {{arg3}}",
    42258:
      "El valor de {{arg1}} es menor que el permitido para la línea de producción",
    42259:
      "{{arg1}} el valor es mayor que el permitido para la línea de producción. paca {{arg2}} se movió a la pila {{arg3}}",
    42260:
      "El valor de {{arg1}} es mayor que el permitido para la línea de producción",
    42261: "paca {{arg1}} ya se ha agregado a la premezcla actual",
    42262: "paca {{arg1}} ya se ha asignado en la premezcla {{arg2}}",
    42263: "Ya se usó la planificación",
    42264:
      "La suma de los porcentajes de los tipos de algodón controlados en el tipo de mezcla {{arg1}} no corresponde al 100%",
    42265: "La pila {{arg1}} no está registrada",
    42266:
      "Uno o más pacas de esta transferencia ya se han utilizado en la mezcla",
    42267: "Uno o más pacas de este lote ya se han utilizado en la mezcla",
    42268: "Lote {{arg1}} ya ha sido transferido",
    42269: "No hay líneas de producción vinculadas al tipo de mezcla {{arg1}}",
    42270:
      "No hay suficientes lotes en stock para el tipo de algodón {{arg1}}. Cantidad necesaria: {{arg2}} - Cantidad disponible: {{arg3}}",
    42271: "No hay lotes en stock para el tipo de mezcla {{arg1}}",
    42272:
      "No es necesario agregar nuevos lotes para el tipo de mezcla {{arg1}}.",
    42273: "No se encontraron pilas vacías para asignar nuevas",
    42274:
      "Ninguno de los tipos de mezcla registrados está conectado a una línea de producción",
    42275: "Se debe especificar el lote de alambre",
    42276: "La línea de producción está vinculada a alguna clase",
    42277: "El valor máximo es menor que el valor mínimo",
    42278: "Parámetro no encontrado en la lista de parámetros preconfigurados",
    42279: "No se encontraron tipos de algodón controlado",
    42280:
      "La posición debe ser menor o igual al número de pacas por mezcla {{arg1}}",
    42281: "No se ha encontrado stock para la planificación",
    42282:
      "Hay un pre mezcla en proceso para el tipo de mezcla. Concluir o eliminar el pre mezcla antes",
  },
};

export { translation };
